import React ,{useState,useEffect,useContext}from "react";
import { MultiSelectView } from "./PartnerPreferenceUtils";

import { LoginContext } from "../../../Contexts/LoginContext";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST, GET } from "../../../Services/api_services";
import config from "../../../config";
import { useSelector } from "react-redux";
const AstrologicalPreference = (props) => {
    const [raasiDropDown, setRaasiDropDown] = useState([]);
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );
    const { loginDetail, logout } = useContext(LoginContext);
    useEffect(()=>{

    },[raasiDropDown])
    useEffect(()=>{
        setRaasiDropDown(props?.edithoroinfofreshData?.raasi)
    },[props?.edithoroinfofreshData])
    const motherTongue = useSelector(
      (state) => state?.DashBoardAPI?.ProfileData?.data?.mothertongueid,
    );

    const apiCallRaasi = async (value) => {
        let { statusCode, data } = await GET(
          `${config.api.API_URL}${EndPoints.getRaasiUrl(value, userData?.mothertongueid)}`
        );
        //console.log("dropdown data ", data);
        if (statusCode === 200) {
          console.log(data.data.raasi, "raasiraasi");
          if (data.status == "Success") {
            setRaasiDropDown(data.data.raasi);
            let temp = []
            if(data.data.raasi?.length>0){
              data.data.raasi?.map((raasi, i) => {
                temp.push(raasi.id)
              })
            }
            console.log(data?.data?.raasi,'oiii');
            props.data.setFieldValue("raasi", temp);
          }

        } else if (statusCode === 401) {
          logout();
        }
      };
  return (
    <div>
        
      <MultiSelectView
        title={"Star"}
        inputID={"star"}
        inputName={"star"}
        value={props.data.values.star}
        defalutValue={[props.data.values.star]}
        onChange={(e) => {
          props.data.setFieldValue("star", Array.from(e));
          apiCallRaasi(Array.from(e))
        }}
        error={props.data.errors.star}
        options={props?.edithoroinfofreshData?.star}
      />
      <MultiSelectView
        title={"Raasi"}
        inputID={"raasi"}
        inputName={"raasi"}
        value={props.data.values.raasi}
        defalutValue={[props.data.values.raasi]}
        onChange={(e) => {
          props.data.setFieldValue("raasi", Array.from(e));
          // props.data.handleChange
        }}
        error={props.data.errors.raasi}
        options={raasiDropDown}
      />
      <MultiSelectView
        title={"Dosham"}
        placeholder="Any"
        inputID={"dosam"}
        inputName={"dosam"}
        value={props.data.values.dosam}
        defalutValue={[props.data.values.dosam]}
        onChange={(e) => {
          props.data.setFieldValue("dosam", Array.from(e));
          // props.data.handleChange
        }}
        error={props.data.errors.dosam}
        options={props?.edithoroinfofreshData?.dosham}
      />
    </div>
  );
};

export default AstrologicalPreference;
