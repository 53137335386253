import React, { useEffect, useState, useContext } from "react";
import EndPoints from "../../Constants/EndPoints";
import { GET, POST } from "../../Services/api_services";

import { LoginContext } from "../../Contexts/LoginContext"; 


import { registerStarList } from "../../Store/Home/Action";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";

import { useAnalytics } from "../../Hooks/usePageChange";
import HabitsAddComponents from "./HabitsAddComponent";
import { NavBarContext } from "../../Contexts/NavBarContext";

const HabitsBasic = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [editMyProfileData, setEditMyProfielData] = useState();
  const [editbasicinfofreshData, seteditbasicinfofresh] = useState();

  const [dashboard1Data, setDashboard1Data] = useState([]);
  const [profileCount, setProfileCount] = useState([]);
  const isV3User = localStorage.getItem("isV3User")

  useEffect(() => {
    // apicallDashboard()
    setDashboard1Data(props.dashboard1Data);
    setEditMyProfielData(props.data);
  }, [props.dashboard1Data, props.data]);

  useEffect(() => {
    setProfileCount(props.proileCount)
  },[props.proileCount])
  useEffect(() => {
    //console.log("editMyProfileData", editMyProfileData?.horoscope);
  }, [
    editMyProfileData,
    editbasicinfofreshData,
   
    dashboard1Data,
  ]);

  const dispatch = useDispatch();


  return (
    <div className="p-3">
    
          <div
          >
            <div className={`${isV3User ? "accordions  border-[#EAE6DF] bg-white" : " rounded-[9px] md:rounded-[10px] lg:rounded-[18px] border border-[#707070]"}`}>
             
                
                    <HabitsAddComponents
                      data={editMyProfileData}
                      callBackReload={props.callBackReload}
                      postData={props?.postData}
                    />
                
                
            </div>
          </div>

         
        </div>
     
  );
};

export default HabitsBasic;
