import React, { Component, useEffect, useState, useContext, useRef, memo, useCallback } from "react";
import DashboardNav from "../Components/DashboardComponents/DashboardNav";
import {
  DashboardSection,
  DashboardSection2,
  DashboardSection3,
  DashboardSection4,
  ViewAllButton,
} from "../Components/DashboardComponents/DashboardSection";
import RouteSegments, { RouteHelper, RoutePaths } from "../Routes/RouteSegment";
import { useNavigate } from "react-router";
import ExploreProfile from "../Components/DashboardComponents/ExploreProfile";
import {
  ProfileCard,
  ProfileLookingForCard,
  ResponseProfileCard,
  ResponseProfileCard2,
} from "../Components/DashboardComponents/ProfileCard";
import ProfileSection from "../Components/DashboardComponents/ProfileSection";
import { useLocation } from "react-router";
import Footer from "../Components/Footer/Footer";
import NavBar from "../Components/NavBar";
import { POST } from "../Services/api_services";
import { IP_Address } from "../Constants/IpAddress";
import endPoints from "../Constants/EndPoints";
import axios from "axios";
import Loader from "../Components/Loader";
import ImageHoroscopePopup from "../Components/Edit Profile/ImageHoroscopePopup";

//context
import { LoginContext } from "../Contexts/LoginContext";
import { NavListContext } from "../Contexts/navContext";
import PhonePopup from "../Components/DashboardComponents/PhonePopup";
import SendMessagePopup from "../Components/DashboardComponents/SendMessagePopup";
import Constants, { EditProfileMenu, loginFrom } from "../Constants/constants";
import {
  BulkRequestPopup,
  CommonPopup,
  CommonPopupWithoutHeader,
  CommonSuccessPopup,
  MessageSuccessPopup,
  UploadPhotoPopup,
  EmailAddPopup,
  PaymentWhatsAppPopup,
  CommonProfilePopup
} from "../Components/CommonPopup";
// import RouteSegments from "../Routes/RouteSegment";
import RequestPhotoHoroTrustPopup from "../Components/DashboardComponents/RequestPhotoHoroTrustPopup";
import KJbannerPopup from "../Components/DashboardComponents/KjBannerPopup";
import ViewMessageBottomNav from "../Components/ViewMessageComponents/ViewMessageBottomNav";
import CardSkeleton from "../Components/skeletonLoader/CardSkeleton";
import {CardSkeletonWithSlider, CardSkeletonWithSliderProfile } from "../Components/skeletonLoader/CardSkeletonWithSlider";
import { NavBarContext } from "../Contexts/NavBarContext";
import ParentsPhonePopup from "../Components/DashboardComponents/ParentsPhonePopup";
import config from "../config";
import { blockProfile } from "../Store/Conversation/Action";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import LoaderGif from "../Gif/loader.gif"
import SidePannelProfileSkeleton from "../Components/skeletonLoader/SidePannelProfileSkeleton";
import $ from 'jquery'
import MessageSentIcon from "../Images/message-sent.svg"
// import {Helmet} from "react-helmet";
import DownloadAppBar from "../Components/DownloadAppBar";
import { dashboardLeftPanelData, dashboardMatcheslist, dashboardProfileData } from '../Store/DashboardAPI/Action';
import { mailExtensionList } from "../Constants/constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import EndPoints from "../Constants/EndPoints";
import { getIsLoginFromAdmin } from "../Utils";



const axiosInstance = axios.create();
export default function Dashboard(props) {
  const { loginDetail, logout, setLoginUserDetail, loginUserDetail } = useContext(LoginContext);
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  //console.log(loginDetail()[0], "LoginDetail");
  const [showImagePopup, setShowImagePopup] = useState(false);
  // const [showKjBannerPopup, setshowKjBannerPopup] = useState(false);
  const [showPhonePopup, setShowPhonePopup] = useState(false);
  const [showBlockPopup, setShowBlockPopup] = useState(false);
  const [blockProfileName, setBlockProfileName] = useState("");
  const [phonenumberStatus, setPhoneNumberStatus ] = useState(false)
  const [phoneNumData, setPhoneNumData] = useState();
  const [showParentNumPopup, setShowParentNumPopup] = useState(false)
  const [showVerifiedPopup, setShowVerifiedPopup] = useState(false)
  const [showSendMessagePopup, setSendMessagePopup] = useState(false);
  const [showKjBannerPopup, setShowKjBannerPopup] = useState(false);
  const [showKjThankPopup, setShowKjThankPopup] = useState(false);
  const [showMessageSuccess, setshowMessageSuccess] = useState(false);
  const [dashboard1Data, setDashboard1Data] = useState([]);
  const [profileData, setProfileData] = useState([]);
  const [profileDataCount, setProfileDataCount] = useState([]);
  const [dashboard2Data, setDashboard2Data] = useState([]);
  const [dashboard3Data, setDashboard3Data] = useState([]);
  const [messageProfileDetails, setMessageProfileDetails] = useState();
  const [showRequestPopup, setShowRequestpopup] = useState(false);
  const [showParentRequestPopup, setShowParentRequestPopup] = useState(false)
  const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
  const [parentNumber, setParentNumber] = useState()
  const [showSendImagePopup, setShowSendImagePopup] = useState(false);
  const [loader, setLoader] = useState(false)
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [showBulkRequestPopupData, setShowBulkRequestPopupData] = useState({show:false,title:"",content:"",message:""})
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [photoRequest, setPhotoRequest] = useState()
  const [recomendedCount, setRecomendedCount] = useState([]);
  const [weeklyCount, setWeeklyCount] = useState([]);
  const [showAdminPopup, setShowAdminPopup] = useState(false)

  console.log(recomendedCount,"recomendedCount");
  const [cardPageNum, setCardPageNum] = useState({
    weeklyDataPage: 2,
    recommendedPage: 2,
    responseReceivedPage: 2,
    profilelookingforPage: 2,
    profileLookingForMePage: 2,
    mutualPage: 2,
    selectedProfilePage: 2,
    photorequestPage: 2,
    recentlyViewedPage: 1,
    photoRequest
  });
  const { navList, setNavList } = useContext(NavListContext);
  const [Weeklydata, setWeeklyData] = useState([]);

  const [RecommendedData, setRecommendedData] = useState([]);

  const [responseReceivedData, setresponseReceivedData] = useState([]);
  const [profileILookingForData, setprofileILookingForData] = useState([]);
  const [profileLoookingForMeData, setprofileLoookingForMeData] = useState([]);
  const [MutualData, setMutualData] = useState([]);

  const [selectedProfileData, setSelectedProfileData] = useState([]);
  const [photoRequestData, setphotoRequestData] = useState([]);

  const [recentlyViewedData, setrecentlyViewedData] = useState([]);
  const [addBanner, setAddBanner] = useState();
  const [banner, setBanner] = useState();

  const [addKjBanner, setAddKjBanner] = useState();


  const [messageFor, setMessageFor] = useState();
  const [isLoadingDashboard1, setLoadingDashboard1] = useState(true);
  const [isLoadingDashboard2, setLoadingDashboard2] = useState(true);
  const [isLoadingDashboard3, setLoadingDashboard3] = useState(true);
  const [isLoadingProfile, setisLoadingProfile] = useState(true);
  const [isRecentlyLoading, setIsRecentlyLoading] = useState(true)

  const [showProfileStatusPopup, setShowProfileStatusPopup] = useState(false)
  const [ProfileStatusMessage, setProfileStatusMessage] = useState("")
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);
  const [onLoad, setOnLoad] = useState(false)
  const [apiCalled, setApiCalled] = useState(false)
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [EmailError, setEmailError] = useState(null);
  const [domainError, setDomainError] = useState(null)
  const [showError, setshowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recentlyViewedDataCount, setrecentlyViewedDataCount] = useState([]);

  const [from, setFrom] = useState("");
  const [isClose, setIsClose] = useState(false);
  const [whatsappPopup, setWhatsappPopup] = useState(false);
  const [whatsAppSuccessPopup, setwhatsAppSuccessPopup] = useState(false);

  useEffect(() => {

    if(profileData?.isOfflinePackFilled == true){
      setIsClose(true);
      setWhatsappPopup(true);
    }
    setwhatsAppSuccessPopup()

  }, [profileData?.isOfflinePackFilled,location])  

  useEffect(() => {
    
  },[])

  useEffect(() => {    
    apicallDashboard(2);
   
    apicallRecentlyViewed();
    setNavList()
    setNavList(["Matches"])
    setMenuSelect("1")
    if(searchParams.get("muhuratPopup")){
      setShowKjBannerPopup(true)
      navigate(RouteSegments.DASHBOARD,{replace:true})
    }
    if(searchParams.get("parentsnumberpopup")){
      setShowParentNumPopup(true)
      navigate(RouteSegments.DASHBOARD,{replace:true})
    }

    if (searchParams.get("whatsappverified")) {
      setShowVerifiedPopup(true)
      navigate(RouteSegments.DASHBOARD, { replace: true })
    }
    //
  }, []);
  
  useEffect(() => {
    console.log("dashboard1Data?.addbanner data", dashboard1Data?.popupbannerWeb?.showphotobanner);
    console.log(dashboard1Data,"dashboard1Datadashboard1Data");
  }, [
    dashboard1Data,
    dashboard2Data,
    dashboard3Data,
    Weeklydata,
    RecommendedData,
    responseReceivedData,
    profileILookingForData,
    profileLoookingForMeData,
    MutualData,
    selectedProfileData,
    photoRequestData,
    recentlyViewedData,
    addBanner,
    phoneNumData,
    messageFor,
    addKjBanner
  ]);
  const apiLogin = async () => {
    let loginRequest = {
      email: loginDetail()[0],
      password: "123123123",
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      ioskey: "",
      androidkey: "",
    };

    let response = await POST(
      `${config.api.API_URL}${endPoints.loginUrl()}`,
      loginRequest
    );
    // //console.log("cookiee", response);
  };
  const apicallDashboard = async (pagenum,category) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      pageno: `${pagenum}`,
      status: "new",
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${pagenum==2 || pagenum==3 ? endPoints.dashboardUrl() :endPoints.getdashboardcounts()}`,
      request
    );

    if (statusCode === 200) {
      if (pagenum === 1) {      
        if(data?.data?.popup[0].showpopup == "RejectedProfile"){
          navigate(RouteSegments.EDITPROFILE)
        }
        setLoginUserDetail({
          ...loginUserDetail,
          userMail: data?.data.usermailid,
          userPhoneNumner: data?.data.userphoneno,
          userCountryCode: data?.data.usercountrycode

        })
        setOnLoad(true)   

        setDashboard1Data(data?.data);
        
        // //console.log("response data", data.data.data.addbanner);
        setDashboardData(data?.data, pagenum);
        setAddBanner(data?.data?.bannerWeb);
        setBanner(data?.data?.addbanner[0]?.showphotobanner)
        setAddKjBanner(data?.data.addbanner[0]?.kjbanner);
        setisLoadingProfile(false)
        setLoader(true)
        // //console.log(data?.data?.data.addBanner?.kjbanner, "add")

      } else if (pagenum === 2) {
        // //console.log("responsedata2", data.data);
        setDashboard2Data(data?.data);
        setDashboardData(data?.data, pagenum);
      } else {
        //console.log("responsedata3", data?.data?.data);

        setDashboard3Data(data?.data);
        setDashboardData(data?.data, pagenum);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  
  const apicallProfileData = async (pagenum,count) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      pageno: `${pagenum}`,
      status: "new",
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${pagenum==1 && count == "count" ? endPoints.getdashboardcounts(): endPoints.getProfileData()}`,
      request
    );

    if (statusCode === 200) {   
      if(Object.values(data?.data)?.length >0){
        if(count != "count"){
        // console.log(data?.data,'data?.data.usermailid');
        localStorage.setItem(Constants.loginLocalStorageKeys.profileId, data?.data.profileId)
        localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, data?.data.status)
        localStorage.setItem(Constants.loginLocalStorageKeys.loginEmail, data?.data.usermailid)
        localStorage.setItem(Constants.loginLocalStorageKeys.loginUserName, data?.data.username)
        localStorage.setItem(Constants.loginLocalStorageKeys.loginPhone, data?.data.userphoneno)
        localStorage.setItem(Constants.loginLocalStorageKeys.loginCountryCode, data?.data.usercountrycode)
        localStorage.setItem(Constants.loginLocalStorageKeys.loginPhone, data?.data.userphoneno)
        }
        setLoginUserDetail({
          ...loginUserDetail,
          userMail: data?.data.usermailid,
          userPhoneNumner: data?.data.userphoneno,
          userCountryCode: data?.data.usercountrycode

        })
        setOnLoad(true)  
        if(count=='count'){
          setProfileDataCount(data?.data)
        } else{
          setProfileData(data?.data);       
        }                  
        setAddBanner(data?.data?.bannerWeb);
        setBanner(data?.data?.addbanner[0]?.showphotobanner)
        setAddKjBanner(data?.data.addbanner[0]?.kjbanner);
        setisLoadingProfile(false)
        setLoader(true)  
      }    
    } else if (statusCode === 401) {
      logout();
    }
  };

  console.log(profileData, "profileData");



  const setDashboardData = (responseData, pagenum) => {
    if (pagenum === 1) {
      setWeeklyData(responseData.Weeklymatches);
      setRecommendedData(responseData.recommendationsmatches);
      setLoadingDashboard1(false);
      //console.log("response set data", responseData);
    } else if (pagenum === 2) {
      setresponseReceivedData(responseData.response_received);
      setprofileILookingForData(responseData.Profileslookingfor);
      setprofileLoookingForMeData(responseData.Profileslookingforme);
      setLoadingDashboard2(false);
      //console.log("response set data 2", responseData);
    } else {
      setMutualData(responseData.Mutalmatches);
      setSelectedProfileData(responseData.Selectedprofiles);
      setphotoRequestData(responseData.PhotoRequestReceived);
      setLoadingDashboard3(false);
      //console.log("response set data 3", responseData);
    }

  };

  console.log(photoRequestData,"hotoRequestDatahotoRequestData");

  const apicallRecentlyViewed = async () => {
  
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      recentpageno: `${cardPageNum.recentlyViewedPage}`,
      recentsize: "5",
      status: "new",
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.recentlyViewUrl()}`,
      request
    );

    if (statusCode === 200) {
      setrecentlyViewedData(data.data.recently_viewed??[]);
      setrecentlyViewedDataCount(data?.data?.recentViewCount)
      setIsRecentlyLoading(false)
      cardPageNum.recentlyViewedPage = cardPageNum.recentlyViewedPage + 1;
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apiCallLoadMore = async (url, request, loadMoreFor) => {
    // //console.log("weekly matches ", cardPageNum);
    let { statusCode, data } = await POST(`${url}`, request);
    //console.log("statusCode ", statusCode);
    if (statusCode === 200) {
      if (loadMoreFor == "WEEKLYMATCH") {
        if (data.data.Weeklymatches.length > 0) {
          cardPageNum.weeklyDataPage = cardPageNum.weeklyDataPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.Weeklymatches.map((e, i) => {
            setWeeklyData((oldValue) => [...oldValue, e]);
          });
          setLoadingDashboard1(false);
          // setWeeklyData([...Weeklydata, data.data.Weeklymatches]);
        }
      } else if (loadMoreFor == "RECOMMENDEDMATCH") {
        if (data.data.recommendationsmatches.length > 0) {
          cardPageNum.recommendedPage = cardPageNum.recommendedPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.recommendationsmatches.map((e, i) => {
            setRecommendedData((oldValue) => [...oldValue, e]);
          });
          setLoadingDashboard1(false);
          // setRecommendedData([
          //   ...RecommendedData,
          //   data.data.recommendationsmatches,
          // ]);
        }
      } else if (loadMoreFor == "MUTUALMATCHES") {
        if (data.data.Mutalmatches.length > 0) {
          cardPageNum.mutualPage = cardPageNum.mutualPage + 1;
          data.data.Mutalmatches.map((e, i) => {
            setMutualData((oldValue) => [...oldValue, e]);
          });
          setLoadingDashboard3(false);
          // setMutualData([...MutualData, data.data.Mutalmatches]);
        }
      } else if (loadMoreFor == "SELECTEDPROFILE") {
        if (data.data.Selectedprofiles.length > 0) {
          cardPageNum.selectedProfilePage = cardPageNum.selectedProfilePage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.Selectedprofiles.map((e, i) => {
            setSelectedProfileData((oldValue) => [...oldValue, e]);
          });
          setLoadingDashboard3(false);
        }
      } else if (loadMoreFor == "RESPONSERECEIVED") {
        if (data.data.response_received.length > 0) {
          cardPageNum.responseReceivedPage =
            cardPageNum.responseReceivedPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.response_received.map((e, i) => {
            setresponseReceivedData((oldValue) => [...oldValue, e]);
          });
          setLoadingDashboard2(false);
          // setresponseReceivedData([
          //   ...responseReceivedData,
          //   data.data.response_received,
          // ]);
        }
      } else if (loadMoreFor == "PROFILELOOKINGFOR") {
        if (data.data.Profileslookingfor.length > 0) {
          cardPageNum.profilelookingforPage =
            cardPageNum.profilelookingforPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.Profileslookingfor.map((e, i) => {
            setprofileILookingForData((oldValue) => [...oldValue, e]);
          });
          setLoadingDashboard2(false);

          // setprofileILookingForData([
          //   ...profileILookingForData,
          //   data.data.Profileslookingfor,
          // ]);
        }
      } else if (loadMoreFor == "PROFILELOOKINGFORME") {
        if (data.data.Profileslookingforme.length > 0) {
          cardPageNum.profileLookingForMePage =
            cardPageNum.profileLookingForMePage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.Profileslookingforme.map((e, i) => {
            setprofileLoookingForMeData((oldValue) => [...oldValue, e]);
          });
          setLoadingDashboard2(false);
          // setprofileLoookingForMeData([
          //   ...profileLoookingForMeData,
          //   data.data.Profileslookingforme,
          // ]);
        }
      } else if (loadMoreFor == "PHOTOREQUESTRECEIVED") {
        if (data.data.PhotoRequestReceived.length > 0) {
          cardPageNum.photorequestPage = cardPageNum.photorequestPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.PhotoRequestReceived.map((e, i) => {
            setphotoRequestData((oldValue) => [...oldValue, e]);
          });
          setLoadingDashboard3(false);
          // setphotoRequestData([
          //   ...photoRequestData,
          //   data.data.PhotoRequestReceived,
          // ]);
        }
      } else if (loadMoreFor === "RECENTLYVIEW") {
        if (data.data.recently_viewed.length > 0) {
          cardPageNum.recentlyViewedPage = cardPageNum.recentlyViewedPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.recently_viewed?.map((e, i) => {
            setrecentlyViewedData((oldValue) => [...oldValue, e]);
          });
          setLoadingDashboard1(false);
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const callbackLoadMore = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      weeklypageno: `${cardPageNum.weeklyDataPage}`,
      weeklypagesize: "5",
      status: "new",
      

    };
    var url = `${config.api.API_URL}${endPoints.weeklyMatchesURl()}`;
    var loadMoreFor = "WEEKLYMATCH";
    apiCallLoadMore(url, request, loadMoreFor);
  };
  const callbackLoadMoreRecommended = async (values) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      recomendationpageno: `${cardPageNum.recommendedPage}`,
      recomendationpagesize: "5",
      status: "new",
     
      
    };
    var url = `${config.api.API_URL}${endPoints.recommendedMatchesUrl()}`;
    var loadMoreFor = "RECOMMENDEDMATCH";
    apiCallLoadMore(url, request, loadMoreFor);
  };

  const callbackLoadMoreMutual = () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      mutalmatchespageno: `${cardPageNum.mutualPage}`,
      mutalmatchespagesize: "5",
      status: "new",
    };
    var url = `${config.api.API_URL}${endPoints.mutalMatchesUrl()}`;
    var loadMoreFor = "MUTUALMATCHES";
    apiCallLoadMore(url, request, loadMoreFor);
  };
  const callbackLoadMoreSelectedProfile = () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      selectedprofilespageno: `${cardPageNum.selectedProfilePage}`,
      selectedprofilepagesize: "5",
      status: "new",
    };
    var url = `${config.api.API_URL}${endPoints.selectedProfilesUrl()}`;
    var loadMoreFor = "SELECTEDPROFILE";
    apiCallLoadMore(url, request, loadMoreFor);
  };
  const callbackLoadMoreResponseReceived = () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      response_receivedpageno: `${cardPageNum.responseReceivedPage}`,
      response_receivedpagesize: "5",
      status: "new",
    };
    var url = `${config.api.API_URL}${endPoints.responseReceivedUrl()}`;
    var loadMoreFor = "RESPONSERECEIVED";
    apiCallLoadMore(url, request, loadMoreFor);
  };
  const callbackLoadMoreProfileILookingFor = () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      profileslookingforpageno: `${cardPageNum.profilelookingforPage}`,
      profileslookingforpagesize: "5",
      status: "new",
    };
    var url = `${config.api.API_URL}${endPoints.profilesLookingForUrl()}`;
    var loadMoreFor = "PROFILELOOKINGFOR";
    apiCallLoadMore(url, request, loadMoreFor);
  };
  const callbackLoadMorePofileLoookingForMe = () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      profileslookingformepageno: `${cardPageNum.profileLookingForMePage}`,
      profileslookingformepagesize: "5",
      status: "new",
    };
    var url = `${config.api.API_URL}${endPoints.profilesLookingFormeUrl()}`;
    var loadMoreFor = "PROFILELOOKINGFORME";
    apiCallLoadMore(url, request, loadMoreFor);
  };
  const callbackLoadMorePhotoRequest = () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      photorequestreceivedpageno: `${cardPageNum.photorequestPage}`,
      photorequestreceivedpagesize: "5",
      status: "new",
    };
    var url = `${config.api.API_URL}${endPoints.photoRequestReceivedUrl()}`;
    var loadMoreFor = "PHOTOREQUESTRECEIVED";
    apiCallLoadMore(url, request, loadMoreFor);
  };
  const callbackLoadMoreRecentlyView = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      recentpageno: `${cardPageNum.recentlyViewedPage}`,
      recentsize: "5",
      status: "new",
    };
    var url = `${config.api.API_URL}${endPoints.recentlyViewUrl()}`;
    var loadMoreFor = "RECENTLYVIEW";
    apiCallLoadMore(url, request, loadMoreFor);
  };

  const callBackBlockProfile = async (profile) => {
    setBlockProfileName(profile)
    setShowBlockPopup(true)
  }

  const callBackViewPhone = async (profileId,profile) => {
    setMessageProfileDetails(profile);

    // api/viewphonenumber
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      phoneViewFrom: loginFrom,
      toProfileId: `${profileId}`,
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.viewPhoneUrl()}`,
      request
    );

    if (statusCode === 200) {
      //console.log("view phone", data);
      if (data.status === "Success") {
        setPhoneNumData(data.data);
        setShowPhonePopup(true);
        // apicallDashboard(1)
        // setPhoneNumberStatus(true)
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
// //console.log(phonenumberStatus,"phonen01");
  const callbackSelectReject = (
    btntype,
    profileID,
    typeRequest,
    profileDetails,
    index,
    type
  ) => {


    // alert(btntype);
    // return false;

    //console.log("next reint", btntype, profileID, typeRequest);
    if (btntype === "SELECT") {
      apicallSelectReject(typeRequest, profileID).then((res) => {
        //console.log("SELECT", res);
      });
    } else if (btntype === "REJECT") {
      apicallSelectReject(typeRequest, profileID).then((res) => {
        //console.log("REJECT", res, type);
        if (res.status === "Success") {
          if (typeRequest === "Reject") {
            // handleReject(profileID, index, type);
          }
        }
      });
    } else if (btntype === "MESSAGE") {
      if (getIsLoginFromAdmin()) {
        setShowAdminPopup(true)
        return false;
      }
      setMessageProfileDetails(profileDetails);
      setSendMessagePopup(true);
    } else {


    }
  };

    const handleReject = (id, index, type) => {
    console.log("messageFor", type);

    if (type === "weekly") {
      var newValue = Weeklydata.filter((e) => e.profileid !== id);
      setWeeklyData(newValue);
    } else if (type === "recommend") {
      var newValue = RecommendedData.filter((e) => e.profileid !== id);
      setRecommendedData(newValue);
    } else if (type === "lookingfor") {
      var newValue = profileILookingForData.filter((e) => e.profileid !== id);
      setprofileILookingForData(newValue);
    } else if (type === "lookingforme") {
      var newValue = profileLoookingForMeData.filter((e) => e.profileid !== id);
      setprofileLoookingForMeData(newValue);
    } else if (type === "mutual") {
      var newValue = MutualData.filter((e) => e.profileid !== id);
      setMutualData(newValue);
    } else if (type === "selected") {
      var newValue = selectedProfileData.filter((e) => e.profileid !== id);
      setSelectedProfileData(newValue);
    }
  };

  const apicallSelectReject = async (type, profileID) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      type: `${type}`,
      toProfileId: `${profileID}`,
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.selectRejectUrl()}`,
      request
    );

    if (statusCode === 200) {
      return data;
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apicallMessage = async (profileID, values) => {
    const notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)
    sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      message: values.message,
      status: "SendMessage",
      sendsms: values.sendSms,
      messageFrom: "Desktop",
      requestparentcheck: values.requestparentcheck ? values.requestparentcheck === "Y" ? true : false : undefined,
      requestphotocheck: values.requestphotocheck ? values.requestphotocheck === "Y" ? true : false : undefined,
      "triggerType": "singletrigger",
      bulktrigger: true,
      notificationId: notificationId ?? undefined,
    };



    let { statusCode, data
    } = await POST(
      `${config.api.API_URL}${endPoints.sendMessageUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.status === "Success") {
        setShowBulkRequestPopupData({ show: true, 
          title: "Message Sent", 
          content: "Message sent successfully!",
          message: data?.data?.bulkrequestcontent,
          imageSrc:MessageSentIcon
        })

        updateRequestedData(request.requestphotocheck === true,request.requestparentcheck === true,profileID)
      }
      return data;
    } else if (statusCode === 401) {
      logout();
    }

    return data;
  };

   


  const handleRequestPhoto = () => {

    if (getIsLoginFromAdmin()) {
      setShowAdminPopup(true)
      return false;
    }
    
    if (dashboard1Data?.status === "pending") {

      // setShowUploadImagePopup(true);
      
      setShowRequestpopup(true);
      
      
    }
    else {
      setShowRequestpopup(true);

    }
  };

    const handleRequestParent = () => {
    //console.log(dashboard1Data,"dashboard1Datadashboard1Data");
    if (getIsLoginFromAdmin()) {
      setShowAdminPopup(true)
      return false;
    }
    if (dashboard1Data?.status === "pending") {

      // setShowUploadImagePopup(true);
      
      setShowParentRequestPopup(true);
      
      
    }
    else {
      setShowParentRequestPopup(true);

    }
  };

  const handleUploadRequestPhoto = () => {

    // setShowUploadImagePopup(true);
    setShowSendImagePopup(true)

  }

  const updateData = (data,isPhoto,isParentNumber,profileId)=>{
    let temp = {...data}

    if(isPhoto && temp.profileid == profileId){
      temp = {...temp,profilerequestcheck: true }
    }

    if(isParentNumber && temp.profileid == profileId){
      temp = {...temp,parentnumberrequestcheck: true }
    }

    return temp;
  }

  const updateRequestedData = (isPhotoRequested, isParentsNumberRequested, profileID) => {
    if (messageFor === "weekly") {

      var newValue = Weeklydata.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID))
      setWeeklyData(newValue);

    }
    else if (messageFor === "recommend") {

      var newValue = RecommendedData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)
      )
      setRecommendedData(newValue);

    } else if (messageFor === "response") {

      var newValue = responseReceivedData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)
      )
      setresponseReceivedData(newValue);

    } else if (messageFor === "lookingfor") {

      var newValue = profileILookingForData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)
      )
      setprofileILookingForData(newValue);

    } else if (messageFor === "lookingforme") {

      var newValue = profileLoookingForMeData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)
      )
      setprofileLoookingForMeData(newValue);

    } else if (messageFor === "photorequest") {

      var newValue = photoRequestData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)
      )
      setphotoRequestData(newValue);

    }
    else if (messageFor === "mutual") {

      var newValue = MutualData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)
      )
      setMutualData(newValue);

    } else if (messageFor === "selected") {
      // alert("selected1")
      var newValue = selectedProfileData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)
      )
      setSelectedProfileData(newValue);

    } else if (messageFor === "recent") {

      var newValue = recentlyViewedData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)
      )
      setrecentlyViewedData(newValue);

    }
    else if (parentNumber === "selected") {
      // alert("selected")
      var newvalue = selectedProfileData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID));
      setSelectedProfileData(newvalue);

    }
    else if (parentNumber === "weekly") {
      var newvalue = Weeklydata.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID));
      setWeeklyData(newvalue);
    } else if (parentNumber === "recommend") {
      var newvalue = RecommendedData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID));
      setRecommendedData(newvalue);
    } else if (parentNumber === "lookingfor") {
      var newvalue = profileILookingForData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID));
      setprofileILookingForData(newvalue);
    } else if (parentNumber === "lookingforme") {
      var newvalue = profileLoookingForMeData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID));
      setprofileLoookingForMeData(newvalue);
    } else if (parentNumber === "mutual") {
      var newvalue = MutualData.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID));
      setMutualData(newvalue);
    }

    console.log("update-request",isPhotoRequested, isParentsNumberRequested, profileID);
  }

  const apicallMultiRequest = async (profileID, value, type,bulktrigger=true) => {
    if(value.typeList?.length === 0){
      return;
    }
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      sendsms: value.sendSms,
      "typeList": value.typeList,
      "triggerType": "singletrigger",
    };

    if(bulktrigger === true){
      request.bulktrigger=true;
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.requestPhotoHoroTrustParent()}`,
      request
    );
    console.log("apicallMultiRequest",data);

    const isPhotoRequested = data?.data?.photoRequest === "Y"
    const isParentsNumberRequested = data?.data?.parentsnumberRequest === "Y"

    if (statusCode === 200) {

      if (isPhotoRequested && isParentsNumberRequested) {
        if (data?.data?.recomm_count > 0 && request.bulktrigger===true) {
          setShowBulkRequestPopupData({
            show: true,
            title: "Photo and Parents Number Request sent!",
            content: "Photo and Parents Number Request sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Photo and Parents Number Request sent!");
          setshowSuccessPopup(true);
        }
        updateRequestedData(isPhotoRequested, isParentsNumberRequested, profileID)
      } else if (isParentsNumberRequested) {
        if ( data?.data?.recomm_count > 0 && request.bulktrigger===true) {
          setShowBulkRequestPopupData({
            show: true,
            title: "Parent No. Request sent",
            content: "Parents No. Request sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Parents Number Request sent!");
          setshowSuccessPopup(true);
        }
        updateRequestedData(isPhotoRequested, isParentsNumberRequested, profileID)
      } else if (isPhotoRequested) {
        if ( data?.data?.recomm_count > 0 && request.bulktrigger===true) {
          setShowBulkRequestPopupData({
            show: true,
            title: "Photo Request sent!",
            content: "Photo Request sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Photo Request sent!");
          setshowSuccessPopup(true);
        }
        updateRequestedData(isPhotoRequested, isParentsNumberRequested, profileID)
      }


      return data;
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apicallRequestPhoto = async (profileID, type) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      type: type,
      sendsms: "Y",
      bulktrigger: true,
    };


    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.requestphotoUrl()}`,
      request
    );

    if (statusCode === 200) {
      
        if (data?.data?.recomm_count > 0) {
          setShowBulkRequestPopupData({ show: true, 
            title: "Parent No. Request sent", 
            content: "Parents No. Request Sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Parents Number Request sent!");
          setshowSuccessPopup(true);
        }
     
       
      updateRequestedData(false,true,profileID)      
      setShowRegisterPopup(true)
      return data;
    } else if (statusCode === 401) {
      logout();
    }
  };
  
 

  const apicallHoroTrustRequest = async (profileID, value, type) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      // type: type,
      sendsms: value.sendSms,
      "typeList": value.typeList,
      "triggerType": "singletrigger",
      bulktrigger: true,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.requestPhotoHoroTrustParent()}`,
      request
    );

    const isPhotoRequested = value.typeList?.includes('Photo')
    const isParentsNumberRequested = value.typeList?.includes('Parents Number')

    if (statusCode === 200) {

      if (type === "photo") {
        if (data?.data?.recomm_count > 0) {
          setShowBulkRequestPopupData({ show: true, 
            title: "Photo Request Sent", 
            content: "Photo Request Sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Photo Request sent!");
          setshowSuccessPopup(true);
        }
      } else {
        setsuccessMessage("Parents Number Request sent!");
        setshowSuccessPopup(true);
      }
     
       
      updateRequestedData(isPhotoRequested,isParentsNumberRequested,profileID)
     
    //  profilerequestcheck
    
      setPhotoRequest(data?.data.photoRequest)
      // apicallDashboard(1);
      
      setShowRegisterPopup(true)
      return data;
    } else if (statusCode === 401) {
      logout();
    }
  };
  console.log(photoRequest,"photorequest1");

 const handleSetParentNumber = (id, type) => {
    console.log("type", type);
    if (type === "weekly") {
      var newvalue = Weeklydata.map((e) => {
        if (e.profileid === id) {
          return { ...e, parentnumberrequestcheck: true };
        }
        return e;
      });
      setWeeklyData(newvalue);
       setsuccessMessage("Parent Number Request sent!");
    } else if (type === "recommend") {
      // alert("hi")
      var newvalue = RecommendedData.map((e) => {
        if (e.profileid === id) {
          return { ...e, parentnumberrequestcheck: true };
        }
        return e;
      });
      setRecommendedData(newvalue);
    } else if (type === "lookingfor") {
      var newvalue = profileILookingForData.map((e) => {
        if (e.profileid === id) {
          return { ...e, parentnumberrequestcheck: true };
        }
        return e;
      });
      setprofileILookingForData(newvalue);
    } else if (type === "lookingforme") {
      var newvalue = profileLoookingForMeData.map((e) => {
        if (e.profileid === id) {
          return { ...e, parentnumberrequestcheck: true };
        }
        return e;
      });
      setprofileLoookingForMeData(newvalue);
    } else if (type === "mutual") {
      var newvalue = MutualData.map((e) => {
        if (e.profileid === id) {
          return { ...e, parentnumberrequestcheck: true };
        }
        return e;
      });
      setMutualData(newvalue);
    } else if (type === "selected") {
    //  alert("hi")
      var newvalue = selectedProfileData.map((e) => {
        if (e.profileid === id) {
           
          return { ...e, parentnumberrequestcheck: true };
        }
        return e;
      });
      setSelectedProfileData(newvalue);
      setsuccessMessage("Parents Number Request sent!");
    }
    // console.log(selectedProfileData,"type2");
  };

  const handleSetMessage = (id, type,sentMessageResponse) => {
    console.log("type", type);
    if (type === "weekly") {
      setWeeklyData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y", messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "recommend") {
     
      setRecommendedData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y", messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "lookingfor") {
     
      setprofileILookingForData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y", messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "lookingforme") {
      
      setprofileLoookingForMeData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "mutual") {
     
      setMutualData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "selected") {
     
   
      setSelectedProfileData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    }
    // console.log(selectedProfileData,"type2");
  };
  const closeModel = () => {
    setShowKjBannerPopup(false)
    setShowKjThankPopup(false)
  }

   const text = "The Profile Match rates us high on compatibility.If you are interested in moving forward please connect  with us at 1211211480 or nanciya.c@praniontech.com";
   const parts = text.split('If');
   const result = parts.join('\nIf');
 
   const parentref = useRef(null);

  //  useEffect(() => {
  //   if(isLoadingDashboard1 && isLoadingDashboard2 && isLoadingDashboard3){

  //     const container = parentref.current;
  //     // Get all child elements within the container
  //     const childElements = Array.from(container.children);
  //     // Apply the CSS class to visible items
  //     const visibleItems = childElements.filter(
  //       (element) => element.offsetParent !== null && !element.classList.contains('banner') && !element.classList.contains('explore')
  //     );
  //     visibleItems.forEach((element,index) => {
  //       if(index%2 === 0){
  //         element.classList.add('bg-white', 'md:bg-white', 'lg:bg-white' ,'xl:bg-white');  
  //       }else {
  //         element.classList.add('bg-[#E9E9E9]', 'md:bg-white', 'lg:bg-white', 'xl:bg-white');
  //       }
  //     });
  //   }
  //  }, [isLoadingDashboard1,isLoadingDashboard2,isLoadingDashboard3]);

  const bannerRedirect = (name, redirect) => {
    if(name == "imageupload"){
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } })
    }
    else if(name == "parentnumber"){
      setShowParentNumPopup(true)
    }
    else if(name == "managepartner"){
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath), { state: { tab: "2" } })
    }
    else if(name == "goldscheme"){
      window.open("https://www.kalyanmatrimony.com/gold-saving-scheme-online?sentFrom=Banner",'_blank')
    }
    else{
      window.open(redirect,'_blank')
    }
  }


  const blockProfileApiCall = (status, receiverId) => {
    dispatch(blockProfile(status, receiverId))
  }

  const closePopup = () => {
    if(blockProfileName?.profileblockedByMe == "Y"){
      blockProfileApiCall(0,blockProfileName?.profileid)
    window.location.href=RouteSegments.DASHBOARD
    // localStorage.setItem("BLOCKED_STATUS",0)
    }
    else window.location.href=RouteSegments.DASHBOARD
  }

  const bannerRedirection = (data) => {
    if(data?.name == "app"){
      window.open(RouteSegments.staticLinkForAppDownload.android,'_blank')
    }
    else if(data?.name == "membership"){
      setMenuSelect("6")
      navigate(RouteSegments.MEMBERSHIP_REGISTER())
    }
    else{
      setMenuSelect("4")
      if(data?.data?.photo){
        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
      }
      else if(data?.data?.horo){
        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[4].urlPath), { state: { tab: "5" } });
      }
      else if(data?.data?.trust){
        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[3].urlPath), { state: { tab: "4" } });
      }
    }
  }

  const closePopupForMsgSent = () => {
    setShowBlockPopup(false)
  }

  const closeBulkRequestPopup = ()=>{
    setShowBulkRequestPopupData({show:false,content:"",message:"",title:""})
  }

  const apiCallBulkRequest = async () => {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      "typeList": [
        "Photo",
        "Parents Number"
      ],
      "triggerType": "bulk"
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.bulkrequest()}`,
      request
    );

    if (statusCode === 200) {
      closeBulkRequestPopup()
      setsuccessMessage("Request Sent Successfully");
      setshowSuccessPopup(true);
    }

  }

  const onBulkRequestSend = (status)=>{
    if(status){
      apiCallBulkRequest()
    }else{
      closeBulkRequestPopup()
    }
  }
  const containerRef = useRef(null);

  useEffect(() => {
    const handleObserver = (entries) => {
      if (entries[0].isIntersecting && !apiCalled) {
        apicallDashboard(3); // Call your API here
        setApiCalled(true); // Set apiCalled to true after the call is made
      }
    };

    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [apiCalled]);



  useEffect(() => {
    dispatch(dashboardMatcheslist())
    dispatch(dashboardLeftPanelData())
    dispatch(dashboardProfileData())
  },[])


    //matches List API Call and Response
  const { matchesListData } = useSelector(state => ({
    matchesListData: state.DashBoardAPI?.matchesListData?.data
  }));


    //Left Panel API Call and Response  
    const { leftPanelData } = useSelector(state => ({
      leftPanelData: state.DashBoardAPI?.leftPanelData?.data
    }));

     //Profile Data API Call and Response  
     const { ProfileData } = useSelector(state => ({
      ProfileData: state.DashBoardAPI?.ProfileData?.data
    }));


    useEffect(()=>{
      
      if(matchesListData){                  
          setOnLoad(true)   
          setDashboard1Data(matchesListData);
          setDashboardData(matchesListData, 1);          
          setisLoadingProfile(false)
          setLoader(true)
        }
        if(leftPanelData){
          setProfileDataCount(leftPanelData)
        }
        if(ProfileData){
          if(ProfileData?.popup[0]?.showpopup == "RejectedProfile"){
            navigate(RouteSegments?.EDITPROFILE_REJECT, { state: { isFrom :'dashboard' } });
          }  

          setAddBanner(ProfileData?.bannerWeb);
          setBanner(ProfileData?.addbanner[0]?.showphotobanner)
          setAddKjBanner(ProfileData.addbanner[0]?.kjbanner);

          localStorage.setItem(Constants.loginLocalStorageKeys.profileId, ProfileData?.profileId)
          localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, ProfileData?.status)
          localStorage.setItem(Constants.loginLocalStorageKeys.loginEmail, ProfileData?.usermailid ?? "")
          localStorage.setItem(Constants.loginLocalStorageKeys.loginUserName, ProfileData?.username)
          localStorage.setItem(Constants.loginLocalStorageKeys.loginPhone, ProfileData?.userphoneno)
          localStorage.setItem(Constants.loginLocalStorageKeys.loginCountryCode, ProfileData?.usercountrycode)
          localStorage.setItem(Constants.loginLocalStorageKeys.loginPhone, ProfileData?.userphoneno)

          setLoginUserDetail({
            ...loginUserDetail,
            userMail: ProfileData.usermailid,
            userPhoneNumner: ProfileData.userphoneno,
            userCountryCode: ProfileData.usercountrycode

          })
          setProfileData(ProfileData); 
        }

    },[matchesListData,ProfileData,leftPanelData])

    // console.log(profileData,'ProfileDataCountnms');
  
    // console.log(leftPanelData,"leftPanelData------");
    // console.log(matchesListData,"matchesListData------");
    // console.log(ProfileData,"ProfileData------");

    const hasPopupBeenShown = localStorage.getItem('popupShown');

    useEffect(() => {
      if(ProfileData && !hasPopupBeenShown){
          if(ProfileData?.isEmailAvailable == false){
            setShowEmailPopup(true);
            localStorage.setItem('popupShown', true);
          }
        
      }
    },[ProfileData])
//     useEffect(() => {
    
//       if(ProfileData?.isEmailAvailable == false){
//         setShowEmailPopup(true);
      
//       if(ProfileData?.isEmailAvailable == true){
//         setShowEmailPopup(false);
//       }
//   }
// },[ProfileData])

    const formik = useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: Yup.object().shape({
        email: Yup.string().email("Enter a Valid e-mail ID").required("Please Enter a Valid e-mail ID"),

      }),
      onSubmit: (values) => {
        if(showError == false && domainError == null){
          setIsLoading(true)
          EmailFormSubmission(values);
        }
      },
    });

    const EmailFormSubmission = async (values) => {
     
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.saveEmailUrl()}`,
        values
      );
        if (data.data.status === "Success") {
          setIsLoading(false)
          if(data.data.data.statusMessage == "Please Enter EmailId"){

            setEmailError(data.data.data.statusMessage);
            setshowError(true);

          }else if(data.data.data.status === "Failure"){
            if(data.data.data.statusMessage == "Email Already Exist"){
                setEmailError(data.data.data.statusMessage);
                setshowError(true);
            }
          }else{
            setsuccessMessage("Email Saved Successfully");
            setShowEmailPopup(false);
            setshowSuccessPopup(true);
          }
        }
    };


    const validateEmail = (value) => {
      let inputExtension = value.toLowerCase().split("@")
      if (!mailExtensionList.includes(inputExtension[1])) {
        if(formik.errors.email){
          setDomainError(null)
        }else{
          setDomainError("Enter a valid domain")
        }
      } else {
        setDomainError(null)
      }
    }
    
 
    console.log(ProfileData?.popupbannerWeb?.showphotobanner,'console.log(ProfileData?.popupbannerWeb?.showphotobanner)')
  return (
    <div>
            {/* <InjectScript script={scriptToInject} /> */}
            {/* <Helmet>
                {scriptToInject}
            </Helmet> */}
      <div>
        <NavBar data={leftPanelData} ProfileData={ProfileData}/>
        <main>
        {onLoad ? 
        ProfileData?.popupbannerWeb?.showphotobanner &&
        (
        <CommonPopupWithoutHeader close={() => setOnLoad(false)} >
          <img className="w-[100%]" src={ProfileData?.popupbannerWeb?.showphotobanner} onClick={()=>bannerRedirection(ProfileData?.popupbannerWeb)} alt=""/>
        </CommonPopupWithoutHeader>
        )
        :(
          <></>
        )
        }
          <div className="overflow-x-hidden pb-16 md:pb-0">
            {showPhonePopup ? (
              <PhonePopup
                phoneNumData={phoneNumData}
                close={() => setShowPhonePopup(false)}
                title={"View Phone number"}
                profileData={messageProfileDetails}
                onSubmit={(profileID, value, type)=>apicallMultiRequest(profileID, value, type,false)}
              />
            ) : (
              <></>
            )}
             {showAdminPopup && <CommonProfilePopup
        close={() => setShowAdminPopup(false)}
        title={"Alert!"}
        content={"You cannot send message/request from Admin"} />
      }

          {showBlockPopup ? (
            <CommonPopup title="Profile Status" close={() => setShowBlockPopup(false)} menu="profileStatus">
              <p>{blockProfileName.profilename + " Profile Blocked by you. Do you want to unblock now?"}</p>
              <div className='flex justify-between'>
              <button className='text-[#D10A11] ml-[10rem]' onClick={closePopup}>Yes</button>
              <button className='text-[#D10A11] mr-[1rem]' onClick={closePopupForMsgSent}>No</button>
              </div>
            </CommonPopup>
          ) : (
            <></>
          )}

            {showParentNumPopup  ?
              <ParentsPhonePopup close={() => setShowParentNumPopup(false)}
                title={"Parent's Number"}
                callBackApply={() => { }}
                parentsIsdCode={dashboard1Data?.parentsIsdCode}
                parentsMobileNumber={dashboard1Data?.parentsMobileNumber}
              />
              :""
            }

            {showVerifiedPopup && <CommonSuccessPopup
              close={() => {
                setShowVerifiedPopup(false)
              }}
              title={"Success!"}
              content={"Whatsapp Number Verified Successfully"}
            />}

            {showSendMessagePopup ? (
              <SendMessagePopup
                 close={() => setSendMessagePopup(false)}
                onButtonCallback={(profileID, value) => { 
                  apicallMessage(profileID, value).then((res) => {
                    // console.log("callbackmessage", profileID);
                    if (res?.status === "Success") {
                      handleSetMessage(profileID, messageFor,res.data);
                    }
                  });
                }}
              title={"Send Message"}
              dashboard1Data={profileData}
              profileData={messageProfileDetails}
              />
            ) : (
              <></>
            )}

            {showKjBannerPopup ? (
              <KJbannerPopup
                closeModel={closeModel}
                setShowKjThankPopup={setShowKjThankPopup}
                setShowKjBannerPopup={setShowKjBannerPopup}
                title={"Send Message"}
                dashboard1Data={dashboard1Data}
                profileData={messageProfileDetails}
              />
            ) : (
              <></>
            )}

            {showMessageSuccess ? (
              <MessageSuccessPopup
                close={() => setshowMessageSuccess(false)}
                title={"Message Sent"}
                dashboard1Data={dashboard1Data}
                callback={() => {
                  //redirection to gallery
                }}
              />
            ) : (
              <></>
            )}
            {showUploadImagePopup ? (
              <UploadPhotoPopup
                close={() => setShowUploadImagePopup(false)}
                title={"Upload Photo"}
                callBack={() => {
                  setShowRequestpopup(true);
                }}
              />
            ) : (
              <></> 
            )}

            {showSendImagePopup? (
              <UploadPhotoPopup
                close={() => setShowSendImagePopup(false)}
                title={"Upload Photo"}
                callBack={() => {

                  setsuccessMessage("Image uploaded succesfully")
                  setshowSuccessPopup(true)

                }}
                isFrom ={"sendphoto"}
              />
            ) : (
              <></>
            )}
            {
              showBulkRequestPopupData.show && (
                <BulkRequestPopup 
                close={closeBulkRequestPopup} 
                onSend={onBulkRequestSend}
                content={showBulkRequestPopupData.content}
                message={showBulkRequestPopupData?.message}
                imageSrc={showBulkRequestPopupData.imageSrc}
                title={showBulkRequestPopupData.title}/>
              )
            }

            {showEmailPopup &&
            <EmailAddPopup
              title="Add Your Email" 
              close={() => {
                setShowEmailPopup(false)
              }} 
              menu="">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="off"
                    value={formik.values.email}
                    onChange={(e)=>{
                      formik.handleChange(e)
                      validateEmail(e.target.value);
                      setshowError(false);
                    }}
                    placeholder="Please Enter Your Email"
                    className="form-input shadow-sm  text-[#495057] text-[13px] md:text-[13px] focus:outline-none rounded-[8px] focus:ring-[#2D2C6F]  py-[8px] px-2 md:py-2 md:px-2  w-full block border border-[#707070]"
                  />
                  {domainError && (<div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                      Enter a valid domain
                    </div>)
                  }
                  <div className="text-left text-[red] text-[12px] md:h-[0.8rem] h-[1rem] ">
                    {showError ?
                      EmailError
                      :
                      formik.errors.email && formik.touched.email && (
                        formik.errors.email
                      )
                      }{" "}
                  </div>
                  
                  <div className="space-y-2 mt-[1rem]">
                      <div className="flex justify-between mt-5">
                        <label className="relative top-[6px] ">

                          <button
                            onClick={()=>{

                              setShowEmailPopup(false)
                             } }
                            className="w-full flex px-[4rem] justify-center text-left py-2 border border-[#707070] rounded-[5px] shadow-sm text-[14px] text-[#575556] font-semibold  hover:bg-[#D10A11] hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                          >
                            Skip
                          </button>
                        </label>
                        <label className="relative top-[6px] ">

                          <button
                            type="submit"
                            onClick={(e)=>{formik.handleSubmit(e)}}
                            className="w-full flex justify-center px-[4rem] text-left py-2 border border-transparent rounded-[5px] shadow-sm text-[14px] text-[#FFFFFF] font-semibold bg-[#D10A11] hover:bg-[#D10A11]  focus:outline-none focus:ring-2 focus:ring-offset-2 "
                          >
                            Submit
                          </button>
                        </label>
                      </div>
                    </div>
                  
            </EmailAddPopup>}

            {isLoading &&  (
        <Loader
          className="loaderTransperancy"
          loaderClassName="loaderTransperancySpin"
        />
      )}

    {whatsappPopup && 
          <PaymentWhatsAppPopup
              heading={"Transaction Successful !"}
              title={"Add Phone Number !"}
              close={() => setWhatsappPopup(false)}
              setFrom={setFrom}
              isClose={isClose}
              setwhatsAppSuccessPopup={setwhatsAppSuccessPopup}
              isFrom={"Match"}
          />
      }
      
      {whatsAppSuccessPopup && (
          <CommonSuccessPopup
            close={() => {
              setwhatsAppSuccessPopup(false)
            }}
            title={"Success!"}
            content={"WhatsApp Number Saved Successfully"}
          />
        )}

            {showSuccessPopup && (
              <CommonSuccessPopup
                close={() => setshowSuccessPopup(false)}
                title={"Success!"}
                content={successMessage}
              />
            )}
            {showRequestPopup && (
              <RequestPhotoHoroTrustPopup
                close={() => setShowRequestpopup(false)}
                onButtonCallback={(profileID, value) => {
                  apicallHoroTrustRequest(profileID, value, "photo");
                }}
                title={"Request Photo"}
                profileData={messageProfileDetails}
              />
            )}
            {showParentRequestPopup && (
              <RequestPhotoHoroTrustPopup
                close={() => setShowParentRequestPopup(false)}
                onButtonCallback={(profileID, value) => {
                  // apicallHoroTrustRequest(profileID, value, "Parents Number");
                  apicallRequestPhoto(profileID,"Parents Number")
                    
                }}
                title={"Parents Number Request"}
                profileData={messageProfileDetails}
              />
            )}
            {showProfileStatusPopup && (
            <CommonPopup title="Profile Status" close={()=>setShowProfileStatusPopup(false)} menu="profileStatus">
              <p>{ProfileStatusMessage}</p>
            </CommonPopup>
          )
          }
           <DashboardNav data={profileData} profileDataCount={profileDataCount}  onSubmitSuccess={(newPhone, newIsd) => { 
                  console.log("newPhone, newIsd",newPhone, newIsd);
                  setProfileData(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))
                }}/>
            {/* <DashboardNav data={profileData} profileDataCount={profileDataCount}  /> */}
            <div className="grid grid-cols-2 md:grid-cols-10 ">
              <div className="col-span-7 md:col-span-6 lg:col-span-7 py-1 md:py-2 lg:py-2 mx-2 md:ml-2 lg:ml-2" ref={parentref}>
                
                 {/* Profile I am Looking for  */}

                 {isLoadingDashboard2 &&  
                  <div className=" section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1]  md:py-1 md:px-3 lg:px-7 lg:py-3  my-4" >
                    <div className="flex justify-between lg:mx-2 md:mx-2 mx-0 ">
                      <CardSkeletonWithSliderProfile cards={3} menu={props?.menu}/>
                    </div>
                  </div>}
                {profileILookingForData.length > 0 && (
                  <>
                    {" "}
                    <div className="hidden md:block">
                      <DashboardSection2
                        // title={"Profiles I am looking for"}
                        title={"Profiles Matching the Partner Expectation"}
                        data={profileILookingForData}
                        count={dashboard2Data?.profileslookingforCount}
                        callLoadMore={callbackLoadMoreProfileILookingFor}
                        ////callBackBlockProfile={callBackBlockProfile}
                        callbackSelectReject={(
                          btntype,
                          profileID,
                          typeRequest,
                          profileDetails,
                          index
                        ) => {
                          callbackSelectReject(
                            btntype,
                            profileID,
                            typeRequest,
                            profileDetails,
                            index, "lookingfor"
                          );
                          setMessageFor("lookingfor");
                        }}
                        
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("lookingfor")
                          callBackViewPhone(profileId,value)
                        }}
                        viewProfileStatusPopup={(value) => {
                          setProfileStatusMessage(value);
                          setShowProfileStatusPopup(true)
                        }}
                        ViewAllCallBack={() => {
                          props.viewAllCallback()
                          navigate(RoutePaths.PROFILELOOKINGFOR, {
                            state: { isfrom: "lookingfor" },
                          });
                          // setNavList([...navList, "Profile Looking For"])
                          setNavList([...navList, "Profiles Matching the Partner Expectation"])
                          // viewallselection("response");
                        }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("lookingfor")
                       
                    }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestParent()
                      setParentNumber("lookingfor");
                    }}
                      />
                    </div>
                    <div className="lg:hidden md:hidden pt-5">
                      <DashboardSection
                        // title={"Profiles I am looking for"}
                        title={"Profiles Matching the Partner Expectation"}
                        count={dashboard2Data?.profileslookingforCount}
                        // photoRequest={photoRequest}
                        data={profileILookingForData}
                        ////callBackBlockProfile={callBackBlockProfile}
                        callLoadMore={callbackLoadMoreProfileILookingFor}
                        callbackSelectReject={(
                          btntype,
                          profileID,
                          typeRequest,
                          profileDetails,
                          index
                        ) => {
                          callbackSelectReject(
                            btntype,
                            profileID,
                            typeRequest,
                            profileDetails,
                            index, "lookingfor"
                          );
                          setMessageFor("lookingfor");
                        }}
                         
                        ViewAllCallBack={() => {
                          props.viewAllCallback()
                          navigate(RoutePaths.PROFILELOOKINGFOR, {
                            state: { isfrom: "lookingfor" },
                          });
                          // setNavList([...navList, "Profile Looking For"])
                          setNavList([...navList, "Profiles Matching the Partner Expectation"])
                          // viewallselection("response");
                        }}
                          callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("lookingfor")
                       
                    }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestParent()
                     setParentNumber("lookingfor");
                    }}
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("lookingfor")
                          callBackViewPhone(profileId,value)
                        }}
                      />
                    </div>
                  </>
                )}

                {/* Recommended Matches */}

                {isLoadingDashboard1 &&  
                  <div className=" section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1]  md:py-1 md:px-3 lg:px-7 lg:py-3  my-4" >
                    <div className="flex justify-between lg:mx-2 md:mx-2 mx-0 ">
                      <CardSkeletonWithSlider cards={4} menu={props?.menu}/>
                    </div>
                  </div>}
                {RecommendedData?.length > 0 && (
                  <div className="">
                    <DashboardSection 
                    
                     count={matchesListData?.recommendationCount}
                      title={"Recommend Matches"}
                      data={RecommendedData}
                      callLoadMore={callbackLoadMoreRecommended}
                      callbackSelectReject={(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index
                      ) => {
                        callbackSelectReject(
                          btntype,
                          profileID,
                          typeRequest,
                          profileDetails,
                          index, "recommend"
                        );
                        setMessageFor("recommend");
                      }}
                    
                      menu="recommended"
                      viewProfileStatusPopup={(value) => {
                        setProfileStatusMessage(value);
                        setShowProfileStatusPopup(true)
                      }}
                      ViewAllCallBack={() => {
                        // // viewallselection("recommended");
                        props.viewAllCallback()
                        navigate(RoutePaths.RECOMMENDED, {
                          state: { isfrom: "recommended" },
                        });
                        setNavList([...navList, "Recommended"])
                      }}
                      callbackRequestPhoto={(value) => {
                        setMessageProfileDetails(value);
                        handleRequestPhoto();
                         setMessageFor("recommend");
                      }}
                      callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestParent()
                      setParentNumber("recommend");
                    }}
                      callBackViewPhone={(profileId,value)=>{
                        setMessageFor("recommend")
                        callBackViewPhone(profileId,value)
                      }}
                      callBackBlockProfile={callBackBlockProfile}
                    />
                  </div>
                )}

                <div className=" md:hidden py-3 banner">
                  {addKjBanner === "Y" ?
                              <img
                                className="cursor-pointer"
                                alt=""
                                src={banner}
                                closeModel={closeModel}
                                onClick={() => {
                                  setShowKjBannerPopup(true);
                                }} />
                              :
                              <img
                                className="cursor-pointer"
                                alt=""
                                src={banner}

                                onClick={() => {
                                  // setShowKjBannerPopup(true);
                                  navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
                                }} />
                              }
                </div>
                  {isLoadingDashboard2 &&  
                  <div className=" section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1]  md:py-1 md:px-3 lg:px-7 lg:py-3  my-4" >
                    <div className="flex justify-between lg:mx-2 md:mx-2 mx-0 ">
                      <CardSkeletonWithSlider cards={4} menu={props?.menu}/>
                    </div>
                  </div>}
                {responseReceivedData.length > 0 && (
                  <DashboardSection3
                  count={dashboard2Data?.responseReceivedCount}
                    title={"Response Received"} 
                    data={responseReceivedData}
                    callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index, "response"
                      );
                      setMessageFor("response");
                    }}
                    callLoadMore={callbackLoadMoreResponseReceived}
                    isViewmore={true}
                    viewProfileStatusPopup={(value) => {
                      setProfileStatusMessage(value);
                      setShowProfileStatusPopup(true)
                    }}
                    ViewAllCallBack={() => {
                      props.viewAllCallback()
                      navigate(RoutePaths.ResponseReceived, {
                        state: { isfrom: "response" },
                      });
                      setNavList([...navList, "Response Received"])
                      // viewallselection("response");
                    }}
                     
                      callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("response")
                       
                    }}
                    
                    callBackChatnow={() => { }}
                    ////callBackBlockProfile={callBackBlockProfile}
                    callBackViewMore={(value) => {
                      //console.log("viewmore", value);
                      //redirect to concersation screen
                    }}
                  />
                )}

                {/* Weekly Matches */}

                {isLoadingDashboard1 &&  
                  <div className=" section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1]  md:py-1 md:px-3 lg:px-7 lg:py-3  my-4" >
                    <div className="flex justify-between lg:mx-2 md:mx-2 mx-0 ">
                      <CardSkeleton cards={3} menu={"selectedprofileslider"}/>
                    </div>
                  </div>
                }
                  {Weeklydata?.length > 0 && (
                  <DashboardSection
                  count={matchesListData?.weeklymatchesCount}
                    title={"Weekly Matches"} 
                    data={Weeklydata} 
                    // phonenumberstatus={phonenumberStatus}
                    callLoadMore={callbackLoadMore}
                    callBackBlockProfile={callBackBlockProfile}
                    callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index, "weekly"
                      );
                      setMessageFor("weekly");
                    }}
                    dashboard1Data={dashboard1Data}
                    menu="weekly"
                    viewProfileStatusPopup={(value) => {
                      setProfileStatusMessage(value);
                      setShowProfileStatusPopup(true)
                    }}
                    callbackRequestPhoto={(value) => {
                        setMessageProfileDetails(value);
                        handleRequestPhoto();
                         setMessageFor("weekly");
                      }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestParent()
                      setParentNumber("weekly");
                    }}
                    ViewAllCallBack={() => {
                      // // viewallselection("weekly");
                      props.viewAllCallback()
                      navigate(RoutePaths.WEEKLYVIEWALL, {
                        state: { isfrom: "weekly" },
                      });
                      setNavList([...navList, "Weekly Matches"])
                      //console.log("viewmore");

                    }}
                    // photoRequest={photoRequest}
                    
                    // photoRequest={photoRequest}
                    
                    callBackViewPhone={(profileId,value)=>{
                      setMessageFor("weekly")
                      callBackViewPhone(profileId,value)
                    }}
                  />
                )}


                {isLoadingDashboard2 &&  
                  <div className=" section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1]  md:py-1 md:px-3 lg:px-7 lg:py-3  my-4" >
                    <div className="flex justify-between lg:mx-2 md:mx-2 mx-0 ">
                      <CardSkeletonWithSlider cards={4} menu={props?.menu}/>
                    </div>
                  </div>}
                {profileLoookingForMeData.length > 0 && (
                  <>
                    <div className="hidden md:block">
                      <DashboardSection2
                        title={"Profiles looking for me"}
                        data={profileLoookingForMeData}
                        count={dashboard2Data?.profileslookingformeCount}
                        ////callBackBlockProfile={callBackBlockProfile}
                        callLoadMore={callbackLoadMorePofileLoookingForMe}
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("lookingforme")
                          callBackViewPhone(profileId,value)
                        }}
                        callbackSelectReject={(
                          btntype,
                          profileID,
                          typeRequest,
                          profileDetails,
                          index
                        ) => {
                          callbackSelectReject(
                            btntype,
                            profileID,
                            typeRequest,
                            profileDetails,
                            index, "lookingforme"
                          );
                          setMessageFor("lookingforme");
                        }}
                         
                        viewProfileStatusPopup={(value) => {
                          setProfileStatusMessage(value);
                          setShowProfileStatusPopup(true)
                        }}
                        ViewAllCallBack={() => {
                          props.viewAllCallback()
                          navigate(RoutePaths.PROFILELOOKINGFORME, {
                            state: { isfrom: "lookingforme" },
                          });
                          setNavList([...navList, "Profile Looking For Me"])
                          // viewallselection("response");
                        }}
                          callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("lookingforme")
                       
                    }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestParent()
                      setParentNumber("lookingforme");
                    }}
                      />
                    </div>
                    <div className="lg:hidden md:hidden">
                      <DashboardSection
                        title={"Profiles looking for me"}
                        count={dashboard2Data?.profileslookingformeCount}
                         
                        data={profileLoookingForMeData}
                        //callBackBlockProfile={callBackBlockProfile}
                        callLoadMore={callbackLoadMorePofileLoookingForMe}
                        callbackSelectReject={(
                          btntype,
                          profileID,
                          typeRequest,
                          profileDetails,
                          index
                        ) => {
                          callbackSelectReject(
                            btntype,
                            profileID,
                            typeRequest,
                            profileDetails,
                            index, "lookingforme"
                          );
                          setMessageFor("lookingforme");
                        }}
                          
                        ViewAllCallBack={() => {
                          props.viewAllCallback()
                          navigate(RoutePaths.PROFILELOOKINGFORME, {
                            state: { isfrom: "lookingforme" },
                          });
                          setNavList([...navList, "Profile Looking For Me"])
                          // viewallselection("response");
                        }}
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("lookingforme")
                          callBackViewPhone(profileId,value)
                        }}
                         callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("lookingforme")
                       
                    }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestParent()
                      setParentNumber("lookingforme");
                    }}
                      />
                    </div>
                  </>
                )}
              <div ref={containerRef}>
                {
                  isLoadingDashboard3 &&  
                  <div className=" section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1]  md:py-1 md:px-3 lg:px-7 lg:py-3  my-4" >
                    <div className="flex justify-between lg:mx-2 md:mx-2 mx-0 ">
                      <CardSkeletonWithSlider cards={4} menu={props?.menu}/>
                    </div>
                  </div>}
                {photoRequestData.length > 0 && (
                  <DashboardSection3
                  count={dashboard3Data?.photoRequestReceivedCount}
                    title={"Photo Request Received"} 
                    data={photoRequestData}
                    callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index, "photorequest"
                      );
                      setMessageFor("photorequest");
                    }}
                    callLoadMore={callbackLoadMorePhotoRequest}
                    isViewmore={false} 
                    ViewAllCallBack={() => {
                      props.viewAllCallback()
                      navigate(RoutePaths.PHOTOREQUESTRECEIVED, {
                        state: { isfrom: "photorequest" },
                      });
                      setNavList([...navList, "Photo Request"])
                      // viewallselection("response");
                    }}
                    callBackChatnow={() => { }}
                    viewProfileStatusPopup={(value) => {
                      setProfileStatusMessage(value);
                      setShowProfileStatusPopup(true)
                    }}
                    //callBackBlockProfile={callBackBlockProfile}
                    callBackViewMore={(value) => {
                      navigate(RoutePaths.INBOX, {
                        state: { menu: "REQUESTRECEIVED", subMenu: 1 },
                      });
                      //console.log("viewmore", value);
                    }}
                      
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("photorequest")
                       
                    }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestParent()
                      setParentNumber("photorequest");
                    }}
                    callbackUploadRequestPhoto={(value) => {
                      // setMessageProfileDetails(value);
                      handleUploadRequestPhoto();
                      
                    }}
                  />
                )}
                <div className=" md:block explore">
                  <ExploreProfile data="data"/>
                </div>               
                {
                  isLoadingDashboard3 &&  
                  <div className=" section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1]  md:py-1 md:px-3 lg:px-7 lg:py-3  my-4" >
                    <div className="flex justify-between lg:mx-2 md:mx-2 mx-0 ">
                      <CardSkeletonWithSlider cards={4} menu={props?.menu}/>
                    </div>
                  </div>}
                {MutualData.length > 0 && (
                  <DashboardSection
                    title={"Mutual Matches"}
                    count={dashboard3Data?.mutalmatchesCount}
                    
                    //  phonenumberstatus={phonenumberStatus}
                    data={MutualData}
                    callLoadMore={callbackLoadMoreMutual}
                    callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index, "mutual"
                      );
                      setMessageFor("mutual");
                    }}
                     
                    menu="mutual"
                    viewProfileStatusPopup={(value) => {
                      setProfileStatusMessage(value);
                      setShowProfileStatusPopup(true)
                    }}
                    ViewAllCallBack={() => {
                      props.viewAllCallback()
                      navigate(RoutePaths.MUTUALMATCHES, {
                        state: { isfrom: "mutual" },
                      });
                      setNavList([...navList, "Mutual Matches"])
                      // viewallselection("response");
                    }}
                    callBackViewPhone={(profileId,value)=>{
                      setMessageFor("mutual")
                      callBackViewPhone(profileId,value)
                    }}
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("mutual")
                       
                    }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestParent()
                      setParentNumber("mutual");
                    }}
                    //callBackBlockProfile={callBackBlockProfile}
                  />
                  // </div>
                )}
                {
                  isLoadingDashboard3 &&  
                  <div className=" section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1]  md:py-1 md:px-3 lg:px-7 lg:py-3  my-4" >
                    <div className="flex justify-between lg:mx-2 md:mx-2 mx-0 ">
                      <CardSkeleton cards={3} menu={"selectedprofileslider"}/>
                    </div>
                  </div>}
                {selectedProfileData.length > 0 && (  
                  <DashboardSection
                    count={dashboard3Data?.selectedprofilesCount}
                    title={"Selected Profile"}
                    console={console.log(selectedProfileData,"selectedProfileData")}
                    data={selectedProfileData}
                    myPhoto={dashboard1Data?.myphoto}
                    callLoadMore={callbackLoadMoreSelectedProfile}
                    callbackSelectReject={(
                      btntype, 
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index, "selected"
                      );
                      setMessageFor("selected");
                    }}
                     
                    menu="selected"
                    viewProfileStatusPopup={(value) => {
                      setProfileStatusMessage(value);
                      setShowProfileStatusPopup(true)
                    }}
                    ViewAllCallBack={() => {
                      setMenuSelect("7");
                      props.viewAllCallback()
                      navigate(RoutePaths.SELECTEDPROFILES, {
                        state: { isfrom: "selectedprofile" },
                      });
                      setNavList([...navList, "Selected Profile"])
                      // viewallselection("response");
                    }}
                    callBackBlockProfile={callBackBlockProfile}
                    callBackViewPhone={(profileId,value)=>{
                      setMessageFor("selected")
                      callBackViewPhone(profileId,value)
                    }}
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("selected")
                       
                    }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestParent()
                      setParentNumber("selected");
                    }}
                  />
                )}  
                </div>              
              </div>

              <div className="hidden col-span-3 md:col-span-4 lg:col-span-3 md:block">
              {isLoadingProfile ?  
                  // <Loader /> : 
                  <div className=" section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1] my-6" >
                    <SidePannelProfileSkeleton />
                    {/* <div className="mt-4 px-3">
                      <CardSkeletonWithSlider cards={3} menu={props?.menu}/>
                    </div> */}
                  </div>
                  : 
                <ProfileSection data={profileData} profileDataCount={profileDataCount}  onSubmitSuccess={(newPhone, newIsd) => { 
                  console.log("newPhone, newIsd",newPhone, newIsd);
                  setProfileData(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))
                }}/>
              }
              {
                isRecentlyLoading && <div className="mt-4 px-3">
                <CardSkeletonWithSlider cards={3} menu={props?.menu}/>
              </div>
              }
                {recentlyViewedData.length > 0 && (
                  <DashboardSection4
                    count={recentlyViewedDataCount} 
                    title={"Recently Viewed"}
                    data={recentlyViewedData}
                    callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index, "recent"
                      );
                      setMessageFor("recent");
                    }}
                    callLoadMore={callbackLoadMoreRecentlyView}
                    isViewmore={true}
                    callBackChatnow={() => { }}
                    viewProfileStatusPopup={(value) => {
                      setProfileStatusMessage(value);
                      setShowProfileStatusPopup(true)
                    }}
                    //callBackBlockProfile={callBackBlockProfile}
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("recent")
                    }}
                    callbackParentNumberRequest={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestParent()
                      setParentNumber("recent");
                    }}
                    ViewAllCallBack={() => {
                      // viewallselection("recentlyviewed");
                      props.viewAllCallback()
                       navigate(RoutePaths.RECENTLYVIEW, {
                        state: { isfrom: "recentlyviewed" },
                      });
                       setNavList([...navList, "Recently Viewed"])
                      //console.log("viewmore");
                    }}
                    callBackViewMore={(value) => {
                      //console.log("viewmore", value);
                    }}
                  />
                )}

                <div className="py-4" >
 
                {addBanner?.name === "kjbanners" ?
                    <img
                      className="cursor-pointer w-[100%]"
                      alt=""
                      src={addBanner?.showphotobanner}
                      closeModel={closeModel}
                      onClick={() => {
                        setShowKjBannerPopup(true);
                      }} />
                    :
                    <img
                      className="cursor-pointer w-[100%] "
                      alt=""
                      src={addBanner?.showphotobanner}

                      onClick={() => {
                        // navigate(addBanner?.redirect);
                        bannerRedirect(addBanner?.name, addBanner?.redirect)
                      }} />

                  }

                </div>
              </div>
            </div>
          </div>

          <Footer />
          <DownloadAppBar dashboardData={dashboard1Data} haveBottom={true}/>
          <ViewMessageBottomNav />
        </main>
        {/* // : 
        // <center>
        //   <img alt='' src={LoaderGif} />
        // </center>
        //         } */}
      </div>
    </div>
  );
}
