import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  SaveCancelButton,
  SelectAstroInput,
  SelectInput,
} from "../Edit Profile/EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../Constants/EndPoints";
import { POST, GET } from "../../Services/api_services";
import config from "../../config";
import Constants, { EventKeys, EventName } from "../../Constants/constants";
import { useAnalytics } from "../../Hooks/usePageChange";
import { useSelector } from "react-redux";
import staricon from "../../Images/star-icon.svg";
import { NavBarContext } from "../../Contexts/NavBarContext";
import axios from "axios";
import Loader from "../Loader";

const AstrologicalComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [isShowEdit, setEditView] = useState(false);
  const [starDropDown, setStarDropDown] = useState([]); // Initialize as an empty array
  const [raasiDropDown, setRaasiDropDown] = useState([]);
  const [dosamDropDown, setDosamDropDown] = useState([]);
  // const [submitStatus, setSubmitStatus] = useState(false);
  const [selectedStarValue, setSelectedStarValue] = useState(null);
  const [packStatusdata, setPackStatusData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log(selectedStarValue,"selectedStarValue");
  const {submitStatus, setSubmitStatus, completeNow,setCompleteNow ,openModel, setOpenModel ,clickAddAstro, setClickAddAstro,} = useContext(NavBarContext);
  const [onchangedata,setOnchangeData] = useState(true)
  const [commonError, setCommonError] = useState("");

  console.log(openModel,"openModel");
  const formik = useFormik({
    initialValues: {
      star: props?.data?.star ? props?.data?.star[0]?.id : "",
      raasi: props?.data?.raasi ? props?.data?.raasi[0]?.id : "",
      dosham: props?.data?.dosham ? props?.data?.dosham[0]?.id : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      star: Yup.string(),
      raasi: Yup.string(),
      dosham: Yup.string(),
    }),
    onSubmit: (values) => {
      apiCallHoroscope(values);
      setSubmitStatus(true);
      setCompleteNow(true)
    
    },
  });

  useEffect(() => {
    setStarDropDown(props?.dropDown?.starData || []); // Fallback to an empty array if undefined
    setDosamDropDown(props?.dropDown?.doshamData || []); // Fallback to an empty array if undefined
  }, [props?.dropDown]);




  useEffect(() => {
    if (props?.data?.star?.at(0)?.id) {
      apiCallRaasi(props?.data?.star?.at(0)?.id);
    }
  }, [props?.data?.star, props?.data?.raasi]);

  const apiCallRaasi = async (id) => {
    const motherTongue = props?.data?.motherTongue[0]?.id;
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getRaasiUrl(id, motherTongue)}`
    );
    if (statusCode === 200 && data.status === "Success") {
      setRaasiDropDown(data.data.raasi || []);
      if (data.data.raasi.length === 1) {
        formik.setFieldValue("raasi", data.data.raasi[0]?.id);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apiCallHoroscope = async (value) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      starId: value.star,
      raasiId: value.raasi,
      doshamId: value.dosham,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.edithoroscopeinfosave()}`,
      request
    );

    if (statusCode === 200 && data.status === "Success") {
      setSubmitStatus(false);
      setClickAddAstro(false)
      setEditView(false);
     
      
      onClickTrack(EventName.profileHoroscopeSaveButton, {
        [EventKeys.profileHoroscope]: value,
      });
      setTimeout(() => {
        setClickAddAstro(false)
        setCompleteNow(true)
        props?.postData()
      }, 2000);
    } else if (statusCode === 401) {
      logout();
    }
  };

 

  const { starList } = useSelector(state => ({
    starList: state.Home?.starList?.data?.data
  }));
  console.log(starList,"starList");

  const selectedStar = useMemo(() => {
    return (
      starList?.starData?.find(
        (option) => option.id === formik.values.star
      ) ||
      starDropDown?.find((option) => option.id === formik.values.star) ||
      null
    );
  }, [formik.values.star, starDropDown, starList?.starData]);

  const selectedRaasi = useMemo(() => {
    return raasiDropDown?.find((option) => option.id === formik.values.raasi) || null;
  }, [formik.values.raasi, raasiDropDown]);

  const selectedDosham = useMemo(() => {
    return dosamDropDown?.find((option) => option.id === formik.values.dosham) || null;
  }, [formik.values.dosham, dosamDropDown]);

  const { onClickTrack } = useAnalytics();

  useEffect(() => {
    setStarDropDown(props?.dropDown?.starData || []); // Ensure it's an array
    setDosamDropDown(props?.dropDown?.doshamData || []); // Ensure it's an array
    setSelectedStarValue(selectedStar || null);
  }, [props?.dropDown, selectedStar]);


  const [stardata,setStarData] = useState([])


  const apiCallStarDetailFromId = async (value) => {
    setLoading(true)
    let request = {
      user_id: loginDetail()[1],
     
    };

    const response = await axios.post(`${config.cms_api}/getStarDetailFromId`, request)


    if(response.status == 200) {
      setLoading(false)
      setStarData(response.data?.stars ?? []); // Set to an empty array if stars is undefined
    } else if (response.status === 401) {
      logout();
    }
  };

  useEffect(()=>{
    apiCallStarDetailFromId()
  },[])

  useEffect(() => {
    console.log(formik.values, "stardatas");
  }, [stardata ,formik.values]);  

  useEffect(() => {
    if (raasiDropDown.length === 1) {
      formik.setFieldValue("raasi", raasiDropDown[0]?.id);
    }
  }, [raasiDropDown]);
  
  console.log(formik.values.star,"formik.values.star");


 
  
  const handleSave = (e) => {
    e.preventDefault();
    if (!formik.values.star || !formik.values.raasi || !formik.values.dosham) {
      setCommonError("All fields are mandatory");
    } else {
      setCommonError("");
      formik.handleSubmit();
      setOpenModel(false);
    }
  };

  return (
    <div>
      {loading ? <Loader/>:
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div className="bg-[#FFF3F3] md:mx-[4rem]  lg:mx-[7rem] flex justify-center items-center gap-5 py-2 px-5 rounded-lg">
            <div className="w-[4rem] h-[3rem]"><img src={staricon}/></div>
            <div className="text-[13px] space-y-2">
              <p className="text-[#2C2B2B] text-[12px]">
              We are Recommending the below stars to choose based on your Date of Birth
              </p>
              <div className="flex gap-3">
                {stardata?.map((star) => (
                  <button
                    key={star.id}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      console.log(`Star ID: ${star.id}`); // Log the star.id value
                      setOnchangeData(false);
                      formik.setFieldValue("star", `${star.id}`, false);
                      apiCallRaasi(star.id).then(() => {
                        formik.setFieldValue("raasi", "", false);
                      });
                    }}
                    className={`${String(formik.values.star) === String(star.id) ? "bg-[#D10A11]" : "bg-[#7A7A7A]"} text-[8px] rounded-md text-white text-center p-2`}
                    >
                    {star.value}
                  </button>
                ))}
              </div>

            </div>

          </div>
        <div className="grid md:grid-cols-2">
          <SelectAstroInput
            name="star"
            title="Star"
            value={selectedStarValue}
            onChange={(selected, ev) => {
              formik.setFieldValue("star", selected.id);
              formik.setFieldValue("raasi", "");
              apiCallRaasi(selected.id);
              setOnchangeData(false)
            }}
            error={formik.errors.star}
            defaultValue={formik.values.star}
            options={
              starList?.starData
                ? [{ id: "", name: "Select", isDisabled: true }, ...starList?.starData]
                : [{ id: "", name: "Select", isDisabled: true }, ...starDropDown]
            }
           
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menu={"packastro"}
          />

          <SelectAstroInput
            name="raasi"
            title="Raasi"
            value={selectedRaasi}
            onChange={(selected, ev) => {
              formik.setFieldValue("raasi", selected.id);
              setOnchangeData(false)
            }}
            error={formik.errors.raasi}
            defaultValue={formik.values.raasi}
            options={[{ id: "", name: "Select", isDisabled: true }, ...raasiDropDown]}
           
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menu={"packastro"}
          />

          <SelectAstroInput
            name="dosham"
            title="Chevvai Dosham/Manglik"
            value={selectedDosham}
            onChange={(selected, ev) => {
              formik.setFieldValue("dosham", selected.id);
              setOnchangeData(false)
            }}
            error={formik.errors.dosham}
            defaultValue={formik.values.dosham}
            options={[{ id: "", name: "Select", isDisabled: true }, ...dosamDropDown]}
           
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menu={"packastro"}
          />
          </div>

          {commonError && (
            <div className="text-red-500 font-bold text-[13px] text-center mt-4">
              {commonError}
            </div>
          )}
          <SaveCancelButton
              saveCallBack={(e) => {
                  e.preventDefault();
          if (!formik.values.star || !formik.values.raasi || !formik.values.dosham) {
            setCommonError("All fields are mandatory");
          } else {
            setCommonError("");
            formik.handleSubmit();
            setOpenModel(false);
          }
                  // e.preventDefault();
            // formik.handleSubmit();
            // setOpenModel(false)
          }}
          menu="astroadd"
          submitStatus={submitStatus}
         
          setOnchangeData={setOnchangeData}
          onchangedata={onchangedata}
        
        />

         

                
        </div>
      </form>
     }
    </div>
  );
};

export default AstrologicalComponent;
