import React, { useState, useContext, useEffect } from "react";

import { POST } from "../Services/api_services";
import { IP_Address } from "../Constants/IpAddress";
import endPoints from "../Constants/EndPoints";
import { EditProfileMenu, emailid, InboxFilters, MenuFunction } from "../Constants/constants";

//context
import { LoginContext } from "../Contexts/LoginContext";
import { NavListContext } from "../Contexts/navContext";
import NavBar from "../Components/NavBar";
import ViewMessageNav from "../Components/ViewMessageComponents/ViewMessageNav";
import Footer from "../Components/Footer/Footer";
import { useNavigate, useLocation } from "react-router";
import RouteSegments, { RouteHelper } from "../Routes/RouteSegment";
import ViewMessageBottomNav from "../Components/ViewMessageComponents/ViewMessageBottomNav";
import config from "../config";
import Constants from "../Constants/constants";
import LoaderGif from "../Gif/loader.gif"
import DownloadAppBar from "../Components/DownloadAppBar";
import { BulkRequestPopup, CommonQualityPopup } from "../Components/CommonPopup";
import { useDispatch, useSelector } from "react-redux";
import { dashboardLeftPanelData, dashboardMatcheslist, dashboardProfileData } from "../Store/DashboardAPI/Action";
import { NavBarContext } from "../Contexts/NavBarContext";
import { SkeletonMatchItem } from "../Components/Kalyanlite/matchs/MatchItem";

const Notification = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const { navList, setNavList } = useContext(NavListContext);
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);

  const navigate = useNavigate();
  const [notificationList, setNotificationList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [isSelect, setIsSelect] = useState(false)
  const [dashboard1Data, setDashboard1Data] = useState([]);
  const [showBulkRequestPopupData, setShowBulkRequestPopupData] = useState({ show: false, title: "", content: "", message: "" })

  const [showtrailpopup,setShowtrailpopup]=useState(false)
  const isV3User = localStorage.getItem("isV3User")

  const location = useLocation()

  const dispatch = useDispatch()
  const { ProfileData, matchesListData, leftPanelData } = useSelector(state => ({
    ProfileData: state.DashBoardAPI?.ProfileData?.data,
    matchesListData: state.DashBoardAPI?.matchesListData?.data,
    leftPanelData: state.DashBoardAPI?.leftPanelData?.data,
  }));

  useEffect(() => {
    apiCallNotification();
    // apicallDashboard();
    dispatch(dashboardProfileData())
    dispatch(dashboardLeftPanelData())
  }, []);

  useEffect(() => {
    if (ProfileData?.status) {
      localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, ProfileData?.status)
      if (ProfileData?.popup[0].showpopup == "RejectedProfile") {
        navigate(RouteSegments.EDITPROFILE)
      }
    }
  }, [ProfileData])


  useEffect(() => { }, [notificationList, props.menu]);
  const apiCallNotification = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice)
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.notificationUrl()}`,
      request
    );

    if (statusCode === 200) {
      setLoading(true)
      if (data?.data?.notificationData?.length > 0) {
        setNotificationList(data?.data?.notificationData);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const callBackRedirection = (notificationFor, senderID, subjectId) => {
    if (notificationFor === "Trust") {
      // Trust Document page
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[3].urlPath), {
        state: {
          tab: "4",
          isfrom: "notification"
        },
        // replace: true,

      });
      setNavList([...navList, "Trust"])
    } else if (notificationFor === "Horoscope") {
      // Horoscope page
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[4].urlPath), {
        state: {
          tab: "5",
          isfrom: "notification"
        },

        // replace: true,

      });
      setNavList([...navList, "Horoscope"])
    } else if (notificationFor === "Photo") {
      // Gallery page
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), {
        state: {
          tab: "3",
          isfrom: "notification"
        },
        // replace: true,

      });
      setNavList([...navList, "Photo"])
    } else if (notificationFor === "PartnerProfile") {
      // Match View Profile page
      navigate(RouteHelper.getProfileUrl, {
        state: { id: senderID },
        // replace: true,
      });
    } else if (notificationFor === "Admin") {
      // Edit my profile page
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath), {
        state: { tab: "1" },
        // replace: true,
      });
    } else if (notificationFor === "Message") {
      // Inbox page All message
      navigate(RouteHelper.getConversationUrl(InboxFilters.message[0].urlPath), {
        state: { profileId: senderID, threadId: subjectId, type: "Sent" },
      })
    }
   
     else if (notificationFor === "bulktrigger") {
      bulrequestPopup()
    }
  };

  

  const callBackRedirectionlite = (notificationFor, senderID, subjectId, notificationContent, notification) => {
    console.log("notificationForddd", notificationFor);
    const clcOptions = [
      "AddPhoto",
      "PartnerPreference",
      "Matches",
      "StartMessage",
      "NewMatches",
      "AddParentsNumber",
      "ReadAndReply",
      "UnreadMessage",
      "MsgSeenNotReply",
    ]
    const queryString = new URLSearchParams({
      "path": notificationFor,
      "notificationId": notification?.notificationId,
    }).toString();

    if (notificationFor == "DownloadApp") {
      return window.open(RouteSegments.staticLinkForAppDownload.android)
    } else if (clcOptions.includes(notificationFor)) {
      return navigate(`/new/matches?${queryString}`)
    } else if (notificationFor === "Admin") {

      const isTrustDocument = notificationContent.includes("Trust Document added");
      const isHoroscope = notificationContent.includes("Horoscope added by you");
      const isPhoto = notificationContent.includes("Photo");

      if (isTrustDocument) {
        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[3].urlPath), {
          state: {
            tab: "4",
          },

        });

      } else if (isHoroscope) {

        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[4].urlPath), {
          state: {
            tab: "5",
            isfrom: "notification"
          },
        });
      } else if (isPhoto) {
        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), {
          state: {
            tab: "3",
            isfrom: "notification"
          },

        });
      }
    } 
    else if (notificationFor === "Trust") {
      const isTrust = notificationContent.includes("Trust Document Request from")

      if (isTrust) {
        navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
          replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
          state: {
            isFrom: "notification",
            id: "KM" + senderID,
            isFromChat: true,
            from: "newConversations"
          }
        },)
      }

    }
    else if (notificationFor === "Horoscope") {
      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
        replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
        state: {
          isFrom: "notification",
          id: "KM" + senderID,
          isFromChat: true,
          from: "newConversations"
        }
      })

    } else if (notificationFor === "Photo") {
      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
        replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
        state: {
          isFrom: "notification",
          id: "KM" + senderID,
          isFromChat: true,
          from: "newConversations"
        }
      })

    }
    else if (notificationFor === "PartnerProfile") {
      const isParentsnumber = notificationContent.includes("Parents Number Request from");
      if (isParentsnumber) {
        navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
          replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
          state: {
            isFrom: "notification",
            id: "KM" + senderID,
            isFromChat: true,
            from: "newConversations"
          }
        })
      } else {
      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
        replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
        state: {
          isFrom: "notification",
          id: "KM" + senderID,
          isFromChat: false,
          from: "search"
        }
      })
      }
      setMenuSelect("3")
    }  else if (notificationFor === "Message") {

      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
        replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
        state: {
          isFrom: "notification",
          id: "KM" + senderID,
          isFromChat: true,
          from: "newConversations"
        }
      })

    } else if (notificationFor === "bulktrigger") {
      bulrequestPopup()
    }
    else if(notificationFor === "TrialPack"){
      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, {
        replace: RouteSegments.MATCH_CONVERSATIONS_LITE == location.pathname,
        
      })
      setShowtrailpopup(true)
    }
  };
  // const apicallDashboard = async () => {
  //   let request = {
  //     email: loginDetail()[0],
  //     userId: loginDetail()[1],
  //     pageno: "1",
  //     status: "new",
  //   };
  //   let { statusCode, data } = await POST(
  //     `${config.api.API_URL}${endPoints.dashboardUrl()}`,
  //     request
  //   );

  //   if (statusCode === 200) {
  //     setDashboard1Data(data?.data);
  //     localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, data?.data.status)
  //     if(data?.data?.popup[0].showpopup == "RejectedProfile"){
  //       navigate(RouteHelper.getEditProfileUrl())
  //     }
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  // };


  const bulrequestPopup = async () => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      "bulktrigger": true
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.bulkrequestpopup()}`,
      request
    );

    if (statusCode === 200) {
      if (data?.data?.recomm_count > 0) {
        setShowBulkRequestPopupData({
          show: true,
          title: "Send Request",
          content: "",
          message: data?.data?.bulkrequestcontent,
        })
      }
    } else if (statusCode === 401) {
      logout();
    }
  }

  const closeBulkRequestPopup = () => {
    setShowBulkRequestPopupData({ show: false, content: "", message: "", title: "" })
  }

  const apiCallBulkRequest = async () => {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      "typeList": [
        "Photo",
        "Parents Number"
      ],
      "triggerType": "bulk"
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.bulkrequest()}`,
      request
    );

    if (statusCode === 200) {
      closeBulkRequestPopup()
      setShowBulkRequestPopupData({
        show: true,
        title: "Send Request",
        content: "Request Sent Successfully",
      })
    }

  }


  const onBulkRequestSend = (status) => {
    if (status) {
      apiCallBulkRequest()
    } else {
      closeBulkRequestPopup()
    }
  }


  return (
    <div>
      {
        showBulkRequestPopupData.show && (
          <BulkRequestPopup
            close={closeBulkRequestPopup}
            onSend={onBulkRequestSend}
            content={showBulkRequestPopupData.content}
            message={showBulkRequestPopupData?.message}
            imageSrc={showBulkRequestPopupData.imageSrc}
            title={showBulkRequestPopupData.title} />
        )
      }
      {showtrailpopup && 
      <CommonQualityPopup OnClose={()=>setShowtrailpopup(false)}/>}


      <div className={`${isV3User ? "overflow-x-hidden" : ""}`}>
        <div className={`${isV3User ? "font-seoge-semibold bg-[#eae6df] h-screen w-screen overflow-y-hidden overflow-hidden" : ""}`}>
          <div className={`${isV3User ? "hidden md:block w-full h-[8.7rem] bg-[#d10a11] absolute top-0" : ""}`} />
          <div className={`${isV3User ? "flex items-center justify-center h-full w-full overflow-x-hidden overflow-y-hidden" : ""}`}>
            <div className={`${isV3User ? "z-[1] flex flex-col items-center justify-center " : " "}`}>
            <div className={`${isV3User ? "w-[100vw] lg:w-[86vw]" : "sticky top-0 z-50"}`}>
              <NavBar data={leftPanelData} ProfileData={ProfileData} />
            </div>
            <div className={`${isV3User ? 'overflow-y-auto lite-scrollbar  w-[100vw] lg:w-[86vw] md:h-[93vh] h-[92vh] xl:h-[82vh] 2xl:h-[85vh]  lg:h-[80vh]' : '' } ${notificationList.length === 0 ? "bg-white" : null}`}>

                {loading ?
                  <>

                    <main>
                      <div className="overflow-x-hidden overflow-y-auto pb-16 md:pb-0">
                        {isV3User ? "" : <ViewMessageNav page={props.menu} menu="" />}
                        <div className={`${isV3User ? "overflow-y-auto lite-scrollbar  w-[100vw] lg:w-[86vw] md:h-[93vh] h-[92vh] xl:h-[82vh] 2xl:h-[85vh]  lg:h-[80vh]" : " block  md:grid md:grid-cols-9 min-h-[43vh]"}`}>
                          <div className={`${isV3User ? "h-full" : " px-[1rem] md:px-[1rem] lg:px-[3rem] mt-[2rem] md:mt-[2rem] lg:mt-[3rem] md:col-span-6"}`}>
                            <div className={`${isV3User ? "h-full" : " space-y-[0.1rem] text-[13px] md:text-[14px] lg:text-[15px] mb-[6rem]"}`}>
                              {notificationList.map((e, i) => (

                                <p
                                  onClick={() => {
                                    if (isV3User) {
                                      callBackRedirectionlite(
                                        e.notificationFor,
                                        e.notificationSenderId,
                                        e.subjectId,
                                        e.notificationContent,
                                        e
                                      )
                                      setMenuSelect("3")
                                    } else {
                                      setIsSelect(true)
                                      callBackRedirection(
                                        e.notificationFlag,
                                        e.notificationSenderId,
                                        e.subjectId

                                      )
                                    }
                                  }}
                                  className={`text-[#7A7A7A] 
                      ${e.isNew ? "bg-[#f3d7de]" : "bg-[#E9E9E9]"
                                    } 
                      
                      font-semibold px-[1.5rem] py-[0.5rem] flex items-center cursor-pointer`}
                                >
                                  {e.notificationContent}
                                  {/* <span className="text-[#575556] pl-[0.5rem] font-bold ">
                      KM1755560
                    </span> */}
                                </p>
                              ))}
                            </div>
                            {/* <div className="flex justify-end mt-[2rem] mb-[6rem] md:mb-0 ">
                  <button className=" bg-[#D10A11] px-[1rem] md:px-[2rem] lg:px-[3rem] text-[12px] md:text-[14px] lg:text-[16px] rounded-[5px] py-1 mdpy-2 font-bold flex items-center text-[#FFFFFF] ">
                    Load More
                  </button>
                </div> */}
                          </div>
                        </div>
                      </div>

                    </main>

                    <>
                     {isV3User?"" :
                     notificationList.length > 0 ?
                        <Footer menu="" />
                        :
                        <Footer menu="notification" />
                      }
                    </>

                    {isV3User ? null
                      : <>
                    <DownloadAppBar dashboardData={ProfileData} haveBottom={true} />
                    <ViewMessageBottomNav />
                      </>}
                  </>
                  :
                  <center>
                    <img alt='' src={LoaderGif} />
                  </center>
                }
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>


  );
};

export default Notification;

