import moment from "moment"
import Constants from "./Constants/constants";




export const getFormattedDateTimeWithPeriod = (date) => {
    try {
        return moment(date).format("DD/MM/YYYY hh:mm A")
    } catch (error) {
        return null
    }
}

export function getFileExtension(fileName) {
    return fileName?.split('.')?.pop() ?? null;
}

export const validateImage = ({
    file,
    onSizeError,
    onExtensionError,
    onFileSizeError,
    onSuccess,
    minSize = { height: 100, width: 100 },
    extensions = ['png', "jpg", "jpeg"],
    minSizeInMb = 10
}) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);

    //file extension check
    const fileType = getFileExtension(file?.name)
    if (!extensions.includes(fileType)) {
        onExtensionError(fileType)
        return false;
    }

    //file size check
    const fileSize = file?.size / 1024 / 1024
    if (parseFloat(fileSize) > minSizeInMb) {
        onFileSizeError(fileSize)
        return false;
    }

    reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = (img) => {
            if (minSize != null) {
                if (image.height < minSize.height || image.width < minSize.width) {
                    onSizeError(image)
                    return false;
                } else {
                    onSuccess(image, reader.result?.toString())
                }
            } else {
                onSuccess(image, reader.result?.toString())
            }
        };
    }
}

export const validateNumber = (str)=> str === ""||/^[0-9]*$/.test(str)

/**
 * The function `convertToCurrency` takes a value and attempts to format it as a currency, returning
 * the formatted value or the original value if formatting fails.
 * @param value - The `value` parameter is the number that you want to convert to a currency format.
 * @returns The function `convertToCurrency` returns the formatted currency value if it is successfully
 * formatted using the `currency.format` function. If there is an error during formatting, it returns
 * the original value.
 */
export const convertToCurrency = (value) => {
    console.log("convertToCurrency",value);
    const currency = new Intl.NumberFormat('en-IN')
    try {
        return currency.format(Number(value))
    } catch (error) {
        return value
    }
}

export const dateFormat = (value) => {
    // Create a new Date object by parsing the date string
    var date = new Date(value);
    
    // Create a new date string with the updated year
    var updatedDateString = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: '2-digit'
    });

    var parts = updatedDateString.split(' ');
    var formattedDateString = `${parts[1]} ${parts[0]} ${parts[2]}`;

    return formattedDateString.replace(/,/g, '');
}

export const commaWithSpace = (value) => {

    return value.replace(/,/g, ", ");
}

export const getIsLoginFromAdmin = () => localStorage.getItem(Constants.loginLocalStorageKeys.loginFromAdmin) ? true : false