import React, { Component, useEffect, useState, useContext, useRef } from "react";
import NavBar from "../Components/NavBar";
import ViewMessageNav from "../Components/ViewMessageComponents/ViewMessageNav";
import AccordianComponents from "../Components/ViewMessageComponents/AccordianComponents";
import ProfileSection from "../Components/DashboardComponents/ProfileSection";
import SearchResultSection from "../Components/SearchResultComponents/SearchResultSection";
import SearchResultCardMobileview from "../Components/SearchResultComponents/SearchResultCardMobileview";
import BasicFilter from "../Components/SearchResultComponents/BasicFilter";
import Modal from "../Components/SearchResultComponents/Modal";
import Footer from "../Components/Footer/Footer";
import KJbannerPopup from "../Components/DashboardComponents/KjBannerPopup";
import { DashboardSection4 } from "../Components/DashboardComponents/DashboardSection";
import ViewMessageBottomNav from "../Components/ViewMessageComponents/ViewMessageBottomNav";
import { POST } from "../Services/api_services";
import { IP_Address } from "../Constants/IpAddress";
import endPoints from "../Constants/EndPoints";
import { LoginContext } from "../Contexts/LoginContext";
import FilterContents from "../Components/SearchResultComponents/FilterContents";
import RefineFilter from "../Components/SearchResultComponents/RefineFilter";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { registerCasteList, registerCityList, registerCountryList, registerRaasiList, registerSubCasteList } from "../Store/Home/Action";
import Constants, { EditProfileMenu, loginFrom } from "../Constants/constants";
/// popups
import RouteSegments, { RouteHelper, RoutePaths } from "../Routes/RouteSegment";
import PhonePopup from "../Components/DashboardComponents/PhonePopup";
import SendMessagePopup from "../Components/DashboardComponents/SendMessagePopup";
import RequestPhotoHoroTrustPopup from "../Components/DashboardComponents/RequestPhotoHoroTrustPopup";
import filterImage from '../Images/Group 230.svg'
import MessageSentIcon from "../Images/message-sent.svg"

import {
  BulkRequestPopup,
  CommonPopup,
  CommonProfilePopup,
  CommonSuccessPopup,
  MessageSuccessPopup,
  UploadPhotoPopup,
} from "../Components/CommonPopup";
import { registerStateList } from "../Store/Home/Action";
import { sendFilterRequest, sendFilterRequestApiError } from "../Store/FilterSection/Action";
import CardSkeleton from "../Components/skeletonLoader/CardSkeleton";
import { NavListContext } from "../Contexts/navContext";
// import { FilterContext } from "../Contexts/filterContext"
import KjBannerPopup from "../Components/DashboardComponents/KjBannerPopup";
import { NavBarContext } from "../Contexts/NavBarContext";
import ParentsPhonePopup from "../Components/DashboardComponents/ParentsPhonePopup";
import config from "../config";
import { blockProfile } from "../Store/Conversation/Action";
import FilterPanel from "../Components/FilterPanel";
import LoaderGif from "../Gif/loader.gif"
import FilterOpenIcon from '../Images/Group 495.svg'
import FilterCloseIcon from '../Images/Group 497.svg'
import SidePannelProfileSkeleton from "../Components/skeletonLoader/SidePannelProfileSkeleton";
import {CardSkeletonWithSlider, CardSkeletonWithSliderProfile } from "../Components/skeletonLoader/CardSkeletonWithSlider";
import EndPoints from "../Constants/EndPoints";
import SearchResultMessage from "../Components/SearchResultComponents/SearchResultMessage";
import { useMediaQuery } from 'react-responsive';
import DownloadAppBar from "../Components/DownloadAppBar";
import { Divider } from "antd";
import { dashboardLeftPanelData,dashboardMatcheslist, dashboardProfileData } from '../Store/DashboardAPI/Action';
import SelectedCount from "../Components/SearchResultComponents/SelectedCount";
import { getIsLoginFromAdmin } from "../Utils";

const LoaderMoreMobileSpinner = () => {
  return (
    <div 
    id="load-more-spinner"
    ref={(ref) => {
      // ref?.scrollIntoView()
      window.scrollBy(0, 300);
    }} className={`flex h-40 items-center justify-center`}>
      <img alt="loader" src={LoaderGif} className={`w-16 h-16`} />
    </div>
  )
}



const SearchResultpage = (props) => {

  const [shortlisted, setShortlisted] = useState("");
  const [ignored, setIgnored] = useState("");
  const [viewed, setViewed] = useState("");
      


  const {navList, setNavList} = useContext(NavListContext)
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedFilter, setSelectedFilter] = useState("0")
  const [isFromComponent, setIsFromComonent] = useState("")
  const [showKjBannerPopup, setShowKjBannerPopup] = useState(false);
  const [showKjThankPopup, setShowKjThankPopup] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [dashboard1Data, setDashboard1Data] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [addBanner, setAddBanner] = useState();
  const [addTopBanner, setAddTopBanner] = useState();
  const [loading, setLoading] = useState(false)
  const [recentlyViewedLoader, setRecentlyViewedLoader] = useState(false)
  const [recentlyViewedData, setrecentlyViewedData] = useState([]);
  const [recentlyViewedData1, setrecentlyViewedData1] = useState([]);
  const [similarmatchesdata, setSimilarMatchesData] = useState([])
  const [exploreMatchesData, setExploreMatchesData] = useState([])
  const [searchPageData, setSearchPageData] = useState([]);
  const [dataLength, SetDataLength] = useState("");
  const [exploreDataLength, setExploreDataLength] = useState(0)

  const [searchResultMessage, setSearchResultMessage] = useState("")
  const [isLoadFromRecommeded, setIsLoadFromRecommeded] = useState(false)
  const [partnerProfileCount, setPartnerProfileCount] = useState("")
  
console.log("isLoadFromRecommeded",isLoadFromRecommeded);
  const [showProfileStatusPopup, setShowProfileStatusPopup] = useState(false)
  const [ProfileStatusMessage, setProfileStatusMessage] = useState("")
  const { openFilter } = useContext(NavBarContext)
  const [profileData, setProfileData] = useState([]);
  const [recentlyViewedDataCount, setrecentlyViewedDataCount] = useState([]);


  const [navitem, setNavItem] = useState(
    location?.state?.isfrom ?? ""
  )
 
  const [isSearch, setIsSearch] = useState(
    {filter:"activity",selected:false},
    {filter:"basic",selected:false},
    {filter:"maritalStatus",selected:false},
    {filter:"physicalStatus",selected:false},
    {filter:"motherTongue",selected:false},
    {filter:"religion",selected:false},
    {filter:"caste",selected:false},
    {filter:"subCaste",selected:false},
    {filter:"education",selected:false},
    {filter:"employedIn",selected:false},
    {filter:"occupation",selected:false},
    {filter:"country",selected:false},
    {filter:"state",selected:false},
    {filter:"city",selected:false},
    {filter:"star",selected:false},
    {filter:"raasi",selected:false},
    {filter:"dosham",selected:false},
    {filter:"food",selected:false},
    {filter:"smoking",selected:false},
    {filter:"drinking",selected:false},
    {filter:"showProfile",selected:false},
    {filter:"dontshowProfile",selected:false},
  )
  const [isSelectAll, setIsSelectAll] = useState("select")

  
 



  const [viewallData, setViewAllData] = useState([]);
  let cardPageNumValue = {
    weeklyDataPage: 1,
    recommendedPage: 1,
    responseReceivedPage: 1,
    profilelookingforPage: 1,
    profileLookingForMePage: 1,
    mutualPage: 1,
    selectedProfilePage: 1,
    photorequestPage: 1,
    recentlyViewedPage: 1,
    similarMatchesPage:1,
    exploreMatchesPage:1,
    basicSearchPage:1,
    advanceSearchPage:1
  };
  const [openFilterPopup, setOpenFilterPopup] = useState(false)
  const [showAdminPopup, setShowAdminPopup] = useState(false)
  const [cardPageNum, setCardPageNum] = useState(cardPageNumValue);
  const [Weeklydata, setWeeklyData] = useState([]);
  const [RecommendedData, setRecommendedData] = useState([]);
  const [responseReceivedData, setresponseReceivedData] = useState([]);
  const [contentCardData, setContentCardData] = useState([]);
  const [profileILookingForData, setprofileILookingForData] = useState([]);
  const [profileLoookingForMeData, setprofileLoookingForMeData] = useState([]);
  const [MutualData, setMutualData] = useState([]);
  const [selectedProfileData, setSelectedProfileData] = useState([]);
  console.log(selectedProfileData,"selectedProfileDataselectedProfileData");
  const [photoRequestData, setphotoRequestData] = useState([]);
  const [advancesearch, setAdvanceSearch] = useState([]);
  const [tempAdvancesearch, setTempAdvanceSearch] = useState([])
  const [messageProfileDetails, setMessageProfileDetails] = useState();
  const [showPhonePopup, setShowPhonePopup] = useState(false);
  const [phoneNumData, setPhoneNumData] = useState();
  const [showBlockPopup, setShowBlockPopup] = useState(false);
  const [blockProfileName, setBlockProfileName] = useState("");
  const [showRequestPopup, setShowRequestpopup] = useState(false);
  const [showUploadImagePopup, setShowUploadImagePopup] = useState(false);
  const [showSendMessagePopup, setSendMessagePopup] = useState(false);
  const [showMessageSuccess, setshowMessageSuccess] = useState(false);
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const [successMessage, setsuccessMessage] = useState("");
  const [messageFor, setMessageFor] = useState();
  const [isChecked,setIsChecked] = useState(false)
  const [photoRequest, setPhotoRequest]  = useState()
  const [selectedActivityFilter,setSelectedActivityFilter] = useState([])
  const [filterRequest, setFilterRequest] = useState()
  // const [showKjBannerPopup, setShowKjBannerPopup] = useState(false);
  //  const [showKjThankPopup, setShowKjThankPopup] = useState(false);
  const [filterData, setFilterData] = useState([])
  const [showCriteriaMessage, setShowCriteriaMessage] = useState("")
  //  const { filterData, setFilterData } = useContext(FilterContext);
  const [selectedStarList, setSelectedStarList] = useState([])
  const [selectedCountries, setSelectedCountries] = useState([])
  const [selectedStates, setSelectedStates] = useState([])
  const [selectedSubCasteList, setSelectedSubCasteList] = useState([])
  console.log(selectedSubCasteList,"selectedSubCasteLists");
  const [selectedCasteList, setSelectedCasteList] = useState([])
  const [submitStatus, setSubmitStatus] = useState(false)
   const [addKjBanner, setAddKjBanner] = useState();
   const [latest, setLatest] = useState(false)
   const[showeducation,setShowEducation]=useState("")
   const [filterDataPage, setFilterDataPage] = useState(1)
   const [isFilterResult, setIsFilterResult] = useState(false)
   const [exploreLoadMore, setExploreLoadMore] = useState(false);
   const [showParentNumPopup, setShowParentNumPopup] = useState(false)
   const [parentsNumberRequest, setParentsNumberRequest] = useState(false);
   const isMobile = useMediaQuery({ maxWidth: 768 }); // Adjust the breakpoint as needed
   const [recommendationCount,setRecommendationCount]=useState([])
   const [responseCount,setResponseCount]=useState([])
   const [weeklyCount,setWeeklyCount]=useState([])
   const [profileLookingCount,setProfileLookingCount]=useState([])
   const [lookingFormeCount,setLookingFormeCount]=useState([])
   const [mutualCount,setMutualCount]=useState([])
   const [similarCount,setSimilarCount]=useState([])
   const [resentlyViewdCount,setResentlyViewedCount]=useState([])
   const [selectedCount,setSelectedCount]=useState([])
   const [photoRequestCount,setPhotoRequestCount]=useState([])

   const [showBulkRequestPopupData, setShowBulkRequestPopupData] = useState({show:false,title:"",content:"",message:""})
console.log(responseCount,"responseCount");
   const allowLoadMore = useRef(true)

    
  const [contentCardPosition, setContentCardPosition] = useState(3)
  const [contentCardRange, setContentCardRange] = useState([3])

const [selectvalue,setSelectvalue]=useState([])

   //console.log(latest,"latest");

  const [selectedMRList, setSelectedMRList] = useState({
    motherTongue:[],
    religion:[]
  })

  let selectedOption = {
    "pageno": 1,
    "email": localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail) == '' ? null: localStorage.getItem(Constants.loginLocalStorageKeys.loginEmail),
    "userId": localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
    "days": "",
    "months": "",
    "weeks": "",
    "ageTo": "",
    "ageFrom": "",
    "heightFrom": "",
    "heightTo": "",
    "maritalStatus": [],
    "physicalStatus":[],
    "motherTongue": [],
    "religion": [],
    "domain":[],
    "caste":[],
    "subCaste":[],
    "education":[],
    "employedIn":[],
    "occupation":[],
    "country":[],
    "state":[],
    "city":[],
    "star":[],
    "raasi":[],
    "dosham":[],
    "food":[],
    "smoking":[],
    "drinking":[],
    "shortlisted": "",
    "ignored": "",
    "contacted": "",
    "viewed": "",
    "withHoroscope": "",
    "withPhoto": ""
  }

  const onResultYes = async (loadMore,from) => {
    const pageNo = loadMore === true ? (Math.ceil(searchPageData.length) / 5) + 1 : 1
    setIsLoadFromRecommeded(true);
    const request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      "pageno": pageNo,
      "status": "new"
    }

    //show btn load if we click yes from load more message
    if (loadMore === true || from === "loadMoreList") {
      setSubmitStatus(true)
    } else {
      setIsLoading(true)
    }

    let { statusCode, data } = await POST(endPoints.recomendmatchnew(), request);

    if (loadMore === true || from === "loadMoreList") {
      setSubmitStatus(false)
    } else {
      setIsLoading(false)
    }

    if (statusCode === 200) {
      SetDataLength(data?.data?.recommendationmatches?.length ?? 0)
      setSearchResultMessage("")
      allowLoadMore.current = true
      if (loadMore === true) {
        setSearchPageData(prev => ([...prev, ...data?.data?.recommendationmatches]))
      } else {
        setSearchPageData(data?.data?.recommendationmatches ?? [])
      }
    }
  }

  useEffect (() => {
    let pathnameURL = window.location.pathname.replace(/^\/([^\/]*).*$/, '$1');
    navList.indexOf(RouteSegments.breadCrumbUrl[pathnameURL]) <0 && setNavList([...navList, RouteSegments.breadCrumbUrl[pathnameURL]])
  },[])

  //console.log(location.state,"location.state");

 
  // useEffect(() => {
  //   let object = advancesearch?.state?.filter(state => state.selected === 'Y');
  //   object?.map((selected)=>{
  //     if(selectedStates.indexOf(selected.id)==-1){
  //       setSelectedStates([...selectedStates,selected.id])
  //     }
  //   })
  // },[advancesearch])


  useEffect(() => {
    setIsFilterResult(false)
    // apicallDashboard();
    dispatch(dashboardLeftPanelData())
    dispatch(dashboardProfileData())
    console.log("basic_searchisfrom",location?.state);
    if(location?.state?.isfrom === "basic_search" || location?.state?.isfrom === "advance_search" || location?.state?.isfrom === "partner_profile_search"){
      // apicallweekly()
      setContentCardData([])
      setContentCardRange([3])
      setSelectvalue((oldValue)=>[...oldValue,location?.state?.isfrom])
        setContentCardData((oldValue) => [...oldValue, location?.state?.contentData])
    }else {
      // apicallweekly(5)
    }
    apicalladvancesearch(location?.state?.isfrom === "basic_search" || location?.state?.isfrom === "advance_search",location?.state?.request);
    
     
  }, [location?.state?.isfrom]);

   useEffect(() => {
    console.log(location?.state?.dataSet,"location?.state?.dataSet");
    if(location?.state?.dataSet?.criteriamorethan15 != ""){
      setShowCriteriaMessage(location?.state?.dataSet?.criteriamorethan15)
    }
    else{
      setShowCriteriaMessage("")
    }
    recentlyviewCall()
  },[])

  const recentlyviewCall = () => 
   location?.state?.isfrom === "recentlyviewed"?"" : apicallRecentlyViewed(5)
  

  useEffect(() => {
    //console.log("cardPageNum", cardPageNum);
  }, [viewallData, cardPageNum, addBanner]);

  useEffect(() => {
    //console.log("advanceuseffect", advancesearch);
  }, [
    dashboard1Data,
    Weeklydata,
    RecommendedData,
    responseReceivedData,
    profileILookingForData,
    profileLoookingForMeData,
    MutualData,
    selectedProfileData,
    photoRequestData,
    recentlyViewedData,
    recentlyViewedData1,
    advancesearch,
    phoneNumData,
    messageFor,
    showRequestPopup,
    showUploadImagePopup,
    showSendMessagePopup,
    submitStatus,
    addKjBanner
  ]);
   const apiCallLoadMore = async (url, request, loadMoreFor) => {
    // //console.log("weekly matches ", cardPageNum);
    let { statusCode, data } = await POST(`${url}`, request);
    //console.log("statusCode ", statusCode);
    if (statusCode === 200) {
      if (loadMoreFor == "WEEKLYMATCH") {
        if (data.data.Weeklymatches.length > 0) {
          cardPageNum.weeklyDataPage = cardPageNum.weeklyDataPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.Weeklymatches.map((e, i) => {
            setWeeklyData((oldValue) => [...oldValue, e]);
          });
          // setLoadingDashboard1(false);
          // setWeeklyData([...Weeklydata, data.data.Weeklymatches]);
        }
      } else if (loadMoreFor == "RECOMMENDEDMATCH") {
        if (data.data.recommendationsmatches.length > 0) {
          cardPageNum.recommendedPage = cardPageNum.recommendedPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.recommendationsmatches.map((e, i) => {
            setRecommendedData((oldValue) => [...oldValue, e]);
          });
          // setLoadingDashboard1(false);
          // setRecommendedData([
          //   ...RecommendedData,
          //   data.data.recommendationsmatches,
          // ]);
        }
        
      } else if (loadMoreFor == "MUTUALMATCHES") {
        if (data.data.Mutalmatches.length > 0) {
          cardPageNum.mutalmatchespageno = cardPageNum.mutalmatchespageno + 1;
          data.data.Mutalmatches.map((e, i) => {
            setMutualData((oldValue) => [...oldValue, e]);
          });
          // setLoadingDashboard3(false);
          // setMutualData([...MutualData, data.data.Mutalmatches]);
        }
      } else if (loadMoreFor == "SELECTEDPROFILE") {
        if (data.data.Selectedprofiles.length > 0) {
          cardPageNum.selectedProfilePage = cardPageNum.selectedProfilePage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.Selectedprofiles.map((e, i) => {
            setSelectedProfileData((oldValue) => [...oldValue, e]);
          });
          // setLoadingDashboard3(false);
        }
      } else if (loadMoreFor == "RESPONSERECEIVED") {
        if (data.data.response_received.length > 0) {
          cardPageNum.responseReceivedPage =
            cardPageNum.responseReceivedPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.response_received.map((e, i) => {
            setresponseReceivedData((oldValue) => [...oldValue, e]);
          });
          // setLoadingDashboard2(false);
          // setresponseReceivedData([
          //   ...responseReceivedData,
          //   data.data.response_received,
          // ]);
        }
      } else if (loadMoreFor == "PROFILELOOKINGFOR") {
        if (data.data.Profileslookingfor.length > 0) {
          cardPageNum.profilelookingforPage =
            cardPageNum.profilelookingforPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.Profileslookingfor.map((e, i) => {
            setprofileILookingForData((oldValue) => [...oldValue, e]);
          });
          // setLoadingDashboard2(false);

          // setprofileILookingForData([
          //   ...profileILookingForData,
          //   data.data.Profileslookingfor,
          // ]);
        }
      } else if (loadMoreFor == "PROFILELOOKINGFORME") {
        if (data.data.Profileslookingforme.length > 0) {
          cardPageNum.profileLookingForMePage =
            cardPageNum.profileLookingForMePage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.Profileslookingforme.map((e, i) => {
            setprofileLoookingForMeData((oldValue) => [...oldValue, e]);
          });
          // setLoadingDashboard2(false);
          // setprofileLoookingForMeData([
          //   ...profileLoookingForMeData,
          //   data.data.Profileslookingforme,
          // ]);
        }
      } else if (loadMoreFor == "PHOTOREQUESTRECEIVED") {
        if (data.data.PhotoRequestReceived.length > 0) {
          cardPageNum.photorequestPage = cardPageNum.photorequestPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.PhotoRequestReceived.map((e, i) => {
            setphotoRequestData((oldValue) => [...oldValue, e]);
          });
          // setLoadingDashboard3(false);
          // setphotoRequestData([
          //   ...photoRequestData,
          //   data.data.PhotoRequestReceived,
          // ]);
        }
      } else if (loadMoreFor === "RECENTLYVIEW") {
        if (data.data.recently_viewed.length > 0) {
          cardPageNum.recentlyViewedPage = cardPageNum.recentlyViewedPage + 1;
          // setCardPageNum(...cardPageNum);
          data.data.recently_viewed.map((e, i) => {
            setrecentlyViewedData((oldValue) => [...oldValue, e]);
          });
          // setLoadingDashboard1(false);
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

   const callbackLoadMoreRecentlyView = async () => {
    // alert("2")
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      recentpageno: `${cardPageNum.recentlyViewedPage}`,
      recentsize: "5",
      status: "new",
    };
    var url = `${config.api.API_URL}${endPoints.recentlyViewUrl()}`;
    var loadMoreFor = "RECENTLYVIEW";
    apiCallLoadMore(url, request, loadMoreFor);
  };

  const callBackBlockProfile = async (profile) => {
    setBlockProfileName(profile)
    setShowBlockPopup(true)
  }

  const callBackViewPhone = async (profileId, profile) => {
    setMessageProfileDetails(profile);
    //console.log("profile id", profileId);

    // api/viewphonenumber
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      phoneViewFrom: loginFrom,
      toProfileId: `${profileId}`,
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.viewPhoneUrl()}`,
      request
    );

    if (statusCode === 200) {
      //console.log("view phone", data);
      if (data.status === "Success") {
        setPhoneNumData(data.data);
        setShowPhonePopup(true);
        // apicallDashboard()
        dispatch(dashboardLeftPanelData())
        dispatch(dashboardProfileData())
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  // const apicallDashboard = async () => {
  //   let request = {
  //     email: loginDetail()[0],
  //     userId: loginDetail()[1],
  //     pageno: "1",
  //     status: "new",
  //   };
  //   let { statusCode, data } = await POST(
  //     `${config.api.API_URL}${endPoints.dashboardUrl()}`,
  //     request
  //   );

  //   if (statusCode === 200) {
  //     setDashboard1Data(data?.data);
  //             localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, data?.data.status)
  //     // setAddTopBanner(data?.data?.bannerWebTop)
  //     setAddKjBanner(data?.data.addbanner[0]?.kjbanner);
  //     if(data?.data?.popup[0].showpopup == "RejectedProfile"){
  //       navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath))
  //     }
  //     setAddKjBanner(data?.data.addbanner[0]?.kjbanner);
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  // };
  // //console.log(dashboard1Data?.isPaid,"paidy");

  const apicallRecentlyViewed = async () => {
    // alert("3")
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      recentpageno: `${cardPageNum.recentlyViewedPage}`,
      recentsize: "5",
      status: "new",
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.recentlyViewUrl()}`,
      request
    );

    if (statusCode === 200) {
       setLoading(true)
      data.data.recently_viewed.map((e, i) => {
        setrecentlyViewedData1((oldValue) => [...oldValue, e]);
      });
      setrecentlyViewedDataCount(data?.data?.recentViewCount);
      // setrecentlyViewedData(data.data.recently_viewed);

      cardPageNum.recentlyViewedPage = cardPageNum.recentlyViewedPage + 1;
    } else if (statusCode === 401) {
      logout();
    }
  };

  
  
  const checkboxchange=()=>{
   
  }
  //console.log(recentlyViewedData1, "recentlyviewedsearch");
  const apicallweekly = async (pagesize,viewpage,cardpageno) => {  
    let url =
      location?.state?.isfrom === "weekly"
        ? endPoints.weeklyMatchesURl()
        : location?.state?.isfrom === "recommended" || viewpage=== "recommended"? endPoints.recommendedMatchesUrl()
        : location?.state?.isfrom === "response"  ? endPoints.responseReceivedUrl()
        : location?.state?.isfrom === "lookingfor"? endPoints.profilesLookingForUrl() 
        : location?.state?.isfrom === "lookingforme"? endPoints.profilesLookingFormeUrl() 
        : location?.state?.isfrom === "photorequest"? endPoints.photoRequestReceivedUrl() 
        : location?.state?.isfrom === "mutual"? endPoints.mutalMatchesUrl() 
        : location?.state?.isfrom === "selectedprofile"|| cardpageno==="selectedprofile"||viewpage==="selectedprofile"? endPoints.selectedProfilesUrl() 
        : location?.state?.isfrom === "recentlyviewed"? endPoints.recentlyViewUrl()
        : location?.state?.isfrom === "similarmatches" ? endPoints.similarMatchesUrl()
        : location?.state?.isfrom === "basic_search" || location?.state?.isfrom === "advance_search" || location?.state?.isfrom === "partner_profile_search"? endPoints.searchPageNew()
        : location?.state?.isfrom === "explore" ? endPoints.exploreUrl()
        : "";
        // alert(location?.state?.isfrom )

    let request =
      location?.state?.isfrom === "weekly"
        ? {
            email: loginDetail()[0],
      userId: loginDetail()[1],
      weeklypageno: `${cardPageNum.weeklyDataPage}`,
      weeklypagesize: pagesize,
      status: "new",
      shortlisted: shortlisted,
      ignored:ignored,
      viewed: viewed,
          }
        : location?.state?.isfrom === "recommended" || viewpage==="recommended"?  {
           email: loginDetail()[0],
      userId: loginDetail()[1],
      recomendationpageno: `${cardPageNum.recommendedPage}`,
      recomendationpagesize: pagesize,
      status: "new",
      shortlisted: shortlisted,
      ignored:ignored,
      viewed: viewed,
     
     
          }
          :location?.state?.isfrom === "response" ? {
           email: loginDetail()[0],
           userId: loginDetail()[1],
           response_receivedpageno: `${cardPageNum.responseReceivedPage}`,
           response_receivedpagesize: `${pagesize}`,
           status: "new",
           shortlisted: shortlisted,
           ignored:ignored,
            viewed: viewed,
          
            

        }
          : location?.state?.isfrom ===  "lookingfor" ?
          {
            email: loginDetail()[0],
      userId: loginDetail()[1],
      profileslookingforpageno: `${cardPageNum.profilelookingforPage}`,
      profileslookingforpagesize: pagesize,
      status: "new",
      shortlisted: shortlisted,
      ignored:ignored,
      viewed: viewed,
          }
          : location?.state?.isfrom ===  "lookingforme" ?
          {
             email: loginDetail()[0],
      userId: loginDetail()[1],
      profileslookingformepageno: `${cardPageNum.profileLookingForMePage}`,
      profileslookingformepagesize: pagesize,
      status: "new",
      shortlisted: shortlisted,
      ignored:ignored,
      viewed: viewed,
          }
          : location?.state?.isfrom ===  "photorequest" ?
          {
           email: loginDetail()[0],
      userId: loginDetail()[1],
      photorequestreceivedpageno: `${cardPageNum.photorequestPage}`,
      photorequestreceivedpagesize: pagesize,
      status: "new",
      shortlisted: shortlisted,
      ignored:ignored,
      viewed: viewed,
          }

          : location?.state?.isfrom ===  "mutual" ?
          {
            email: loginDetail()[0],
      userId: loginDetail()[1],
      mutalmatchespageno: `${cardPageNum.mutualPage}`,
      mutalmatchespagesize: pagesize,
      status: "new",
      shortlisted: shortlisted,
      ignored:ignored,
      viewed: viewed,
          }
          : location?.state?.isfrom ===  "selectedprofile" ||viewpage==="selectedprofile"?
          {
            email: loginDetail()[0],
      userId: loginDetail()[1],
      selectedprofilespageno: `${cardPageNum.selectedProfilePage}`,
      selectedprofilepagesize: pagesize,
      status: "new", 
      shortlisted: shortlisted,
      ignored:ignored,
      viewed: viewed,
          }
          : location?.state?.isfrom === "recentlyviewed"?
          {
                email:  loginDetail()[0],
    userId:  loginDetail()[1],
    status: "new",
    recentpageno: `${cardPageNum.recentlyViewedPage}`,
    recentsize: pagesize,
    shortlisted: shortlisted,
    ignored:ignored,
    viewed: viewed,
          }
          :location?.state?.isfrom === "similarmatches"?
          {
             email: loginDetail()[0],
    userId: loginDetail()[1],
    viewprofileid: location?.state?.id?location?.state?.id:localStorage.getItem("profileId"),
    status: "new",
    similarpageno: `${cardPageNum.similarMatchesPage}`,
    similarsize: pagesize,
    shortlisted: shortlisted,
      ignored:ignored,
      viewed: viewed,
          }
          :location?.state?.isfrom === "explore"?
          {
             email: loginDetail()[0],
              userId: loginDetail()[1],
              filterBy:location.state?.filterBy,
              cityId:location.state?.cityId,
              stateId:location.state?.stateId,
              countryId:location.state?.countryId,
              pageno:cardPageNum.exploreMatchesPage,
              educationId:location.state?.educationId,
              occupationId:location.state?.occupationId
          }
          :location?.state?.isfrom === "basic_search"?
          {
            pageno: Number(location?.state?.request?.pageno) + cardPageNum.basicSearchPage,
            email: loginDetail()[0] ?? null,
            userId: loginDetail()[1],
            ageTo: location?.state?.request?.ageTo,
            ageFrom: location?.state?.request?.ageFrom,
            heightFrom: location?.state?.request?.heightFrom,
            heightTo: location?.state?.request?.heightTo,
            marital_status: location?.state?.request?.marital_status,
            mother_tongue: location?.state?.request?.mother_tongue,
            educationId: location?.state?.request?.educationId,
            subcasteId: location?.state?.request?.subcasteId,
            casteIdList: location?.state?.request?.casteIdList,
            countryIdList: location?.state?.request?.countryIdList,
            religionIdList: location?.state?.request?.religionIdList,
            domainIdList:location?.state?.request?.domainIdList,
            shortlisted: location?.state?.request?.shortlisted,
            ignored: location?.state?.request?.ignored,
            contacted: location?.state?.request?.contacted,
            viewed: location?.state?.request?.viewed,
            withHoroscope: location?.state?.request?.withHoroscope,
            withPhoto: location?.state?.request?.withPhoto,
          }

          :location?.state?.isfrom === "advance_search" || location?.state?.isfrom === "partner_profile_search"?
          {
            pageno: Number(location?.state?.request?.pageno) + cardPageNum.advanceSearchPage,
            email: loginDetail()[0] ?? null,
            userId: loginDetail()[1],
            ageTo: location?.state?.request?.ageTo,
            physicalStatus:location?.state?.request?.physicalStatus,
            ageFrom: location?.state?.request?.ageFrom,
            heightFrom: location?.state?.request?.heightFrom,
            heightTo: location?.state?.request?.heightTo,
            marital_status: location?.state?.request?.marital_status,
            mother_tongue: location?.state?.request?.mother_tongue,
            educationId: location?.state?.request?.educationId,
            subcasteId: location?.state?.request?.subcasteId,
            casteIdList: location?.state?.request?.casteIdList,
            countryIdList: location?.state?.request?.countryIdList,
            religionIdList: location?.state?.request?.religionIdList,
            domainIdList:location?.state?.request?.domainIdList, 
            employedInIdList: location?.state?.request?.employedInIdList,
            occupationIdList: location?.state?.request?.occupationIdList,
            cityIdList: location?.state?.request?.cityIdList,
            stateIdList: location?.state?.request?.stateIdList,
            starIdList: location?.state?.request?.starIdList,
            raasiIdList: location?.state?.request?.raasiIdList,
            doshamIdList: location?.state?.request?.doshamIdList,
            drinkingIdList: location?.state?.request?.drinkingIdList,
            smokingIdList: location?.state?.request?.smokingIdList,
            foodIdList: location?.state?.request?.foodIdList,
            shortlisted: location?.state?.request?.shortlisted,
            ignored: location?.state?.request?.ignored,
            contacted: location?.state?.request?.contacted,
            viewed: location?.state?.request?.viewed,
            withHoroscope: location?.state?.request?.withHoroscope,
            withPhoto: location?.state?.request?.withPhoto,
          }
          : " "
          ;
      var loadMoreFor = location?.state?.isfrom === "weekly" ? "weekly":
                        location?.state?.isfrom === "recommended" || viewpage==="recommended"? "recommended":
                        location?.state?.isfrom === "response"?  "response" :
                        location?.state?.isfrom === "lookingfor"?"lookingfor" :
                        location?.state?.isfrom === "lookingforme"?"lookingforme" :
                        location?.state?.isfrom === "photorequest"?"photorequest" :
                        location?.state?.isfrom === "mutual"?"mutual" :
                        location?.state?.isfrom === "selectedprofile"||viewpage==="selectedprofile"?"selectedprofile" 
                        : location?.state?.isfrom === "recentlyviewed"? "recentlyviewed"
                        :location?.state?.isfrom === "similarmatches"? "similarmatches"
                        :location?.state?.isfrom === "explore"? "explore"
                        : location?.state?.isfrom === "basic_search" ? "basic_search"
                        : location?.state?.isfrom === "advance_search" ? "advance_search"
                        : location?.state?.isfrom === "partner_profile_search" ? "partner_profile_search"
                        : " ";
          
                        

     let { statusCode, data } = await POST(`${config.api.API_URL}${url}`, request);
     allowLoadMore.current = true
    if (statusCode === 200) {
      setIsLoading(false)
      setContentCardData((oldValue) =>
       ([...oldValue, data?.data?.contentCard]),
        
       )
      // setLoading(true)

      console.log(contentCardData, "card")
      setSubmitStatus(false)
      if(loadMoreFor === "weekly"){
        setFilterData('')
        setWeeklyCount(data.data)
        SetDataLength(data.data.Weeklymatches.length)
        setLoading(true)
      data.data.Weeklymatches.map((e, i) => {
        setWeeklyData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });

      cardPageNum.weeklyDataPage = cardPageNum.weeklyDataPage + 1;
     }
     else if (loadMoreFor === "recommended"  || viewpage==="recommended"){

      setFilterData('')
      setRecommendationCount(data.data)
      SetDataLength(data.data.recommendationsmatches.length)
      setLoading(true)
       data.data.recommendationsmatches.map((e, i) => {
        setRecommendedData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });
      cardPageNum.recommendedPage = cardPageNum.recommendedPage + 1;
     }
     else if (loadMoreFor === "response") {
      setFilterData('')
      setResponseCount(data.data)
      SetDataLength(data.data.response_received.length)
      setLoading(true)
       data.data.response_received.map((e, i) => {
        setresponseReceivedData((oldValue) => [...oldValue, e]);
        //console.log(e,"latestvalue");
        if(e.length>0){
          setLatest(true)
        } 
        
        // e.length>0?setLatest(true): ""

        setIsLoading(false)
      });

      cardPageNum.responseReceivedPage = cardPageNum.responseReceivedPage + 1;
     } else if (loadMoreFor === "lookingfor") {
      setFilterData('')
      setProfileLookingCount(data.data)
       SetDataLength(data.data.Profileslookingfor.length)
      setLoading(true)
       data.data.Profileslookingfor.map((e, i) => {
        setprofileILookingForData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });
      cardPageNum.profilelookingforPage = cardPageNum.profilelookingforPage + 1;
     }else if (loadMoreFor === "lookingforme") {
      setFilterData('')
      setLookingFormeCount(data.data)
      SetDataLength(data.data.Profileslookingforme.length)
      setLoading(true)
       data.data.Profileslookingforme.map((e, i) => {
        setprofileLoookingForMeData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
        
      });
      cardPageNum.profileLookingForMePage = cardPageNum.profileLookingForMePage + 1;
     }else if (loadMoreFor === "photorequest") {
      setPhotoRequestCount(data.data)
      setFilterData('')
      SetDataLength(data.data.PhotoRequestReceived.length)
      setLoading(true)
      data.data.PhotoRequestReceived.map((e, i) => {
        setphotoRequestData((oldValue) => [...oldValue, e]);
        setIsLoading(false)

      });

      cardPageNum.photorequestPage = cardPageNum.photorequestPage + 1;
     }else if (loadMoreFor === "mutual") {
      setFilterData('')
      setMutualCount(data.data)
       SetDataLength(data.data.Mutalmatches.length)
      setLoading(true)
       data.data.Mutalmatches.map((e, i) => {
        setMutualData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });
      cardPageNum.mutualPage = cardPageNum.mutualPage + 1;
      
     } 
   
     else if (loadMoreFor === "selectedprofile") {
      setSelectedCount(data.data)
      
       setFilterData('')
       SetDataLength(data.data.Selectedprofiles.length)
       setLoading(true)
       data.data.Selectedprofiles.map((e, i) => {
        setSelectedProfileData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });

      cardPageNum.selectedProfilePage = cardPageNum.selectedProfilePage +1;
     } else if (loadMoreFor === "recentlyviewed") {
      setFilterData('')
      // setRecentlyViewedLoader(true)
      setResentlyViewedCount(data.data)
      SetDataLength(data.data.recently_viewed.length)
       setLoading(true)
       data.data.recently_viewed.map((e, i) => {
        setrecentlyViewedData((oldValue) => [...oldValue, e]);
        setIsLoading(false)
      });

      cardPageNum.recentlyViewedPage = cardPageNum.recentlyViewedPage +1 ;
     }  
     else if (loadMoreFor === "similarmatches") {
      setFilterData('')
      setSimilarCount(data.data)
       SetDataLength(data.data.similarmatches.length)
      setLoading(true)
        if (data.data.similarmatches.length > 0) {
          cardPageNum.similarMatchesPage = cardPageNum.similarMatchesPage + 1;

          data.data.similarmatches.map((e, i) => {
            setSimilarMatchesData((oldValue) => [...oldValue, e]);
          });
          setIsLoading(false)
        }
      }
      else if (loadMoreFor === "explore") {
        setFilterData('')
        SetDataLength(data.data.filtermatches.length)
        setLoading(true)
        //console.log(data?.data,"exploredataaa");
        setExploreDataLength(data.data.filtermatches.length)
        if (data.data.filtermatches.length > 0) {
          cardPageNum.exploreMatchesPage = cardPageNum.exploreMatchesPage + 1;

          data.data.filtermatches.map((e, i) => {
            setExploreMatchesData((oldValue) => [...oldValue, e]);
          });
          setIsLoading(false)
        }else if(data.data.filtermatches.length===0){
          setShowEducation("No profiles found")
          setIsLoading(false)

        }
        setExploreLoadMore(false)
      }
      else if (loadMoreFor === "basic_search" || loadMoreFor === "advance_search" || location?.state?.isfrom === "partner_profile_search") {
        
        setFilterData('')
        SetDataLength(data.data.filtermatches.length)
        setLoading(true)
        //console.log(data?.data,"searchdata");
        
          if(data.data.filtermatches?.length <5 && location?.state?.isfrom !== "partner_profile_search"){
            setSearchResultMessage(data.data?.Recommendationmatchesmessagemore)
          }
        if (data.data.filtermatches.length > 0) {
          if(loadMoreFor === "basic_search"){
            cardPageNum.basicSearchPage = cardPageNum.basicSearchPage + 1;
          }else{
            cardPageNum.advanceSearchPage = cardPageNum.advanceSearchPage + 1;
          }

          data.data.filtermatches.map((e, i) => {
            setSearchPageData((oldValue) => [...oldValue, e]);
          });
          setIsLoading(false)
        }
      }
    }
     
     else if (statusCode === 401) {
      logout();
    }
  };
  
  useEffect(()=>{
    cardPageNum.weeklyDataPage= 1
    cardPageNum.recommendedPage= 1
    cardPageNum.responseReceivedPage= 1
    cardPageNum.profilelookingforPage= 1
    cardPageNum.profileLookingForMePage= 1
    cardPageNum.mutualPage= 1
    cardPageNum.selectedProfilePage= 1
    cardPageNum.photorequestPage= 1
    cardPageNum.recentlyViewedPage= 1
    cardPageNum.similarMatchesPage=1
    
    setSelectedProfileData([])
    setMutualData([])
    setphotoRequestData([])
    setprofileILookingForData([])
    setprofileLoookingForMeData([])
    setresponseReceivedData([])
    setRecommendedData([])
    setrecentlyViewedData([])
    setSimilarMatchesData([])
    setWeeklyData([])
  
    if(location?.state?.isfrom === "basic_search" || location?.state?.isfrom === "advance_search" || location?.state?.isfrom === "partner_profile_search"){
      // apicallweekly(5)
    }else{
      apicallweekly(5)
    }
    console.log(shortlisted,ignored,viewed,"shortlisted");

  },[shortlisted,ignored,viewed,location?.state?.isfrom])


  console.log(loading,submitStatus, "loading");
   console.log(contentCardData, "datacard")

  const callBackLoadMore = () => {
    if(submitStatus) return;
     setSubmitStatus(true)
    //  alert(isFromComponent)
     if(isFromComponent == "Filter"){ 
      setContentCardRange((oldValue) => [...oldValue,contentCardRange[contentCardRange?.length-1]+5])
      // setFilterDataPage(filterDataPage+1)
      console.log(" allowLoadMore.current", allowLoadMore.current);
      RefineSearch("load")
     }
     else{
      setContentCardRange((oldValue) => [...oldValue,contentCardRange[contentCardRange?.length-1]+5])
      apicallweekly(5);
     }
    
  };
  const callbackSelectReject = (
    btntype,
    profileID,
    typeRequest,
    profileDetails,
    index,
    type
  ) => {
    //console.log("next reint", btntype, profileID, typeRequest);
    if (btntype === "SELECT") {
      apicallSelectReject(typeRequest, profileID).then((res) => {
        //console.log("SELECT", res);
      });
    } else if (btntype === "REJECT") {
      apicallSelectReject(typeRequest, profileID).then((res) => {
        //console.log("REJECT", res, type);
        if (res.status === "Success") {
          if (typeRequest === "Reject") {
            handleReject(profileID, index, type);
          }
        }
      });
    } else if (btntype === "MESSAGE") {
      if (getIsLoginFromAdmin()) {
        setShowAdminPopup(true)
        return false;
      }
      setMessageProfileDetails(profileDetails);
      setSendMessagePopup(true);
    } else {
    }
  };

  const handleReject = (id, index, type) => {
    //console.log("messageFor", type);

    if (type === "weekly") {
      var newValue = Weeklydata.filter((e) => e.profileid !== id);
      setWeeklyData(newValue);
    } else if (type === "recommend") {
      var newValue = RecommendedData.filter((e) => e.profileid !== id);
      setRecommendedData(newValue);
    } else if (type === "lookingfor") {
      var newValue = profileILookingForData.filter((e) => e.profileid !== id);
      setprofileILookingForData(newValue);
    } else if (type === "lookingforme") {
      var newValue = profileLoookingForMeData.filter((e) => e.profileid !== id);
      setprofileLoookingForMeData(newValue);
    } else if (type === "mutual") {
      var newValue = MutualData.filter((e) => e.profileid !== id);
      setMutualData(newValue);
    } else if (type === "selected") {
      var newValue = selectedProfileData.filter((e) => e.profileid !== id);
      setSelectedProfileData(newValue);
    }
  };
  

  const handleRequestPhoto = () => {
    if (getIsLoginFromAdmin()) {
      setShowAdminPopup(true)
      return false;
    }
    if ( ProfileData?.status === "pending") {
      // setShowUploadImagePopup(true);
      setShowRequestpopup(true);
    } 
     else{
      setShowRequestpopup(true);
    }
  };

  const handleSetMessage = (id, type,sentMessageResponse) => {
    //console.log("type", type);
    if (type === "weekly") {
      setWeeklyData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "recommend") {
     
      setRecommendedData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "lookingfor") {
     
      setprofileILookingForData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "lookingforme") {
      
      setprofileLoookingForMeData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "mutual") {
     
      setMutualData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "selected") {
     
      setSelectedProfileData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if (type === "search") {
    
      setSearchPageData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    }else if (type === "filter") {
    
      setFilterData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    } else if(type === "explore"){
      setExploreMatchesData(prev=>prev.map((e) => {
        if (e.profileid === id) {
          return { ...e, profilealreadyContected: "Y" , messageSent: [{ subjectId: sentMessageResponse?.subjectId }] };
        }
        return e;
      }));
    }
   
  };

  
  const updateData = (data,isPhoto,isParentNumber,profileId,isMessaged,sentMessageResponse)=>{
    let temp = {...data}

    if(isPhoto && temp.profileid == profileId){
      temp = {...temp,profilerequestcheck: true }
    }

    if(isMessaged && temp.profileid == profileId){
      temp = {...temp,profilealreadyContected: "Y", messageSent: [{ subjectId: sentMessageResponse?.subjectId }] }
    }

    if(isParentNumber && temp.profileid == profileId){
      temp = {...temp,parentnumberrequestcheck: true }
    }

    return temp;
  }

  const updateRequestedData = (isPhotoRequested, isParentsNumberRequested, profileID, isMessaged,sentMessageResponse) => {
    if (messageFor === "weekly") {
      setWeeklyData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)));
    }else if (messageFor === "recommend") {
      setRecommendedData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)));
    } else if (messageFor === "response") {
      setresponseReceivedData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)));
    } else if (messageFor === "lookingfor") {
      setprofileILookingForData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)));
    } else if (messageFor === "lookingforme") {
      setprofileLoookingForMeData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)));
    } else if (messageFor === "photorequest") {
      setphotoRequestData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)));
    }else if (messageFor === "mutual") {
      setMutualData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)));
    } else if (messageFor === "selected") {
      setSelectedProfileData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)));
    } else if (messageFor === "recent") {
      setrecentlyViewedData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID, isMessaged, sentMessageResponse)));
      setrecentlyViewedData1(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID, isMessaged, sentMessageResponse)))
    } else if(messageFor === "similar"){
      setSimilarMatchesData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID, isMessaged, sentMessageResponse)))
    }else if(messageFor === "explore"){
      setExploreMatchesData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)))
    }else if(messageFor === "filter"){
      setFilterData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)))
    }else if(messageFor === "search"){
      setSearchPageData(prev=>prev.map((e) => updateData(e, isPhotoRequested, isParentsNumberRequested, profileID)))
    }
    console.log("update-request",isPhotoRequested, isParentsNumberRequested, profileID);
  }

  const apicallMultiRequest = async (profileID, value, type,bulktrigger=true) => {
    if(value.typeList?.length === 0){
      return;
    }
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      sendsms: value.sendSms,
      "typeList": value.typeList,
      "triggerType": "singletrigger",
    };
    if(bulktrigger === true){
      request.bulktrigger=true;
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.requestPhotoHoroTrustParent()}`,
      request
    );
    console.log("apicallMultiRequest",data);

    const isPhotoRequested = data?.data?.photoRequest === "Y"
    const isParentsNumberRequested = data?.data?.parentsnumberRequest === "Y"

    if (statusCode === 200) {

      if (isPhotoRequested && isParentsNumberRequested) {
        if (data?.data?.recomm_count> 0 && request.bulktrigger===true) {
          setShowBulkRequestPopupData({
            show: true,
            title: "Photo and Parents Number Request sent!",
            content: "Photo and Parents Number Request sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Photo and Parents Number Request sent!");
          setshowSuccessPopup(true);
        }
        updateRequestedData(isPhotoRequested, isParentsNumberRequested, profileID)
      } else if (isParentsNumberRequested) {
        if (data?.data?.recomm_count > 0 && request.bulktrigger===true) {
          setShowBulkRequestPopupData({
            show: true,
            title: "Parents Number Request sent!",
            content: "Parents Number Request sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Parents Number Request sent!");
          setshowSuccessPopup(true);
        }
        updateRequestedData(isPhotoRequested, isParentsNumberRequested, profileID)
      } else if (isPhotoRequested) {
        if (data?.data?.recomm_count > 0 && request.bulktrigger===true) {
          setShowBulkRequestPopupData({
            show: true,
            title: "Photo Request sent!",
            content: "Photo Request sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Photo Request sent!");
          setshowSuccessPopup(true);
        }
        updateRequestedData(isPhotoRequested, isParentsNumberRequested, profileID)
      }

      return data;
    } else if (statusCode === 401) {
      logout();
    }
  };
  

  const apicallHoroTrustRequest = async (profileID, value, type) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      // type: type,
      sendsms: value.sendSms,
      "typeList": value.typeList,
      "triggerType": "singletrigger",
      bulktrigger: true,
    };


    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.requestPhotoHoroTrustParent()}`,
      request
    );

    const isPhotoRequested = value.typeList?.includes('Photo')
    const isParentsNumberRequested = value.typeList?.includes('Parents Number')

    if (statusCode === 200) {

      if (type === "photo") {
        if (data?.data?.recomm_count > 0) {
          setShowBulkRequestPopupData({ show: true, 
            title: "Photo Request Sent", 
            content: "Photo Request Sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Photo Request sent!");
          setshowSuccessPopup(true);
        }
      } else {
        if (data?.data?.recomm_count > 0) {
          setShowBulkRequestPopupData({ show: true, 
            title: "Parent No. Request sent", 
            content: "Parents No. Request sent Successfully",
            message: data?.data?.bulkrequestcontent,
          })
        } else {
          setsuccessMessage("Photo Request sent!");
          setshowSuccessPopup(true);
        }
      }

      updateRequestedData(isPhotoRequested, isParentsNumberRequested, profileID, false)


      // apicallDashboard(1);
      setPhotoRequest(data?.data)
      // setshowSuccessPopup(true);
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apicallSelectReject = async (type, profileID) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      type: `${type}`,
      toProfileId: `${profileID}`,
    };
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.selectRejectUrl()}`,
      request
    );

    if (statusCode === 200) {
    } else if (statusCode === 401) {
      logout();
    }
  };
   const apicallMessage = async (profileID, values) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      toProfileId: `${profileID}`,
      message: values.message,
      status: "SendMessage",
      sendsms: values.sendSms,
      messageFrom: "Desktop",
      requestparentcheck: values.requestparentcheck ? values.requestparentcheck  === "Y" ? true : false : undefined,
      "triggerType": "singletrigger",
      requestphotocheck: values.requestphotocheck ? values.requestphotocheck === "Y" ? true : false : undefined,
      bulktrigger: true,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.sendMessageUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.status === "Success") {
        setShowBulkRequestPopupData({ show: true, 
          title: "Message Sent", 
          content: "Message sent successfully!",
          message: data?.data?.bulkrequestcontent,
          imageSrc:MessageSentIcon
        })
        updateRequestedData(false,request.requestparentcheck === true,profileID, true,data?.data)
        // setshowMessageSuccess(true);
        
      }
      return data;
    } else if (statusCode === 401) {
      logout();
    }
    return data;
  };
  
  const apicalladvancesearch = async (preSearch, preSearchRequest) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
    }
    if (preSearch) {
      request = {
        email: loginDetail()[0],
        userId: loginDetail()[1],
        "ageTo": preSearchRequest?.ageTo,
        "ageFrom": preSearchRequest?.ageFrom,
        "heightFrom": Number(preSearchRequest?.heightFrom),
        "heightTo":  Number(preSearchRequest?.heightTo),
        "marital_status": preSearchRequest?.marital_status??[],
        "physicalStatus": preSearchRequest?.physicalStatus??[],
        "mother_tongue": preSearchRequest?.mother_tongue??[],
        "educationId": preSearchRequest?.educationId??[],
        "casteIdList": preSearchRequest?.casteIdList??[],
        "subcasteId": preSearchRequest?.subcasteId??[],
        "countryIdList": preSearchRequest?.countryIdList??[],
        "religionIdList":preSearchRequest?.religionIdList??[],
        "employedInIdList": preSearchRequest?.employedInIdList??[],
        "occupationIdList": preSearchRequest?.occupationIdList??[],
        "cityIdList": preSearchRequest?.cityIdList??[],
        "stateIdList": preSearchRequest?.stateIdList??[],
        "starIdList":preSearchRequest?.starIdList??[],
        "raasiIdList": preSearchRequest?.raasiIdList??[],
        "domainIdList":preSearchRequest?.domainIdList??[],
        "doshamIdList":preSearchRequest?.doshamIdList??[],
        "drinkingIdList":preSearchRequest?.drinkingIdList??[],
        "smokingIdList":preSearchRequest?.smokingIdList??[],
        "foodIdList": preSearchRequest?.foodIdList??[],
        "shortlisted": preSearchRequest?.shortlisted??"",
        "ignored": preSearchRequest?.ignored??"",
        "contacted": preSearchRequest?.contacted??"",
        "viewed": preSearchRequest?.viewed??"",
        "withHoroscope": preSearchRequest?.withHoroscope??"",
        "withPhoto": preSearchRequest?.withPhoto??"",
        "days": preSearchRequest?.days??"",
        "weeks": preSearchRequest?.weeks??"",
        "months": preSearchRequest?.months??"",
      };
    }
    

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${preSearch? endPoints.searchfilterUrl() : endPoints.advanceSearchUrl()}`,
      request
    );
console.log("datasearchfilterUrl",data);
    if (statusCode === 200) {
      setSelectedFilter("2")
      setAdvanceSearch(data.data);
    } else if (statusCode === 401) {
      logout();
    }
  };

  const dispatch = useDispatch()
  
    const {raasiList}  = useSelector(state => ({
      raasiList: state.Home?.raasiList?.data?.data?.raasi
    }));

    const {stateList}  = useSelector(state => ({
      stateList: state.Home?.stateList?.data?.data?.states
    }));

    const {cityList}  = useSelector(state => ({
      cityList: state.Home?.cityList?.data?.data?.cities
    }));

    const {casteList}  = useSelector(state => ({
      casteList: state.Home?.cList?.data?.data?.castes
    }));
  
  
    const {subCasteList}  = useSelector(state => ({
      
      subCasteList: state.Home?.scList?.data?.data?.subcastes
    }));
  
    //console.log(raasiList,"1234567890");

    //console.log(stateList,"stateList");

    //console.log(cityList,"cityList");
    
    // console.log(casteList,"ppppp");

    // console.log(subCasteList,"subCasteList");


    useEffect(() => {
      raasiList && addFilter(raasiList, "raasi")
    },[raasiList])

    useEffect(() => {
      //console.log(stateList,"stateList");
      stateList && addFilter(stateList, "state")
    },[stateList])

    useEffect(() => {
      //console.log(cityList,"cityList");
      cityList && addFilter(cityList, "city")
    },[cityList])

    useEffect(() => {
      //console.log(casteList,"casteList");
      casteList && addFilter(casteList, "caste")
    },[casteList])

    useEffect(() => {
      // console.log(subCasteList,"subCasteList");
      subCasteList && addFilter(subCasteList, "subCaste")
    },[subCasteList])

    const addFilter = (event, menu, all) => {
        //console.log(event, menu, advancesearch, "stateList");
        // Filter out duplicate child options before appending
        if(isChecked == "N"){
        const uniqueChildOptions = event?.filter(
          (child) =>
            !advancesearch?.[menu]?.find(
              (existingChild) => existingChild.id === child.id
            )
        );
        //console.log(uniqueChildOptions,"uniqueChildOptions");

        let selectAll = uniqueChildOptions?.map((status, i) =>{
          status.selected = 'N'
        })
        // Append child options to raasi options
        setAdvanceSearch((prevSearchOptions) => ({
          ...prevSearchOptions,
          [menu]: [...prevSearchOptions?.[menu], ...uniqueChildOptions],
        }));
        return false;
      }
      else if(isChecked=='C'){
        setAdvanceSearch((prevSearchOptions) => ({
          ...prevSearchOptions,
          [menu]: [],
        }));
      }
      else{
        let as = []
        event.map((selected)=>{
          if(as.indexOf(selected.id)==-1){
            as.push(selected.id)
          }
        })
        
        var uniqueChild = ""
        let res = ""
        res = advancesearch?.[menu]?.map(sel=>sel.id)
        uniqueChild = res?.filter(function(obj) { return as.indexOf(obj) == -1; });
        uniqueChild?.map((unique,i)=>{
          res = advancesearch?.[menu]?.map(sel=>sel.id)
          uniqueChild = res?.filter(function(obj) { return as.indexOf(obj) == -1; });
          let index1 = res.indexOf(unique)
            advancesearch?.[menu].splice(index1, 1)
        })
        //console.log(advancesearch?.[menu],"advancesearch?.[menu]");
      }
    }

    // //console.log(advancesearch,"add all filter");

  
    const selectAllFilter = (event, menu, all) => {
      //console.log(event, menu, all,"select all filter");
      if(menu == "motherTongue" ){
        dispatch(registerCasteList(
           
        advancesearch?.["motherTongue"]?.map((status, i) =>(
          status.id
        )),
        advancesearch?.["religion"]?.map((status, i) =>(
          status.selected == "Y" ?? status.id
        )), 
        ))
        advancesearch?.["caste"]?.splice(0,advancesearch?.["caste"]?.length)
        advancesearch?.["subcaste"]?.splice(0,advancesearch?.["subcaste"]?.length)
      }
      if(menu == "religion" ){
        dispatch(registerCasteList(
          advancesearch?.["motherTongue"]?.map((status, i) =>{
            if(status.selected == "Y" ){
              return (
                status.id
              )
            }
            
          }), 
        advancesearch?.["religion"]?.map((status, i) =>(
          status.id
        )), 
       
        ))
        advancesearch?.["caste"]?.splice(0,advancesearch?.["caste"]?.length)
        advancesearch?.["subcaste"]?.splice(0,advancesearch?.["subcaste"]?.length)
      }
      if(menu == "caste") {
        dispatch(registerSubCasteList(
        advancesearch?.["caste"]?.map((status, i) =>(
          status.id
        ))))
        advancesearch?.["subcaste"]?.splice(0,advancesearch?.["subcaste"]?.length)
      }
      // if(menu == "state") {
      //   dispatch(registerCityList(
      //   advancesearch?.["state"]?.map((status, i) =>(
      //     status.id
      //   ))))
      //   advancesearch?.["city"]?.splice(0,advancesearch?.["city"]?.length)
      // }
      // if(menu == "country") {
      //   dispatch(registerStateList(
      //   advancesearch?.["country"]?.map((status, i) =>(
      //     status.id
      //   ))))
      //   advancesearch?.["state"]?.splice(0,advancesearch?.["state"]?.length)
      //   advancesearch?.["city"]?.splice(0,advancesearch?.["city"]?.length)
      // }
      if(menu == "country") {
        let id=[]
        advancesearch?.["country"]?.map((status, i) =>{
          id.push(status.id)
        })
        setIsChecked('N')
        dispatch(registerStateList(id))
      }
      if(menu == "state") {
        let id=[]
        advancesearch?.["state"]?.map((status, i) =>{
          id.push(status.id)
        })
        setIsChecked('N')
        dispatch(registerCityList(id))
      }
      if(menu == "star") {
        let id=[]
        advancesearch?.["star"]?.map((status, i) =>{
          id.push(status.id)
        })
        setIsChecked('N')
        dispatch(registerRaasiList(id))
      }
      let selectAll = advancesearch?.[menu]?.map((status, i) =>{
        status.selected = 'Y'
      })
      setAdvanceSearch({
        ...advancesearch,
        [menu]: advancesearch?.[menu]
      })
    }
  
    const clearAllFilter = (event, menu, clear) => {
      
      if(menu == "religion" || menu == "motherTongue") {
        let clearAll = advancesearch?.["religion"]?.map((status, i) =>{
          status.selected = 'N'
        })    
        advancesearch?.["motherTongue"]?.map((status, i) =>{
          status.selected = 'N'
        })   
        advancesearch?.["caste"]?.map((status, i) =>{
          status.selected = 'N'
        }) 
        advancesearch?.["subCaste"]?.map((status, i) =>{
          status.selected = 'N'
        })  
        setSelectedMRList({
          motherTongue:[],
          religion:[]
        })
        console.log(clearAll,"clearAllclearAll");
        setIsChecked('C')
        dispatch(registerCasteList(0,0)) 
        dispatch(registerSubCasteList(0)) 
        console.log(advancesearch?.["caste"],"advancesearchadvancesearch");
         setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }
      else if(menu == "caste") {
        let clearAll = advancesearch?.[menu]?.map((status, i) =>{
          status.selected = 'N'
        })    
        setIsChecked('C')
        dispatch(registerSubCasteList(0)) 
         setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }

      // if(menu == "religion" || menu == "motherTongue") {
        // let clearAllCaste = advancesearch?.["caste"]?.splice(0,advancesearch?.["caste"]?.length)
        // let clearAllSubCaste = advancesearch?.["subCaste"]?.splice(0,advancesearch?.["subCaste"]?.length)
        // setAdvanceSearch({
        //   ...advancesearch,
        //   ["caste"]: advancesearch?.[menu],
        //   ["subCaste"]: advancesearch?.[menu]
        // }) 
      // }

      // if(menu == "caste") {
      //   let clearAllSubCaste = advancesearch?.["subCaste"]?.splice(0,advancesearch?.["subCaste"]?.length)
      //   setAdvanceSearch({
      //     ...advancesearch,
      //     ["subCaste"]: advancesearch?.[menu]
      //   }) 
      // }

      // else if(menu == "country") {
      //   let clearAllState = advancesearch?.["state"]?.splice(0,advancesearch?.["state"]?.length)
      //   setAdvanceSearch({
      //     ...advancesearch,
      //     ["state"]: advancesearch?.[menu]
      //   }) 
      //   let clearAllCity = advancesearch?.["city"]?.splice(0,advancesearch?.["city"]?.length)
      //   setAdvanceSearch({
      //     ...advancesearch,
      //     ["city"]: advancesearch?.[menu]
      //   }) 
      // }

      // else if(menu == "state") {
      //   let clearAll = advancesearch?.["city"]?.splice(0,advancesearch?.["city"]?.length)
      //   setAdvanceSearch({
      //     ...advancesearch,
      //     ["city"]: advancesearch?.[menu]
      //   }) 
      // }
      else if(menu == "star") {
        let selectAll = advancesearch?.[menu]?.map((status, i) =>{
          status.selected = 'N'
        })    
        setIsChecked('C')
        dispatch(registerRaasiList(0))  
         setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }

      else if(menu == "country") {
        let selectAll = advancesearch?.[menu]?.map((status, i) =>{
          status.selected = 'N'
        })    
        setIsChecked('C')
        dispatch(registerStateList(0)) 
        dispatch(registerCityList(0)) 
         setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }
      else if(menu == "state") {
        let selectAll = advancesearch?.[menu]?.map((status, i) =>{
          status.selected = 'N'
        })    
        setIsChecked('C')
        dispatch(registerCityList(null)) 
         setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }
     
       else{ let clearAll = advancesearch?.[menu]?.map((status, i) =>{
          status.selected = 'N'
        })
        setAdvanceSearch({
          ...advancesearch,
          [menu]: advancesearch?.[menu]
        }) 
      }
      //console.log(advancesearch,"advancesearch");
    }

    const isChristain = advancesearch?.religion?.filter(data=>data?.id == Constants.christialId && data?.selected == "Y")?.length !=0
  
    const filterOnchange = (id, name, selected, menu, event) => {
      //console.log(id, name, selected, menu,event,"selct filter");
      if(menu == "star"){
        let selectedStars = selectedStarList
        let index1 = ""
        index1 = selectedStars.indexOf(id)
        if(index1==-1){
          let object = advancesearch?.[menu]?.filter(star => star.selected === 'Y');
          object.map((selected)=>{
            if(selectedStars.indexOf(selected.id)==-1){
              selectedStars.push(selected.id)
            }
          })
        }
        if(selected == "Y"){
          selectedStars.splice(index1, 1)
        }
        else{
          selectedStars.push(id)
        }
        setSelectedStarList(selectedStars)
        setIsChecked(selected)
        //console.log(selectedStarList,"selectedStarList");
        dispatch(registerRaasiList(selectedStars))
      }


      if(menu == "motherTongue" || menu == "religion"){
        advancesearch?.["subCaste"]?.splice(0,advancesearch?.[menu]?.length)
        let as = selectedMRList?.[menu]
        let index1 = ""
        let object = advancesearch?.[menu]?.filter(o => o.selected === 'Y');
        if(object?.length>0)
        object.map((obj, j) => {
        let index = as.indexOf(obj.id)
        index1 = as.indexOf(id)
        if(index == -1){
          as.push(obj.id)
        }
        if( index1 == -1){
          as.push(id)
        }
      })

      else{
        index1 = as.indexOf(id)
        if( index1 == -1){
          as.push(id)
        }
      }

      if(selected == "Y"){
        as.splice(index1, 1)
      }
        setSelectedMRList(selectedMRList)
        //console.log(as,"selct filter");
        setIsChecked(selected)
        //console.log(selectedMRList,as,"selectedMRList");

        dispatch(registerCasteList(selectedMRList?.motherTongue?.length==0?"0":selectedMRList?.motherTongue, selectedMRList?.religion?.length==0?"0":selectedMRList?.religion))

      }
      if(menu == "country"){
        let selectedCountry = selectedCountries
        let index1 = ""
        index1 = selectedCountry.indexOf(id)
        if(index1==-1){
          let object = advancesearch?.[menu]?.filter(country => country.selected === 'Y');
          object.map((selected)=>{
            if(selectedCountry.indexOf(selected.id)==-1){
              selectedCountry.push(selected.id)
            }
          })
        }
        if(selected == "Y"){
          selectedCountry.splice(index1, 1)
        }
        else{
          selectedCountry.push(id)
        }
        setIsChecked(selected)
        dispatch(registerStateList(selectedCountry))
        if(selectedCountry?.length==0){
          dispatch(registerCityList(selectedCountry))
        }       
      }

      if(menu == "state"){
        let selectedState = selectedStates
        let index1 = ""
        index1 = selectedState.indexOf(id)
        if(index1==-1){
          let object = advancesearch?.[menu]?.filter(state => state.selected === 'Y');
          object.map((selected)=>{
            if(selectedState.indexOf(selected.id)==-1){
              selectedState.push(selected.id)
            }
          })
        }
        let index2 = selectedState.indexOf(id)
        if(selected == "Y"){
          selectedState.splice(index2, 1)
        }
        else{
          selectedState.push(id)
        }
        setIsChecked(selected)
        dispatch(registerCityList(selectedState))
      }      

      if(menu == "caste"){
        let selectedCaste = selectedCasteList
        let index1 = ""
        index1 = selectedCaste.indexOf(id)
        if(index1==-1){
          let object = advancesearch?.[menu]?.filter(state => state.selected === 'Y');
          object.map((selected)=>{
            if(selectedCaste.indexOf(selected.id)==-1){
              selectedCaste.push(selected.id)
            }
          })
        }
        console.log(selectedCaste,"selectedCaste");
        let index2 = selectedCaste.indexOf(id)
        if(selected == "Y"){
          selectedCaste.splice(index2, 1)
        }
        else{
          selectedCaste.push(id)
        }
        setIsChecked(selected)
        dispatch(registerSubCasteList(selectedCaste?.length==0?"0":selectedCaste))
      }

      if(menu == "subCaste"){
        let selectedSubCaste = selectedSubCasteList
        let index1 = ""
        index1 = selectedSubCaste.indexOf(id)
        if(index1==-1){
          let object = advancesearch?.[menu]?.filter(state => state.selected === 'Y');
          object.map((selected)=>{
            if(selectedSubCaste.indexOf(selected.id)==-1){
              selectedSubCaste.push(selected.id)
            }
          })
        }
        console.log(selectedSubCaste,"selectedSubCasteList");
        let index2 = selectedSubCaste.indexOf(id)
        if(selected == "Y"){
          selectedSubCaste.splice(index2, 1)
        }
        else{
          selectedSubCaste.push(id)
        }
        setIsChecked(selected)
        dispatch(registerSubCasteList(selectedSubCaste?.length==0?"0":selectedSubCaste))
      }
  
      let selectOption = advancesearch?.[menu]?.map((status, i) =>{
        if(status.id == id){
          if(selected == "N"){
            status.selected = 'Y'
          }
          else if(selected == "Y"){
            status.selected = 'N'
          }
        }
      })
      console.log(advancesearch,"advancesearch?.[menu]");
      if(menu == "religion"){
        if(advancesearch?.religion?.filter(data=>data?.id == Constants.christialId && data?.selected == "Y")?.length == 0){
          advancesearch.domain = advancesearch.domain?.map(data=>({...data, selected:"N"}))
        }
      }
      setAdvanceSearch({
        ...advancesearch,
        [menu]: advancesearch?.[menu]
      }) 

     
    }

    const filterOnchangeForActivity = (item) => {
      let index = selectedActivityFilter.indexOf(item)
      if(index >-1){
        let removeActivityFIlter = selectedActivityFilter.splice(index,1)
        //console.log(removeActivityFIlter,selectedActivityFilter,"removeActivityFIlter");
        setSelectedActivityFilter(selectedActivityFilter)
      }
      else{
        setSelectedActivityFilter([...selectedActivityFilter,item])
      }
    }

    //console.log(selectedActivityFilter,"selectedActivityFilter");

    const handleSearchFIlter = (e,menu) => {
      //console.log(advancesearch, "advancesearch");
      // setsearchText(e.target.value);
      if (e.target.value !== "") {
        setIsSearch({...isSearch, [menu]:true})
        const filteredEntity = advancesearch?.[menu]?.filter(entity => entity?.name?.toLowerCase()?.indexOf(e.target.value.toLowerCase()) !== -1);
        setTempAdvanceSearch({
          ...tempAdvancesearch,
          [menu]: filteredEntity
        })
      } else {
        setIsSearch({...isSearch, [menu]:false})
        setAdvanceSearch(advancesearch)
      }
    } 
  
    const RefineSearch = (component) => {
      let pageNo = filterDataPage;
      console.log(selectedOption,"selectedOptionselectedOption");
      if(component != "load"){
        setContentCardData([])
        setContentCardRange([3])
        setIsLoading(true)
        setFilterData('')
        SetDataLength(-1) 
        setFilterDataPage(1)
        pageNo = 1;
      }
      else{
        setFilterDataPage(filterDataPage + 1)
        pageNo = filterDataPage + 1
      }
      setIsFilterResult(true)
      setIsFromComonent("Filter")
      setIsLoadFromRecommeded(false)
      advancesearch?.minheightId?.map((heightId, i)=>{
        if(heightId.floatValue == advancesearch?.minheight){
          console.log(advancesearch?.minheight,"advancesearch?.minheight");
          selectedOption["heightFrom"] = heightId.id
          if(heightId.floatValue == advancesearch?.maxheight){
            selectedOption["heightTo"] = heightId.id
         }
        }
        else if(heightId.floatValue == advancesearch?.maxheight){
           selectedOption["heightTo"] = heightId.id
        }
      })
      Object.entries(selectedOption).map((key,value) => {
        let keyName = key[0]
        //console.log(keyName,"keyNamekeyName");
        if(keyName == "pageno"){
          selectedOption[keyName] = pageNo
        }
        else if(keyName == "ageFrom" ){
          selectedOption[keyName] = advancesearch?.["minage"]
        }
        else if(keyName == "ageTo"){
          selectedOption[keyName] = advancesearch?.["maxage"]
        }
        // else if(keyName == "heightFrom"){
        //   selectedOption[keyName] = advancesearch?.["minheight"]
        // }
        // else if(keyName == "heightTo"){
        //   selectedOption[keyName] = advancesearch?.["maxheight"]
        // }
        else if(keyName == "days" ||keyName == "weeks" ||keyName == "months" ){
          //console.log(selectedActivityFilter,"selectedActivityFilter");
          selectedActivityFilter?.map((activity,i) => {

            if(activity == "Within a day" && keyName == "days"){
              selectedOption[keyName] = activity
            }
            else if(activity == "Within a week" && keyName == "weeks"){
              selectedOption[keyName] = activity
            }
            else if(activity == "Within a month" && keyName == "months"){
              selectedOption[keyName] = activity
            }
            else{

            }
          })
        }
        
        else if(keyName == "withHoroscope" || keyName == "withPhoto"){
          advancesearch?.["showProfile"]?.map((val,i) => {
            if(val.selected == "Y" && val.name == "With Photo"){
                selectedOption["withPhoto"] = "on"
              }
              else if(val.selected == "Y" && val.name == "With Horoscope"){
                selectedOption["withHoroscope"] = "on"
              }
          })
        }
        else if(keyName == "shortlisted" || keyName == "ignored" || keyName == "contacted" || keyName == "viewed"){
          advancesearch?.["dontshowProfile"]?.map((val,i) => {
            if(val.selected == "Y"){
              if(val.name == "Rejected"){
                selectedOption["ignored"] = "on"
              }
              else if(val.name == "Already Contacted"){
                selectedOption["contacted"] = "on"
              }
              else if(val.name == "Viewed Profiles"){
                selectedOption["viewed"] = "on"
              }
              else {
                selectedOption["shortlisted"] = "on"
              }
            }
          })
        }
        else{
          advancesearch?.[keyName]?.map((val,i) => {
            if(val.selected == "Y"){
              if(keyName == "maritalStatus"){
                selectedOption[keyName].push(val.name)
              }
              else{
                selectedOption[keyName].push(val.id)
              }
            }
            //console.log(selectedOption,"selectedOption");
          })
        }
        console.log(selectedOption,"selectedOptionselectedOption");


        setFilterRequest(selectedOption)
      })
      sendFilterRequest()
      dispatch(sendFilterRequest(selectedOption))
    }


   

    let {filterResponseData} = useSelector(state => ({
      filterResponseData: state.FilterSection?.filterResponseData?.data
    }));

    console.log(filterResponseData,"filterResponseDatafilterResponseData");

    const ageRange = (min,max) => {
      advancesearch["minage"] = min
      advancesearch["maxage"] = max
      setAdvanceSearch(advancesearch)
    }

    const heightRange = (min,max) => {
      console.log(min,max,"min,max");
      console.log(advancesearch.minheightId,"advancesearchadvancesearch");
          advancesearch.minheight = min
          advancesearch.maxheight = max
    }
    //  const data =   location?.state?.isfrom === "response"?  setFilterData([]) : ""
    useEffect(() => {
      // alert(isFromComponent)
      setSubmitStatus(false)
      setExploreLoadMore(false)
      // data()
      console.log(contentCardData,"contentCardData");
      if(filterResponseData?.contentCard){
        setContentCardData((oldValue) => [...oldValue, filterResponseData?.contentCard])
      }
      filterResponseData?.filtermatches?.map((e, i) => {
        setFilterData((oldValue) => [...oldValue, e]);
      });
      
      console.log(filterResponseData?.filtermatches?.length,"checkingp");
      setSelectedProfileData([])
      setMutualData([])
      setphotoRequestData([])
      setprofileILookingForData([])
      setprofileLoookingForMeData([])
      setresponseReceivedData([])
      setRecommendedData([])
      setrecentlyViewedData([])
      setSimilarMatchesData([])
      setWeeklyData([])
      setSearchPageData([])
      if(isFromComponent !== "Filter"){
        location.state?.data?.map((e,i) => {
          setSearchPageData((oldValue) => [...oldValue, e]);
        })
        if(location?.state?.isfrom !== "partner_profile_search"){
          if(location.state?.data?.length === 0){

            setSearchResultMessage(location.state?.dataSet?.recommendationmatchesmessageless)
          } else if(location.state?.data?.length < 5){
            setSearchResultMessage(location.state?.dataSet?.Recommendationmatchesmessagemore)
          }
        }else{
          setPartnerProfileCount(location.state?.dataSet?.searchresultcount)
        }
        SetDataLength(location.state?.data?.length)
      }else{
        setPartnerProfileCount("")
      }
     
      
      allowLoadMore.current = true;
     
      if(filterResponseData){
        setAddTopBanner(filterResponseData?.bannerWebTop)
        setAddBanner(filterResponseData?.bannerWebright);
        SetDataLength(filterResponseData?.filtermatches?.length)
        // setLoading(true)
      }
      else{
        setAddTopBanner(location?.state?.topBanner)
        setAddBanner(location?.state?.banner);
      }
      setIsLoading(false)
    },[filterResponseData?.filtermatches])

    useEffect(() => {
      if(Object.keys(advancesearch).length > 0){
      let as = selectedMRList?.["motherTongue"]
        let object = advancesearch?.["motherTongue"]?.filter(o => o.selected === 'Y');
        object?.map((obj, j) => {
        let index = as.indexOf(obj.id)
        if(index == -1){
          as.push(obj.id)
        }
      })
      setSelectedMRList(selectedMRList)
    }
    },[selectedMRList?.["motherTongue"],advancesearch?.["motherTongue"]])

    useEffect(() => {
      if(Object.keys(advancesearch).length > 0){
      let as = selectedMRList?.["religion"]
        let object = advancesearch?.["religion"]?.filter(o => o.selected === 'Y');
        object?.map((obj, j) => {
        let index = as.indexOf(obj.id)
        if(index == -1){
          as.push(obj.id)
        }
      })
      setSelectedMRList(selectedMRList)
    }
    },[selectedMRList?.["religion"],advancesearch?.["motherTongue"]])

  //console.log(filterResponseData,"filterResponseData");
  //console.log(dashboard1Data?.isPaid, "paidsearch");
  //console.log(Weeklydata, "photoRequestData");
  const closeModel = () => {
    setShowKjBannerPopup(false)
    setShowKjThankPopup(false)
  }

  const callBackExploreLoadMore = () => {
    if (exploreLoadMore || isLoading) return;
    setExploreLoadMore(true)
    
    if (isFromComponent == "Filter") {
      setContentCardRange((oldValue) => [...oldValue,contentCardRange[contentCardRange?.length-1]+5])
      if (submitStatus) return;
      setSubmitStatus(true)
      // setFilterDataPage(filterDataPage+1)
      RefineSearch("load")
    }
    else {
      setContentCardRange((oldValue) => [...oldValue,contentCardRange[contentCardRange?.length-1]+5])
      apicallweekly()
    }
  }

  const bannerRedirect = (name, redirect) => {
    // alert(redirect)
    if(name == "imageupload"){
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } })
    }
    else if(name == "managepartner"){
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath), { state: { tab: "2" } })
    }
    else if(name == "parentnumber"){
      setShowParentNumPopup(true)
    }
    else if(name == "goldscheme"){
      window.open("https://www.kalyanmatrimony.com/gold-saving-scheme-online?sentFrom=Banner",'_blank')
    }
    else{
    window.open(redirect,'_blank')
    }
  }

  const blockProfileApiCall = (status, receiverId) => {
    dispatch(blockProfile(status, receiverId))
  }
  const closePopup = () => {
    if(blockProfileName?.profileblockedByMe == "Y"){
      blockProfileApiCall(0,blockProfileName?.profileid)
    window.location.href=RouteSegments.DASHBOARD
    // localStorage.setItem("BLOCKED_STATUS",0)
    }
    else window.location.href=RouteSegments.DASHBOARD
  }
  const closePopupForMsgSent = () => {
    setShowBlockPopup(false)
  }

  const newPreferenceAdd = async () => {


    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.managepreferencessaveUrl()}`,
      JSON.parse(sessionStorage.getItem("preferenceRequest"))
    );   

    if (statusCode === 200) {
      if (data.status === "Success") {
        // props.callBackReload();
        // setIsLoading(false)
        setShowCriteriaMessage("")
        apicalladvancesearch()
        //   setEditView(false);
      }
    } else if (statusCode === 401) {
      logout();
    }
  }

  // useEffect(()=>{
  //   window.onbeforeunload = function () 
  //   { window.scrollTo(0, 0);}
  // },[])

  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     e.preventDefault()
  //     const isEnd=  window.innerHeight + window.scrollY >= document.body.offsetHeight
  //     if (isMobile && isEnd && searchResultMessage !='Loading...' && !submitStatus) {   
  //       // Scroll to the top        
  //       if(isLoadFromRecommeded){ 
  //       onResultYes(true)
  //       window.scrollBy(0, 300);

  //       }
  //       else{
  //       callBackLoadMore()}
  //       window.scrollBy(0, 300);
         
  //     }
  //     else if(isMobile && isEnd && searchResultMessage !='Loading...' && !exploreLoadMore){
  //       setExploreLoadMore(true) ;       
  //       callBackExploreLoadMore()
  //       window.scrollBy(0, -200);

  //     }
  //   };
    
  //   window.addEventListener('scroll', handleScroll);
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);


  useEffect(() => {
    const handleScroll = (e) => {
      const isEnd = (window.innerHeight + window.scrollY) + 80 >= document.body.offsetHeight
      const spinner = document.getElementById('load-more-spinner')
      if (isMobile && isEnd && spinner === null && allowLoadMore.current &&(dataLength >= 5 || exploreDataLength >= 5)) {
        allowLoadMore.current = false
        if (isLoadFromRecommeded) {
          onResultYes(true)
        } else if(location.state?.menu === "explorematches"){
          callBackExploreLoadMore()
        } else {
          callBackLoadMore()
        }
      }
    }


    if(isMobile){
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMobile, isLoadFromRecommeded, onResultYes, location.state?.menu, callBackExploreLoadMore, dataLength, callBackLoadMore,exploreDataLength]);


  const closeBulkRequestPopup = ()=>{
    setShowBulkRequestPopupData({show:false,content:"",message:"",title:""})
  }

  const apiCallBulkRequest = async () => {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      "typeList": [
        "Photo",
        "Parents Number"
      ],
      "triggerType": "bulk"
    }

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.bulkrequest()}`,
      request
    );

    if (statusCode === 200) {
      closeBulkRequestPopup()
      setsuccessMessage("Request Sent Successfully");
      setshowSuccessPopup(true);
    }

  }


  const onBulkRequestSend = (status)=>{
    if(status){
      apiCallBulkRequest()
    }else{
      closeBulkRequestPopup()
    }
  }

  console.log({isSearch,tempAdvancesearch, advancesearch});


      //Left Panel API Call and Response  
      const { leftPanelData } = useSelector(state => ({
        leftPanelData: state.DashBoardAPI?.leftPanelData?.data
      }));
  
       //Profile Data API Call and Response  
       const { ProfileData } = useSelector(state => ({
        ProfileData: state.DashBoardAPI?.ProfileData?.data
      }));

      useEffect(() => {
        setProfileData(ProfileData)

        setAddKjBanner(ProfileData?.addbanner[0]?.kjbanner);
        if(ProfileData?.popup[0].showpopup == "RejectedProfile"){
          navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[0].urlPath))
        }
        setAddKjBanner(ProfileData?.addbanner[0]?.kjbanner);
      },[ProfileData])
      
  

  return (
    <>
      <div
       >
         {showAdminPopup && <CommonProfilePopup
        close={() => setShowAdminPopup(false)}
        title={"Alert!"}
        content={"You cannot send message/request from Admin"} />
      }
        {openFilterPopup? (
          <FilterPanel 
          setContentCardRange={setContentCardRange}
          setOpenFilterPopup={setOpenFilterPopup} setFilterDataPage={setFilterDataPage} RefineSearch={RefineSearch} 
          setIsLoading={setIsLoading}
          setContentCardData={setContentCardData}
          menu="apply"
          isFromComponent={isFromComponent}
          setIsFromComonent={setIsFromComonent}
          setIsLoadFromRecommeded={setIsLoadFromRecommeded}
          setIsFilterResult={setIsFilterResult}
           setFilterData={setFilterData} setAdvanceSearch={setAdvanceSearch}
           SetDataLength={SetDataLength}
           />
        )
        :""
        }
        {
              showBulkRequestPopupData.show && (
                <BulkRequestPopup 
                close={closeBulkRequestPopup} 
                onSend={onBulkRequestSend}
                content={showBulkRequestPopupData.content}
                message={showBulkRequestPopupData?.message}
                imageSrc={showBulkRequestPopupData.imageSrc}
                title={showBulkRequestPopupData.title}/>
              )
            }
      {showBlockPopup ? (
            <CommonPopup title="Profile Status" close={() => setShowBlockPopup(false)} menu="profileStatus">
              <p>{blockProfileName.profilename + " Profile Blocked by you. Do you want to unblock now?"}</p>
              <div className='flex justify-between'>
              <button className='text-[#D10A11] ml-[10rem]' onClick={closePopup}>Yes</button>
              <button className='text-[#D10A11] mr-[1rem]' onClick={closePopupForMsgSent}>No</button>
              </div>
            </CommonPopup>
          ) : (
            <></>
          )}
      {showParentNumPopup &&
              <ParentsPhonePopup close={() => setShowParentNumPopup(false)}
                title={"Parent's Number"}
                callBackApply={() => { }}
                parentsIsdCode={ProfileData?.parentsIsdCode}
                parentsMobileNumber={ProfileData?.parentsMobileNumber}
              />
            }
      {showKjBannerPopup ? (
              <KjBannerPopup
                closeModel={closeModel}
                setShowKjThankPopup={setShowKjThankPopup}
                setShowKjBannerPopup={setShowKjBannerPopup}
                // onButtonCallback={(profileID, value) => {
                //   apicallMessage(profileID, value).then((res) => {
                //     //console.log("callback message", res);
                //     if (res?.status === "Success") {
                //       handleSetMessage(profileID, messageFor);
                //     }
                //   });
                // }}
                title={"Send Message"}
                dashboard1Data={dashboard1Data}
                // profileData={messageProfileDetails}
              />
            ) : (
              <></>
            )}
      {showProfileStatusPopup && (
            <CommonPopup title="Profile Status" close={()=>setShowProfileStatusPopup(false)} menu="profileStatus">
              <p>{ProfileStatusMessage}</p>
            </CommonPopup>
          )
          }
        <NavBar data={leftPanelData} ProfileData={ProfileData} />
        {/* {loading ? */}
        <main>
          <div className="overflow-hidden">
            {showPhonePopup ? (
              <PhonePopup
                phoneNumData={phoneNumData}
                close={() => setShowPhonePopup(false)}
                title={"View Phone number"}
                profileData={messageProfileDetails}
                onSubmit={(profileID, value, type)=>apicallMultiRequest(profileID, value, type,false)}
              />
            ) : (
              <></>
            )}
            {showSendMessagePopup ? (
              <SendMessagePopup
                close={() => setSendMessagePopup(false)}
                onButtonCallback={(profileID, value) => {
                  apicallMessage(profileID, value).then((res) => {
                    console.log("callback message", res);
                    if (res?.status === "Success") {
                      handleSetMessage(profileID, messageFor,res?.data);
                    }
                  });
                }}
                title={"Send Message"}
                dashboard1Data={ProfileData}
                profileData={messageProfileDetails}
                
              />
            ) : (
              <></>
            )}
            {showMessageSuccess ? (
              <MessageSuccessPopup 
                close={() => setshowMessageSuccess(false)}
                title={"Message Sent"}
                dashboard1Data={ProfileData}
                callback={() => {
                  //redirection to gallery
                }}
              />
            ) : (
              <></>
            )}

            {showUploadImagePopup ? (
              <UploadPhotoPopup
                close={() => setShowUploadImagePopup(false)}
                title={"Upload Photo"}
                callBack={() => {
                  setShowRequestpopup(true);
                }}
              />
            ) : (
              <></>
            )}
            {/* api/submitparentsnumber
    api/getisdcode */}
            {/* api/notificationlist */}
            {showSuccessPopup && (
              <CommonSuccessPopup
                close={() => setshowSuccessPopup(false)}
                title={"Success!"}
                content={successMessage}
              />
            )}
            {showKjBannerPopup ? (
              <KJbannerPopup
                closeModel={closeModel}
                setShowKjThankPopup={setShowKjThankPopup}
                setShowKjBannerPopup={setShowKjBannerPopup}
                onButtonCallback={(profileID, value) => {
                  apicallMessage(profileID, value).then((res) => {
                    //console.log("callback message", res);
                    if (res?.status === "Success") {
                      handleSetMessage(profileID, messageFor,res?.data);
                    }
                  });
                }}
                title={"Send Message"}
                dashboard1Data={dashboard1Data}
                profileData={messageProfileDetails}
              />
            ) : (
              <></>
            )}
            {showRequestPopup && (
              <RequestPhotoHoroTrustPopup
                close={() => setShowRequestpopup(false)}
                onButtonCallback={(profileID, value) => {
                  apicallHoroTrustRequest(profileID, value, "photo");
                }}
                title={"Request Photo"}
                profileData={messageProfileDetails}
              />
            )}
            {parentsNumberRequest ?
              getIsLoginFromAdmin()
                ? <CommonProfilePopup
                  close={() => {
                    setShowAdminPopup(false)
                    setParentsNumberRequest(false)
                  }}
                  title={"Alert!"}
                  content={"You cannot send message/request from Admin"} />
                : (
                  <RequestPhotoHoroTrustPopup
                    close={() => setParentsNumberRequest(false)}
                    onButtonCallback={(profileID, value) => {
                      apicallHoroTrustRequest(profileID, value, "Parents Number");
                    }}
                    title={"Parents Number Request"}
                    profileData={messageProfileDetails}
                  />
                )
              : null}
            <ViewMessageNav page="viewall" menu={props.menu} state={navitem} /> 
            {/* {!isLoading? */}
            
            <div className="grid md:grid-cols-12 lg:grid-cols-10 grid-cols-1 ">
              
              <div className="  absolute ml-[20rem] md:ml-[22rem] top-[8rem] left-[-1.2rem] md:top-[9rem] md:left-0  block lg:hidden  ">
                <button 
                  type="button"
                  className=" bg-[#D10A11] text-white justify-center rounded-md  px-5 py-1 text-[14px] font-segeo font-bold m-1 "
                  onClick={()=>setOpenFilterPopup(true)}
                  
                >
                  <span className="hidden md:block ">
                  Filter
                  </span>
                  <div className="block md:hidden ">
                  <img src={filterImage} />
                  </div>
                </button>
                
              </div>
               <div className="hidden md:hidden lg:block  col-span-2 md:col-span-2 lg:col-span-2 pt-[1.5rem] mb-[34rem]">
                <div className="container mx-auto ">
                  <div
                    className="max-w-2xl"
                    //  className="p-6 mx-auto bg-white"
                  >
                    <div className="md:mb-4">
                      <AccordianComponents
                        title={"Filter"}
                        titleClassName={
                          "flex items-center font-segeo justify-between  w-full py-2 pl-3 pr-2 font-semibold text-[#FFFFFF] bg-[#7A7A7A] cursor-pointer"
                        }
                        onClick={()=>setSelectedFilter(selectedFilter)}
                        defaultView={true}
                        titleIcon1={
                          <svg
                            className="w-3 h-3 fill-current"
                            viewBox="0 -192 469.33333 469"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m437.332031.167969h-405.332031c-17.664062 0-32 14.335937-32 32v21.332031c0 17.664062 14.335938 32 32 32h405.332031c17.664063 0 32-14.335938 32-32v-21.332031c0-17.664063-14.335937-32-32-32zm0 0" />
                          </svg>
                        }
                        titleIcon2={
                          <svg
                            className="w-3 h-3 fill-current"
                            viewBox="0 0 469.33333 469.33333"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0" />
                          </svg>
                        }
                      >
                        <div>
                          <div className="p-1   bg-[#F3F3F3] ">
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                title={"Activity"}
                                onClick={()=>setSelectedFilter(selectedFilter==1? 0 :1)}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[13px] text-[#575556] font-semibold rounded cursor-pointer"
                                }
                                defaultView={selectedFilter=="1"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-2 px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                        data={advancesearch}
                                        menu="activity"
                                        selectedActivityFilter={selectedActivityFilter}
                                        filterOnchangeForActivity={filterOnchangeForActivity}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                  <div>
                                     <RefineFilter menu ="activity" RefineSearch={RefineSearch}/>
                                  </div>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==2? 0 :2)}
                                title={"Basic Details"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[13px] text-[#575556] font-semibold rounded cursor-pointer"
                                }
                                defaultView={selectedFilter=="2"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-2 px-6">
                                     
                                  <BasicFilter
                                    menu="Age"
                                    onChange={ageRange}
                                    data={advancesearch}
                                  />

                                  <BasicFilter
                                    menu="Height"
                                    onChange={heightRange}
                                    data={advancesearch}
                                  />
                                                                           
                                  </div>
                                  <RefineFilter menu ="basic" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>

                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==3? 0 :3)}
                                title={"Marital Status"}
                                titleClassName={
                                  "flex items-center  justify-between w-full pl-3 text-[13px] text-[#575556] font-semibold rounded cursor-pointer"
                                }
                                defaultView={selectedFilter=="3"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["maritalStatus"]?tempAdvancesearch:advancesearch}
                                     menu ="maritalStatus"
                                     title={"Marital Status"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                  <RefineFilter menu ="maritalStatus" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                title={"Physical Status"}
                                onClick={()=>setSelectedFilter(selectedFilter==4? 0 :4)}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                defaultView={selectedFilter=="4"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["physicalStatus"]?tempAdvancesearch:advancesearch}
                                     menu ="physicalStatus"
                                     title={"Physical Status"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                  <RefineFilter menu ="physicalStatus" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==5? 0 :5)}
                                title={"Mother Tongue"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                defaultView={selectedFilter=="5"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["motherTongue"]?tempAdvancesearch:advancesearch}
                                     menu ="motherTongue"
                                     title={"Mother Tongue"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="motherTongue" RefineSearch={RefineSearch} selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                           
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==6? 0 :6)}
                                title={"Religion"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="6"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["religion"]?tempAdvancesearch:advancesearch}
                                     menu ="religion"
                                     title={"Religion"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="religion" RefineSearch={RefineSearch} selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div >
                              {
                                isChristain?
                                <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==23? 0 :23)}
                                title={"Denomination"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="23"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={!isChristain?[]:isSearch["domain"]?tempAdvancesearch:advancesearch}
                                     menu ="domain"
                                     title={"Denomination"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="domain" RefineSearch={RefineSearch} selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            :""
                              }
                            
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==7? 0 :7)}
                                title={"Caste"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="7"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["caste"]?tempAdvancesearch:advancesearch}
                                     menu ="caste"
                                     title={"Caste"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="caste" RefineSearch={RefineSearch} selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                title={"Sub Caste"}
                                onClick={()=>setSelectedFilter(selectedFilter==8? 0 :8)}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="8"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["subCaste"]?tempAdvancesearch:advancesearch}
                                     menu ="subCaste"
                                     title={"Sub Caste"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="subCaste" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                title={"Education"}
                                onClick={()=>setSelectedFilter(selectedFilter==9 ? 0 :9)}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="9"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["education"]?tempAdvancesearch:advancesearch}
                                     menu ="education"
                                     title={"Education"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="education" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                title={"Employment Type"}
                                onClick={()=>setSelectedFilter(selectedFilter==10? 0 :10)}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="10"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["employedIn"]?tempAdvancesearch:advancesearch}
                                     menu ="employedIn"
                                     title={"Employment Type"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="employedIn" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>

                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==11? 0 :11)}
                                title={"Occupation"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="11"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["occupation"]?tempAdvancesearch:advancesearch}
                                     menu ="occupation"
                                     title={"Occupation"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="occupation" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==12? 0 :12)}
                                title={"Country Living in"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="12"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["country"]?tempAdvancesearch:advancesearch}
                                     menu ="country"
                                     title={"Country Living in"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="country" RefineSearch={RefineSearch} selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter}/>
                                </div>
                              </AccordianComponents>
                            </div>

                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                title={"State"}
                                onClick={()=>setSelectedFilter(selectedFilter==13? 0 :13)}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="13"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["state"]?tempAdvancesearch:advancesearch}
                                     menu ="state"
                                     title={"State"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="state" RefineSearch={RefineSearch} selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==14? 0 :14)}
                                title={"City"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="14"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["city"]?tempAdvancesearch:advancesearch}
                                     menu ="city"
                                     title={"City"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="city" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==15? 0 :15)}
                                title={"Star"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="15"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["star"]?tempAdvancesearch:advancesearch}
                                     menu ="star"
                                     title={"Star"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="star" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==16? 0 :16)}
                                title={"Raasi"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="16"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["raasi"]?tempAdvancesearch:advancesearch}
                                     menu ="raasi"
                                     title={"Raasi"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="raasi" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==17? 0 :17)}
                                title={"Dhosam"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="17"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["dosham"]?tempAdvancesearch:advancesearch}
                                     menu ="dosham"
                                     title={"dosham"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="dosham" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==18? 0 :18)}
                                title={"Food Habit"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="18"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["food"]?tempAdvancesearch:advancesearch}
                                     menu ="food"
                                     title={"Food Habit"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="food" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==19? 0 :19)}
                                title={"Smoke"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="19"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["smoking"]?tempAdvancesearch:advancesearch}
                                     menu ="smoking"
                                     title={"Smoke"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="smoking" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==20? 0 :20)}
                                title={"Drink"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="20"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["drinking"]?tempAdvancesearch:advancesearch}
                                     menu ="drinking"
                                     title={"Drink"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="drinking" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==21? 0 :21)}
                                title={"Show Profile"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="21"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                    {advancesearch && Object.keys(advancesearch).length > 0 ? (
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["showProfile"]?tempAdvancesearch:advancesearch}
                                     menu ="showProfile"
                                     title={"Show Profile"}
                                      />
                                    ) : (
                                      " "
                                    )}
                                  </div>
                                   <RefineFilter menu ="showProfile" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                            <div className="py-2 ">
                              <AccordianComponents
                                onClick={()=>setSelectedFilter(selectedFilter==22? 0 :22)}
                                title={"Don’t show Profile"}
                                titleClassName={
                                  "flex items-center justify-between w-full pl-3 text-[#575556] font-semibold  font-normal text-[13px] text-[#575556] rounded cursor-pointer "
                                }
                                  defaultView={selectedFilter=="22"?true:false}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >
                                <div>
                                  <div className="p-1 px-[0.5rem] lg:p-2 lg:px-6">
                                  {advancesearch && Object.keys(advancesearch).length > 0 ?
                                      <FilterContents
                                      handleSearchFIlter={handleSearchFIlter}
                                      isSelectAll={isSelectAll}
                                      filterOnchange={filterOnchange}
                                     data={isSearch["dontshowProfile"]?tempAdvancesearch:advancesearch}
                                     menu ="dontshowProfile"
                                     title={"Don’t show Profile"}
                                      />
                                      : " "
                                    } 
                                    
                                  </div>
                                   <RefineFilter menu ="dontshowProfile" selectAllFilter={selectAllFilter} clearAllFilter={clearAllFilter} RefineSearch={RefineSearch}/>
                                </div>
                              </AccordianComponents>
                            </div>
                          </div>
                        </div>
                      </AccordianComponents>
                    </div>
                  </div>
                </div>
              </div>
              <div className="hidden md:hidden lg:block col-span-5 md:col-span-7 md:pr-[0.6rem] lg:pr-0 lg:col-span-5">
                {isFilterResult?
                  <>
                    <>
                    {filterData?.length>0?
                    <>
                    {/* {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>} */}
                    {addTopBanner?.name === "kjbanners" ?
                                  <img
                                    className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem] 1"
                                    alt=""
                                    src={addTopBanner?.showphotobanner}
                                    closeModel={closeModel}
                                    onClick={() => {
                                      setShowKjBannerPopup(true);
                                    }} />
                                  :
                                  <img
                                    className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem] 11"
                                    alt=""
                                    src={addTopBanner?.showphotobanner}

                                    onClick={() => {
                                      bannerRedirect(addTopBanner?.name, addTopBanner?.redirect)
                                    }} />
                                  }
                       
                        
                        {filterData?.length > 0 && (
                          <SearchResultSection
                          onSubmitSuccess={(newPhone, newIsd) => { 
                            setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                            console={console.log(contentCardData,"contentCardData")}
                            content={contentCardData}
                            setContentCardPosition={setContentCardPosition}
                            setContentCardRange={setContentCardRange}
                            contentCardPosition={contentCardPosition}
                            contentCardRange={contentCardRange}
                            title={"Weekly Matches"}
                            dashboardData={ProfileData}
                            data={filterData}
                            menu={"weekly" }
                          callbackSelectReject={( 
                          btntype,
                          profileID,
                          typeRequest,
                          profileDetails,
                          index
                        ) => {
                          callbackSelectReject(
                            btntype,
                            profileID,
                            typeRequest,
                            profileDetails,
                            index,"weekly"
                          );
                          setMessageFor("filter");
                        }}
                          // menu="weekly"
                        callbackRequestPhoto={(value) => {
                          setMessageProfileDetails(value);
                          handleRequestPhoto();
                          setMessageFor("filter")
                          
                        }}
                        callBackParentsNumber={(value) => {
                          setMessageProfileDetails(value);
                          setParentsNumberRequest(true);
                          setMessageFor("filter")
                        }}
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("filter")
                          callBackViewPhone(profileId,value)
                        }}
                          />
                        )}
                    </>
                    :
                    addTopBanner?.name === "kjbanners" ?
                                  <img
                                    className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem]"
                                    alt=""
                                    src={addTopBanner?.showphotobanner}
                                    closeModel={closeModel}
                                    onClick={() => {
                                      setShowKjBannerPopup(true);
                                    }} />
                                  :
                                  <img
                                    className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem]"
                                    alt=""
                                    src={addTopBanner?.showphotobanner}

                                    onClick={() => {
                                      bannerRedirect(addBanner?.name, addBanner?.redirect)
                                    }} />
                                  }
                    </>
                    {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  </>            
                // <p>No profiles found</p>
                :
                props.menu === "weekly"
                  ? 
                  <>
                           <SelectedCount 
                            shortlisted={shortlisted}
                            ignored={ignored}
                            viewed={viewed} 
                            setIgnored={setIgnored} 
                            setViewed={setViewed} 
                            setShortlisted={setShortlisted}
                            setIsLoading={setIsLoading}
                            title={"Weekly Matches"} 
                            count={weeklyCount?.WeeklymatchesCount}/>
                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {Weeklydata?.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Weekly Matches"}
                        dashboardData={ProfileData}
                        data={Weeklydata}
                        menu="weekly"
                      callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"weekly"
                      );
                      setMessageFor("weekly");
                    }}
                      // menu="weekly"
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("weekly")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("weekly")
                    }}
                    callBackViewPhone={(profileId,value)=>{
                      setMessageFor("weekly")
                      callBackViewPhone(profileId,value)
                    }}
                    // photoRequest={photoRequest} 
                      />
                    )}
                    </>
                    
                  : props.menu === "recommended"
                  ?
                  <>
                <SelectedCount 
                  
                  shortlisted={shortlisted}
                  ignored={ignored}
                  viewed={viewed}
                        setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                        
                          setIsLoading={setIsLoading} 
                         title={"Recommend Matches"} count={recommendationCount?.recommendationsmatchesCount}
                         />
                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>} 
                  {RecommendedData?.length > 0 && (
                      <div className="">
                        
                        <SearchResultSection

                        selectCount={recommendationCount?.recommendationsmatchesCount}
                        onSubmitSuccess={(newPhone, newIsd) => { 
                          setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                          content={contentCardData}
                          setContentCardPosition={setContentCardPosition}
                          setContentCardRange={setContentCardRange}
                          contentCardPosition={contentCardPosition}
                          contentCardRange={contentCardRange}
                          title={"Recommend Matches"}
                          data={RecommendedData}
                           dashboardData={ProfileData}
                          // callLoadMore={callbackLoadMoreRecommended}
                          callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"recommend"
                      );
                      setMessageFor("recommend");
                    }}
                          menu='recommended'
                          callBackViewPhone={(profileId,value)=>{
                            setMessageFor("recommend")
                            callBackViewPhone(profileId,value)
                          }}
                          callbackRequestPhoto={(value) => {
                            setMessageProfileDetails(value);
                            handleRequestPhoto();
                            setMessageFor("recommend")
                          }}
                          callBackParentsNumber={(value) => {
                            setMessageProfileDetails(value);
                            setParentsNumberRequest(true);
                            setMessageFor("recommend")
                          }}
                          
                          //  ViewAllCallBack={() => {viewallselection("recommended")}}
                        />
                      </div>
                    )}
                    </>
                  : props.menu === "response"
                  ? 
                  <>
                   <SelectedCount  
                    shortlisted={shortlisted}
                    ignored={ignored}
                    viewed={viewed}
                        setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                        
                          setIsLoading={setIsLoading}
                         title={"Response Received"} count={responseCount?.responseReceivedCount}/>
                  {isLoading &&  <CardSkeleton cards={4} />}
                 

                  {responseReceivedData.length > 0 && (
                    
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={responseReceivedData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"weekly"
                      );
                      setMessageFor("weekly");
                    }}
                        menu="response"
                        viewProfileStatusPopup={(value) => {
                          setProfileStatusMessage(value);
                          setShowProfileStatusPopup(true)
                        }}
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("response")
                          callBackViewPhone(profileId,value)
                        }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("response") 
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                    )}
                    </>
                  : props.menu === "profilelookingfor"
                  ?
                  <>
                     <SelectedCount
                        shortlisted={shortlisted}
                        ignored={ignored}
                        viewed={viewed}
                        setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                        setIsLoading={setIsLoading}
                        title={"Profiles Matching the Partner Expectation"} 
                        count={profileLookingCount?.ProfileslookingforCount}
                    />

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
               
                   {profileILookingForData.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={profileILookingForData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"lookingfor"
                      );
                      setMessageFor("lookingfor");
                    }}
                        menu="profilelookingfor"
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("lookingfor")
                          callBackViewPhone(profileId,value)
                        }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("lookingfor")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("lookingfor")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                    )
                  }
                  </>
                  : props.menu === "profilelookingforme"
                  ? 
                  <>
                    <SelectedCount 
                    shortlisted={shortlisted}
                    ignored={ignored}
                    viewed={viewed}
                   setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                   
                     setIsLoading={setIsLoading}
                   title={"Profiles looking for me"} count={lookingFormeCount?.ProfileslookingformeCount}/>

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                
                  {profileLoookingForMeData.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={profileLoookingForMeData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"lookingforme"
                      );
                      setMessageFor("lookingforme");
                    }}
                        menu="profilelookingforme"
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("lookingforme")
                          callBackViewPhone(profileId,value)
                        }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("lookingforme")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("lookingforme")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                    )}
                    </>
                  : props.menu === "photorequestreceived"
                  ? 
                  <>
                   <SelectedCount  
                   shortlisted={shortlisted}
                   ignored={ignored}
                   viewed={viewed}
                  setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                  
                    setIsLoading={setIsLoading}
                  title={"Photo Request Received"} count={photoRequestCount?.PhotoRequestReceivedCount}/>

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                 
                  {photoRequestData.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={photoRequestData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"weekly"
                      );
                      setMessageFor("weekly");
                    }}
                    //  photoRequest={photoRequest} 
                    viewProfileStatusPopup={(value) => {
                      setProfileStatusMessage(value);
                      setShowProfileStatusPopup(true)
                    }}
                        menu="photorequestreceived"
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("photorequest")
                          callBackViewPhone(profileId,value)
                        }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("photorequest")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                    )}
                    </>
                  : props.menu === "mutualmatches"
                  ? 
                  <>
                   <SelectedCount 
                   shortlisted={shortlisted}
                   ignored={ignored}
                   viewed={viewed}
                  setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                  
                    setIsLoading={setIsLoading}
                   title={"Mutual Matches"} count={mutualCount?.MutalmatchesCount}/>

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                 
                  {MutualData.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={MutualData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"mutual"
                      );
                      setMessageFor("mutual");
                    }}
                        menu="mutualmatches"
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("mutual")
                          callBackViewPhone(profileId,value)
                        }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("mutual")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("mutual")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                    )}
                    </>
                  : props.menu === "similarmatches"
                  ? 
                  <>
                   <SelectedCount 
                   
                   shortlisted={shortlisted}
                   ignored={ignored}
                   viewed={viewed}
                   setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                   
                     setIsLoading={setIsLoading}
                   title={"Similar Matches"} count={similarCount?.similarmatchesCount}/>

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                 
                  {similarmatchesdata.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={similarmatchesdata} 
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"selected"
                      );
                      setMessageFor("similar");
                    }}
                        menu="similarmatches"
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("similar")
                          callBackViewPhone(profileId,value)
                        }}
                        callbackRequestPhoto={(value) => {
                        setMessageProfileDetails(value);
                        handleRequestPhoto();
                        setMessageFor("similar")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("similar")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      /> 
                    )}
                    </>
                    : location.state?.menu === "explorematches"
                  ? 
                  <>
                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {exploreMatchesData.length > 0 && 
                      <>
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Explore Matches"}
                        searchBy={location?.state?.filterBy}
                        data={exploreMatchesData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"selected"
                      );
                      setMessageFor("explore");
                    }}
                        menu="explorematches"
                        callBackViewPhone={(profileId,value)=>{
                          setMessageFor("explore")
                          callBackViewPhone(profileId,value)
                        }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("explore")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("explore")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                      <div className="text-center py-2">
                          {exploreDataLength >= 5 && (
                         <>
                            <button
                                onClick={callBackExploreLoadMore}
                              className="hidden md:block md:mx-auto rounded-[5px] text-[10px] px-3 py-2 bg-[#D10A11] text-[#FFFFFF] font-bold font-segeo"
                              console={console.log(exploreMatchesData.length,"exploreLoadMore")}
                            >
                            {exploreLoadMore?
                            <>
                                <span className="flex">
                                <img alt='' src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5"/><span>Loading...</span>
                                </span>
                            </> 
                            : <>Load More</>
                            }
                            </button>
                            {exploreLoadMore && <div className="block md:hidden"><LoaderMoreMobileSpinner /></div>}
                                </>
                          ) 
                        }
                      </div> 
                      </>
                    }
                    </>
                  
                  : props.menu === "selectedprofile" ||   location?.state?.isfrom === "selectedprofile"?
                   
                  <>
                     <SelectedCount    
                     
                     shortlisted={shortlisted}
                     ignored={ignored}
                     viewed={viewed}
                    
                   setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                 
                   
                    
                       setIsLoading={setIsLoading}  
                    title={"Selected Profile"} count={selectedCount?.selectedProfileDetailsCount}/>

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
               
                  {selectedProfileData.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                      callBackBlockProfile={callBackBlockProfile}
                        title={"Response Received"}
                        data={selectedProfileData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"selected"
                      );
                      setMessageFor("selected");
                    }}

                    viewProfileStatusPopup={(value) => {
                      setProfileStatusMessage(value);
                      setShowProfileStatusPopup(true)
                    }}
                        menu="selectedprofile"
                         callBackViewPhone={(profileId,value)=>{
                            setMessageFor("selected")
                            callBackViewPhone(profileId,value)
                          }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("selected")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("selected")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      /> 
                    )
                  }
                  </>
                  : props.menu === "recentlyviewed"
                  ?
                  <>
             <SelectedCount 
                   shortlisted={shortlisted}
                   ignored={ignored}
                   viewed={viewed}
                  setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                  
                    setIsLoading={setIsLoading}
                   title={"Recently Viewed"} count={resentlyViewdCount?.recentViewCount}/>

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  
                  {
                  // recentlyViewedLoader?
                  recentlyViewedData.length > 0 && (
                    <SearchResultSection
                    
                    
                    onSubmitSuccess={(newPhone, newIsd) => { 
                      setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                      content={contentCardData}
                      setContentCardPosition={setContentCardPosition}
                      setContentCardRange={setContentCardRange}
                      contentCardPosition={contentCardPosition}
                      contentCardRange={contentCardRange}
                        title={"Recently Viewed"}
                        data={recentlyViewedData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"selected"
                      );
                      setMessageFor("selected");
                    }}
                        menu="recentlyviewed"
                         callBackViewPhone={(profileId,value)=>{
                            setMessageFor("recent")
                            callBackViewPhone(profileId,value)
                          }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("recent")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("recent")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      /> 
                    )   
        //           : 
        //           <center>
        //   <img alt='' src={LoaderGif} />
        // </center>

}
                  </> 
                  :
                  props.menu === "weekly"
                  ? 
                  <>
                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {Weeklydata?.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Explore Mtahces"}
                        dashboardData={ProfileData}
                        data={Weeklydata}
                        menu="explore"
                      callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"explore"
                      );
                      setMessageFor("explore");
                    }}
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("weekly")
                    }}
                     callBackViewPhone={(profileId,value)=>{
                            setMessageFor("weekly")
                            callBackViewPhone(profileId,value)
                          }}
                      />
                    )}
                    </>
                    :
                    props.menu == "savepartnerpreferences" ?
                    <>
                    {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                    
                   
                    {searchPageData?.length > 0 && (
                        <>
                         {addTopBanner?.name === "kjbanners" ?
                              <img
                                className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem] an"
                                alt=""
                                src={addTopBanner?.showphotobanner}
                                closeModel={closeModel}
                                onClick={() => {
                                  setShowKjBannerPopup(true);
                                }} />
                              :
                              <img
                                className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem] ana"
                                alt=""
                                src={addTopBanner?.showphotobanner}
                                onClick={() => {
                                  bannerRedirect(addTopBanner?.name, addTopBanner?.redirect)
                                }} />
                              }
                               {showCriteriaMessage != "" ?
                          <div className="flex mt-2 px-2 justify-center items-center pt-[1rem]">
                          <p>{showCriteriaMessage}?</p>
                          <button className='rounded-[5px] text-[#FFFFFF] bg-[#D10A11] px-[3%] py-[0.5%] font-bold ml-[1rem]' onClick={newPreferenceAdd}>YES</button>
                          <button className='rounded-[5px] text-[#FFFFFF] bg-[#D10A11] px-[3%] py-[0.5%] font-bold ml-[1rem]' onClick={()=>setShowCriteriaMessage("")} >NO</button>
                        </div>
                        :""}
                        {partnerProfileCount && <p className="text-center py-2">{partnerProfileCount}</p>}
                        <SearchResultSection
                        onSubmitSuccess={(newPhone, newIsd) => { 
                          setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                          content={contentCardData}
                          setContentCardPosition={setContentCardPosition}
                          setContentCardRange={setContentCardRange}
                          contentCardPosition={contentCardPosition}
                          contentCardRange={contentCardRange}
                          viewProfileStatusPopup={(value) => {
                            setProfileStatusMessage(value);
                            setShowProfileStatusPopup(true)
                          }}
                          callbackRequestPhoto={(value) => {
                            setMessageProfileDetails(value);
                            handleRequestPhoto();
                            setMessageFor("search")
                          }}
                          callBackParentsNumber={(value) => {
                            setMessageProfileDetails(value);
                            setParentsNumberRequest(true);
                            setMessageFor("search")
                          }}
                          dashboardData={ProfileData}
                           callBackViewPhone={(profileId,value)=>{
                            setMessageFor("search")
                            callBackViewPhone(profileId,value)
                          }}
                            title={"Save Partner Preferences"}
                            data={searchPageData}
                            // callbackSelectReject={callbackSelectReject}
                            callbackSelectReject={(
                              btntype,
                              profileID,
                              typeRequest,
                              profileDetails,
                              index
                            ) => {
                              callbackSelectReject(
                                btntype,
                                profileID,
                                typeRequest,
                                profileDetails,
                                index,"search"
                              );
                              setMessageFor("search");
                            }}
                            menu={props.menu}
                          />
                          </>
                    )}
                    </>
                    :
                    addTopBanner?.name === "kjbanners" ?
                              <img
                                className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem]"
                                alt=""
                                src={addTopBanner?.showphotobanner}
                                closeModel={closeModel}
                                onClick={() => {
                                  setShowKjBannerPopup(true);
                                }} />
                              :
                              <img
                                className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem]"
                                alt=""
                                src={addTopBanner?.showphotobanner}

                                onClick={() => {
                                  bannerRedirect(addTopBanner?.name, addTopBanner?.redirect)
                                }} />
                              }

                <div className="hidden md:block text-center py-2">
                  {
                     selectedProfileData.length  >= 5 ||
                  MutualData.length >= 5 ||
                  photoRequestData.length >= 5 ||
                  profileLoookingForMeData.length >= 5 ||
                  profileILookingForData.length >= 5 ||
                  responseReceivedData.length >= 5 ||
                  RecommendedData.length>= 5 ||
                  recentlyViewedData.length>= 5 ||
                  similarmatchesdata.length >= 5||
                  Weeklydata.length >= 5 ||
                  searchPageData.length >= 1 ||
                  filterResponseData?.filtermatches?.length >= 5 
                  && 
                  loading
                  
                  ? (
                    
                    (dataLength >= 5 ?
                    
                    <button
                      onClick={isLoadFromRecommeded?()=>onResultYes(true) :callBackLoadMore} 
                      className=" webload rounded-[5px] text-[10px] px-3 py-2 bg-[#D10A11] text-[#FFFFFF] font-bold font-segeo"
                    >{ 
                    submitStatus?
                     <><span className="flex">
                     <img alt='' src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5"/><span>Loading...</span>
                     </span>
                     </> : <>Load More </>
                    }
                    </button>
                    : <>
                     {searchResultMessage && <SearchResultMessage loading={submitStatus} message={submitStatus?"Loading...": searchResultMessage} onClick={()=>onResultYes(false,"loadMoreList")}/>}
                     </>)
                  ) 
                  :
                    // filterData?.length > 0 ?
                    // (filterResponseData?.filtermatches?.length >= 5 ? 
                    //   <button
                    //     onClick={callBackLoadMore} 
                    //     className="  rounded-[5px] text-[10px] px-3 py-2 bg-[#D10A11] text-[#FFFFFF] font-bold font-segeo"
                    //   >{ 
                    //   submitStatus?
                    //    <><span className="flex">
                    //    <img alt='' src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5"/><span>Loading...</span>
                    //    </span>
                    //    </> : <>Load More filter </>
                    //   }
                    //   </button> 
                    //   : 
                    //   <>
                     
                    //   </> 
                    //   )
                      
                   
                 
                 
                
                  
                  // :
      //              !loading ? (
      //   <CardSkeleton cards={4} menu={props?.menu} />
      // ) : 
      //   dataLength === 0 ? (
      //     <p className="py-5">No Profiles Found</p>
      //   ) : (
      //     <CardSkeleton cards={4} menu={props?.menu} />
      //   )
       
                  selectedProfileData.length  >= 1 ||
                  MutualData.length >= 1 ||
                  photoRequestData.length >= 1 ||
                  profileLoookingForMeData.length >= 1 ||
                  profileILookingForData.length >= 1 ||
                  responseReceivedData.length >= 1 ||
                  RecommendedData.length>= 1 ||
                  recentlyViewedData.length>= 1 ||
                  similarmatchesdata.length >= 1||
                  Weeklydata.length >= 1 ||
                  exploreMatchesData.length >= 1 ||
                  searchPageData.length >= 1 ||
                  filterResponseData?.filtermatches?.length >= 1
                   ?
                   (
                     ""
                     )
                  :  !loading ?   <CardSkeleton cards={4} menu={props?.menu} />:  
                  
                  dataLength == 0 
                  ?props.menu === "savepartnerpreferences" && searchResultMessage 
                  ? <SearchResultMessage message={searchResultMessage} onClick={onResultYes}/> 
                  : <p className="py-5 ">No Profiles Found</p> 
                  : <CardSkeleton cards={4} menu={props?.menu} />
                  //     ?
                  //     //  <CardSkeleton cards={4} menu={props?.menu} />
                  //     " "
                  // :  !loading ?   <CardSkeleton cards={4} menu={props?.menu} />:  
                  //   dataLength == 0 ? <p className="py-5"> No Profile Found </p> 
                  //   :filterResponseData?.filtermatches?.length ==0 ?
                  // <p className="py-5"> No Profile Found </p> : <CardSkeleton cards={4} menu={props?.menu} />
  
                 
              }
              
                </div>
              </div>
              {/* mobile and tab view */}
              <div className="lg:hidden    col-span-5 md:col-span-7 md:pr-[0.6rem] lg:pr-0 lg:col-span-5">
                {isFilterResult?
                <>
                <>
                
                {filterData?.length>0?
                 <>
                 {/* {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>} */}
                 {addTopBanner?.name === "kjbanners" ?
                              <img
                                className="cursor-pointer w-[100%] mt-[3rem] px-[0.5rem] 1"
                                alt=""
                                src={addTopBanner?.showphotobanner}
                                closeModel={closeModel}
                                onClick={() => {
                                  setShowKjBannerPopup(true);
                                }} />
                              :
                              <img
                                className="cursor-pointer w-[100%] mt-[3rem] px-[0.5rem] 11"
                                alt=""
                                src={addTopBanner?.showphotobanner}

                                onClick={() => {
                                  bannerRedirect(addTopBanner?.name, addTopBanner?.redirect)
                                }} />
                              }
                {filterData?.length > 0 && (
                  <SearchResultSection
                  onSubmitSuccess={(newPhone, newIsd) => { 
                    setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                    content={contentCardData}
                    setContentCardPosition={setContentCardPosition}
                    setContentCardRange={setContentCardRange}
                    contentCardPosition={contentCardPosition}
                    contentCardRange={contentCardRange}
                    title={"Weekly Matches"}
                    dashboardData={ProfileData}
                    data={filterData}
                    menu={"weekly" }
                  callbackSelectReject={( 
                  btntype,
                  profileID,
                  typeRequest,
                  profileDetails,
                  index
                ) => {
                  callbackSelectReject(
                    btntype,
                    profileID,
                    typeRequest,
                    profileDetails,
                    index,"weekly"
                  );
                  setMessageFor("filter");
                }}
                  // menu="weekly"
                callbackRequestPhoto={(value) => {
                  setMessageProfileDetails(value);
                  handleRequestPhoto();
                  setMessageFor("filter")
                   
                }}
                callBackParentsNumber={(value) => {
                  setMessageProfileDetails(value);
                  setParentsNumberRequest(true);
                  setMessageFor("filter")
                }}
                 callBackViewPhone={(profileId,value)=>{
                            setMessageFor("filter")
                            callBackViewPhone(profileId,value)
                          }}
                  />
                )}
                </>
                :
                addTopBanner?.name === "kjbanners" ?
                              <img
                                className="cursor-pointer w-[100%] mt-[3rem] px-[0.5rem]"
                                alt=""
                                src={addTopBanner?.showphotobanner}
                                closeModel={closeModel}
                                onClick={() => {
                                  setShowKjBannerPopup(true);
                                }} />
                              :
                              <img
                                className="cursor-pointer w-[100%] mt-[3rem] px-[0.5rem]"
                                alt=""
                                src={addTopBanner?.showphotobanner}

                                onClick={() => {
                                  bannerRedirect(addBanner?.name, addBanner?.redirect)
                                }} />
                              }
                            
                </>
                 {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}   
                 </>          
                // <p>No profiles found</p>
                :
                props.menu === "weekly"
                  ? 
                  <>
                 <SelectedCount  
                    shortlisted={shortlisted}
                    ignored={ignored}
                    viewed={viewed}
                    setIgnored={setIgnored} 
                    setViewed={setViewed} 
                    setShortlisted={setShortlisted}
                    setIsLoading={setIsLoading}
                    title={"Weekly Matches"} 
                    count={weeklyCount?.WeeklymatchesCount}
                  />
                  {isLoading &&  

                  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {Weeklydata?.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Weekly Matches"}
                        dashboardData={ProfileData}
                        data={Weeklydata}
                        menu="weekly"
                      callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"weekly"
                      );
                      setMessageFor("weekly");
                    }}
                      // menu="weekly"
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("weekly")
                    }}
                     callBackViewPhone={(profileId,value)=>{
                            setMessageFor("weekly")
                            callBackViewPhone(profileId,value)
                          }}
                    // photoRequest={photoRequest} 
                      />
                    )}
                    </>
                    
                  : props.menu === "recommended"
                  ?
                  <>
                <SelectedCount 
                 shortlisted={shortlisted}
                 ignored={ignored}
                 viewed={viewed}
                        setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                        
                          setIsLoading={setIsLoading} 
                         title={"Recommend Matches"} count={recommendationCount?.recommendationsmatchesCount}
                         />
                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>} 
                  {RecommendedData?.length > 0 && (
                      <div className="">
                        <SearchResultSection
                        onSubmitSuccess={(newPhone, newIsd) => { 
                          setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                          content={contentCardData}
                          setContentCardPosition={setContentCardPosition}
                          setContentCardRange={setContentCardRange}
                          contentCardPosition={contentCardPosition}
                          contentCardRange={contentCardRange}
                          title={"Recommend Matches"}
                          data={RecommendedData}
                           dashboardData={ProfileData}
                          // callLoadMore={callbackLoadMoreRecommended}
                          callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"recommend"
                      );
                      setMessageFor("recommend");
                    }}
                          menu='recommended'
                           callBackViewPhone={(profileId,value)=>{
                            setMessageFor("recommend")
                            callBackViewPhone(profileId,value)
                          }}
                          callbackRequestPhoto={(value) => {
                            setMessageProfileDetails(value);
                            handleRequestPhoto();
                            setMessageFor("recommend")
                          }}
                          callBackParentsNumber={(value) => {
                            setMessageProfileDetails(value);
                            setParentsNumberRequest(true);
                            setMessageFor("recommend")
                          }}
                          //  ViewAllCallBack={() => {viewallselection("recommended")}}
                        />
                      </div>
                    )}
                    </>
                  : props.menu === "response"
                  ? 
                  <>
                                <SelectedCount  
                                 shortlisted={shortlisted}
                                 ignored={ignored}
                                 viewed={viewed}
                        setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                        
                          setIsLoading={setIsLoading}
                         title={"Response Received"} count={responseCount?.responseReceivedCount}/>
                  {isLoading &&  <CardSkeleton cards={4} />}
                  {responseReceivedData.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={responseReceivedData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"weekly"
                      );
                      setMessageFor("weekly");
                    }}
                        menu="response"
                        viewProfileStatusPopup={(value) => {
                          setProfileStatusMessage(value);
                          setShowProfileStatusPopup(true)
                        }}
                         callBackViewPhone={(profileId,value)=>{
                            setMessageFor("response")
                            callBackViewPhone(profileId,value)
                          }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("response")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                    )}
                    </>
                  : props.menu === "profilelookingfor"
                  ?
                  <>
                      <SelectedCount
                        shortlisted={shortlisted}
                        ignored={ignored}
                        viewed={viewed}
                        setIgnored={setIgnored} setViewed={setViewed} 
                        setShortlisted={setShortlisted}
                        setIsLoading={setIsLoading}
                        title={"Profiles Matching the Partner Expectation"} 
                        count={profileLookingCount?.ProfileslookingforCount}
                        />

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                   {profileILookingForData.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={profileILookingForData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"lookingfor"
                      );
                      setMessageFor("lookingfor");
                    }}
                        menu="profilelookingfor"
                         callBackViewPhone={(profileId,value)=>{
                            setMessageFor("lookingfor")
                            callBackViewPhone(profileId,value)
                          }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("lookingfor")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("lookingfor")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                    )
                  }
                  </>
                  : props.menu === "profilelookingforme"
                  ? 
                  <>
                     <SelectedCount 
                      shortlisted={shortlisted}
                      ignored={ignored}
                      viewed={viewed}
                   
                   setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                   
                     setIsLoading={setIsLoading}
                   title={"Profiles looking for me"} count={lookingFormeCount?.ProfileslookingformeCount}/>

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {profileLoookingForMeData.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={profileLoookingForMeData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"lookingforme"
                      );
                      setMessageFor("lookingforme");
                    }}
                        menu="profilelookingforme"
                         callBackViewPhone={(profileId,value)=>{
                            setMessageFor("lookingforme")
                            callBackViewPhone(profileId,value)
                          }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("lookingforme")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("lookingforme")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                    )}
                    </>
                  : props.menu === "photorequestreceived"
                  ? 
                  <>
                    <SelectedCount  
                   shortlisted={shortlisted}
                   ignored={ignored}
                   viewed={viewed}
                  setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                  
                    setIsLoading={setIsLoading}
                  title={"Photo Request Received"} count={photoRequestCount?.PhotoRequestReceivedCount}/>

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {photoRequestData.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={photoRequestData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"weekly"
                      );
                      setMessageFor("weekly");
                    }}
                    //  photoRequest={photoRequest} 
                    viewProfileStatusPopup={(value) => {
                      setProfileStatusMessage(value);
                      setShowProfileStatusPopup(true)
                    }}
                        menu="photorequestreceived"
                         callBackViewPhone={(profileId,value)=>{
                            setMessageFor("photorequest")
                            callBackViewPhone(profileId,value)
                          }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("photorequest")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                    )}
                    </>
                  : props.menu === "mutualmatches"
                  ? 
                  <>
                    <SelectedCount
                     shortlisted={shortlisted}
                     ignored={ignored}
                     viewed={viewed} 
                  
                  setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                  
                    setIsLoading={setIsLoading}
                   title={"Mutual Matches"} count={mutualCount?.MutalmatchesCount}/>

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {MutualData.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={MutualData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"mutual"
                      );
                      setMessageFor("mutual");
                    }}
                        menu="mutualmatches"
                         callBackViewPhone={(profileId,value)=>{
                            setMessageFor("mutual")
                            callBackViewPhone(profileId,value)
                          }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("mutual")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("mutual")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                    )}
                    </>
                  : props.menu === "similarmatches"
                  ? 
                  <>
                      <SelectedCount 
                       shortlisted={shortlisted}
                       ignored={ignored}
                       viewed={viewed}
                   
                   setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                   
                     setIsLoading={setIsLoading}
                   title={"Similar Matches"} count={similarCount?.similarmatchesCount}/>

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {similarmatchesdata.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Response Received"}
                        data={similarmatchesdata}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"selected"
                      );
                      setMessageFor("selected");
                    }}
                        menu="similarmatches"
                         callBackViewPhone={(profileId,value)=>{
                            setMessageFor("similar")
                            callBackViewPhone(profileId,value)
                          }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("similar")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("similar")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      /> 
                    )}
                    </>
                    : location.state?.menu === "explorematches"
                  ? 
                  <>
                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {exploreMatchesData.length > 0 && 
                      <>
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Explore Matches"}
                        searchBy={location?.state?.filterBy}
                        data={exploreMatchesData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"selected"
                      );
                      setMessageFor("explore");
                    }}
                        menu="explorematches"
                         callBackViewPhone={(profileId,value)=>{
                            setMessageFor("explore")
                            callBackViewPhone(profileId,value)
                          }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("explore")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("explore")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      />
                      <div className="text-center py-2">
                          {/* {exploreMatchesData.length > 0 && ( */}
                          {(exploreDataLength >= 5 && !isFilterResult)&&(
                          
                          <>
                            <button
                                onClick={callBackExploreLoadMore}
                              className="hidden md:block md:mx-auto rounded-[5px] text-[10px] px-3 py-2 bg-[#D10A11] text-[#FFFFFF] font-bold font-segeo"
                            >
                            {exploreLoadMore?
                            <>
                                <span className="flex">
                                <img alt='' src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5"/><span>Loading...</span>
                                </span>
                            </> 
                            : <>Load More</>
                          }
                            </button>
                            {exploreLoadMore && <div className="block md:hidden"><LoaderMoreMobileSpinner /></div>}
                          </>
                        )
                        }
                      </div> 
                      </>
                    }
                    </>
                  
                  : props.menu === "selectedprofile" ||   location?.state?.isfrom === "selectedprofile"?
                   
                  <>
                                 <SelectedCount   
                                  shortlisted={shortlisted}
                                  ignored={ignored}
                                  viewed={viewed}
                   setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                   
                     setIsLoading={setIsLoading}
                    title={"Selected Profile"} count={selectedCount?.selectedProfileDetailsCount}/>
                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {selectedProfileData.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                      callBackBlockProfile={callBackBlockProfile}
                        title={"Response Received"}
                        data={selectedProfileData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"selected"
                      );
                      setMessageFor("selected");
                    }}

                    viewProfileStatusPopup={(value) => {
                      setProfileStatusMessage(value);
                      setShowProfileStatusPopup(true)
                    }}
                        menu="selectedprofile"
                         callBackViewPhone={(profileId,value)=>{
                            setMessageFor("selected")
                            callBackViewPhone(profileId,value)
                          }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("selected")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("selected")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      /> 
                    )
                  }
                  </>
                  : props.menu === "recentlyviewed"
                  ?
                  <>
              <SelectedCount 
                   shortlisted={shortlisted}
                   ignored={ignored}
                   viewed={viewed}
                  setIgnored={setIgnored} setViewed={setViewed} setShortlisted={setShortlisted}
                  
                    setIsLoading={setIsLoading}
                   title={"Recently Viewed"} count={resentlyViewdCount?.recentViewCount}/>

                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {
                  // recentlyViewedLoader?
                  recentlyViewedData.length > 0 && (
                    <SearchResultSection
                    onSubmitSuccess={(newPhone, newIsd) => { 
                      setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                      content={contentCardData}
                      setContentCardPosition={setContentCardPosition}
                      setContentCardRange={setContentCardRange}
                      contentCardPosition={contentCardPosition}
                      contentCardRange={contentCardRange}
                        title={"Recently Viewed"}
                        data={recentlyViewedData}
                         dashboardData={ProfileData}
                        // callLoadMore={callbackLoadMoreResponseReceived}
                        callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"selected"
                      );
                      setMessageFor("selected");
                    }}
                        menu="recentlyviewed"
                         callBackViewPhone={(profileId,value)=>{
                            setMessageFor("recent")
                            callBackViewPhone(profileId,value)
                          }}
                        callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("recent")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("recent")
                    }}
                        // ViewAllCallBack={() => {viewallselection("response")}}
                      /> 
                    )   
        //           : 
        //           <center>
        //   <img alt='' src={LoaderGif} />
        // </center>

}
                  </> 
                  :
                  props.menu === "weekly"
                  ? 
                  <>
                  {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                  {Weeklydata?.length > 0 && (
                      <SearchResultSection
                      onSubmitSuccess={(newPhone, newIsd) => { 
                        setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                        content={contentCardData}
                        setContentCardPosition={setContentCardPosition}
                        setContentCardRange={setContentCardRange}
                        contentCardPosition={contentCardPosition}
                        contentCardRange={contentCardRange}
                        title={"Explore Mtahces"}
                        dashboardData={ProfileData}
                        data={Weeklydata}
                        menu="explore"
                      callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index,"explore"
                      );
                      setMessageFor("explore");
                    }}
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("weekly")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("weekly")
                    }}
                     callBackViewPhone={(profileId,value)=>{
                            setMessageFor("weekly")
                            callBackViewPhone(profileId,value)
                          }}
                      />
                    )}
                    </>
                    :
                    props.menu == "savepartnerpreferences" ?
                    <>
                    {isLoading &&  <CardSkeleton cards={4} menu={props?.menu}/>}
                    
                   
                    {searchPageData?.length > 0 && (
                        <>
                         {addTopBanner?.name === "kjbanners" ?
                              <img
                                className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem] an"
                                alt=""
                                src={addTopBanner?.showphotobanner}
                                closeModel={closeModel}
                                onClick={() => {
                                  setShowKjBannerPopup(true);
                                }} />
                              :
                              <img
                                className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem] ana"
                                alt=""
                                src={addTopBanner?.showphotobanner}
                                onClick={() => {
                                  bannerRedirect(addTopBanner?.name, addTopBanner?.redirect)
                                }} />
                              }
                               {showCriteriaMessage != "" ?
                               <>
                          <div className="flex mt-2 px-2 justify-center items-center">
                          <p>{showCriteriaMessage}?</p>
                          </div>
                          <div className="flex mt-2 px-2 justify-end">
                          <button className='rounded-[5px] text-[#FFFFFF] bg-[#D10A11] px-[3%] py-[0.5%] font-bold ml-[1rem]' onClick={newPreferenceAdd}>YES</button>
                          <button className='rounded-[5px] text-[#FFFFFF] bg-[#D10A11] px-[3%] py-[0.5%] font-bold ml-[1rem]' onClick={()=>setShowCriteriaMessage("")} >NO</button>
                        </div>
                        </>
                        :""}
                         {partnerProfileCount && <p className="text-center py-2">{partnerProfileCount}</p>}
                        <SearchResultSection
                        onSubmitSuccess={(newPhone, newIsd) => { 
                          setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))}}
                          content={contentCardData}
                          setContentCardPosition={setContentCardPosition}
                          setContentCardRange={setContentCardRange}
                          contentCardPosition={contentCardPosition}
                          contentCardRange={contentCardRange}
                          viewProfileStatusPopup={(value) => {
                            setProfileStatusMessage(value);
                            setShowProfileStatusPopup(true)
                          }}
                          callbackRequestPhoto={(value) => {
                            setMessageProfileDetails(value);
                            handleRequestPhoto();
                            setMessageFor("search")
                          }}
                          callBackParentsNumber={(value) => {
                            setMessageProfileDetails(value);
                            setParentsNumberRequest(true);
                            setMessageFor("search")
                          }}
                          dashboardData={ProfileData}
                           callBackViewPhone={(profileId,value)=>{
                            setMessageFor("search")
                            callBackViewPhone(profileId,value)
                          }}
                            title={"Save Partner Preferences"}
                            data={searchPageData}
                            // callbackSelectReject={callbackSelectReject}
                            callbackSelectReject={(
                              btntype,
                              profileID,
                              typeRequest,
                              profileDetails,
                              index
                            ) => {
                              callbackSelectReject(
                                btntype,
                                profileID,
                                typeRequest,
                                profileDetails,
                                index,"search"
                              );
                              setMessageFor("search");
                            }}
                            menu={props.menu}
                          />
                          </>
                    )}
                    </>
                    :
                    addTopBanner?.name === "kjbanners" ?
                              <img
                                className="cursor-pointer w-[100%] mt-[3rem] px-[0.5rem]"
                                alt=""
                                src={addTopBanner?.showphotobanner}
                                closeModel={closeModel}
                                onClick={() => {
                                  setShowKjBannerPopup(true);
                                }} />
                              :
                              <img
                                className="cursor-pointer w-[100%] mt-[3rem] px-[0.5rem]"
                                alt=""
                                src={addTopBanner?.showphotobanner}

                                onClick={() => {
                                  bannerRedirect(addTopBanner?.name, addTopBanner?.redirect)
                                }} />
                              }
                              {/* old mob view load more */}
                {/* <div className="text-center py-2 mb-[5.25rem] md:hidden ">
                  {

                 
                      selectedProfileData.length  >= 5 ||
                  MutualData.length >= 5 ||
                  photoRequestData.length >= 5 ||
                  profileLoookingForMeData.length >= 5 ||
                  profileILookingForData.length >= 5 ||
                  responseReceivedData.length >= 5 ||
                  RecommendedData.length>= 5 ||
                  recentlyViewedData.length>= 5 ||
                  similarmatchesdata.length >= 5||
                  Weeklydata.length >= 5 ||
                  searchPageData.length >= 1  ? (
                    
                    (dataLength >= 5 ?
                    
                    <button
                      onClick={callBackLoadMore} 
                      className=" webload rounded-[5px] text-[10px] px-3 py-2 bg-[#D10A11] text-[#FFFFFF] font-bold font-segeo"
                    >{ 
                    submitStatus?
                     <><span className="flex">
                     <img alt='' src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5"/><span>Loading...</span>
                     </span>
                     </> : <>Load More</>
                    }
                    </button>
                    : <></>)
                  ) 
                 :
                    filterData?.length > 0 ?
                    (filterResponseData?.filtermatches?.length >= 5 ? 
                      <button
                        onClick={callBackLoadMore} 
                        className="  rounded-[5px] text-[10px] px-3 py-2 bg-[#D10A11] text-[#FFFFFF] font-bold font-segeo"
                      >{ 
                      submitStatus?
                       <><span className="flex">
                       <img alt='' src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5"/><span>Loading...</span>
                       </span>
                       </> : <>Load More</>
                      }
                      </button> 
                      : 
                      <>
                     
                      </> 
                      )
                      
                 
                
                  
                  :
                  selectedProfileData.length  >=1 ||
                  MutualData.length >= 1 ||
                  photoRequestData.length >= 1 ||
                  profileLoookingForMeData.length >= 1 ||
                  profileILookingForData.length >= 1 ||
                  responseReceivedData.length >= 1 ||
                  RecommendedData.length>= 1 ||
                  recentlyViewedData.length>= 1 ||
                  similarmatchesdata.length >= 1||
                  Weeklydata.length >= 1 ||
                  exploreMatchesData.length >=1 ||
                  searchPageData.length >= 1 
                   ? (
                     ""
                     )
                  :  !loading ?   <CardSkeleton cards={4} menu={props?.menu} />:  
                  
                  dataLength == 0 ?
                  
                  <p className="py-5 ">No Profiles Found</p> : <CardSkeleton cards={4} menu={props?.menu} />
  
                //  (
                //     " "
                //     )
                //   :  !loading ?   <CardSkeleton cards={4} menu={props?.menu} />:  
                  
                //   dataLength == 0 ?
                  
                //   <p className="py-5 ">No Profiles Found</p> : <CardSkeleton cards={4} menu={props?.menu} />
              }
              
                </div> */}
                
                {/* new mob view load more */}
                 <div className="text-center py-2 mb-[5.25rem] lg:hidden">
                  {
                     selectedProfileData.length  >= 5 ||
                  MutualData.length >= 5 ||
                  photoRequestData.length >= 5 ||
                  profileLoookingForMeData.length >= 5 ||
                  profileILookingForData.length >= 5 ||
                  responseReceivedData.length >= 5 ||
                  RecommendedData.length>= 5 ||
                  recentlyViewedData.length>= 5 ||
                  similarmatchesdata.length >= 5||
                  Weeklydata.length >= 5 ||
                  searchPageData.length >= 1 ||
                  filterResponseData?.filtermatches?.length >= 5 
                  && 
                  loading
                  
                  ? (
                    
                        (dataLength >= 5 ?
                          <>
                            <button
                              onClick={isLoadFromRecommeded ? () => onResultYes(true) : callBackLoadMore}
                              className="hidden md:block md:mx-auto rounded-[5px] text-[10px] px-3 py-2 bg-[#D10A11] text-[#FFFFFF] font-bold font-segeo"
                            >
                              {
                                submitStatus ?
                                  <><span className="flex">
                                    <img alt='' src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" /><span>Loading...</span>
                                  </span>
                                  </> : <>Load More </>
                              }
                            </button>
                            {submitStatus && <div className="block md:hidden"><LoaderMoreMobileSpinner /></div>}
                          </>
                          : <>
                            {/* <CardSkeleton cards={4} menu={props?.menu} /> */}
                            {searchResultMessage && <SearchResultMessage loading={submitStatus} message={submitStatus ? "Loading..." : searchResultMessage} onClick={() => onResultYes(false, "loadMoreList")} />}
                          </>
                        )
                  ) 
                  :
                    
       
                  selectedProfileData.length  >= 1 ||
                  MutualData.length >= 1 ||
                  photoRequestData.length >= 1 ||
                  profileLoookingForMeData.length >= 1 ||
                  profileILookingForData.length >= 1 ||
                  responseReceivedData.length >= 1 ||
                  RecommendedData.length>= 1 ||
                  recentlyViewedData.length>= 1 ||
                  similarmatchesdata.length >= 1||
                  Weeklydata.length >= 1 ||
                  exploreMatchesData.length >= 1 ||
                  searchPageData.length >= 1 ||
                  filterResponseData?.filtermatches?.length >= 1 
                  ? (
                     ""
                     )
                  :  !loading ?   <CardSkeleton  cards={4} menu={props?.menu} />:  
                  
                          dataLength == 0
                            ? props.menu === "savepartnerpreferences" && searchResultMessage 
                              ? <SearchResultMessage message={searchResultMessage} onClick={onResultYes}/>
                              : <p className="py-5 ">No Profiles Found</p>
                            : <CardSkeleton cards={4} menu={props?.menu} />
                  
  
                 
              }
              
                </div>

              </div>
              <div
                className="hidden col-span-3 md:col-span-5 lg:col-span-3  ml-[1.2rem]  lg:ml-0  md:block"
                style={{
                  borderLeft: "1px solid #00000029",
                }}
              >
                {/* <ProfileSection /> */}
                {loading ? 
                  <ProfileSection data={ProfileData} profileDataCount={leftPanelData} onSubmitSuccess={(newPhone, newIsd) => { 
                  console.log("newPhone, newIsd",newPhone, newIsd);
                  setProfileData(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))
                }}/>
                  :
                  <div className=" section md:border md:border-[#F1F1F1] lg:border lg:border-[#F1F1F1] my-6 bg-white" >
                    <SidePannelProfileSkeleton  cards={1} menu="profile"/>
                    <div className="mt-4 px-3">
                        <CardSkeletonWithSlider cards={3} menu={props?.menu}/>
                    </div>
                  </div>
                }
                {location?.state?.isfrom === "recentlyviewed"? ""
                :
                recentlyViewedData1.length > 0 && (
                  <DashboardSection4
                    title={"Recently Viewed"}
                    data={recentlyViewedData1}
                    count={recentlyViewedDataCount} 
                    callLoadMore={callbackLoadMoreRecentlyView}
                    callbackSelectReject={(
                      btntype,
                      profileID,
                      typeRequest,
                      profileDetails,
                      index
                    ) => {
                      callbackSelectReject(
                        btntype,
                        profileID,
                        typeRequest,
                        profileDetails,
                        index, "recent"
                      );
                      setMessageFor("recent");
                    }}
                    isViewmore={true}
                    ViewAllCallBack={() => {
                      // viewallselection("recentlyviewed");
                      setNavList([])
                      setNavList(["Matches", "Recently Viewed"])
                       navigate(RouteHelper.getRecentlyViewedUrl, {
                        state: { isfrom: "recentlyviewed" },
                      });
                      //  setNavList([...navList, "RecentlyViewed"])
                      //console.log("viewmore");
                    }}
                    callBackViewMore={(value) => { 
                      //console.log("viewmore", value);
                    }}
                    callbackRequestPhoto={(value) => {
                      setMessageProfileDetails(value);
                      handleRequestPhoto();
                      setMessageFor("recent")
                    }}
                    callBackParentsNumber={(value) => {
                      setMessageProfileDetails(value);
                      setParentsNumberRequest(true);
                      setMessageFor("recent")
                    }}
                  />
                )
                  }
                  <div className="py-4 md:ml-[1rem]">
                              {isFilterResult || props.menu == "savepartnerpreferences"?
                              addBanner?.name === "kjbanners" ?
                              <img
                                className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem]"
                                alt=""
                                src={addBanner?.showphotobanner}
                                closeModel={closeModel}
                                onClick={() => {
                                  setShowKjBannerPopup(true);
                                }} />
                              :
                              <img
                                className="cursor-pointer w-[100%] mt-[2rem] px-[0.5rem]"
                                alt=""
                                src={addBanner?.showphotobanner}

                                onClick={() => {
                                  bannerRedirect(addBanner?.name, addBanner?.redirect)
                                }} />
                                :""
                              }
                              </div>
              </div>
            </div>
            {/* : */}
                     {/* <center><img src={LoaderGif} className='pt-[9rem] pb-[2rem]' alt="" /></center> */}
{/* } */}
          </div>
          <Footer />
          <DownloadAppBar dashboardData={ProfileData} haveBottom={true}/>
          <ViewMessageBottomNav />
        </main>
{/* //         :
//         <center>
//           <img alt='' src={LoaderGif} />
//         </center>
// } */}
      </div>
      

    </>
  );
};

export default SearchResultpage;





