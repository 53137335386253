import React, { useEffect } from 'react'
import * as Images from "../../Components/Kalyanlite/assets/images"
import { toast } from 'react-toastify';
import Constants, { EditProfileMenu } from '../../Constants/constants';
import { Store_Activity_Store_List } from '../../Helpers/api_helpers';
import PaperBlast from '../PaperBlast';
import { useDispatch, useSelector } from 'react-redux';
import { hideCelebration } from '../../Store/KalyanRewards/Action';
import { useNavigate } from 'react-router-dom';
import { RouteHelper } from '../../Routes/RouteSegment';

// export const logActivity = ({ activityId, activityData }) => {
//     showPointEarnedNotification("Congratulations! you have successfully logged in and earned 5 points, Your rewards are being calculated. This process may take a few moments", 5)

//     const axiosConfig = {
//         headers: {
//             Authorization: `Bearer ${localStorage.getItem(Constants.loginLocalStorageKeys.chatLogin)}`,
//         },
//         data: {
//             profile_id: localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
//             activity_id: activityId,
//             user_id: localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
//             status: "pending",
//         }
//     };
//     Store_Activity_Store_List(axiosConfig)
// }

// export const showPointEarnedNotification = (message) => {
//     toast(<PointEarnedNotification message={message} />, { hideProgressBar: true })
// }


const PointEarnedNotification = () => {
    const message = useSelector(state => state?.KalyanRewards?.celebrationMessage)
    const dateTime = useSelector(state => state?.KalyanRewards?.dateTime)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (message) {
            toast(
            <div className='flex items-center'>
                <img src={Images.KalyanCoin} className='w-10 h-10 mr-4 kalyan-coin' alt='' />
                {message}
            </div>,
                {
                    hideProgressBar: true,
                    onClose: () => {
                        dispatch(hideCelebration())
                    },
                    onClick: () => {
                        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[13].urlPath))
                    }
                }
            )
        }
    }, [message, dateTime])


    return <></>
    // return (
    //     <div className='flex justify-center bg-opacityblack75  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-[100] outline-none focus:outline-none'>
    //         <div className='relative md:w-auto  my-[0.5rem] md:my-6 lg:my-6 mx-6 flex flex-col justify-center items-center'>
    //             <img src={Images.KalyanCoin} className='w-16 h-16 mr-4 my-4 kalyan-coin' alt='' />
    //             <p className='text-[16px] font-Poppins-Regular font-semibold w-[35rem] text-white text-center'>{message}</p>
    //         </div>
    //         <PaperBlast />
    //     </div>
    // )
};

export default PointEarnedNotification