import React, { useEffect, useState, useContext, useMemo } from "react";
import NavBar from "../Components/NavBar";
import Footer from "../Components/Footer/Footer";
import AccordianComponents from "../Components/ViewMessageComponents/AccordianComponents";
import ViewMessageCards from "../Components/ViewMessageComponents/ViewMessageCards";
import ProfileSection from "../Components/DashboardComponents/ProfileSection";
import { DashboardSection4 } from "../Components/DashboardComponents/DashboardSection";
import { useNavigate } from "react-router";

import { POST } from "../Services/api_services";
import { IP_Address } from "../Constants/IpAddress";
import endPoints from "../Constants/EndPoints";
import ViewMessageNav from "../Components/ViewMessageComponents/ViewMessageNav";
import { RedButton } from "../Components/Buttons";
import ViewMessageBottomNav from "../Components/ViewMessageComponents/ViewMessageBottomNav";
import { NavBarContext } from "../Contexts/NavBarContext";

//context
import { LoginContext } from "../Contexts/LoginContext";
import { useLocation, useMatch } from "react-router";
import { message } from "antd";
import RouteSegments, { RouteHelper, RoutePaths } from "../Routes/RouteSegment";
import Conversation from "../Components/Conversation/Conversation";
import { CommonPopup } from "../Components/CommonPopup";
import { useSearchParams, useParams } from "react-router-dom";
import Constants, { EditProfileMenu, InboxFilters } from "../Constants/constants";
import KjBannerPopup from "../Components/DashboardComponents/KjBannerPopup";
import { NavListContext } from "../Contexts/navContext";
import config from "../config";
import LoaderGif from "../Gif/loader.gif"
import FilterOpenIcon from '../Images/Group 495.svg'
import FilterCloseIcon from '../Images/Group 497.svg'
import ParentsPhonePopup from "../Components/DashboardComponents/ParentsPhonePopup";
import Loader from "../Components/Loader";
import DownloadAppBar from "../Components/DownloadAppBar";
import { useDispatch, useSelector } from "react-redux";
import { dashboardLeftPanelData, dashboardMatcheslist, dashboardProfileData } from "../Store/DashboardAPI/Action";

const ViewMessage = (props) => {
  const navigate = useNavigate()
  const location = useLocation();
  const {tab} = useParams()
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);

  const [showKjBannerPopup, setShowKjBannerPopup] = useState(false);
  const [showKjThankPopup, setShowKjThankPopup] = useState(false);
  const [selectedListIndex, setSelectedListIndex] = useState(0)
  const [selectedMessageType, setSelectedMessageType] = useState("")
  const [dashboard1Data, setDashboard1Data] = useState([]);
  const [loading, setLoading] = useState(true)
  const [isFetching, setIsFetching] = useState(true)
  const [addBanner, setAddBanner] = useState();
  const [showProfileStatusPopup, setShowProfileStatusPopup] = useState(false)
  const [ProfileStatusMessage, setProfileStatusMessage] = useState("")
  const [addKjBanner, setAddKjBanner] = useState();
  const [fromComponent, setFromComponent] = useState("")
  const [isLoading, setisLoading] = useState(false)
  const [isSidepanel, setIsSidePanel]=useState(false)
  const { navList, setNavList } = useContext(NavListContext);
// console.log(navList,"navList");

  const { loginDetail, logout } = useContext(LoginContext);
  const [showParentNumPopup, setShowParentNumPopup] = useState(false)
  const [recentlyViewedData, setrecentlyViewedData] = useState([]);
  const [inboxData, setInboxData] = useState([]);
  let cardPageNumValue = {
    inboxMessagePage: 0,
    requestReceivedPage: 0,
    requestSentPage: 0,
  };
  const [cardPageNum, setCardPageNum] = useState(cardPageNumValue);
  const [isShowLoadMore, setShowLoadMore] = useState(true)

  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamTab = tab
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(dashboardLeftPanelData())
    dispatch(dashboardProfileData())
  },[])




    //Left Panel API Call and Response  
    const { leftPanelData } = useSelector(state => ({
      leftPanelData: state.DashBoardAPI?.leftPanelData?.data
    }));

     //Profile Data API Call and Response  
     const { ProfileData } = useSelector(state => ({
      ProfileData: state.DashBoardAPI?.ProfileData?.data
    }));

    useEffect(() => {
      if(ProfileData?.status){
        localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, ProfileData?.status)

        if (ProfileData?.popup[0].showpopup == "RejectedProfile") {
          navigate(RouteSegments.EDITPROFILE)
        }

        setAddBanner(ProfileData?.addbanner[0]?.showphotobanner);
        setAddKjBanner(ProfileData.addbanner[0]?.kjbanner);
      }
    }, [ProfileData])
  
 
  const conversationData = useMemo(() => {
    return {
      show: location.pathname.split("/").includes(RoutePaths.CONVERSATION),
      profileId: location.state?.profileId,
      threadId: location.state?.threadId,
      messageType: location.state?.type,
    }
  }, [location])
  console.log("location.location",location?.state);

  useEffect(() => {
    console.log(conversationData.messageType,"type");
  },[])

  useEffect(() => {
    let currentSection = "message";
    Object.keys(InboxFilters).forEach(key => {
      const index = InboxFilters[key].findIndex(data => data.urlPath === searchParamTab);
      if (index !== -1) {
        currentSection = key;
      }
    })

    if (currentSection === "message") {
      setMainAccordian({...mainAccordian,  message: true})
      callbackFilter(null, searchParamTab, "MESSAGE");
    } else if (currentSection === "requestReceived") {
      setMainAccordian({...mainAccordian,  requestReceived: true })
      callbackFilter(null, searchParamTab, "REQUESTRECEIVED");
    }
    else if (currentSection === "sentRequest") {
      setMainAccordian({...mainAccordian,  requestsent: true })
      callbackFilter(null, searchParamTab, "REQUESTSENT");
    }
  }, [searchParamTab])
  

  // const tab = parseInt(searchParams.get('tab'))

  // useEffect(() => {
  //   setFromComponent("View Message")
  //   if(searchParams.get('tab')){
  //     setActiveTab(tab)
  //   }
  // },[searchParams.get('tab')])

  // const setActiveTab = (tab)=>{
  //   if (tab === 1) {
  //     setMainAccordian({ ...mainAccordian, message: true })
  //     callbackFilter(tab, 0, "MESSAGE");
  //   } else if (tab === 2) {
  //     setMainAccordian({ ...mainAccordian, requestReceived: true })
  //     callbackFilter(tab, 0, "REQUESTRECEIVED");
  //   }
  //   else if (tab === 3) {
  //     setMainAccordian({ ...mainAccordian, requestsent: true })
  //     callbackFilter(tab, 0, "REQUESTSENT");
  //   }
  //   else {
  //     setMainAccordian(mainAccordian)
  //   }
  //   searchParams.set('tab',tab)
  //   setSearchParams(searchParams,{replace:true})
  // }

 
  const [mainAccordian, setMainAccordian] = useState({
    message: false,
    requestReceived: false,
    requestsent: false
  })
  const [inboxFilter, setInboxFilter] = useState(InboxFilters);
  const [selectedFilter, setSelectedFilter] = useState({
    selectedType: "MESSAGE",
    selectedRequest: "All Message",
  });

  useEffect(() => {   
    //console.log(isSidepanel,"isSidepanel");
    //console.log(location?.state,"location?.state?.type");

  },[props?.menu,isSidepanel])


  //console.log(showList, "showList");
  useEffect(() => {

    // apicallDashboard();

    // callbackFilter("", location?.state?.subMenu ?? 0, location?.state?.menu ?? "MESSAGE")
    // apicallInboxMessage(10, "All Message");
    //console.log(selectedFilter,"location?.state?.subMenu ")
    // if (location?.state?.menu === "MESSAGE") {

    //   setMainAccordian({ ...mainAccordian, message: true })
    // } else if (location?.state?.menu === "REQUESTRECEIVED") {

    //   setMainAccordian({ ...mainAccordian, requestReceived: true })
    // }
    // else if (location?.state?.menu === "REQUESTSENT") {

    //   setMainAccordian({ ...mainAccordian, requestsent: true })
    // }
    // else {
    //   setMainAccordian(mainAccordian)
    // }

  }, []);
  useEffect(() => {
    //console.log("cardPageNum", cardPageNum);
  }, [inboxData, inboxFilter, cardPageNum, addBanner, mainAccordian, isShowLoadMore]);
  // const apicallDashboard = async () => {
  //   let request = {
  //     email: loginDetail()[0],
  //     userId: loginDetail()[1],
  //     pageno: "1",
  //     status: "new",
  //   };
  //   let { statusCode, data } = await POST(
  //     `${config.api.API_URL}${endPoints.dashboardUrl()}`,
  //     request
  //   );

  //   if (statusCode === 200) {
  //     setDashboard1Data(data?.data);
  //     localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, data?.data.status)
  //     setAddBanner(data?.data?.addbanner[0]?.showphotobanner);
  //     setAddKjBanner(data?.data.addbanner[0]?.kjbanner);
  //     if(data?.data?.popup[0].showpopup == "RejectedProfile"){
  //       navigate(RouteHelper.getEditProfileUrl())
  //     }
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  // };

  const apicallFilter = async (pageSize, selectedType, requestType) => {
    
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      requesttype: requestType,
      type: selectedType,
      pageno: `${selectedType === "Sent"
        ? cardPageNum.requestSentPage
        : cardPageNum.requestReceivedPage
        }`,
      size: pageSize,
      //removed because of parents number section
      // loginFrom: requestType !== "Parents Number"? localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice):undefined,
    };
    setIsFetching(true);
    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.inboxRequestViewUrl()}`,
      request
    );
    setLoading(false)
    setIsFetching(false);
    if (statusCode === 200) {
      setIsSidePanel(false)
      if (data.data.inboxdetails.length > 0) {
        data.data.inboxdetails.map((e, i) => {
          setInboxData((oldValue) => [...oldValue, e]);
        });
        if (selectedType === "Sent") {
          cardPageNum.requestSentPage = cardPageNum.requestSentPage + 1;
        } else {
          cardPageNum.requestReceivedPage = cardPageNum.requestReceivedPage + 1;
        }
        if ((pageSize === 10 && data.data.inboxdetails.length < 10) || (pageSize === 5 && data.data.inboxdetails.length < 5)) {
          setShowLoadMore(false)
        } else {
          setShowLoadMore(true)

        }

      } else {

      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apicallInboxMessage = async (pageSize, selectType) => {
    
    setSelectedMessageType(selectType)
    //console.log("selectType", selectType);
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      selectedtext: selectType,
      filtertext: [],
      pageno: `${cardPageNum.inboxMessagePage}`,
      size: pageSize,
    };

    setIsFetching(true);

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${endPoints.inboxMessageUrl()}`,
      request
    );

    setIsFetching(false);

    if (statusCode === 200) {
      setLoading(false)
      setIsSidePanel(false)
      if (data.data.inboxdetails.length > 0) {
        data.data.inboxdetails.map((e, i) => {
          setInboxData((oldValue) => [...oldValue, e]);
        });

        cardPageNum.inboxMessagePage = cardPageNum.inboxMessagePage + 1;
        if ((pageSize === 10 && data.data.inboxdetails.length < 10) || (pageSize === 5 && data.data.inboxdetails.length < 5)) {
          setShowLoadMore(false)
          setisLoading(true)
        } else {
          setShowLoadMore(true)
          setisLoading(false)
        }

      } else {
        // setShowLoadMore(false)
      }
    } else if (statusCode === 401) {
      logout();
    }
    // setLoading(false);
  };
  const callBackLoadMore = () => {
    if (selectedFilter.selectedType === "MESSAGE") {
      setisLoading(true)
      apicallInboxMessage(5, selectedFilter.selectedRequest);
    } else if (selectedFilter.selectedType === "REQUESTRECEIVED") {
      apicallFilter(5, "Received", selectedFilter.selectedRequest);
    } else if (selectedFilter.selectedType === "REQUESTSENT") {
      apicallFilter(5, "Sent", selectedFilter.selectedRequest);
    }
  };

  // useEffect(() => {

  // },[loca])

  const callbackLoadMoreRecentlyView = async () => { };
  const callbackFilter = (element, index, isFrom) => {
    if(localStorage.getItem("currentChat")==1){
      localStorage.setItem("currentChat",0)
    }
    else if(localStorage.getItem("BLOCKED_STATUS") == 0){
      setSelectedListIndex(localStorage.getItem("selectedIndex"))
    }
    else if(location.state?.type){
      setFromComponent(location.state?.type)
    }
    //console.log(location.state?.type,"location.state?.type")
    //console.log("inbox initial",index, isFrom)
    setInboxFilter({
      ...inboxFilter,
      message: inboxFilter.message.map((e, i) => {
        if (isFrom === "MESSAGE") {
          if (index == e.urlPath) {
            //console.log(index,i,'index');
            if (e.isSelected === false) {
              setInboxData([]);
              cardPageNum.inboxMessagePage = 0;

              setSelectedFilter({
                selectedType: isFrom,
                selectedRequest: e.value,
              });
              apicallInboxMessage(10, e.value);
            }
            return { ...e, isSelected: true };
          } else {
            return { ...e, isSelected: false };
          }
        } else {
          return { ...e, isSelected: false };
        }
      }),
      requestReceived: inboxFilter.requestReceived.map((e, i) => {
        if (isFrom === "REQUESTRECEIVED") {
          if (index == e.urlPath) {
            if (e.isSelected === false) {
              setInboxData([]);
              cardPageNum.requestReceivedPage = 0;
              setSelectedFilter({
                selectedType: isFrom,
                selectedRequest: e.value,
              });
              apicallFilter(10, "Received", e.value);
            }
           
            return { ...e, isSelected: true };
          } else {
            return { ...e, isSelected: false };
          }
        } else {
          return { ...e, isSelected: false };
        }
      }),
      sentRequest: inboxFilter.sentRequest.map((e, i) => {

        //console.log(inboxData, "req")
        if (isFrom === "REQUESTSENT") {
          if (index == e.urlPath) {
            if (e.isSelected === false) {
              setInboxData([]);
              cardPageNum.requestSentPage = 0;
              setSelectedFilter({
                selectedType: isFrom,
                selectedRequest: e.value,
              });
              apicallFilter(10, "Sent", e.value);
            }
           
            return { ...e, isSelected: true };
          } else {
            return { ...e, isSelected: false };
          }
        } else {
          return { ...e, isSelected: false };
        }
      }),
    });
  };

  useEffect(() => {

    return () => {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    }
  }, [])


  const handleClickViewBtn = (i, btnType, profileId, message) => {

  
    
    //console.log("btnType", btnType)
    //console.log(selectedFilter.selectedType,"selectedFilter.selectedType");
    //console.log(location?.state?.type,"location?.state?.type");
    //console.log(inboxData,"inbox");
    console.log("inbox initial", i, btnType, profileId, message)
      setFromComponent(btnType);
    if (selectedFilter.selectedType === "MESSAGE") {
      localStorage.setItem("selectedIndex",i)
      setSelectedListIndex(i)
      // searchParams.set("refId",`${profileId}/${message?.subjectId}/${message?.msgtype}`)
      // setSearchParams(searchParams)
      let messageType = message?.msgtype;
      if(btnType === "Reply"){
        messageType = message?.messagetype;
      }

      if(messageType === "Read"){
        messageType = "Sent"
      } else if(messageType === "ReplyPending"){
        messageType = "Read"
      }

      if(location.pathname.split("/").includes(RoutePaths.CONVERSATION)){
        navigate(location.pathname, { state: { profileId: profileId, threadId:message?.subjectId, type:messageType } })
      }else{
        navigate(RoutePaths.CONVERSATION, { state: { profileId: profileId, threadId:message?.subjectId, type:messageType } })
      }

    } else if (selectedFilter.selectedType === "REQUESTRECEIVED") {

      if (btnType === "Add Photo") {
        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3", isfrom: "inbox" } });
        setMenuSelect("4")
      } else if (btnType === "Add Horoscope") {
        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[4].urlPath), { state: { tab: "5", isfrom: "inbox" } });
        setMenuSelect("4")
      } else if (btnType === "Add Document") {
        navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[3].urlPath), { state: { tab: "4", isfrom: "inbox" } });
        setMenuSelect("4")
      }else if (btnType === "Add Parent's Number") {
        setShowParentNumPopup(true)
      }
    } else if (selectedFilter.selectedType === "REQUESTSENT") {

      if (btnType !== "Requested") {
        navigate(RoutePaths.PROFILE, { state: { id: profileId } });
        setMenuSelect("")
      }
      else if (btnType === "Requested") {
        // navigate(RouteSegments.DASHBOARD , { state: { id: profileId } });
        navigate(RoutePaths.PROFILE, { state: { id: profileId } });
        setMenuSelect("")
       

      }
    }
  }

  const closeModel = () => {
    setShowKjBannerPopup(false)
    setShowKjThankPopup(false)
  }

  const scrollToView =(i,id) =>{
    window.scrollTo(0,0);
    // document.getElementById('profileDiv').scrollIntoView({ behavior: 'smooth' })
  }
  //console.log(location?.state,"bcjdvckeci");

  //console.log(location?.state?.isfrom ,"location?.state?.isfrom");
  //console.log(location?.state?.type,"location.state.type");

  const setActiveTab = (tab)=>{
    navigate(RouteHelper.getInboxByTab(tab))
  }

  let heightPixell = document.getElementById("main-page")?.clientHeight-document.getElementById("mobile-header")?.clientHeight-document.getElementsByClassName("mobile-header")[0]?.clientHeight-document.getElementsByClassName("fixed-header")[0]?.clientHeight

  return (
    <>
      <div>
      {showKjBannerPopup ? (
              <KjBannerPopup
                closeModel={closeModel}
                setShowKjThankPopup={setShowKjThankPopup}
                setShowKjBannerPopup={setShowKjBannerPopup}
                // onButtonCallback={(profileID, value) => {
                //   apicallMessage(profileID, value).then((res) => {
                //     //console.log("callback message", res);
                //     if (res?.status === "Success") {
                //       handleSetMessage(profileID, messageFor);
                //     }
                //   });
                // }}
                title={"Send Message"}
                dashboard1Data={dashboard1Data}
                // profileData={messageProfileDetails}
              />
            ) : (
              <></>
            )}
            {showParentNumPopup &&
      <ParentsPhonePopup close={() => setShowParentNumPopup(false)}
        title={"Parent's Number"}
        callBackApply={() => { }}
        parentsIsdCode={ProfileData?.parentsIsdCode}
        parentsMobileNumber={ProfileData?.parentsMobileNumber}
        refresh={()=>dispatch(dashboardMatcheslist())}
      />
    }
        <NavBar  data={leftPanelData} 
        ProfileData={ProfileData} profileCallBack={() => { }} />
        {showProfileStatusPopup && (
            <CommonPopup title="Profile Status" close={()=>setShowProfileStatusPopup(false)} menu="profileStatus">
              <p>{ProfileStatusMessage}</p>
            </CommonPopup>
          )
          }
            {!loading?
            <main id="main-page">
            <div 
            // style={{height:heightPixell+"px"}}
             className={location.state?.type?`overflow-hidden md:h-full h-full
               `
              :`overflow-hidden `}>
        {/* <main>
          <div className={location.state?.type?"overflow-hidden h-[74vh] md:h-full":"overflow-hidden "}> */}
            <ViewMessageNav page="inbox" tab="allmessage" menu="headerpanel"/> 
          
            <div className={location.state?.type?"grid grid-cols-10 md:grid-cols-12  lg:grid-cols-10 h-full md:h-full":"grid grid-cols-10 md:grid-cols-12  lg:grid-cols-10 "}>
              <div className={location.state?.type? "hidden md:block col-span-3 md:col-span-2 lg:col-span-2 bg-[#F3F3F3] pt-[1.5rem] pl-[1px]":"col-span-3 md:col-span-2 lg:col-span-2 bg-[#F3F3F3] pt-[1.5rem] pl-[1px]"}>
                <div className="container mx-auto ">
                  <div
                    className="max-w-2xl"
                  //  className="p-6 mx-auto bg-white"
                  >
                    <div className="md:mb-4">
                      <AccordianComponents
                        title={"Mail"}
                        titleClassName={
                          "flex items-center font-segeo justify-between  w-full py-2 lg:pl-6 pl-4 pr-2 font-semibold text-[#FFFFFF] bg-[#7A7A7A] cursor-pointer"
                        }
                        defaultView={true}
                        titleIcon1={
                          <svg
                            className="w-3 h-3 fill-current"
                            viewBox="0 -192 469.33333 469"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m437.332031.167969h-405.332031c-17.664062 0-32 14.335937-32 32v21.332031c0 17.664062 14.335938 32 32 32h405.332031c17.664063 0 32-14.335938 32-32v-21.332031c0-17.664063-14.335937-32-32-32zm0 0" />
                          </svg>
                        }
                        titleIcon2={
                          <svg
                            className="w-3 h-3 fill-current"
                            viewBox="0 0 469.33333 469.33333"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="m437.332031 192h-160v-160c0-17.664062-14.335937-32-32-32h-21.332031c-17.664062 0-32 14.335938-32 32v160h-160c-17.664062 0-32 14.335938-32 32v21.332031c0 17.664063 14.335938 32 32 32h160v160c0 17.664063 14.335938 32 32 32h21.332031c17.664063 0 32-14.335937 32-32v-160h160c17.664063 0 32-14.335937 32-32v-21.332031c0-17.664062-14.335937-32-32-32zm0 0" />
                          </svg>
                        }
                      >
                        <div>
                          <div className="p-1   bg-[#F3F3F3] ">
                            <div className="py-2 border-b border-[#CFCFCF] ">
                              <AccordianComponents
                                title={"Message"}
                                onClick={()=>{
                                  setMainAccordian({message:true,requestReceived:false,requestsent:false});
                                  // callbackFilter('All Message', 0, "MESSAGE");
                                  setActiveTab(InboxFilters.message[0].urlPath);
                                }}
                                titleClassName={
                                  "flex items-center justify-between w-full lg:pl-5 pl-3 text-[14px]  rounded cursor-pointer "
                                }
                                // defaultView={select===1}
                                defaultView={mainAccordian.message}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                    alt=""
                                  />
                                }
                              >                                
                                  <div className="p-2 lg:px-10 px-4 space-y-3">
                                    {inboxFilter.message.map((e, i) => (
                                      <p key={i}
                                        className={`font-medium text-[13px] w-fit ${
                                          e.isSelected
                                            ? "text-[#D10A11]"
                                            : "text-[#575556]"
                                        }    cursor-pointer`}

                                        onClick={(event) => {
                                          localStorage.removeItem("BLOCKED_STATUS")
                                          // callbackFilter(e, i, "MESSAGE");
                                          setActiveTab(e.urlPath)
                                        }}
                                      >
                                        {e.lable}
                                      </p>
                                    ))}
                                    
                                    {/* <p className="mb-2 font-semibold text-[12px] leading-[13px] ">
                                      Unread Messages
                                    </p>
                                    <p className="mb-2 font-semibold text-[12px] text-[#575556] leading-[13px] ">
                                      Replied Messages
                                    </p>
                                    <p className="mb-2 font-semibold text-[12px] text-[#575556] leading-[13px] ">
                                      Sent Messages
                                    </p> */}
                                  </div>
                              
                              </AccordianComponents>
                            </div>

                            <div className="py-2 border-b border-[#CFCFCF] " >
                              <AccordianComponents
                                title={"Requests Received"}
                                onClick={()=>{
                                  setMainAccordian({message:false,requestReceived:true,requestsent:false});
                                  // callbackFilter('All', 0, "REQUESTRECEIVED");
                                  setActiveTab(InboxFilters.requestReceived[0].urlPath);
                                }}
                                titleClassName={
                                  "flex items-center  justify-between w-full lg:pl-5 pl-3 text-[14px] rounded cursor-pointer"
                                }
                                defaultView={mainAccordian.requestReceived}
                                // defaultView={select===2}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                  />
                                }
                              >                               
                                  <div className="p-2 lg:px-10 px-4 space-y-3">
                                    {inboxFilter.requestReceived.map((e, i) => (
                                      <p key={i}
                                        className={`font-medium text-[13px] w-fit ${
                                          e.isSelected
                                            ? "text-[#D10A11]"
                                            : "text-[#575556]"
                                        }  cursor-pointer`}
                                        onClick={() => {
                                          // callbackFilter(e,i,"REQUESTRECEIVED");
                                          setActiveTab(e.urlPath)
                                        }}
                                      >
                                        {e.lable}
                                      </p>
                                    ))}
                                    {/* <p className="mb-2 font-semibold text-[12px] text-[#575556] whitespace-nowrap">
                                      All Requests
                                    </p>
                                    <p className="mb-2 font-semibold text-[12px] text-[#575556] leading-[13px] ">
                                      Photo Requests
                                    </p>
                                    <p className="mb-2 font-semibold text-[12px]  text-[#575556] leading-[13px]">
                                      Horoscope Requests
                                    </p>
                                    <p className="mb-2 font-semibold  text-[12px] text-[#575556] leading-[13px] ">
                                      Trust Documents
                                    </p> */}
                                  </div>                                
                              </AccordianComponents>
                            </div>
                            <div className="py-2 ">
                              <AccordianComponents
                                title={"Sent Requests"}
                                onClick={()=>{
                                  setMainAccordian({message:false,requestReceived:false,requestsent:true});
                                  // callbackFilter('All', 0, "REQUESTSENT")
                                  setActiveTab(InboxFilters.sentRequest[0].urlPath);
                                }}
                                titleClassName={
                                  "flex items-center justify-between w-full lg:pl-5 pl-3 font-normal text-[14px] text-[#575556] rounded cursor-pointer "
                                }
                                defaultView={mainAccordian.requestsent}
                                // defaultView={select===3}
                                titleIcon1={
                                  <img
                                    src={FilterOpenIcon}
                                    className="w-3 h-3 fill-current"
                                  />
                                }
                                titleIcon2={
                                  <img
                                    src={FilterCloseIcon}
                                    className="w-3 h-3 fill-current"
                                  />
                                }
                              >                               
                                  <div className="p-2 lg:px-10 px-4 space-y-3">
                                    {inboxFilter.sentRequest.map((e, i) => (
                                      <p key={i}
                                        className={`font-medium text-[13px] w-fit ${
                                          e.isSelected
                                            ? "text-[#D10A11]"
                                            : "text-[#575556]"
                                        }    cursor-pointer`}

                                        onClick={() => {
                                          // callbackFilter(e, i, "REQUESTSENT");
                                          setActiveTab(e.urlPath)
                                        }}
                                      >
                                        {e.lable}
                                      </p>
                                    ))}
                                    {/* <p className="mb-2 font-semibold text-[12px] text-[#575556] whitespace-nowrap ">
                                      All Requests
                                    </p>
                                    <p className="mb-2 font-semibold text-[12px] text-[#575556] leading-[13px] ">
                                      Photo Requests
                                    </p>
                                    <p className="mb-2 font-semibold text-[12px]  text-[#575556] leading-[13px]">
                                      Horoscope Requests
                                    </p>
                                    <p className="mb-2 font-semibold  text-[12px] text-[#575556] leading-[13px]">
                                      Trust Documents
                                    </p> */}
                                  </div>                               
                              </AccordianComponents>
                              <div className="py-4">
                              {addKjBanner === "Y" ?
                              <img
                                className="cursor-pointer"
                                alt=""
                                src={addBanner}
                                closeModel={closeModel}
                                onClick={() => {
                                  setShowKjBannerPopup(true);
                                }} />
                              :
                              <img
                                className="cursor-pointer"
                                alt=""
                                src={addBanner}

                                onClick={() => {
                                  navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } });
                                }} />
                              }
                                </div>
                            </div>
                          </div>
                        </div>
                      </AccordianComponents>
                    </div>
                  </div>
                </div>
                {/* <!-- <div className="py-4">
                  <img className="" src="../images/addprofile.png">
               </div> --> */}
              </div>

                <div className={location.state?.type ? `col-span-10 md:col-span-5 lg:col-span-5 mx-[1rem] md:mx-0 md:ml-[0.7rem] lg:mx-[1rem] mt-[0.2rem] md:mt-7 lg:mt-8 ${isShowLoadMore ? '' : 'mb-4'} space-y-4` : `col-span-7 md:col-span-5 lg:col-span-5 mx-[1.2rem] md:mx-0 md:ml-[0.7rem] lg:mx-[1rem] mt-[1rem] md:mt-7 lg:mt-8 ${isShowLoadMore ? '' : 'mb-4'} space-y-4`}>
                  {!conversationData.show ?
                    isFetching
                    ? <Loader />
                    : <>
                      {inboxData.length > 0 ?
                        inboxData.map((element, i, row) => {
                          let viewBtnName = "";
                          let profileName = "";
                          let profileAge = "";
                          let profileHeight = "";
                          let profileContent = "";
                          let messageType = ""
                          let profileimageurl = ""

                          if (selectedFilter.selectedType === "MESSAGE") {
                            profileName = element.profilename;
                            profileAge = element.profileage;
                            profileHeight = element.profileheight;
                            profileContent = element.messagesamplecontent;
                            messageType = element?.msgtype ?? element.messagetype
                            profileimageurl = element.profileimageurl
                            if (
                              messageType === "Sent" ||
                              messageType === "Read"
                            ) {
                              viewBtnName = "View Message";
                            } else if (messageType === "ReplyPending") {
                              viewBtnName = "Reply";
                            } else if (messageType === "Unread") {
                              viewBtnName = "Read & Reply";
                            } else {
                              viewBtnName = "View Message";
                            }
                          } else if (
                            selectedFilter.selectedType === "REQUESTRECEIVED"
                          ) {
                            profileName = element.name;
                            profileAge = element.age;
                            profileHeight = element.height;
                            profileContent = element.requestContent;
                            profileimageurl = element.profilepic
                            if (element.requestType === "Photo") {
                              viewBtnName = "Add Photo";
                            } else if (element.requestType === "Horoscope") {
                              viewBtnName = "Add Horoscope";
                            } else if (element.requestType === "Trust") {
                              viewBtnName = "Add Document";
                            } else if (element.requestType === "Parents Number") {
                              viewBtnName = "Add Parent's Number";
                            }  else {
                              viewBtnName = "View";
                            }
                          } else if (selectedFilter.selectedType === "REQUESTSENT") {
                            profileName = element.name;
                            profileAge = element.age;
                            profileHeight = element.height;
                            profileContent = element.requestContent;
                            profileimageurl = element.profilepic
                            if (element.acceptmsgtype === "Y") {
                              if (element.requestType === "Photo") {
                                viewBtnName = "Photo Added";
                              } else if (element.requestType === "Horoscope") {
                                viewBtnName = "Horoscope Added";
                              } else if (element.requestType === "Trust") {
                                viewBtnName = "Document Added";
                              } else {
                                viewBtnName = "View";
                              }
                            } else {
                              viewBtnName = "Requested";
                            }
                          }

                          return (
                            <ViewMessageCards
                              showList={conversationData.show}
                              key1={i}
                              cardData={element}
                              profileimageurl={profileimageurl}
                              profileName={profileName}
                              profileAge={profileAge}
                              profileHeight={profileHeight}
                              profileContent={profileContent}
                              viewBtnName={viewBtnName}
                              viewProfileStatusPopup={(value) => {
                                setProfileStatusMessage(value);
                                setShowProfileStatusPopup(true)
                              }}
                              selectedFilter={selectedFilter.selectedType === "MESSAGE"}
                              onClickViewBtn={(btnName) => { handleClickViewBtn(i, btnName, element.profileId, element); scrollToView(); setIsSidePanel(true) }}
                              onClickViewProfile={() => {
                                navigate(RoutePaths.PROFILE, { state: { id: element.profileId } })
                                setMenuSelect("")
                              }}


                              lengthOfRow={row}
                              isFrom={"middlepanel"}
                            />
                          );
                        }) :
                        <div className="flex justify-center text-[16px] text-[#7A7A7A] font-semibold">No Data</div>}

                      {(inboxData.length > 0 && isShowLoadMore) && (
                        <div className="flex justify-center pb-[1rem]">
                          <RedButton
                            btnName={"Load More"}
                            onClick={callBackLoadMore}
                            loading={isLoading}
                          />
                        </div>
                      )}<div className="h-20 md:hidden"></div>
                    </>
                    : <Conversation
                      threadId={conversationData.threadId}
                      profileId={localStorage.getItem("LOGIN_ID")}
                      toProfileId={conversationData.profileId}
                      type={conversationData.messageType}
                    />
                  }
                </div>


              <div className="hidden col-span-3 md:col-span-5 lg:col-span-3 md:block md:pl-[0.8rem] lg:pl-0 sidepa">
                <ProfileSection data={ProfileData} profileDataCount={leftPanelData}  menu={props?.menu}  inboxData={inboxData} selectedFilter={selectedFilter} showList={!conversationData.show}
                  onClickViewBtn={(i,val,element) => {handleClickViewBtn(i,val,element.profileId,element);scrollToView(i,val);}}
                  selectedMessageType={selectedMessageType}
                  refresh={()=>dispatch(dashboardMatcheslist())}
                  onSubmitSuccess={(newPhone, newIsd) => { 
                    console.log("newPhone, newIsd",newPhone, newIsd);
                    setDashboard1Data(prev=>({...prev,parentsMobileNumber:newPhone,parentsIsdCode:newIsd}))
                    dispatch(dashboardMatcheslist())
                  }}
                />
                {recentlyViewedData.length > 0 && (
                  <DashboardSection4
                    title={"Recently Viewed"}
                    data={recentlyViewedData}
                    callLoadMore={callbackLoadMoreRecentlyView}
                    isViewmore={true}
                    callBackViewMore={(value) => {
                      //console.log("viewmore", value);
                    }}
                    ViewAllCallBack={() => {
                      navigate(RouteHelper.getRecentlyViewedUrl);
                    }}
                  />
                )}
                {/* <div className="py-4">
                  {addBanner && (
                    <img
                      className=""
                      alt=""
                      src={addBanner}
                    // "/Assets/Images/addprofile.png"
                    />
                  )}
                </div> */}
              </div>
            </div>
          
          </div>
          <Footer />
          <DownloadAppBar dashboardData={ProfileData} haveBottom={true}/>
          {location.state?.type ?"":
            <ViewMessageBottomNav />}
            
        </main>
        :
        <center>
          <img alt='' src={LoaderGif} />
        </center>
}
      </div>
    </>
  );
};

export default ViewMessage;
