import React, { useEffect, useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import EndPoints from "../../Constants/EndPoints";
import { IP_Address } from "../../Constants/IpAddress";
import { POST, GET } from "../../Services/api_services";
import { LoginContext } from "../../Contexts/LoginContext";
import Constants, { loginFrom } from "../../Constants/constants";
import '../../index.css'
import Loader from '../Loader'
import config from "../../config";
import CloseIcon from "../../Images/Group 761@2x.png"
import { validateNumber } from "../../Utils";
import { useDispatch } from "react-redux";
import { dashboardProfileData } from '../../Store/DashboardAPI/Action';
import SuccessIcon from '../../Images/Group 809.svg'

const ParentsPhonePopupLite = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [isdCodeList, setIsdCodeList] = useState([]);
  const [relationDropDown, setRelationDropDown] = useState([{id: 5 , name: "Mother"},{id: 6, name: "Father"}]);

  const[onSubmitPopup,setOnSubmitPopup]=useState(false)
  const [isShowMessage,setIsShowMessage]=useState("")
  const [showpopup ,setShowpopup]=useState(false)
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      number:props?.parentsMobileNumber?? "",
      isdCode: props?.parentsIsdCode?? "",
      relationshipId : props?.relationshipId ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      // number: Yup.string().min(10, "Length should be 10")
      // .test("india", "Mobile number should have 10 digits", function (value) {
      //   const { isdCode } = this.parent;
      //   if (isdCode === '+91') {
      //     return !value || value.length === 10 ? true : this.createError({ message: "Mobile Number should have 10 digits", path: "number" });
      //   }
      //   return true;
      // }).test("other", "Mobile number should have 20 digits", function (value) {
      //   const { isdCode } = this.parent;
      //   if (isdCode !== '+91') {
      //     return !value || (value.length < 21 && !/^\d+$/.test(value) && !/^\d{6}$/.test(value))
      //     ? true
      //     : this.createError({
      //         message: 'Please enter a valid mobile number',
      //         path: 'number',
      //       });        }
      //   return true;
      // }).required("Please enter a Mobile Number"),
      // isdCode:Yup.string().required("Please Select Isd code")
      number: Yup.string()
      .test("india", "Please enter a valid Mobile Number", function (value) {
        const { isdCode } = this.parent;
        if (isdCode === '+91') {
          return !value || value.length === 10 ? true : this.createError({ message: "Please enter a valid Mobile Number", path: "number" });
        }
        return true;
      }).test("other", "Please enter a valid Mobile Number", function (value) {
        const { isdCode } = this.parent;
        if (isdCode !== '+91') {
          return !value || (value.length >= 7 && value.length <= 15)
          ? true
          : this.createError({
              message: 'Please enter a valid Mobile Number',
              path: 'number',
            });        }
        return true;
      }).required("Please enter Mobile Number"),
      relationshipId : Yup.string().required("Please select Relation"),
    
    }),

    onSubmit: (values) => {
        //console.log("values",values)
        setOnSubmitPopup(true)
        apicallSubmitParentsNum(values)
        // setTimeout(function(){
        //   setOnSubmitPopup(false)

        // },[200])

        // setTimeout(function(){
        //   setIsShowMessage("Parent's number saved")
        // },[500])
    //   props.callBackApply(values);
    },
  });

  useEffect(() => {
    //console.log("Popup called");
    apicallIsdCode();
    console.log(props,"contentResponseData");
    
  }, []);
  useEffect(() => {
    //console.log("Popup called");
  }, [isdCodeList]);

  {
    /* api/submitparentsnumber
    api/getisdcode */
  }
  const apicallIsdCode = async () => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.gteIsdCodeUrl()}`
    );

    if (statusCode === 200) {
      setIsdCodeList(data?.data?.isdcode);
    } else if (statusCode === 401) {
      logout();
    }
  };

  const [status, setStatus] = useState(null);

  function changeStatus(e) {
    setStatus(e.target.value);
  }
//   "{
//     ""userId"": ""1631292"",
//     ""email"": ""nayekkarthikeyan@gmail.com"",
//     ""parentsMobileNumber"": ""7686858823"",
//     ""loginFrom"": ""android"",
//     ""parentsNumberFrom"": """",
//     ""countryId"": ""+91""
// }"
const apicallSubmitParentsNum = async (value) => {
  let notificationId = null
  const notificationPath = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationPath)
  const loginUrl = sessionStorage.getItem(Constants.loginLocalStorageKeys.loginUrl);

  if (props?.notificationId || notificationPath == "AddParentsNumber" || loginUrl?.includes("parentsnumber")) {
    notificationId = sessionStorage.getItem(Constants.loginLocalStorageKeys.notificationId)
  }
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      parentsIsdCode: value.isdCode,
      parentsMobileNumber: value.number,
      parentsNumberFrom:"",
      isContent: props?.name,
      contentId: props?.contentId,
      notificationId: notificationId ?? undefined,
      relationshipId : value.relationshipId
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.submitparentsnumberUrl()}`,
      request
    );

    if (statusCode === 200) {
      if(data?.data?.status=="SUCCESS"){
        if(props.onSubmitSuccess){
          props.onSubmitSuccess(value.number,value.isdCode)
        }
        if (props?.notificationId || notificationPath == "AddParentsNumber" || loginUrl?.includes("parentsnumber")) {
          sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
          sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationPath)
        }
        // window.location.reload(false)
        // alert("Parent's number saved")
        setIsShowMessage("Parent's number saved")
        setShowpopup(true)
        setOnSubmitPopup(false)
        if(props.refresh){
          props.refresh()
        }
        if(props?.isFrom == 'family'){
          props?.callBackApply();
        }
        dispatch(dashboardProfileData())
        
        
      } else if(data?.data?.status=="FAILURE"){
        if(data?.data?.parentsNumberExist === "Yes"){
          setOnSubmitPopup(false)
          formik.setFieldError("number","Mobile Number Already Exist")
        }
      }
      // props.close();
    } else if (statusCode === 401) {
      logout();
    }
  };


  useEffect(() => {


    return () => {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId)
    }
  }, [])


  return (
    <div className='flex justify-center bg-opacityblack items-end md:items-center lg:items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
      <div className='relative w-[100%] lg:w-auto xl:w-[45%] md:my-6 md:mx-6 md:flex md:justify-center md:items-center'>
        <div className=''>
          <button
            className='hidden md:block bg-transparent z-40 border-0 text-black  absolute  md:top-[-.5rem] md:right-[3.5rem] lg:right-0 lg:top-0'
            onClick={() => {
              props.close();
            }}>
            <img className='h-6 w-6 ' src={CloseIcon} alt='' />
          </button>
        </div>
        <div className="rounded-tr-[35px] rounded-tl-[35px] shadow-lg relative lg:mt-[0.5rem] lg:mr-[0.5rem] flex flex-col md:w-fit lg:w-full bg-white outline-none focus:outline-none md:rounded-[22px] md:py-3 lg:py-1">

          <div className="relative p-6 flex-auto justify-center items-center text-center">
  
            <p className="text-[18px] md:text-[#D10A11] text-[#1A1A1A] mt-2 md:pt-2 lg:pt-1  md:ml-2 font-semibold font-poppins text-center">
              {showpopup ? "Parent's Phone Number" : props.title}
              </p>
            
          </div>
          <div className="flex items-center justify-center relative px-10 md:pb-2 pt-3 lg:py-3 bottom-6 flex-auto ">
           {onSubmitPopup ?
        <div className=" flex items-center justify-center  pb-8 pt-3 lg:py-3 bottom-6 flex-auto  h-[8rem] relative w-[100%] px-10"> <Loader/></div>
         :showpopup ?
         <div className='relative mt-[0.5rem] mr-[0.5rem] flex flex-col w-full bg-white '>
         <div className='flex items-center py-1 justify-center mx-2  rounded-t '>
           <h3 className='text-[20px] text-[#D10A11] ml-2 font-semibold text-center'>
             Success
           </h3>
         </div>
         <div className='relative p-6 flex-auto'>
           <div className='flex flex-col justify-center items-center w-[60vw] md:w-full '>
             <span className='text-[16px] font-semibold whitespace-nowrap'>
                        Parent's Number Added Successfully
             </span>
             <img className='w-16 h-16 mt-2' alt='' src={SuccessIcon} />
           </div>
         </div>
       </div>
           :
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col items-center justify-center"
            >
              <div className="flex space-x-2 my-2 ">
                <label className="parentpopup select font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[7rem]">
                  <select
                    name="isdCode"
                    id="isdCode"
                    value={formik.values.isdCode}
                    // onChange={changeStatus}
                    onChange={formik.handleChange}
                    className=" md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] font-semibold font-Poppins md:text-[12px] xl:text-[14px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]  "
                  >
                    <option >select</option>
                    {isdCodeList?.map((e, i) => (
                      <option key={i} value={e.isdcode}>
                         {`${e.isdcode} - ${e.country}`} 
                      </option>
                    ))}
                  </select>
                  {formik.errors.isdCode && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.isdCode}
                      </p>
                    )}
                </label>

                <label className="">
                  <input
                    name={"number"}
                    id={"number"}
                    type={"tel"}
                    placeholder="Enter Parent's Mobile Number"
                    value={formik.values.number}
                    
                    onChange={(e)=>{
                      if(validateNumber(e.target.value)){
                        formik.handleChange(e)
                      }
                    }}
                    className="no-spinner w-[13rem] md:w-[15rem] lg:w-[15rem] p-2.5 my-1  text-sm font-semibold font-Poppins text-[#575556]  rounded-[6px] border border-[#C4C4C4]"
                  />
                  <div className="h-2 mb-2">
                    {formik.errors.number && (
                      <p className="text-[red] text-[12px]">
                        {formik.errors.number}
                      </p>
                    )}
                    
                  </div>
                </label>

                <label className="parentpopup select font-bold text-[#575556] w-[5rem] md:w-[8rem] lg:w-[9rem]">
                  <select
                    name="relationshipId"
                    id="relationshipId"
                    placeholder="Select Relation"
                    value={formik.values.relationshipId}
                    // onChange={changeStatus}
                    onChange={formik.handleChange}
                    className=" md:p-[0.4rem] lg:p-2.5 my-1 text-[#575556] font-semibold font-Poppins md:text-[12px] xl:text-[14px] bg-white border border-[#C4C4C4] rounded-[4px] leading-[20px]  placeholder:!text-[#575556]"
                  >
                    <option >Select Relation</option>
                    {relationDropDown?.map((e, i) => (
                      <option key={i} value={e.id}>
                         {`${e.name}`} 
                      </option>
                    ))}
                  </select>
                  {formik.errors.relationshipId && (
                      <p className="text-[red] text-[10px] md:text-[12px] lg:text-[12px] font-medium">
                        {formik.errors.relationshipId}
                      </p>
                    )}
                </label>

              </div>
                <div className="flex justify-center py-2">
                  <p className="text-[11px] text-[red]">* This number will be visible to Matches. Matches can also contact you on this number</p>
                </div>

            {onSubmitPopup ?
            <button
            type="submit"
           
            className=" flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[3rem] whitespace-nowrap rounded-[5px] font-bold py-[1rem] md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
          >
            Submitig...
          </button>
          :
          <div className="flex  justify-center items-center space-x-8 w-full mt-1 mb-2">
             <button
             onClick={() => {
              props.close();
            }}
                type="submit"
               
                
                className=" w-auto flex items-center justify-center my-1 h-[1.5rem]  text-[#575556] bg-white border border-[#707070] px-[2.3rem] md:px-[2.3rem] lg:px-[2.5rem] whitespace-nowrap rounded-[6px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
              >
                Cancel
              </button>
              <button
                type="submit"
               
                
                className="w-auto flex items-center justify-center my-1 h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] px-[2.3rem] md:px-[2.3rem] lg:px-[2.5rem] whitespace-nowrap rounded-[6px] font-segoe font-semibold py-[1rem] md:py-[1rem] lg:py-4 md:text-[12px] lg:text-[12px]"
              >
                Submit
              </button>
          </div>
            }  
            </form>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentsPhonePopupLite;
