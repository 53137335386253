import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  HoroscopeSearchView,
  SaveCancelButton,
  SelectInput,
} from "../EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST, GET } from "../../../Services/api_services";
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";

import { Select } from "antd";
import { SelectDropdownUtil, SingleSelectDropdown } from "../CustomSelect";
import config from "../../../config";
const LocationComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [isShowEdit, setEditView] = useState(false);
  const [countryDropDown, setcountryDropDown] = useState([]);
  const [stateDropDown, setstateDropDown] = useState([]);
  const [cityDropDown, setcityDropDown] = useState([]);
  const [pincodeDropDown, setpincodeDropDown] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false)
  const [selectedCountryNameValue, setSelectedCountryNameValue] = useState()

  const formik = useFormik({
    initialValues: {
      country: props?.data?.country ? props?.data?.country[0].id : "",
      state: props?.data?.state ? props?.data?.state[0].id : "",
      city: props?.data?.city ? props?.data?.city[0].id : "",
      pincode: props?.data?.pincode ? props?.data?.pincode[0].id : "",
      address: props?.data?.address ? props?.data?.address : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      country: Yup.string().required("Please Select Country"),
      state: Yup.string().required("Please Select State"),
      city: Yup.string().required("Please Select City"),
      pincode:selectedCountryNameValue == "499"?
      Yup.string().required("Please Select Pincode"):
      Yup.string().nullable(),
      address: Yup.string().nullable(),
    }),

    onSubmit: (values) => {
      setSubmitStatus(true)
      apiCallLocation(values);
    },
  });

  useEffect(() => {
    setSelectedCountryNameValue(props?.data?.country[0].id)
  },[props?.data?.pincode])

  const SubmitCall = (value) => {
    //console.log(value);
  };

  useEffect(() => {
    setcountryDropDown(props?.dropDown?.country);
    apiCallState(props?.data?.country[0].id);
    apiCallCity(props?.data?.state[0].id);
  }, [props?.dropDown]);
  useEffect(() => {}, [
    countryDropDown,
    stateDropDown,
    cityDropDown,
    pincodeDropDown,
    formik.values,
  ]);
  const apiCallState = async (countryID) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getStateUrl(countryID)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setstateDropDown(data.data.states);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apiCallCity = async (stateID) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getCityUrl(stateID)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setcityDropDown(data.data.cities);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apiCallPincode = async (pincode) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getPincodeUrl(pincode)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setpincodeDropDown(data.data.pincode);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const apiCallLocation = async (value) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      countryId: value.country,
      stateId: value.state,
      cityId: value.city,
      pincode: value.pincode.toString(),
      address: value.address,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editlocationinfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        setSubmitStatus(false)
        props.callBackReload();
        setEditView(false);
        onClickTrack(EventName.profileLocationSaveButton , {[EventKeys.profileLocation]:value})
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const selectedCountry = useMemo(()=>{
    return countryDropDown?.filter(option=>option.id === formik.values.country)?.at(0)
  },[countryDropDown, formik.values.country])

  const selectedState = useMemo(()=>{
    return stateDropDown?.filter(option=>option.id === formik.values.state)?.at(0)
  },[formik.values.state, stateDropDown])

  const selectedCity = useMemo(()=>{
    return cityDropDown?.filter(option=>option.id === formik.values.city)?.at(0)
  },[cityDropDown, formik.values.city])

  const { onClickTrack } = useAnalytics()

  return (
    <div>
      {isShowEdit ? (
        <></>
      ) : (
        <div className="md:mb-4 p-5 md:p-0">
          <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />

          <BasicTextView
            title={"Country"}
            value={
              props?.data?.country
                ? props?.data?.country[0].name
                : "Not Specified"
            }
          />
          <BasicTextView
            title={"State"}
            value={
              props?.data?.state ? props?.data?.state[0].name : "Not Specified"
            }
          />
          <BasicTextView
            title={"City"}
            value={
              props?.data?.city ? props?.data?.city[0].name : "Not Specified"
            }
          />
          {selectedCountryNameValue == "499" &&
          <BasicTextView
            title={"Pincode"}
            value={
              props?.data?.pincode
                ? props?.data?.pincode[0].name
                : "Not Specified"
            }
          />
          }
          <BasicTextView
            title={"Address"}
            value={
              props?.data?.address ? props?.data?.address : "Not Specified"
            }
          />
        </div>
      )}
      {/* <!-- edit part  -->  */}
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit ? (
          <div className="p-5 md:p-0">
            <SelectInput
              name="country"
              title="Country"
              value={selectedCountry}
              onChange={(selected, ev) => {
                formik.setFieldValue("country", selected.id)
                formik.setFieldValue("state", "")
                formik.setFieldValue("city", "")
                setSelectedCountryNameValue(selected.id)
                setcityDropDown([])
                apiCallState(selected.id);
              }}
              error={formik.errors.country}
              defaultValue={formik.values.country}
              options={[{ id: "", name: "Select", isDisabled: true  }, ...countryDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
            {/* <BasiSelectView
              title={"Country"}
              inputID={"country"}
              inputName={"country"}
              value={formik.values.country}
              onChange={(e) => {
                formik.handleChange(e);
                apiCallState(e.target.value);
              }}
              error={formik.errors.country}
              options={countryDropDown}
            /> */}
            <SelectInput
              name="state"
              title="State"
              value={selectedState}
              onChange={(selected, ev) => {
                formik.setFieldValue("state", selected.id)
                formik.setFieldValue("city", "")
                apiCallCity(selected.id);
              }}
              error={formik.errors.state}
              defaultValue={formik.values.state}
              options={[{ id: "", name: "Select", isDisabled: true  }, ...stateDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
            {/* <BasiSelectView
              title={"State"}
              inputID={"state"}
              inputName={"state"}
              value={formik.values.state}
              onChange={(e) => {
                formik.handleChange(e);
                apiCallCity(e.target.value);
              }}
              error={formik.errors.state}
              options={stateDropDown}
            /> */}
            <SelectInput
              name="city"
              title="City"
              value={selectedCity}
              onChange={(selected, ev) => {
                formik.setFieldValue("city", selected.id)
              }}
              error={formik.errors.city}
              defaultValue={formik.values.city}
              options={[{ id: "", name: "Select", isDisabled: true  }, ...cityDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            />
            {/* <BasiSelectView
              title={"City"}
              inputID={"city"}
              inputName={"city"}
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.errors.city}
              options={cityDropDown}
            /> */}
            {selectedCountryNameValue == "499" &&
              <div className=" grid grid-cols-9 mt-[2rem] ">
                <div className="md:col-span-4 col-span-9 flex items-center  md:pl-[1rem] lg:pl-[2rem] ">
                  <p className=" md:text-[12px] lg:text-[14px] flex text-[#7A7A7A] font-semibold  ">
                    Pincode
                    <span className="text-[#D10A11]">*</span>
                  </p>
                </div>
                <div className="md:col-span-5 col-span-9 md:pr-[1rem] lg:pr-[4rem]  md:pl-[1rem]">               
                    <div className="flex ">
                      <label className=" select font-bold text-[#575556] w-full ">
                        {/* <Select
                        id={"pincode"}
                        name={"pincode"}
                        showSearch
                        style={{
                          width: "100%"
                        }}
                        value={formik.values.pincode}
                        onChange={event => {
                          //console.log(event)
                            if (event.target.value.length > 2) {
                              apiCallPincode(event.target.value);
                            }
                          formik.handleChange("pincode")(event)
                        }}
                        /> */}

                        <SingleSelectDropdown
                          id="pincode"
                          name="pincode"
                          value={props?.data?.pincode&&formik.values.pincode==props?.data?.pincode[0].id?props?.data?.pincode[0].name:formik.values.pincode}
                          onChange={(e) => formik.setFieldValue("pincode", e)}
                          option={pincodeDropDown}
                          callBackSearch={apiCallPincode}
                          // mode={"multiple"}
                        />
                        <SelectDropdownUtil
                          onChange={(e) => {
                            // formik.handleChange(e);
                            //console.log(e);
                            // if (e.target.value.length > 2) {
                            //   apiCallPincode(e.target.value);
                            // }
                          }}
                          // placeholder="select profile for"
                          option={pincodeDropDown}
                        />

                        {formik.errors.pincode && (
                          <p className="text-[red] text-[12px]">
                            {formik.errors.pincode}
                          </p>
                        )}
                      </label>
                    </div>               
                </div>
              </div>
            }

            {/* <BasiSelectView
              title={"Pincode"}
              inputID={"pincode"}
              inputName={"pincode"}
              value={formik.values.pincode}
              onChange={(e) => {
                formik.handleChange(e);
                if (e.target.value.length > 2) {
                  apiCallPincode(e.target.value);
                }
              }}
              error={formik.errors.pincode}
              options={pincodeDropDown}
            /> */}

            <BasicEditTextView
              title={"Address"}
              inputType={"text"}
              inputID={"address"}
              inputName={"address"}
              value={formik.values.address}
              onChange={formik.handleChange}
              error={formik.errors.address}
            />

            <SaveCancelButton
              saveCallBack={(e) => {
                e.preventDefault();
              }}
              cancelCallback={() => {
                setEditView(false);
                props.refresh()
                onClickTrack(EventName.profileLocationCancelButton)
              }}
              submitStatus={submitStatus}
            />
          </div>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
};

export default LocationComponent;
