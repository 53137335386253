import React, { useState } from 'react'

//components
import RegisterForm from '../../Components/RegisterForm/Form'
import LoginForm from '../../Components/LoginForm/Form'

import HeartIcon from "../../Images/Group 1634.svg"
import XIcon from "../../Images/Group 761@2x.png"
import { useAnalytics } from '../../Hooks/usePageChange'
import { EventName } from '../../Constants/constants'

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
const FormPopup = () => {
    const [registerOrLogin, setRegsiterOrLogin] = useState("1")
    const [showRegisterPopup, setShowRegisterPopup] = useState(false);
    const {onClickTrack} = useAnalytics()

  return (
    <div>
      <>
       <button onClick={() => {setShowRegisterPopup(true);onClickTrack(EventName.Home_page_Register_Register_Button)}} className=" text-[12px] md:text-[15px] font-bold bg-white text-[#D10A11]  border-[5px] border-[#D10A11] px-[0.5rem]  md:px-[2.5rem] rounded-[1rem] py-2  hover:text-white hover:bg-[#D10A11]">
                   REGISTER
            </button>
      </>
      <>
      {showRegisterPopup ? (
        <div className="Noscroll flex justify-center bg-opacityblack  items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="mt-[10rem]  relative w-auto my-6 mx-auto ">
           
            <div className="bg-white rounded-[1rem] ">
              <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-[23rem]  md:w-[34rem] rounded-2xl">
              <div className="  items-start ">
                <p className="text-[#D10A11] flex md:justify-center font-bold text-[18px]  md:text-[24px] lg:text-[26px] ">
                   Welcome to kalyanmatrimony.com
                </p>
                
                <div
                    style={{ borderWidth: "2px" }}
                  className="  border-t-[#D10A11] border-dashed border-[#FFFFFF]  md:block md:col-span-5 lg:col-span-5 mx-[0.2rem] md:mx-0 md:ml-[0.7rem] mt-[1rem] py-[1.5rem] "
                >
                  <div>
                    <div className="md:mx-[2rem]">
                       <p className="text-[#7A7A7A]  text-[12px] lg:text-[14px] ">
                       <div className=" md:pr-[1rem] ">
                        <div className="rounded-t-[10px] h-auto rounded-b-[10px] w-[100%] ">
                            <div className="p-1 space-y-4 ">
                            <div className="flex justify-center space-x-4 pt-[0.25rem]   ">
                        <div className=" flex justify-center ">
                        <button onClick={() => setRegsiterOrLogin("1")} className={classNames(" flex items-center h-[2rem] md:h-[1.5rem] lg:h-[2.5rem] font-bold text-white  w-[10rem] justify-center whitespace-nowrap  rounded-tl-lg rounded-br-lg lg:py-2 md:text-[12px] lg:text-[13px]  "
                        , registerOrLogin == "1"? "bg-[#D10A11]":"bg-[#575556]" )}>
                                REGISTER
                            </button>
                        </div>
                        <div className=" flex justify-center ">
                        <button onClick={() => setRegsiterOrLogin("2")} className={classNames(" flex items-center md:h-[1.5rem] lg:h-[2.5rem] font-bold text-white  w-[10rem] justify-center whitespace-nowrap rounded-tl-lg rounded-br-lg lg:py-2 md:text-[12px] lg:text-[13px]  "
                        , registerOrLogin == "2"? "bg-[#D10A11]":"bg-[#575556]" )}>
                                SIGN IN 
                            </button>
                        </div>
                        </div>
                        <div className=' '>                            
                        {registerOrLogin == "1"?
                            <RegisterForm utmsource="" menu={"registerPopup"}/>
                            :<LoginForm menu ="signup" state={showRegisterPopup} />
                            }
                            </div>
                            </div>
                            </div>
                            </div>
                       </p>
                       
                    </div>
                  </div>
                </div>
                {registerOrLogin== "1" &&
                <div className="bg-[#efefef] p-[1rem] ">
              <div className="">
              <p className="text-[#D10A11] text-[18px] flex items-center justify-start  ">
              REGISTER FOR KALYAN MATRIMONY ADVANTAGE
              </p>
             <div className='flex pt-[1rem]'>
                <div className='flex space-x-3'>
                    <div className='flex items-baseline justify-start pt-[0.3rem]'>
                        <img src={HeartIcon} alt="" className='w-[2rem]' />
                    </div>
                    <p className='text-[13px] flex justify-start items-baseline'>
                        Preference for profiles of highly educated, professionally driven people.
                    </p>
                </div>
                <div className='flex space-x-3'>
                    <div className='flex items-baseline justify-start pt-[0.3rem]'>
                        <img src={HeartIcon} alt="" className="w-[2rem]"/>
                    </div>
                    <p className='text-[13px] flex justify-start items-baseline'>
                      Matches based on pre-defined dimensions and a scoring logic algorithm.
                    </p>
                </div>
             </div>
             <div className='flex pt-[1rem] '>
                <div className='flex space-x-3 '>
                    <div className='flex items-baseline justify-start pt-[0.3rem]'>
                        <img src={HeartIcon} alt="" className="w-[2rem]"/>
                    </div>
                    <p className='text-[13px] flex justify-start items-baseline'>
                      A balanced gender distribution is maintained across search criteria.
                    </p>
                </div>
                <div className='flex space-x-3'>
                    <div className='flex items-baseline justify-start pt-[0.3rem]'>
                        <img src={HeartIcon} alt="" className="w-[2rem]"/>
                    </div>
                    <p className='text-[13px] flex justify-start items-baseline'>
                        The matches with the highest potential are sent to your inbox.
                    </p>
                </div>
             </div>
              </div>
              </div>
              }
              </div>
             
              </div>
               
            </div>
            
            <button              
              onClick={() => setShowRegisterPopup(false)
              }
              className="
                    absolute md:top-[2.5rem] lg:top-[0.5rem] lg:right-[0rem] top-[2rem] right-[2rem]  md:right-[2rem] inline-flex items-center justify-center  transform translate-x-1/2 -translate-y-1/2"
            >
              <img
            //   onClick={()=> setShowModal(false)}
                className="w-[2rem]"
                src={XIcon}
                alt=""
              />
            </button>
          </div>
        </div>
      ) : (
        " "
      )

      }
      </>
    </div>
  )
}

export default FormPopup