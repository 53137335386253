import React, { useEffect, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom';
import $ from 'jquery'
import "./Form.css"

//validation 
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux";

//components
import { loginUser } from "../../Store/Login/Action";

import withRouter from '../withRouter';
import RouteSegments from '../../Routes/RouteSegment';
import ForgetPasswordPopup from './ForgetPasswordPopup';
import Loader from '../Loader';
import { SuspendedPopup } from '../CommonPopup';
import Constants, { EventKeys, EventName } from '../../Constants/constants';
import EyeOpenIcon from "../../Images/passwordeyeopen.png"
import EyeIcon from "../../Images/passwordeye.svg"
import { useAnalytics } from '../../Hooks/usePageChange';
import FamilyfriedloginPopup from '../Kalyanlite/Popup/FamilyfriedloginPopup';

import KMLogo from '../Kalyanlite/assets/Images/KM logo.png';
import LoaderGif from "../../Gif/loader.gif" 








const LoginForm = (props) => {

    useEffect(() => {
        $('#name,#password').bind("cut copy paste", function (e) {
            e.preventDefault();
        });
    })
 
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        let loginDevice=window.innerWidth<1024?"Mobile Site":"Desktop"
        const finalLoginDevice = loginDevice || "Unknown";
        localStorage.setItem(Constants.loginLocalStorageKeys.loginDevice,finalLoginDevice)
    }, [props.state])

    const [showHidePassword, changeShowHidePassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")
    const [showLoader, setShowLoader] = useState(false)
    const [showSuspendedPopup, setshowSuspendedPopup] = useState(false)
    const {onClickTrack} = useAnalytics()

    const [showFamilyPopup, setShowFamilyPopup] = useState(false)
    const [offlineLoader, setOfflineLoader] = useState(false)
    const [isChecked, setIsChecked] = useState(true);
    useEffect(() => {
        localStorage.setItem(Constants.loginLocalStorageKeys.isChecked, JSON.stringify(isChecked));
    }, [isChecked]);

    const onFamilyFriendPopupClick = () => {
        setShowFamilyPopup(true)

    }

    //Error msg for Login form
    const formik = useFormik({
        initialValues: {
            name: localStorage.getItem("email") ? localStorage.getItem("email") : '',
            password: localStorage.getItem("pass") ? localStorage.getItem("pass") : ''
        },
        validationSchema: Yup.object().shape({
            name: Yup.string()
                .required('Please enter email or mobile no '),
            password: Yup.string()
                .required(' Please enter password'),
        }),
        onSubmit: (values) => {
            LoginFormSubmission(values);
            onClickTrack(EventName.HomePageLoginButtonClick)
            onClickTrack(EventName.Home_page_Signin_Submit_Button,{[EventKeys.Home_page_Signin_Submit_Button_Request]:values})
        }
    })

    // form submit
    const LoginFormSubmission = (values) => {
        setShowLoader(true)
        // console.log(values);
        // console.log('before props', props.router.navigate);
        // return false;
        if (values.name !== "" && values.password !== "") {
            values.profileid = searchParams.get("profileid")
            values.action = searchParams.get("action")
            values.offlinepack = searchParams.get("offlinepack")
            localStorage.setItem(Constants.loginLocalStorageKeys.isFrom, "sign in");
            dispatch(loginUser(values, props.router.navigate))
        }
        const rmCheck = document.getElementById("rememberMe"),
            emailInput = document.getElementById("name"),
            passwordd = document.getElementById("password");

        if (rmCheck.checked && emailInput.value !== "" && passwordd.value !== "") {
            localStorage.setItem("checkbox", rmCheck.checked);
            localStorage.setItem("email", emailInput.value);
            localStorage.setItem("pass", passwordd.value);
        } else {
            localStorage.setItem("email", "");
            localStorage.setItem("pass", "");
            localStorage.setItem("checkbox", "");
        }
    }

    useEffect(() => {
        const rmCheck = document.getElementById("rememberMe"),
            emailInput = document.getElementById("name"),
            passwordd = document.getElementById("password");
        if (localStorage.getItem("checkbox")) {
            // console.log("true rememberme")
            rmCheck.setAttribute("checked", "checked");
            emailInput.value = localStorage.getItem("email");
            passwordd.value = localStorage.getItem("pass");
        }
    }, [])

    let { error } = useSelector(state => ({
        error: state.Login?.error?.data
    }));

    let { responseData } = useSelector(state => ({
        responseData: state.Login?.adminLoginData?.data
      }));

    console.log(responseData, "responseDataresponseData");

    useEffect(() => {
        console.log(responseData,"errorerror");
        if (responseData?.status === "FAILURE" && responseData?.title === "Your Profile is suspended due to the following reason") {
            setshowSuspendedPopup(true)
            setErrorMessage("")
        } else {
            // setshowSuspendedPopup(false)
            setErrorMessage(responseData?.message)
        }
        setShowLoader(false)
    }, [responseData])

    useEffect(() => {
        setShowLoader(false)
    }, [responseData])

    useEffect(() => {
        setErrorMessage("")
        setshowSuspendedPopup(false)
    }, [formik.values])

    useEffect(() => {
        // if(showLoader == false) {
            let text = new URL(window.location.href)
            if (text.pathname == "/offline-profile") {
                setOfflineLoader(true)
                if (responseData?.nextPage == "/new/offline-page") {
                    setOfflineLoader(false)
                }else{
                    if(responseData?.status == "alreadyloggedin"){
                        setOfflineLoader(false)
                    }else{
                        setOfflineLoader(true)
                    }
                }
            }
        // }
    }, [responseData])
    

    return (
        <div className='font-segeo '>
            {showFamilyPopup &&
            

<FamilyfriedloginPopup close={() => setShowFamilyPopup(false)}

    title={"FAMILY/FRIEND LOGIN"} />
}
            {showLoader && <Loader className={props?.subMenu==="googleLandingpage" ?"loaderTransperancyGoogle":"loaderTransperancy"} loaderClassName={props?.subMenu==="googleLandingpage" ?"loaderTransperancySpinGoogle":"loaderTransperancySpin"} />}


            {offlineLoader && 
            <div className={`flex items-center justify-center bg-white fixed z-50 w-full h-full left-0 right-0`}>
                <div className='flex flex-col justify-center items-center'>
                    <img alt="kalyan" width="200" src={KMLogo} />
                    <img alt="" width="50" src={LoaderGif} />
                </div>
            </div>
                }
            {props?.menu === "signup" || props?.menu === "homeSignin" || props?.menu === "indSignin" || props?.page === "invsignup" ?

                <div>
                    <form className='' onSubmit={formik.handleSubmit}>
                        <div className=" mt-[1.1rem] ">
                            <div className=" ">
                                {/* Email or phone no */}
                                <div className="space-y-1 mt-[0rem]">
                                    <div  >
                                        <label className="relative block" >
                                            <div className="text-[red] text-[12px] h-[1rem]">{errorMessage}</div   >
                                            {formik.errors.name && formik.touched.name && (
                                                <p className="text-[red] text-[12px]">{formik.errors.name}</p>
                                            )
                                            }
                                            <input
                                                id="name"
                                                name="name"
                                                type="text"
                                                autoComplete="off"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                placeholder="e-mail ID / Mobile Number"
                                                className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block " />
                                        </label>

                                    </div>
                                </div>
                                {/* Password */}
                                <div className="space-y-1 mt-[1rem]">
                                    <div  >
                                        {formik.errors.password && formik.touched.password && (
                                            <p className="text-[red] text-[12px] h-[1rem]">{formik.errors.password}</p>
                                        )}
                                        <label className="relative flex justify-end	">
                                            <input id="password" name="password" autoComplete="off"
                                                placeholder="Password"
                                                type={showHidePassword ? "text" : "password"}
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                                className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-2 md:px-2  w-full block " />
                                            <div className="flex absolute inset-y-0 m-[0.6rem] pointer-events-auto">
                                                <img onClick={() => changeShowHidePassword(!showHidePassword)}
                                                                src={showHidePassword ? ("https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161601/passwordeyeopen_jkm7v5.png") : ("https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161797/passwordeye_cpa0p8.png")}
                                                                className="  w-[1rem] h-[1rem] md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5" alt="" />
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div onClick={onFamilyFriendPopupClick}>
                                    <a className='text-[12px] font-bold cursor-pointer text-[#D10A11] underline'>Login as Family Member/ Friend</a>
                                </div>
                                <div className='lg:block md:block hidden'>
                                    <div className='flex justify-between mt-2 md:mt-[0.6rem] lg:mt-0'>
                                        <div className="flex lg:pt-[0.5rem] justify-center items-center ">

                                            <label className=''>
                                                <input type="checkbox" onClick={()=>onClickTrack(EventName.Home_page_Sign_in_Remember_me_Button)} className=" border-[#F18181]  focus:ring-red-200 rounded-[3px]  " id="rememberMe" value="lsRememberMe" />
                                            </label>
                                            <p className=" text-[#000000] font-bold text-[14px] md:text-[11px] lg:text-[14px] pl-2 " htmlFor="rememberMe"> Keep me logged in</p>
                                        </div>
                                        <div className='text-[#000000] font-bold text-[14px] md:text-[11px] lg:text-[14px] lg:pt-[0.5rem] float-left'>
                                            {/* <ForgetPasswordPopup menu="sigin" /> */}
                                            {/* <p className=" text-[#000000] font-bold text-[14px] md:text-[11px] lg:text-[14px] lg:pt-[0.5rem] float-left"> Forgot Password? </p> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='lg:hidden md:hidden'>
                                    <div className='flex justify-between mt-2 md:mt-[0.6rem] lg:mt-0'>
                                        <div className="flex lg:pt-[0.5rem] justify-center items-center ">

                                            <label className=''>
                                                <input type="checkbox" onClick={()=>{onClickTrack(EventName.Home_page_Sign_in_Remember_me_Button); setIsChecked(!isChecked);}} className=" border-[#F18181]  focus:ring-red-200 rounded-[3px]  " id="rememberMe" value="lsRememberMe" checked={isChecked}/>
                                            </label>
                                            <p className=" text-[#000000] font-bold text-[14px] md:text-[11px] lg:text-[14px] pl-2 lg:pt-[0.5rem]" htmlFor="rememberMe"> Keep me logged in</p>
                                        </div>
                                        <div className='text-[#000000] font-bold text-[14px] md:text-[11px] lg:text-[14px] lg:pt-[0.5rem] float-left'>
                                            {/* <ForgetPasswordPopup menu="sigin" /> */}
                                            {/* <p className=" text-[#000000] font-bold text-[14px] md:text-[11px] lg:text-[14px] lg:pt-[0.5rem] float-left"> Forgot Password? </p> */}
                                        </div>
                                    </div> 
                                </div>
                                {/* Login Button      */}
                                <div className='space-y-1 lg:mt-[0.9rem] md:mt-[0.9rem] pt-[3rem]'>
                                    <div>
                                        <label className="" >
                                            <button type="submit"
                                                className=" w-full flex justify-center text-left py-2 border border-transparent rounded-[7px] shadow-sm text-[14px] text-[#FFFFFF] font-semibold bg-[#D10A11] hover:bg-[#D10A11]  focus:outline-none">
                                                Sign In
                                            </button>
                                        </label>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </form>

                    <div className={`${props?.menu === "homeSignin" ?
                        "space-y-1 mt-[0rem] relative float-right top-[-1.1rem] md:top-[-2.8rem] lg:top-[-3.2rem] "
                        : props?.menu === "sigin" ?
                            "space-y-1 mt-[0rem] relative float-right top-[-1.1rem] md:top-[-2.8rem] lg:top-[-3.2rem] "
                            : props.subMenu == "morelink" ? "space-y-1 mt-[-1rem] pl-[13rem] md:pl-[14rem] lg:pl-[5rem] relative md:top-[-2.9rem] lg:top-[-3.1rem] lg:left-[8rem]"
                                : "space-y-1 mt-[0rem] relative float-right top-[-0.1rem] md:top-[-2.8rem] lg:top-[-3.2rem] "

                        }`}>
                            {props?.page === "register" ?
                              <ForgetPasswordPopup popup={props.state} page="invsignup" menu="homeSignin"  type="registers"/>
                              :
                              <ForgetPasswordPopup popup={props.state} page="invsignup" menu="homeSignin" />



                            }

                        

                    </div>
                </div>


                :
                props?.menu == "login" ?
                    <>
                        <form className='' onSubmit={formik.handleSubmit}>

                            <>
                                <div className="flex space-x-4  mt-[1.1rem] ">
                                    <div className="hidden  md:flex  space-x-4 ">
                                        {/* Email or phone no */}
                                        <div className="space-y-1 mt-[1rem]">
                                            <div  >
                                                <label className="relative block" >

                                                    <input
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={formik.values.name}
                                                        onChange={formik.handleChange}
                                                        placeholder="e-mail ID / Mobile Number"
                                                        className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-[0.3rem] lg:py-2 md:px-2  w-full block " />
                                                </label>
                                            </div>
                                        </div>
                                        {/* Password */}
                                        <div className="space-y-1 mt-[1rem]">
                                            <div  >
                                                <label className="relative flex justify-end	">
                                                    <input id="password" name="password" autoComplete="off"
                                                        placeholder="Password"
                                                        value={formik.values.password}
                                                        onChange={formik.handleChange}
                                                        type={showHidePassword ? "text" : "password"}
                                                        className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-[0.3rem] md:px-2  lg:py-2 w-full block " />
                                                    <div className="flex absolute inset-y-0 m-[0.6rem] pointer-events-auto">
                                                        <img onClick={() => changeShowHidePassword(!showHidePassword)}
                                                            src={showHidePassword ? (EyeOpenIcon) : (EyeIcon)}
                                                            className=" md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5" alt="" />
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        {/* Login Button      */}
                                        <div className='space-y-1 mt-[0.9rem]'>
                                            <div>
                                                <label className="" >
                                                    <button type="submit"
                                                        className=" md:w-[5rem] lg:w-[7rem] flex justify-center text-left py-2 border border-transparent rounded-[7px] shadow-sm text-[14px] md:text-[11px] lg:text-[14px] text-[#FFFFFF] font-semibold bg-[#D10A11] hover:bg-[#D10A11]  focus:outline-none">
                                                        LOG IN
                                                    </button>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:flex space-x-4 md:mt-1 lg:mt-0">
                                    <div className="space-y-1 mt-[0rem]">
                                        <div  >

                                            <div className="flex lg:pt-[0.5rem] justify-center items-center">
                                                <label className='' >
                                                    <input type="checkbox" className=" border-[#F18181]  focus:ring-red-200 rounded-[3px]  " id="rememberMe" value="lsRememberMe" />
                                                </label>
                                                <p className=" text-[#8F8F8F] text-[14px] md:text-[11px] lg:text-[14px] pl-2 " htmlFor="rememberMe">Keep me logged in</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {responseData?.status == "alreadyloggedin" || responseData?.status == "/invalidate"?
                                    <div className="text-[red] text-[12px] h-[1rem]">Other User Logged in</div   >
                                :
                                errorMessage ?
                                    <div className="text-[red] text-[12px] h-[1rem] hidden  md:flex 1">{errorMessage}</div> :
                                    formik.errors.name && formik.touched.name ?
                                        <div className="text-[red] text-[12px] h-[1rem] hidden  md:flex 2">{formik.errors.name}</div>
                                        :
                                        <div className="text-[red] text-[12px] h-[1rem] hidden  md:flex 3"></div   >
                                }
                                

                            </>
                        </form>
                        <div className="hidden md:block space-y-1 mt-[0rem] lg:pl-[4.8rem] xl:pl-[3.2rem] relative top-[-1.5rem] md:top-[-2rem] lg:top-[-2.3rem] md:left-[2.9rem] lg:left-[8rem] xl:left-[10rem] forget-password ">
                            <div  >

                                <ForgetPasswordPopup />

                            </div>
                        </div>

                        {/* {responseData?.status == "alreadyloggedin"?
                            <div className="text-[red] text-[12px] h-[1rem]">Other User Logged in</div   >
                        : */}
                        {formik.errors.password && formik.touched.password ?
                            <p className="text-[red] text-[12px] h-[1rem] -mt-[1rem] ml-[11rem] lg:ml-[13rem]">{formik.errors.password}</p>
                            : <div className="text-[red] text-[12px] h-[1rem] -mt-[1rem] ml-[12rem]"></div   >
                        }
                        
                    </>
                    :
                    props?.menu == "community" ?
                        <>
                            {/* <p className="text-[8px] md:text-[11px] lg:text-[14px]  flex justify-center text-[#575556] font-semibold ">Exclusive Site for Vanniyar Community</p> */}
                            <form className='' onSubmit={formik.handleSubmit}>
    
                                <>
                                    <div className="flex space-x-4 mt-[1.4rem] ">
                                        <div className="hidden  md:flex  space-x-4 ">
                                            {/* Email or phone no */}
                                            <div className="space-y-1 mt-[1rem]">
                                                <div  >
                                                    <label className="relative block" >
    
                                                        <input
                                                            id="name"
                                                            name="name"
                                                            type="text"
                                                            autoComplete="off"
                                                            value={formik.values.name}
                                                            onChange={formik.handleChange}
                                                            placeholder="e-mail ID / Mobile Number"
                                                            className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-[0.3rem] lg:py-2 md:px-2  w-full block " />
                                                    </label>
                                                </div>
                                            </div>
                                            {/* Password */}
                                            <div className="space-y-1 mt-[1rem]">
                                                <div  >
                                                    <label className="relative flex justify-end	">
                                                        <input id="password" name="password" autoComplete="off"
                                                            placeholder="Password"
                                                            value={formik.values.password}
                                                            onChange={formik.handleChange}
                                                            type={showHidePassword ? "text" : "password"}
                                                            className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-[0.3rem] md:px-2  lg:py-2 w-full block " />
                                                        <div className="flex absolute inset-y-0 m-[0.6rem] pointer-events-auto">
                                                            <img onClick={() => changeShowHidePassword(!showHidePassword)}
                                                                src={showHidePassword ? ("https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161601/passwordeyeopen_jkm7v5.png") : ("https://res.cloudinary.com/dn8lo1gxs/image/upload/v1689161797/passwordeye_cpa0p8.png")}
                                                                className=" md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5" alt="" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* Login Button      */}
                                            <div className='space-y-1 mt-[0.9rem]'>
                                                <div>
                                                    <label className="" >
                                                        <button type="submit"
                                                            className=" md:w-[5rem] lg:w-[7rem] flex justify-center text-left py-2 border border-transparent rounded-[7px] shadow-sm text-[14px] md:text-[11px] lg:text-[14px] text-[#FFFFFF] font-semibold bg-[#D10A11] hover:bg-[#D10A11]  focus:outline-none">
                                                            SIGN IN
                                                        </button>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="hidden md:flex space-x-4 md:mt-1 lg:mt-0">
                                        <div className="space-y-1 mt-[0rem]">
                                            <div  >
    
                                                <div className="flex lg:pt-[0.5rem] justify-center items-center">
                                                    <label className='' >
                                                        <input type="checkbox" className=" border-[#F18181]  focus:ring-red-200 rounded-[3px]  " id="rememberMe" value="lsRememberMe" />
                                                    </label>
                                                    <p className=" text-[#8F8F8F] text-[14px] md:text-[11px] lg:text-[14px] pl-2 " htmlFor="rememberMe">Keep me logged in</p>
                                                </div>
                                            </div>
                                        </div>
    
                                    </div>
                                    {responseData?.status == "alreadyloggedin" || responseData?.status == "/invalidate"?
                                        <div className="text-[red] text-[12px] h-[1rem]">Other User Logged in</div   >
                                    :
                                    errorMessage ?
                                        <div className="text-[red] text-[12px] h-[1rem] hidden  md:flex 4">{errorMessage}</div> :
                                        formik.errors.name && formik.touched.name ?
                                            <div className="text-[red] text-[12px] h-[1rem] hidden  md:flex 5">{formik.errors.name}</div>
                                            :
                                            <div className="text-[red] text-[12px] h-[1rem] hidden  md:flex 6"></div   >
                                    }
                                    
    
                                </>
                            </form>
                            <div className="hidden md:block space-y-1 mt-[0rem] lg:pl-[5.1rem] xl:pl-[3.2rem] relative top-[-1.5rem] md:top-[-2rem] lg:top-[-2.3rem] md:left-[2.9rem] lg:left-[6rem] xl:left-[10rem] ">
                                <div  >
    
                                  {props?.type==="campainLandingPage" ?
                                  <ForgetPasswordPopup type="campainLanding" />
                                  :
                                  <ForgetPasswordPopup menu="community"/>

                                  }  
                                  {/* <ForgetPasswordPopup /> */}
    
                                </div>
                            </div>
    
                            {/* {responseData?.status == "alreadyloggedin"?
                                <div className="text-[red] text-[12px] h-[1rem]">Other User Logged in</div   >
                            : */}

                            {props?.type==="campainLandingPage" ?
                             formik.errors.password && formik.touched.password ?
                                <p className="text-[red] text-[12px] h-[1rem] -mt-[1rem] relative left-[-3rem] lg:left-0 lg:ml-[13rem]">{formik.errors.password}</p>
                                : <div className="text-[red] text-[12px] h-[1rem] -mt-[1rem]"></div   >
                            
                            :
                            formik.errors.password && formik.touched.password ?
                                <p className="text-[red] text-[12px] h-[1rem] -mt-[1rem] ml-[11rem] lg:ml-[13rem]">{formik.errors.password}</p>
                                : <div className="text-[red] text-[12px] h-[1rem] -mt-[1rem]"></div   >
                            

                            }
                           
                        </>
                    :
                    <>
                        <form className='' onSubmit={formik.handleSubmit}>

                            <div className='hidden  md:flex'>
                                <div className="flex space-x-4  mt-[1.1rem] ">
                                    <div className="hidden  md:flex  space-x-4 ">
                                        {/* Email or phone no */}
                                        <div className="space-y-1 mt-[0rem]">
                                            <div  >
                                                <label className="relative block" >
                                                    {errorMessage ?
                                                        <div className="text-[red] text-[12px] h-[1rem]">{errorMessage}</div> :
                                                        formik.errors.name && formik.touched.name ?
                                                            <div className="text-[red] text-[12px] h-[1rem]">{formik.errors.name}</div>
                                                            :
                                                            <div className="text-[red] text-[12px] h-[1rem]"></div   >
                                                    }
                                                    <input
                                                        id="name"
                                                        name="name"
                                                        type="text"
                                                        autoComplete="off"
                                                        value={formik.values.name}
                                                        onChange={formik.handleChange}
                                                        placeholder="e-mail ID / Mobile Number"
                                                        className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-[0.3rem] lg:py-2 md:px-2  w-full block " />
                                                </label>
                                            </div>
                                        </div>
                                        {/* Password */}
                                        <div className="space-y-1 mt-[0rem]">
                                            <div  >
                                                {formik.errors.password && formik.touched.password ?
                                                    <p className="text-[red] text-[12px] h-[1rem]">{formik.errors.password}</p>
                                                    : <div className="text-[red] text-[12px] h-[1rem]"></div   >
                                                }
                                                <label className="relative flex justify-end	">
                                                    <input id="password" name="password" autoComplete="off"
                                                        placeholder="Passwordss"
                                                        value={formik.values.password}
                                                        onChange={formik.handleChange}
                                                        type={showHidePassword ? "text" : "password"}
                                                        className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-[0.3rem] md:px-2  lg:py-2 w-full block " />
                                                    <div className="flex absolute inset-y-0 m-[0.6rem] pointer-events-auto">
                                                        <img onClick={() => changeShowHidePassword(!showHidePassword)}
                                                            src={showHidePassword ? (EyeOpenIcon) : (EyeIcon)}
                                                            className=" md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5" alt="" />
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        {/* Login Button      */}
                                        <div className='space-y-1 mt-[0.9rem]'>
                                            <div>
                                                <label className="" >
                                                    <button type="submit"
                                                        className=" md:w-[5rem] lg:w-[7rem] flex justify-center text-left py-2 border border-transparent rounded-[7px] shadow-sm text-[14px] md:text-[11px] lg:text-[14px] text-[#FFFFFF] font-semibold bg-[#D10A11] hover:bg-[#D10A11]  focus:outline-none">
                                                        LOG IN
                                                    </button>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden md:flex space-x-4 md:mt-1 lg:mt-0">
                                    <div className="space-y-1 mt-[0rem]">
                                        <div  >

                                            <div className="flex lg:pt-[0.5rem] justify-center items-center">
                                                <label className='' >
                                                    <input type="checkbox" className=" border-[#F18181]  focus:ring-red-200 rounded-[3px]  " id="rememberMe" value="lsRememberMe" />
                                                </label>
                                                <p className=" text-[#8F8F8F] text-[14px] md:text-[11px] lg:text-[14px] pl-2 " htmlFor="rememberMe">Keep me logged in</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                {errorMessage ?
                                    <div className="text-[red] text-[12px] h-[1rem] ">{errorMessage}</div> :
                                    formik.errors.name && formik.touched.name ?
                                        <div className="text-[red] text-[12px] h-[1rem]">{formik.errors.name}</div>
                                        :
                                        <div className="text-[red] text-[12px] h-[1rem]"></div   >
                                }

                            </div>
                        </form>
                        <div className="hidden md:block space-y-1 mt-[0rem] lg:pl-[5.1rem] xl:pl-[3.2rem] relative top-[-1.5rem] md:top-[-0.9rem] lg:top-[-1.2rem] md:left-[1.9rem] lg:left-[6rem] xl:left-[15rem] ">
                            <div  >

                                <ForgetPasswordPopup />

                            </div>
                        </div>
                    </>
            }
            {
                showSuspendedPopup ?
                    <SuspendedPopup
                        close={() => {
                            setshowSuspendedPopup(!showSuspendedPopup)
                        }}
                        title={responseData.title}
                        content={responseData.reason}
                    />

                    : <></>
            }


        </div >
    )
}
export default withRouter(LoginForm);

