import React, { useContext, useEffect ,useState} from 'react'

//Components
import Header from "../Components/ProfileRegisterationOtp/Header"
import OtpSection from '../Components/ProfileRegisterationOtp/OtpSection'
import Bannersection from '../Components/ProfileRegisterationOtp/Bannersection'
import MobileviewOptSection from '../Components/ProfileRegisterationOtp/MobileviewOptSection'
import Footer from '../Components/Footer/Footer'
import MobileFooter from './Footer/MobileFooter'
import Loader from "../Components/Loader";
import $ from "jquery"

//context
import { LoginContext } from '../Contexts/LoginContext'
import Constants from '../Constants/constants'

const ProfileRegisterationOtp = (props) => {

  const [isLoadingProfile, setisLoadingProfile] = useState(true); 
  //console.log(props.isLoading,"props.isLoading");
  const {logout} = useContext(LoginContext)
  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);
  useEffect(() => {
    setisLoadingProfile(false)
    // alert(window.location.href)
    window.onpopstate = ()=> {
        if(window.location.href.indexOf('dashboard')>-1 || window.location.href.indexOf('mobileVerification')>-1){
          logout();
        }
      }
  },[])

  const scriptToInject = `
  <div class="pixelScript">
  <!-- Google tag (gtag.js) -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=AW-875555676"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'AW-875555676');
  </script>
  </div>
  `;


  useEffect(() => {
    if(isCommunitySite){
      //no script for this community site
    }else{
      if ($('.pixelScript').length > 0) {
        $('.pixelScript').remove()
        $('head').append(scriptToInject)
      } else {
        $('head').append(scriptToInject)
      }
	  }
    return () => {
      $('.pixelScript').remove()
    }
  }, [])

  return (
    <div className='min-h-0 flex-1 overflow-hidden'>
      <Header />
      <main className=" font-segeo ">
        <div className="overflow-x-hidden">
        {isLoadingProfile ?  
                  <Loader /> : 
          <div className=" hidden  md:grid md:grid-cols-9 ">
            <OtpSection />
            <Bannersection />
          </div>
          }
          {/* mobileview */}

          <div className=" px-[1rem] mt-[2rem]  md:hidden ">
            <MobileviewOptSection />
            <Bannersection />

          </div>

        </div>
      </main>
      <div className='md:fixed md:right-0 md:bottom-0 lg:relative '>
      <Footer />
      </div>
      <MobileFooter/>

    </div>
  )
}

export default ProfileRegisterationOtp
