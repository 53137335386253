import React, { useState, useEffect, useContext, useMemo, memo, useRef } from "react";
import { ReactDOM } from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer/Footer";
import "../Styles/app-styles.css";
import EndPoints from "../Constants/EndPoints";
import Loader from "../Components/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { POST, GET } from "../Services/api_services";
import { LoginContext } from "../Contexts/LoginContext";
import { IP_Address } from "../Constants/IpAddress";
import NavBar from "../Components/NavBar";
import $ from 'jquery'
import { useNavigate } from "react-router";
import RouteSegments, { RouteHelper } from "../Routes/RouteSegment";
import ViewMessageNav from "../Components/ViewMessageComponents/ViewMessageNav";
import ViewMessageBottomNav from "../Components/ViewMessageComponents/ViewMessageBottomNav";
import { ProfileAccordianComponent } from "../Components/ViewMessageComponents/AccordianComponents";
import { MultiSelectView } from "../Components/Edit Profile/PartnerPreference/PartnerPreferenceUtils";
import { EducationCheckView } from "../Components/Edit Profile/PartnerPreference/PartnerPreferenceUtils";
import { EduactionListTag } from "../Components/Edit Profile/EditProfileUtills";
import EducationComponent from "../Components/BasicAdvanceSearch/educationComponent";
import AdvanceSearch from "../Components/BasicAdvanceSearch/advanceSearch";
import { CommonCheckButton } from "../Components/DashboardComponents/DashboardUtills";
import Searchbyid from "../Components/BasicAdvanceSearch/searchbyid";
import { NavListContext } from "../Contexts/navContext";
import { useSearchParams } from "react-router-dom";
import Constants, { EditProfileMenu } from "../Constants/constants";
import { NavBarContext } from "../Contexts/NavBarContext";
import KjBannerPopup from "../Components/DashboardComponents/KjBannerPopup";
import ParentsPhonePopup from "../Components/DashboardComponents/ParentsPhonePopup";
import config from "../config";
import LoaderGif from "../Gif/loader.gif"
import { useSelector, useDispatch } from 'react-redux';
import { searchPageCheckRequest } from '../Store/SearchPageCheck/Action';
import { CommonPopup } from "../Components/CommonPopup";
import DownloadAppBar from "../Components/DownloadAppBar";
import { dashboardLeftPanelData, dashboardMatcheslist, dashboardProfileData } from "../Store/DashboardAPI/Action";



const BasicAdvanceSearch = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const { menuSelect, setMenuSelect } = useContext(NavBarContext);

  const { navList, setNavList } = useContext(NavListContext);
  const [dashboard1Data, setDashboard1Data] = useState([]);
  // const [activeTab, setActiveTab] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [basicSearch, setBasicSearch] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [stateDropDown, setstateDropDown] = useState([]);
  const [cityDropDown, setcityDropDown] = useState([]);
  const [submitStatus, setSubmitStatus] = useState(false)
  const [loader, setLoader] = useState(false)
   const [showLoader, setShowLoader] = useState(false)
  const [castDropDown, setCastDropDown] = useState([]);
  const [subCasteDropDown, setSubCastDropDown] = useState([]);
  const [addBanner, setAddBanner] = useState();
  const [showKjBannerPopup, setShowKjBannerPopup] = useState(false);
  const [showKjThankPopup, setShowKjThankPopup] = useState(false);
  const [showParentNumPopup, setShowParentNumPopup] = useState(false)
  const [filterCheckData, setFilterCheckData] = useState("")
  const [showFilterCheckPopup, setShowFilterCheckPopup] = useState(false)
  const [filterOnchange, setFilterOnchange] = useState(false)
  const [ageError, setAgeError] = useState(false)
  const [heightError, setHeightError] = useState(false)
  const [educationError, setEducationError] = useState(false)
  const [isPPEdited, setIsPPEdited] = useState("no")

  const [editPreference, setEditPreference] = useState({
    email: loginDetail()[0],
    userId: loginDetail()[1],
    ppfromsearch: "yes"
  });

  console.log(editPreference,"editPreferenceeditPreference");

  /// pagenumber

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams({tab:"1"})
  const tab = parseInt(searchParams.get('tab')??"1")

  const setActiveTab = (tab)=>{
    searchParams.set('tab',tab)
    setSearchParams(searchParams,{replace:true})
  }

  const activeTab = useMemo(()=>{
    if([1,2].includes(tab)){
      return tab;
    }
    return 1;
  },[tab])

  const PageNumValue = {
    pageCount: 1,
  };
  const [pageNumber, setPageNumber] = useState(PageNumValue);
  const navigate = useNavigate();
  const [ageFromValue, setageFromValue] = useState([])

  /// age
  // const ageFromValue = [];
  const apiCallState = async (countryID) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getStateUrl(countryID)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setstateDropDown(data.data.states);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  const apiCallCity = async (stateID) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getCityUrl(stateID)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setcityDropDown(data.data.cities);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const [ageToValue, setAgeToValue] = useState([]);

  useEffect(() => {
    for (let i = 18; i <= 70; i++) {
      setageFromValue(oldvalue => [...oldvalue, i]);
      setAgeToValue(oldvalue => [...oldvalue, i]);
    }
    // return false
  },[])

 

  const setAge = (from) => {
    let temp = [];
    for (let i = from; i <= 70; i++) {
      temp.push(i);
    }
    setAgeToValue(temp);
    //console.log("age to value");
  };
  
  // const apicallDashboard = async () => {
  //   let request = {
  //     email: loginDetail()[0],
  //     userId: loginDetail()[1],
  //     pageno: "1",
  //     status: "new",
  //   };
  //   let { statusCode, data } = await POST(
  //     `${config.api.API_URL}${EndPoints.dashboardUrl()}`,
  //     request
  //   );

  //   if (statusCode === 200) {
  //     setDashboard1Data(data?.data);
  //     localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, data?.data.status)
  //     if(data?.data?.popup[0].showpopup == "RejectedProfile"){
  //       navigate(RouteHelper.getEditProfileUrl())
  //     }
  //   } else if (statusCode === 401) {
  //     logout();
  //   }
  // };

      //Left Panel API Call and Response  
      const { leftPanelData } = useSelector(state => ({
        leftPanelData: state.DashBoardAPI?.leftPanelData?.data
      }));
  
       //Profile Data API Call and Response  
       const { ProfileData } = useSelector(state => ({
        ProfileData: state.DashBoardAPI?.ProfileData?.data
      }));

  useEffect(()=>{
    if(ProfileData){
      setDashboard1Data(ProfileData);
      localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, ProfileData.status)      
    }
      if(ProfileData?.popup[0]?.showpopup == "RejectedProfile"){
        navigate(RouteHelper.getEditProfileUrl())      
    }

  },[ProfileData])

  // console.log(leftPanelData,'leftPanelData');
  
  /// basicsearch load
  const apicallbasicsearch = async () => {
    setLoading(true);
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.basicSearchUrl()}`,
      request
    );

    if (statusCode === 200) {
      setBasicSearch(data.data);
      setAddBanner(data?.data?.bannerWeb);
       setLoader(true)
    } else if (statusCode === 401) {
      logout();
    }
    setLoading(false);
  };

  //console.log(basicSearch, "basic");
  const searchData = basicSearch;
  console.log(basicSearch?.subCaste, "search");

  //// formik
  const formik = useFormik({
    initialValues: {
      maritalStatus: basicSearch?.maritalStatus
        ? basicSearch?.maritalStatus
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.name)
        : [],
      ageFrom: basicSearch?.minage ? basicSearch?.minage : "",
      ageTo: basicSearch?.maxage ? basicSearch?.maxage : "",
      country: basicSearch?.country
        ? basicSearch?.country
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      motherTongue: basicSearch?.motherTongue
        ? basicSearch?.motherTongue
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      religion: basicSearch?.religion
        ? basicSearch?.religion
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
        denomination: basicSearch?.domain
        ? basicSearch?.domain
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      caste: basicSearch?.caste
        ? basicSearch?.caste
            .filter((e) => e.selected === "Y")
            .map((e, i) =>  e.id)
        : [],
      subcaste: basicSearch?.subCaste
        ? basicSearch?.subCaste
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      minheight: basicSearch?.minheightId
        ? basicSearch?.minheightId
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)[0]
        : [],
      maxheight: basicSearch?.maxheightId
        ? basicSearch?.maxheightId
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)[0]
        : [],

      education: basicSearch?.education
        ? basicSearch?.education
            .filter((e) => e.selected === "Y")
            .map((e, i) => e.id)
        : [],
      withPhoto: "",
      withHoroscope: "",
      shortlisted: "",
      ignored: "on",
      contacted: "on",
      viewed: "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({}),

   onSubmit : (values) => {
      apiCallSubmitPreference(values); 
       setSubmitStatus(true)
        setShowLoader(true)

    },
  });

  const isChristian = formik.values.religion?.includes(Constants.christialId)

  useEffect(() => {
    setAge(formik.values.ageFrom)
    if((Math.abs(formik.values.ageFrom - formik.values.ageTo))<3){
      setAgeError(true)
    }
    else{
      setAgeError(false)
    }
    if((Math.abs(Number(formik.values.minheight- formik.values.maxheight)))<12){
      setHeightError(true)
    }
    else{
      setHeightError(false)
    }
    if(formik.values?.education?.length == 1){
      setEducationError(true)
    }
    else{
      setEducationError(false)
    }
  },[formik.values.ageFrom])

  const apiCallSubmitPreference = async (values) => {
   
    //console.log(values, "values");
    let request = {
      pageno: `${pageNumber.pageCount}`,
      email: loginDetail()[0] ?? null,
      userId: loginDetail()[1],
      ageTo: values?.ageFrom>values?.ageTo?values?.ageFrom:values?.ageTo,
      ageFrom: values?.ageFrom,
      heightFrom: values?.minheight,
      heightTo: Number(values?.minheight)>Number(values?.maxheight)?values?.minheight:values?.maxheight,
      marital_status: values?.maritalStatus,
      mother_tongue: values?.motherTongue,
      educationId: values?.education,
      subcasteId: values?.subcaste,
      casteIdList: values?.caste,
      countryIdList: values?.country,
      religionIdList: values?.religion,
      domainIdList:values?.religion?.includes(Constants.christialId)?values?.denomination:[],
      shortlisted: values?.shortlisted,
      ignored: values?.ignored,
      contacted: values?.contacted,
      viewed: values?.viewed,
      withHoroscope: values?.withHoroscope,
      withPhoto: values?.withPhoto,
      ppedited:isPPEdited
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.searchPageNew()}`,
      request
    );

    if (statusCode === 200) {
      setFilteredData(data?.data);
      sessionStorage.setItem("preferenceRequest",JSON.stringify(editPreference))
      props.viewAllCallback()
      navigate(RouteHelper.getSavePartnerPreferenceUrl, {
        state: {contentData:data?.data?.contentCard, dataSet:data?.data, data: data?.data?.filtermatches, isfrom:"basic_search",banner:data?.data?.bannerWebright, topBanner: data?.data?.bannerWebTop,  menu: "savepreferences",request : request },
        // replace: true 
      }); 
      setMenuSelect("")
      setNavList([...navList, "Profile Details"])
     
      //   setEditView(false);
    } else if (statusCode === 401) {
      logout();
    }
  };

  //console.log(filteredData, "filter");
  /// advance search load

    // getcast
  const apiCallCast = async (mothertonge, religion, initial) => {
    // getCastUrl

    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getCastUrl(mothertonge, religion)}`
    );
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setCastDropDown(data.data.castes);
       if (initial === false) { 
        const temp = []; 
        data?.data?.castes?.forEach(caste => {
          if(formik?.values?.caste?.includes(caste.id)){
            temp.push(caste.id)
          }
        });
        formik.setFieldValue("caste", temp);
        apiCallSubCast(temp,false)

        // const temp2 = [];
        // data?.data?.subcastes?.forEach(caste => {
        //   if(formik?.values?.subcaste?.includes(caste.id)){
        //     temp.push(caste.id)
        //   }
        // });
        // formik.setFieldValue("subcaste", temp2);

      }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  // getsubcaste
  const apiCallSubCast = async (castId, initial=false) => {
    let { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getSubCastUrl(castId)}`
    );
    //console.log("subcaste", data);
    if (statusCode === 200) {
      // setDropDownData(data.data, type);
      if (data.status == "Success") {
        setSubCastDropDown(data.data.subcastes);

        if(initial === false){
          const temp = [];
          data?.data?.subcastes?.forEach(caste => {
            if(formik?.values?.subcaste?.includes(caste.id)){
              temp.push(caste.id)
            }
          });
          formik.setFieldValue("subcaste", temp);
        }
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  const clearCaste = ()=>{
  formik.setFieldValue("caste", []);
  apiCallSubCast([])
}
 
  useEffect(() => {
    //console.log(formik.values?.motherTongue, "formiksearch");
    //console.log(formik.values?.religion, "formiksearch1");
    //console.log(formik.values?.caste, "formiksearch2");
     setShowLoader(false)
    const initialMotherTongue = basicSearch?.motherTongue
      ?.filter((e) => e.selected === "Y")
      .map((e, i) => e.id);

    const initialReligion = basicSearch?.religion
      ?.filter((e) => e.selected === "Y")
      .map((e, i) => e.id);

      if(initialMotherTongue != null && initialMotherTongue?.length > 0 && initialReligion != null && initialReligion?.length > 0){
        apiCallCast(initialMotherTongue, initialReligion, true);
      }
      
    
     const initialCaste = basicSearch?.caste
      ?.filter((e) => e.selected === "Y")
      .map((e, i) => e.id);
      if(initialCaste!=null && initialCaste?.length > 0){
        apiCallSubCast(initialCaste,true)
      }

  }, [ basicSearch])

  useEffect(() => {
    apicallbasicsearch();
    // apicallDashboard();
    dispatch(dashboardProfileData())
    // dispatch(dashboardMatcheslist())
    dispatch(dashboardLeftPanelData())
  },[])

  useEffect(() => {
        console.log(basicSearch,"basicSearchbasicSearch")


  }, [
    dashboard1Data,
    basicSearch,
    castDropDown,
    subCasteDropDown,
    filteredData,
  ]);
  const bannerRedirect = (name, redirect) => {
    if(name == "imageupload"){
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), { state: { tab: "3" } })
    }
    else if(name == "parentnumber"){
      setShowParentNumPopup(true)
    }
    else if(name == "managepartner"){
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath), { state: { tab: "2" } })
    }
    else if(name == "goldscheme"){
      window.open("https://www.kalyanmatrimony.com/gold-saving-scheme-online?sentFrom=Banner",'_blank')
    }
    else if(name == "payment"){
      navigate(RouteSegments.MEMBERSHIP_REGISTER())
    }
    else{
      window.open(redirect,'_blank')
    }
  }

  useEffect(()=>{
    if(filterOnchange){
      setShowLoader(true)
      basicSearchCheck(formik.values)
    }
  },[formik.values ])

  useEffect(()=>{
    if(filterOnchange){
      if(formik.values?.education?.length == 1){
              setEducationError(true)
            }
            else{
              setEducationError(false)
            }
    }
  },[formik.values.education ])

  const basicSearchCheck = async(values) => {
   
      let request = {
        pageno: `${pageNumber.pageCount}`,
        email: loginDetail()[0],
        userId: loginDetail()[1],
        ageTo: values?.ageFrom>values?.ageTo?values?.ageFrom:values?.ageTo,
        ageFrom: values?.ageFrom,
        heightFrom: values?.minheight,
        heightTo: Number(values?.minheight)>Number(values?.maxheight)?values?.minheight:values?.maxheight,
        marital_status: values?.maritalStatus,
        mother_tongue: values?.motherTongue,
        educationId: values?.education,
        subcasteId: values?.subcaste,
        casteIdList: values?.caste,
        countryIdList: values?.country,
        religionIdList: values?.religion,
        shortlisted: values?.shortlisted,
        ignored: values?.ignored,
        contacted: values?.contacted,
        viewed: values?.viewed,
        withHoroscope: values?.withHoroscope,
        withPhoto: values?.withPhoto,
      };
  
      let { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.searchPageResultSet()}`,
        request
      );
  
      if (statusCode === 200) {
        if(data?.data?.criterialessthan15!=""){
        setShowFilterCheckPopup(true)
        setFilterCheckData(data?.data?.criterialessthan15);
        }

        
      } else if (statusCode === 401) {
        logout();
      }
      setFilterOnchange(false)
      setShowLoader(false)
    };





  return (
    <>
      {/* <InjectScript script={scriptToInject} /> */}
            {showFilterCheckPopup &&
            <CommonPopup title="Search Criteria Alert" close={() => setShowFilterCheckPopup(false)} menu="profileStatus">
              <div className='flex  flex-col justify-center'>
                <p>{filterCheckData}</p>
                <div className='flex justify-center mt-3 '>
                <button className='rounded-[5px] text-[#FFFFFF] bg-[#D10A11] px-[6%] py-[2%] font-bold' onClick={()=>setShowFilterCheckPopup(false)}>OK</button>
                </div>
              </div>
            </CommonPopup>
            }

          {showParentNumPopup &&
              <ParentsPhonePopup close={() => setShowParentNumPopup(false)}
                title={"Parent's Number"}
                callBackApply={() => { }}
                parentsIsdCode={ProfileData?.parentsIsdCode}
                parentsMobileNumber={ProfileData?.parentsMobileNumber}
              />
            }

      <NavBar data={leftPanelData} ProfileData={ProfileData}/>
       {showLoader && <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />}
      {loader? 
      <main>
        <div className="overflow-x-hidden">
          <ViewMessageNav page='searchpage' menu="" />
          {basicSearch ?
          <div className="  md:grid md:grid-cols-9 ">
            <div className=" px-[1rem] md:px-0  md:pl-[1rem] lg:pl-[3rem] lg:pr-0   col-span-6 ">
               <Searchbyid />
              <div className="pt-[1.4rem]">
                <div className="flex justify-between border-b-[2px] border-[#D10A11]">
                <div className="   ">
                  <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
                    <li
                      onClick={() => setActiveTab(1)}
                      className="mr-2"
                      role="presentation"
                    >
                      <button
                        className={`${
                          activeTab === 1
                            ? " bg-[#D10A11]  text-white font-bold "
                            : " text-[#575556] "
                        }inline-block p-4    font-bold rounded-t-lg text-[16px] `}
                        href="#"
                      >
                        Basic Search
                      </button>
                    </li>
                    <li
                      onClick={() => setActiveTab(2)}
                      className="mr-2"
                      role="presentation"
                    >
                      <button
                        className={`${
                          activeTab === 2
                            ? " bg-[#D10A11]  text-white font-bold "
                            : " text-[#575556] "
                        }inline-block p-4    font-bold rounded-t-lg text-[16px] `}
                      >
                        Advanced Search
                      </button>
                    </li>
                  </ul>
                 
                </div>
                  {/* <Searchbyid /> */}
                </div>
                {activeTab === 1
                ?<div
                  className={`bg-white`}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <div
                      className=" p-4 rounded-lg bg-gray-50 "
                      x-show="tab === 1"
                    >
                      <div className=" md:grid grid-cols-9 mt-[2rem] ">
                        <div className=" col-span-2  flex items-center  ">
                          <p className=" md:text-[12px] lg:text-[14px] flex  items-center text-[#575556] font-semibold  ">
                            Age Range
                            {/* <!-- <span className="text-[#D10A11]">*</span> --> */}
                          </p>
                        </div>
                        <div className="  col-span-7 ">
                          <p className="  ">
                            <div className="flex space-x-3 ">
                              <label className=" select font-bold text-[#575556] ">
                                <select
                                  name="ageFrom"
                                  id="ageFrom"
                                  value={formik.values?.ageFrom}
                                  onChange={(e) => {
                                    // add a key/value pair
                                    setIsPPEdited("yes")
                                    formik.handleChange(e);
                                    setAge(e.target.value);
                                    if(e.target.value>formik.values?.ageTo){
                                      setEditPreference({ ...editPreference, ["age_from"]: e.target.value, ["age_to"]: e.target.value });
                                    }
                                    else{
                                      setEditPreference({ ...editPreference, ["age_from"]: e.target.value, ["age_to"]: formik?.values?.ageTo  });
                                    }
                                    setFilterOnchange(true)
                                    if(e.target.value<formik.values?.ageTo){
                                      if((Math.abs(formik.values.ageTo - e.target.value))<3){
                                        setAgeError(true)
                                      }
                                      else{
                                        setAgeError(false)
                                      }
                                    }
                                    else{
                                      setAgeError(true)
                                    }
                                  }}
                                  className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
                                >
                                  {ageFromValue.map((e, i) => (
                                    <option key={i} value={e}>
                                      {e}
                                    </option>
                                  ))}
                                </select>
                              </label>
                              <p className="flex justify-center items-center font-bold text-[#575556] md:text-[12px] lg:text-[16px] ">
                                To
                              </p>
                              <label className=" select font-bold text-[#575556] ">
                                <select
                                  name="ageTo" 
                                  id="ageTo"
                                  value={formik.values?.ageTo}
                                  onChange={(e) => {
                                    setEditPreference({ ...editPreference, ["age_from"]: formik.values.ageFrom, ["age_to"]: e.target.value  });
                                    setIsPPEdited("yes")
                                    formik.handleChange(e);
                                    setFilterOnchange(true)
                                    if((Math.abs(formik.values.ageFrom - e.target.value))<3){
                                      setAgeError(true)
                                    }
                                    else{
                                      setAgeError(false)
                                    }
                                  }}
                                  className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
                                >
                                  {ageToValue.map((e, i) => (
                                    <option key={i} value={e}>
                                      {e}
                                    </option>
                                  ))}
                                  {/* {//console.log(formik.values?.ageTo,"formik.values?.ageTo")} */}
                                </select>
                              </label>
                            </div>
                            {ageError ? <span className="text-[red] text-[12px]">Broaden this Parameter a little more to get better results</span> : ""}
                          </p>
                        </div>
                      </div>

                      <div className=" md:grid grid-cols-9 mt-[1rem] md:mt-[2rem] ">
                        <div className=" col-span-2 flex items-center ">
                          <p className="   md:text-[12px] lg:text-[14px] text-[#575556] font-semibold   ">
                            Height
                          </p>
                        </div>
                        <div className="  col-span-7 ">
                          <p className="  ">
                            <div className="flex space-x-3 ">
                              <label className=" select font-bold text-[#575556] ">
                                <select
                                  name="minheight"
                                  id="minheight"
                                  onChange={(e) => {
                                    if(e.target.value>formik.values?.maxheight){
                                      setEditPreference({ ...editPreference, ["height_from"]: e.target.value, ["height_to"]: e.target.value });
                                    }
                                    else{
                                      setEditPreference({ ...editPreference, ["height_from"]: e.target.value, ["height_to"]: formik?.values?.maxheight  });
                                    }
                                    setIsPPEdited("yes")
                                    formik.handleChange(e);
                                    setFilterOnchange(true)
                                    if(Number(formik.values.maxheight) > Number(e.target.value)){
                                      if((Math.abs(Number(formik.values.maxheight) - Number(e.target.value)))<12){
                                        setHeightError(true)
                                      }
                                      else{
                                        setHeightError(false)
                                      }
                                    }
                                    else{
                                      setHeightError(true)
                                    }
                                  }}
                                  value={formik.values?.minheight}
                                  className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
                                >
                                  {basicSearch?.minheightId?.map((e, i) => (
                                    <option key={i} value={e.id}>
                                      {e.name}
                                    </option>
                                  ))}
                                </select>
                              </label>
                              <p className="flex justify-center items-center font-bold text-[#575556] md:text-[12px] lg:text-[16px] ">
                                To
                              </p>
                              <label className=" select font-bold text-[#575556] ">
                                <select
                                  name="maxheight"
                                  id="maxheight"
                                  onChange={(e) => {
                                    setEditPreference({ ...editPreference, ["height_to"]: e.target.value, ["height_from"]: formik.values.minheight });
                                    setIsPPEdited("yes")
                                    formik.handleChange(e);
                                    setFilterOnchange(true)
                                    if((Math.abs((Number(formik.values.minheight)) - e.target.value))<12){
                                      setHeightError(true)
                                    }
                                    else{
                                      setHeightError(false)
                                    }
                                  }}
                                  value={formik.values?.maxheight}
                                  className=" w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#8F8F8F] font-semibold md:text-[12px] lg:text-[16px] bg-white rounded-[8px]   "
                                >
                                  {basicSearch?.maxheightId?.map((e, i) => {
                                  return(
                                    formik.values?.minheight?.length>0 && parseInt(formik.values?.minheight) <= parseInt(e.id) ?
                                    <option key={i} value={e.id}>
                                      {e.name}
                                    </option>
                                    :""
                                  )})}
                                </select>
                              </label>
                            </div>
                            {heightError ? <span className="text-[red] text-[12px]">Broaden this Parameter a little more to get better results</span> : ""}
                          </p>
                        </div>
                      </div>
                      <MultiSelectView
                        page="search"
                        title={"Marital Status"}
                        inputID={"maritalStatus"}
                        inputName={"maritalStatus"}
                         placeholder="Any Marital Status"
                        value={formik.values?.maritalStatus}
                        defalutValue={[formik.values?.maritalStatus]}
                        onChange={(e) => {
                          let maritalStatusId = basicSearch?.maritalStatus?.filter((data)=>{
                            return Array.from(e).includes(data.name)
                          }).map((data)=>data?.id)
                          setEditPreference({ ...editPreference, ["maritalStatusId"]: maritalStatusId })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("maritalStatus", Array.from(e));
                          // props.data.handleChange
                        }}
                        error={formik.errors.maritalStatus}
                        options={basicSearch?.maritalStatus}
                      />
                      <MultiSelectView
                        page="search"
                        title={"Mother Tongue"}
                        inputID={"motherTongue"}
                        inputName={"motherTongue"}
                        placeholder="Any Mother Tongue"
                        value={formik.values?.motherTongue}
                        defalutValue={[formik.values?.motherTongue]}
                        onChange={(e) => {
                          setEditPreference({ ...editPreference, ["motherTongueId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("motherTongue", Array.from(e));
                          apiCallCast(Array.from(e), formik.values?.religion,false);
                          //console.log(e, Array.from(e), "selectede");
                          // clearCaste()
                          // if(e.length === 0 ){
                          //    //console.log(e,"mother");
                          //    formik.setFieldValue("caste", []);
                          //    formik.setFieldValue("subcaste", []);
                          // }
                           
                        }}
                        error={formik.errors.motherTongue}
                        options={basicSearch?.motherTongue}
                      />
                      <MultiSelectView
                        page="search"
                        title={"Religion"}
                        inputID={"religion"}
                        inputName={"religion"}
                        value={formik.values?.religion}
                        placeholder="Any Religion"
                        defalutValue={[formik.values?.religion]}
                        onChange={(e) => {
                          setEditPreference({ ...editPreference, ["religionId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("religion", Array.from(e));
                          apiCallCast(
                            formik.values?.motherTongue,
                            Array.from(e),false
                          );
                          // clearCaste()

                          // if(e.length === 0 ){
                             
                          //    formik.setFieldValue("caste", []);
                          //    formik.setFieldValue("subcaste", []);
                          // }
                          // props.data.handleChange
                        }}
                        error={formik.errors.religion}
                        options={

                          basicSearch?.religion
                        }
                      />
                      {isChristian && <MultiSelectView
                        page="search"
                        title={"Denomination"}
                        inputID={"denomination"}
                        inputName={"denomination"}
                        value={formik.values?.denomination}
                        placeholder="Any Denomination"
                        onChange={(e) => {
                          setEditPreference({ ...editPreference, ["denominationId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("denomination", Array.from(e));
                        }}
                        error={formik.errors.denomination}
                        options={basicSearch?.domain}
                      />}
                      <MultiSelectView
                        page="search"
                        title={"Caste"}
                        inputID={"caste"}
                        inputName={"caste"}
                        placeholder="Any Caste"
                        value={formik.values?.caste}
                        defalutValue={[formik.values?.caste]}
                        onChange={(e) => {
                          setEditPreference({ ...editPreference, ["casteId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("caste", Array.from(e));
                          apiCallSubCast(Array.from(e));
                           
                          // props.data.handleChange
                        }}
                        error={formik.errors.caste}
                        options={castDropDown}
                      />
                      <MultiSelectView
                        page="search"
                        title={"Sub-Caste"}
                        inputID={"subcaste"}
                        inputName={"subcaste"}
                        placeholder="Any Sub-Caste"
                        value={formik.values?.subcaste}
                        defalutValue={[formik.values?.subcaste]}
                        onChange={(e) => {
                          setEditPreference({ ...editPreference, ["subcasteId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("subcaste", Array.from(e));
                        }}
                        error={formik.errors.subcaste}
                        options={subCasteDropDown}
                      />

                      <div className=" md:grid grid-cols-9 mt-[2rem] ">
                        <div className=" col-span-2 flex items-center ">
                          <p className="   md:text-[12px] lg:text-[14px] text-[#575556] font-semibold   ">
                            Education
                          </p>
                        </div>
                        <div className="col-span-7">
                          <EducationComponent
                            setIsPPEdited = {setIsPPEdited}
                            menu= "search"
                            fromPath = "basic_advance_search"
                            data={formik}
                            editPreference={editPreference}
                            setEditPreference={setEditPreference}
                            setEducationError={setEducationError}
                            setFilterOnchange={setFilterOnchange}
                            educationinfofreshData={basicSearch}
                          />
                          {educationError ? <span className="text-[red] text-[12px]">Add more criteria to get better results</span> : ""}
                        </div>
                      </div>

                      <MultiSelectView
                        page="search"
                        title={"Country Living in"}
                        inputID={"country"}
                        inputName={"country"}
                        placeholder="Any Country"
                        value={formik.values?.country}
                        defalutValue={[formik.values?.country]}
                        onChange={(e) => {
                          console.log(e.target,"e.target.value");
                          setEditPreference({ ...editPreference, ["countryId"]: Array.from(e) })
                          setIsPPEdited("yes")
                          setFilterOnchange(true)
                          formik.setFieldValue("country", Array.from(e));
                          apiCallState(Array.from(e))
                          // props.data.handleChange
                        }}
                        error={formik.errors.country}
                        options={basicSearch?.country}
                      />

                      <div className=" md:grid grid-cols-9 mt-[2rem] ">
                        <div className=" col-span-2 flex items-center ">
                          <p className="   md:text-[12px] lg:text-[14px] text-[#575556] font-semibold   ">
                            Show Profile
                          </p>
                        </div>
                        <div className="col-span-7 mt-[1rem] md:mt-0 ">
                          <div className="flex space-x-3">
                            <div className="flex md:items-start space-x-1 lg:items-center ">
                              <label>
                                <input
                                  id="withPhoto"
                                  name="withPhoto"
                                  onChange={(e) => {
                                    setIsPPEdited("yes")
                                    formik.setFieldValue(
                                      "withPhoto",
                                      formik.values?.withPhoto === "on"
                                        ? ""
                                        : "on"
                                    );
                                  }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.withPhoto}
                                  checked={
                                    formik.values?.withPhoto === "on"
                                      ? true
                                      : false
                                  }
                                />
                              </label>
                              <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                With Photo
                              </p>
                            </div>
                            <div className="flex md:items-start space-x-1 lg:items-center  ">
                              <label>
                                <input
                                  id="withHoroscope"
                                  name="withHoroscope"
                                  onChange={(e) => {
                                    setIsPPEdited("yes")
                                    formik.setFieldValue(
                                      "withHoroscope",
                                      formik.values?.withHoroscope === "on"
                                        ? ""
                                        : "on"
                                    );
                                  }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.withHoroscope}
                                  checked={
                                    formik.values?.withHoroscope === "on"
                                      ? true
                                      : false
                                  }
                                />
                              </label>
                              <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                With Horoscope
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className=" md:grid grid-cols-9 mt-[2rem] ">
                        <div className=" col-span-2 flex  items-baseline ">
                          <p className="   md:text-[12px] lg:text-[14px] text-[#575556] font-semibold   ">
                            Don't show Profile
                          </p>
                        </div>
                        <div className="col-span-7 mt-[1rem] md:mt-0 ">
                          <div className=" grid grid-cols-2 md:flex  md:space-x-7 ">
                            <div className="flex md:items-start space-x-1 lg:items-center  ">
                              <label>
                                <input
                                  id="ignored"
                                  name="ignored"
                                  onChange={(e) => {
                                    setIsPPEdited("yes")
                                    formik.setFieldValue(
                                      "ignored",
                                      formik.values?.ignored === "on" ? "" : "on"
                                    );
                                  }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.ignored}
                                  checked={
                                    formik.values?.ignored === "on"
                                      ? true
                                      : false
                                  }
                                />
                              </label>
                              <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                Rejected
                              </p>
                            </div>
                            <div className="flex md:items-start space-x-1 lg:items-center  ">
                              <label>
                                <input
                                  id="contacted"
                                  name="contacted"
                                  onChange={(e) => {
                                    setIsPPEdited("yes")
                                    formik.setFieldValue(
                                      "contacted",
                                      formik.values?.contacted === "on"
                                        ? ""
                                        : "on"
                                    );
                                  }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.contacted}
                                  checked={
                                    formik.values?.contacted === "on"
                                      ? true
                                      : false
                                  }
                                />
                              </label>
                              <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                Already Contacted
                              </p>
                            </div>
                            <div className="flex md:items-start space-x-1 lg:items-center  ">
                              <label>
                                <input
                                  id="viewed"
                                  name="viewed"
                                  onChange={(e) => {
                                    setIsPPEdited("yes")
                                    formik.setFieldValue(
                                      "viewed",
                                      formik.values?.viewed === "on" ? "" : "on"
                                    );
                                  }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.viewed}
                                  checked={
                                    formik.values?.viewed === "on" ? true : false
                                  }
                                />
                              </label>
                              <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                Viewed Profiles
                              </p>
                            </div>
                            <div className="flex md:items-start space-x-1 lg:items-center  ">
                              <label>
                                <input
                                  id="shortlisted"
                                  name="shortlisted"
                                  onChange={(e) => {
                                    setIsPPEdited("yes")
                                    formik.setFieldValue(
                                      "shortlisted",
                                      formik.values?.shortlisted === "on"
                                        ? ""
                                        : "on"
                                    );
                                  }}
                                  type="checkbox"
                                  className="accent-red-500 border border-[#F18181] "
                                  value={formik.values?.shortlisted}
                                  checked={
                                    formik.values?.shortlisted === "on"
                                      ? true
                                      : false
                                  }
                                />
                              </label>
                              <p className=" text-[#000000] text-[14px] md:text-[11px] lg:text-[14px] pl-2 ">
                                Selected Profiles
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                       onClick={() => 
                        setMenuSelect("")
                        }
                      className="flex justify-end mb-[6rem]  md:mb-[3rem] "
                    >
                      <button
                        type="submit"
                        className=" flex items-center justify-center h-[2rem] md:h-[1.5rem]  text-white bg-[#D10A11] border border-[#D10A11] w-[7rem] md:w-[8rem] whitespace-nowrap rounded-[5px] font-bold  md:py-[1rem] lg:py-5 md:text-[12px] lg:text-[14px]"
                      > 
                         <>Search</>
                    
                       
                      </button>
                    </div> 
                  </form>
                </div>
                :null}
                { activeTab === 2 ?
                <div>
                  <AdvanceSearch viewAllCallback={props.viewAllCallback}/>
                </div>
                :null}
              </div>
            </div>
            <div className=" hidden md:block md:col-span-3 md:px-[2rem] lg:px-[4rem] md:py-[2rem] lg:py-[3rem] ">
                    <img
                      className="cursor-pointer w-[100%]"
                      alt=""
                      src={addBanner?.showphotobanner}

                      onClick={() => {
                        bannerRedirect(addBanner?.name, addBanner?.redirect)
                      }} />
                              </div>

          </div>
          : 
          <center>
            <img src={LoaderGif} className='pt-[9rem] pb-[2rem]' alt="" />
            </center>
          }
        </div>
        <Footer />
        <DownloadAppBar dashboardData={ProfileData} haveBottom={true}/>
        <ViewMessageBottomNav />
      </main>
      : 
      <center>
         <img src={LoaderGif} alt=""/>
      </center>
        }
    </>
  );
};

export default BasicAdvanceSearch;
