import React, { useEffect, useState } from 'react'
// import { LeftOutlined, RightOutlined } from '@ant-design/icons'
// import {Helmet} from "react-helmet";
// import 'antd/dist/antd.css';


import Slider from 'react-slick';
import { loginForAkhayatritiya, loginForViewProfile, loginFromAdminPartialUser, loginFromAdminUser, loginFromEmailUser, loginFromOffline, loginFromSMSUser, loginUser } from "../Store/Login/Action";

//components
import RegisterForm from '../Components/RegisterForm/Form'
import KalyanMatrimonySpecification from '../Components/HomeRegisterContent/Specification';

//Background Image
// import bg1 from '../Images/Mask-Group-23@2x.png'
// import bg2 from '../Images/Group-817.png'
// import responsivebg2 from '../Images/homebg.png'

//Route segment
import RouteSegments from '../Routes/RouteSegment'
import FormPopup from '../Components/HomeRegisterContent/FormPopup';
import ForgetPasswordPopup from '../Components/LoginForm/ForgetPasswordPopup';
import Constants, { EventName } from '../Constants/constants';
import Loader from '../Components/Loader';
import withRouter from '../Components/withRouter';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import $ from 'jquery'
import LoginForm from '../Components/LoginForm/Form'

// import { api } from "../config";
import { LoginVia, SuspendedPopup } from '../Components/CommonPopup';
import { registerDetail } from '../Store/Home/Action';
import { useAnalytics } from '../Hooks/usePageChange';
import FamilyfriedloginPopup from '../Components/Kalyanlite/Popup/FamilyfriedloginPopup';

import MobileFooter from './Footer/MobileFooter';
import MoreLink from '../Components/MoreLink/MoreLink';

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Register = (props) => {
  const navigate = useNavigate();
  const [registerOrLogin, setRegsiterOrLogin] = useState("1")
  const [showLoader, setShowLoader] = useState(false)

  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

  const [searchParams, setSearchParams] = useSearchParams();
  const [showSuspendedPopup, setshowSuspendedPopup] = useState(false)
  const regData = useSelector(state => state?.Home?.rData?.data)
  const { onClickTrack } = useAnalytics()
  const [pageScrool,setPageScrool] = useState(false);
  useEffect(()=>{
    const handleScroll = ()=>{
      setPageScrool(true);
    }
    window.addEventListener('scroll',handleScroll);

    return ()=>{
      window.removeEventListener('scroll',handleScroll);
    }
  })


  useEffect(() => {

    let text = new URL(window.location.href)
    if (text.pathname == "/loginfromsms") {
      let values = {
        profiletoken: searchParams.get("profiletoken"),
        jwt: searchParams.get("jwt"),
        photo: searchParams.get("photo"),
        otherMailFlag: searchParams.get("otherMailFlag"),
        toProfileId: searchParams.get("toProfileId"),
        matching: searchParams.get("matching"),
        editpreferences: searchParams.get("editpreferences"),
        upgrade: searchParams.get("upgrade"),
        horoscope: searchParams.get("horoscope"),
        trust: searchParams.get("trust"),
        manage: searchParams.get("manage"),
        bridexmail: searchParams.get("bridexmail"),
        profileId: searchParams.get("profileId"),
        settings: searchParams.get("settings"),
        inboxunread: searchParams.get("inboxunread"),
        appoinment: searchParams.get("appoinment"),
        fairPolicyFlag: searchParams.get("fairPolicyFlag"),
        muhurat: searchParams.get("muhurat"),
        parentsnumber: searchParams.get("parentsnumber"),
        upgradeFlag: searchParams.get('upgradeFlag'),
        mobileverification: searchParams.get("mobileverification"),
        pp_matches: searchParams.get("pp_matches"),
        replypending: searchParams.get("replypending"),
        notificationId: searchParams.get("notificationId"),
        recommendation: searchParams.get("recommendation"),
        parentsMobileNumber: searchParams.get("parentsMobileNumber"),
        whatsappverified:searchParams.get("whatsappverified"),
        startmessage:searchParams.get("startmessage"),
        iswhatsappClick:searchParams.get("iswhatsappClick"),
        messageId:searchParams.get("messageId"),
        trialpackpopup: searchParams.get("trialpackpopup")
      }
      dispatch(loginFromSMSUser(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    }
    else if (text.pathname == "/loginfromadmin") {
      let values = {
        jwt: searchParams.get("jwt"),
        afterregister: searchParams.get("afterregister")
      }
      dispatch(loginFromAdminUser(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    }
    else if (text.pathname == "/loginfromadminPartial") {
      let values = {
        jwt: searchParams.get("jwt")
      }
      dispatch(loginFromAdminPartialUser(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    }
    else if (text.pathname == "/loginfromemail") {
      let values = {
        photo: searchParams.get("photo"),
        otherMailFlag: searchParams.get("otherMailFlag"),
        fairPolicyFlag: searchParams.get("fairPolicyFlag"),
        jwt: searchParams.get("jwt"),
        toProfileId: searchParams.get("toProfileId"),
        appoinment: searchParams.get("appoinment"),
        matching: searchParams.get("matching"),
        editpreferences: searchParams.get("editpreferences"),
        upgrade: searchParams.get("upgrade"),
        horoscope: searchParams.get("horoscope"),
        trust: searchParams.get("trust"),
        manage: searchParams.get("manage"),
        bridexmail: searchParams.get("bridexmail"),
        profileId: searchParams.get("profileId"),
        settings: searchParams.get("settings"),
        inboxunread: searchParams.get("inboxunread"),
        muhurat: searchParams.get("muhurat"),
        parentsnumber: searchParams.get("parentsnumber"),
        upgradeFlag: searchParams.get('upgradeFlag'),
        email: searchParams.get('email'),
        password: searchParams.get('password'),
        profileRegisterFrom: searchParams.get('profileRegisterFrom')
      }
      dispatch(loginFromEmailUser(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    }
    else if (text.pathname == "/akhayatritiya") {
      let values = {
        profileId: searchParams.get("profileId") ? searchParams.get("profileId") : localStorage.getItem(Constants.loginLocalStorageKeys.profileId),
        mobileNumber: searchParams.get("mobileNumber"),
        sentFrom: searchParams.get("sentFrom")
      }
      dispatch(loginForAkhayatritiya(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    }else if (text.pathname == "/offline-profile") {
      let values = {
        jwt: searchParams.get("jwt"),
        matching: searchParams.get("matching"),
        mobilenumber: searchParams.get("mobilenumber"),
        profiletoken: searchParams.get("profiletoken"),
        toProfileId: searchParams.get("toProfileId"),
        offlinepack: searchParams.get("offlinepack"),
        loginfrom: searchParams.get("offlinepack") == "Y" ? localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice) : "",

      }
      dispatch(loginFromSMSUser(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    }
    // else if (text.pathname == "/TrialPack") {
    //   let values = {
    //     jwt: searchParams.get("jwt"),
    //     profiletoken: searchParams.get("profiletoken"),
    //     notificationId: searchParams.get("notificationId"),
    //     trialpackpopup: searchParams.get("trialpackpopup") == "Y" ,

    //   }
    //   dispatch(loginFromSMSUser(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    // }
    // else if (text.pathname == "/") {
    //   let values = {
    //     profileid: searchParams.get("profileid"),
    //     action: searchParams.get("action"),
    //   }
    //   dispatch(loginForViewProfile(values, props.router.navigate, window.location.pathname.replace(/^\/([^\/]*).*$/, '$1')))
    // }

  }, [searchParams])

  let { responseData } = useSelector(state => ({
    responseData: state.Login?.adminLoginData?.data
  }));

  console.log(responseData, "responseData");

  useEffect(() => {
    $('#name,#password').bind("cut copy paste", function (e) {
      e.preventDefault();
    });
    window.history.pushState(null, null, window.location.href);
  })


  useEffect(() => {

  }, [props.state], [props?.popup])

  const dispatch = useDispatch();



  const [showHidePassword, changeShowHidePassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [showFamilyPopup, setShowFamilyPopup] = useState(false)
  const [isChecked, setIsChecked] = useState(localStorage.getItem("checkbox") == "true" ? true : false)
  
  
  useEffect(() => {

    if(isChecked){
      localStorage.setItem("checkbox",true);
    }else{
      localStorage.setItem("checkbox",false);
    }
    
  }, [isChecked]);


  const onFamilyFriendPopupClick = () => {
    setShowFamilyPopup(true)
}

  useEffect(() => {
    dispatch({ type: "CLEAR_STORE" })
    dispatch(registerDetail());

  }, []);



  //Error msg for Login form
  const formik = useFormik({
    initialValues: {
      name: localStorage.getItem("email") ? localStorage.getItem("email") : '',
      password: localStorage.getItem("pass") ? localStorage.getItem("pass") : ''
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required('Please enter email or mobile no'),
      password: Yup.string()
        .required('Please enter password'),
    }),
    onSubmit: (values) => {
      LoginFormSubmission(values);
    }
  })

  // form submit
  const LoginFormSubmission = (values) => {
    setShowLoader(true)
    //console.log(values);
    // //console.log('before props', props.router.navigate);
    // return false;
    if (values.name !== "" && values.password !== "") {
      values.profileid = searchParams.get("profileid")
      values.action = searchParams.get("action")
      values.offlinepack = searchParams.get("offlinepack")
      dispatch(loginUser(values, props.router.navigate))
      localStorage.setItem(Constants.loginLocalStorageKeys.isFrom, "login");
    }
    const rmCheck = document.getElementById("rememberMe"),
      emailInput = document.getElementById("name"),
      passwordd = document.getElementById("password");

    if (isChecked  && values.name !== "" && values.password !== "") {
      localStorage.setItem("checkbox", isChecked);
      localStorage.setItem("email", values.name);
      localStorage.setItem("pass", values.password);
    } else {
      localStorage.setItem("email", "");
      localStorage.setItem("pass", "");
      localStorage.setItem("checkbox", "");
    }
  }


  useEffect(() => {
    const rmCheck = document.getElementById("rememberMe"),
      emailInput = document.getElementById("name"),
      passwordd = document.getElementById("password");
    if (localStorage.getItem("checkbox") == true) {
      //console.log("true rememberme")
      rmCheck.setAttribute("checked", "checked");
      emailInput.value = localStorage.getItem("email");
      passwordd.value = localStorage.getItem("pass");
    }
  }, [])

  let { error } = useSelector(state => ({
    error: state.Login?.adminLoginData?.data
  }));

  console.log(error, "errorr");

  useEffect(() => {
    if (error?.status === "FAILURE" && error?.title === "Your Profile is suspended due to the following reason") {
      setshowSuspendedPopup(true)
      setErrorMessage("")
    } else {
      // setshowSuspendedPopup(false)
      setErrorMessage(error?.message)
    } setShowLoader(false)
  }, [error])

  useEffect(() => {
    setErrorMessage("")
  }, [formik.values])


  var sliderImage = [
    {
      id: 1,
      img: "https://km-horoscope-response.s3.us-west-2.amazonaws.com/resources/dist1/images/slider_1.jpg",
      text: "Register now to find your special someone."

    },
    {
      id: 2,
      img: "https://km-horoscope-response.s3.us-west-2.amazonaws.com/resources/dist1/images/slider_3.jpg",
      text: "Come find the one you want to spend the rest of your life with."
    },
    {
      id: 3,
      img: "https://km-horoscope-response.s3.us-west-2.amazonaws.com/resources/dist1/images/slider_2.jpg",
      text: "We simplify your search for the perfect match."
    }
  ]
  var settings = {
    dots: false,
    infinite: true,
    cssEase: "linear",
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  useEffect(() => {

    $('head').append(`<link  class="canonical" rel="canonical" href="https://www.kalyanmatrimony.com" />`);


    return () => {
      $('.canonical').remove()
    }
  }, [])


  const scriptToInject = `
  <div class="pixelScript">
  <meta class="pixelScript" http-equiv="X-UA-Compatible" content="IE=edge,chrome=1" />
  <meta class="pixelScript" name="viewport" content="width=device-width, initial-scale=1" />
  <meta class="pixelScript" name="author" content="Rage 5.0" />
  <meta class="pixelScript" name="copyright" content="&copy; Project 2017" />
  <meta class="pixelScript" itemprop="name" content="Kalyanmatrimony.com"/>
  <meta class="pixelScript" itemprop="url" content="https://www.kalyanmatrimony.com/"/> 
  <meta class="pixelScript" itemprop="telephone" content="044 4014 6969"/>
  <meta class="pixelScript" itemprop="email" content="info@kalyanmatrimony.com"/>
  <meta class="pixelScript"  name="description" content="Find Lakhs of Indian brides and grooms on kalyanmatrimony.com. Register now to find the right life partner!"/>
  <meta class="pixelScript" name="keywords" content="matrimonial websites, Marriage, match making, free matrimonial sites, matrimonial for tamil, matrimony in tamilnadu, kerala bride, tamil brides, telugu brides, kerala grooms, telugu grooms, tamil grooms, matrimony sites in Kerala, matrimonial for kerala, matrimony for malayalam, matrimony for kerala christian, matrimony for tamil christian, matrimonial for kerala, matrimony for christian, matrimony for muslim, matrimony for nair, matrimony for brahmin, matrimony for ezhava, matrimony in kerala, matrimony for nadar, matrimony for iyer, matrimony for yadav, matrimony for jain, matrimony for iyengar, matrimony for sikh, matrimony for Adidravidar, matrimony for telugu, matrimony for reddy, matrimony for naidu" />
  <meta class="pixelScript" name="abstract" content="Kalyan Matrimony is a matrimonial website that uses intelligent matchmaking technology to help you find the most compatible match. Register Free." />
  <meta class="pixelScript" name="facebook-domain-verification" content="kkp6u82qh7hma8gu1w6fsgibwzrmir" />
  <meta class="pixelScript" name="facebook-domain-verification" content="2yhwv3rnals6sgsyivv9izs7lwumo3" />

  <!-- Google tag (gtag.js) -->
  <script class="pixelScript" defer src="https://www.googletagmanager.com/gtag/js?id=AW-875555676"></script>
  <script class="pixelScript" defer>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', 'AW-875555676');
  </script>

<!-- Google tag (gtag.js) -->
<script class="pixelScript" defer src="https://www.googletagmanager.com/gtag/js?id=G-G4GYGWS9XB"></script>
<script class="pixelScript" defer> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-G4GYGWS9XB'); </script>

<!-- Meta Pixel Code -->
<script class="pixelScript" defer>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '276119551481259');
fbq('track', 'PageView');
</script>
<noscript class="pixelScript"><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=276119551481259&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->


<!-- Meta Pixel Code -->
<script defer>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '1522870085153048');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=1522870085153048&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->


<!-- Meta Pixel Code -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '757518326483604');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=757518326483604&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->


<!-- Meta Pixel Code -->
<script>
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '398290656299866');
fbq('track', 'PageView');
</script>
<noscript><img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=398290656299866&ev=PageView&noscript=1"
/></noscript>
<!-- End Meta Pixel Code -->


<script>
  (function (w, d, s, l, i) {
      w['scSdkId'] = i;
      w[l] = w[l] || []
      w.scq = function (eventName, eventType, p) {
      var props = p || {}
        w[l].push({ eventName: eventName, eventType: eventType, meta: props, eventFireTs: Date.now() })
      };
      w.scq("PAGE_VIEW", "AUTO", {
        pageUrl: w.location.href
      });
      var scr = d.createElement(s);
      scr.type = 'text/javascript';
      scr.async = true;
      scr.src = 'https://sc-events-sdk.sharechat.com/web-sdk.js';
      var x = d.getElementsByTagName(s)[0];
      x.parentNode.insertBefore(scr, x);
    })(window, document, "script", "scLayer", "k0EyRoALq8");
</script>



</div>
`


  useEffect(() => {
    if(isCommunitySite){
      //no script for this community site
    }else{
      if ($('.pixelScript').length > 0) {
        $('.pixelScript').remove()
        $('head').append(scriptToInject)
      } else {
        $('head').append(scriptToInject)
      }
    }
    return () => {
      $('.pixelScript').remove()
    }
  }, [])



  return (
    <div>
      {/* <InjectScript script={scriptToInject} /> */}
      {showFamilyPopup &&

<FamilyfriedloginPopup
    close={() => setShowFamilyPopup(false)}

    title={"FAMILY/FRIEND LOGIN"} />
}
      {props?.from == "admin" ?
        responseData?.status == 'alreadyloggedin' || error?.status == "failure" ?
          <LoginVia
            close={() => {
              navigate("/")
            }}
            // title={error.title}
            status={responseData.status}
          />
          :
          <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />
        :
        <main>
          {
            showSuspendedPopup ?
              <SuspendedPopup
                close={() => {
                  setshowSuspendedPopup(!showSuspendedPopup)
                }}
                title={error.title}
                content={error.reason}
              />

                    : <></>
            }
        {/* <div style={{
          width: "100% !important",
            backgroundImage: `url(Assets/Images/marriage_pic.jpg)`,
          backgroundPosition: "initial",
          backgroundRepeat: "round",
          backgroundAttachment: "fixed",

          }}
        > */}
        <div className="backgroundimage">
            {/* <div className=" bg-opacityblack ">
            <div className=" flex justify-between  space-x-3  pt-[5rem] px-[1.4rem] md:hidden ">
              <div className="  relative  mt-[1.3rem] md:hidden ">

                <input type="text" id="search-navbar" className="placeholder-[#575556] text-[8px] font-semibold border border-[#F18181]  block p-2 pr-[3rem]  md:pr-0 lg:pr-[4.75rem] w-[100%] md:w-[90%]  rounded-md sm:text-sm " placeholder="e-mail ID / Mobile Number " />
                <div className="flex mt-2">
                  <label>
                    <input type="checkbox" className="accent-red-500 border border-[#F18181] " checked />
                  </label>
                  <p className=" text-[#FFFFFF] md:text-[#8F8F8F] text-[11px] md:text-[11px] lg:text-[14px] pl-2 "> Remember me</p>
                </div>
              </div>

              <div className=" relative  mt-[1.3rem] md:hidden ">

                <input type="text" id="search-navbar" className="placeholder-[#575556] font-semibold border border-[#F18181] text-[8px]  block p-2 pr-[3rem]  md:pr-0 lg:pr-[3.75rem]  w-[100%] md:w-[90%]  rounded-md sm:text-sm " placeholder="Password" />
                <p className="mt-2 text-[#FFFFFF] md:text-[#8F8F8F] text-[11px] md:text-[11px] lg:text-[14px] "> Forgot Password? </p>

              </div>
            </div>
            <div className=" flex justify-center pb-[1rem] md:hidden ">
              <button className=" flex items-center h-[1.5rem] font-bold text-white bg-[#D10A11] px-[1rem] whitespace-nowrap  rounded-lg py-2 text-[9px] md:hidden ">
                LOG IN
              </button>
            </div>
          </div> */}


            {showLoader && <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" />}
            <div className='md:hidden bg-opacityblack'>

              <div className="  space-x-3  pt-[5rem] px-[1.4rem] md:hidden ">
                <div className='absolute right-[2rem]'>
                  <a onClick={() => {
                    onFamilyFriendPopupClick();
                  }}
                    className='underline text-[#D10A11] text-[11px] cursor-pointer'>Login as Family Member/ Friend</a>
                </div>

                <>
                  <form className=' pt-1 md:pt-0' onSubmit={formik.handleSubmit}>

                    <>
                      <div className="relative  mt-[1.3rem] md:hidden">
                        <div className="  ">
                          {/* Email or phone no */}
                          <div className='flex justify-between'>
                            <div className="space-y-1 mt-[0rem]">

                              <label className="relative block" >

                                <input
                                  id="name"
                                  name="name"
                                  type="text"
                                  autoComplete="off"
                                  value={formik.values.name}
                                  onChange={formik.handleChange}
                                  placeholder="e-mail ID / Mobile Number"
                                  // className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-[0.3rem] lg:py-2 md:px-2  w-full block " 
                                  className='placeholder-[#575556] text-[8px] font-semibold border border-[#F18181]  block p-2 pr-[3rem]  md:pr-0 lg:pr-[4.75rem] w-[100%] md:w-[90%]  rounded-md sm:text-sm '
                                />
                              </label>

                              <div className='flex'>

                                <label className=''>
                                  <input type="checkbox" className=" border-[#F18181]  focus:ring-red-200 rounded-[3px]  " id="rememberMe" value="lsRememberMe" onClick={()=>setIsChecked(!isChecked)} checked={isChecked}/>
                                </label>
                                <p className=" text-[#FFFFFF] md:text-[#8F8F8F] text-[11px] md:text-[11px] lg:text-[14px] pl-2 "> Keep me logged in</p>
                              </div>
                            </div>
                            {/* Password */}


                            <div>

                              <label className="relative flex justify-end	mbl-forget_passwort">
                                <input id="password" name="password" autoComplete="off"
                                  placeholder="Password"
                                  value={formik.values.password}
                                  onChange={formik.handleChange}
                                  type={showHidePassword ? "text" : "password"}
                                  // className="w-[14rem] form-input shadow-sm  bg-[#FFFFFF] text-[#000000] text-[13px] focus:outline-none rounded-[5px] focus:ring-[#2D2C6F] border border-[#F18181] py-1 px-1 md:py-[0.3rem] md:px-2  lg:py-2 w-full block "
                                  className='className="placeholder-[#575556] font-semibold border border-[#F18181] text-[8px]  block p-2 pr-[3rem]  md:pr-0 lg:pr-[3.75rem]  w-[100%] md:w-[90%]  rounded-md sm:text-sm "'
                                />
                                <div className="flex absolute inset-y-0 m-[0.6rem] pointer-events-auto">
                                  <img onClick={() => changeShowHidePassword(!showHidePassword)}
                                    src={showHidePassword ? ("/Assets/Images/passwordeyeopen.png") : ("/Assets/Images/passwordeye.svg")}
                                    className=" w-[1rem] h-[.8rem] md:w-[1rem] md:h-[1rem] lg:w-5 lg:h-5" alt="" />
                                </div>
                              </label>
                            </div>


                          </div>
                          <div className='flex justify-between'>

                            {errorMessage ?
                              <div className=" text-[red] text-[9px] md:text-[red] md:text-[12px] h-[1rem]">{errorMessage}</div> :
                              formik.errors.name && formik.touched.name ?
                                <div className="text-[red] text-[9px] md:text-[red] md:text-[12px] h-[1rem]">{formik.errors.name}</div>
                                :
                                <div className="text-[red] text-[9px] md:text-[red] md:text-[12px] h-[1rem]"></div   >
                            }

                            {formik.errors.password && formik.touched.password ?
                              <p className="text-[red] text-[9px] md:text-[red] md:text-[12px] h-[1rem] absolute right-[4rem]">{formik.errors.password}</p>
                              : <div className="text-[red] text-[9px] md:text-[red] md:text-[12px] h-[1rem] absolute right-[4rem]"></div   >
                            }

                          </div>
                          {/* Login Button      */}
                          <div className='flex justify-center pb-[1rem] md:hidden space-y-1 mt-[0.9rem]'>
                            <div>
                              <label className="" >
                                <button type="submit"
                                  className="flex items-center h-[1.5rem] font-bold text-white bg-[#D10A11] px-[1rem] whitespace-nowrap  rounded-lg py-2 text-[9px] md:hidden">
                                  LOG IN
                                </button>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                    </>
                  </form>



                  <div className="absolute left-[12rem] top-[12.7rem] flex  md:text-[#8F8F8F] text-[11px] md:text-[11px] lg:text-[14px] pl-2 mbl-forget-password">

                    <ForgetPasswordPopup />

                  </div>

                </>
              </div>
            </div>



          </div>
          <div className='hidden md:block'>
            <Slider {...settings} className='home-page-slider'>
              {sliderImage?.map((image, i) => (
                <div
                  className=""
                >
                  <div key={i} className=''
                    style={{
                      width: "100% !important",
                      backgroundImage: `url(${image.img}) `,
                      backgroundPosition: "initial",
                      // backgroundRepeat: "round",
                      backgroundAttachment: "fixed",
                      backgroundSize: 'cover',
                      // backgroundRepeat: 'noRepeat',

                    }}>

                    <div className="hidden md:grid md:grid-cols-9 lg:grid lg:grid-cols-6 bg-opacityblack ">
                      <div className=" mt-[6rem] mb-[1rem] md:col-span-5 lg:col-span-4  lg:mt-[9rem]">
                        <div className=" md:pl-[1rem] lg:pl-0 ">
                          {image.id === 1 ?
                            <p className="md:w-[16rem] lg:w-[34rem] xl:w-[55rem] text-white drop-shadow-md font-[900] md:text-[40px] lg:text-[2.67rem] text-center 2xl:w-[40rem] 2xl:h-[10rem]" >

                              {image.text}
                            </p>
                            :
                            image.id === 3 ?
                              <p className="md:w-[18rem] lg:w-[34rem] xl:w-[55rem] text-white drop-shadow-md font-[900] md:text-[40px] lg:text-[41px] text-center 2xl:w-[40rem] 2xl:h-[10rem]" >

                                {image.text}
                              </p>
                              : <p className="md:w-[20rem] lg:w-[34rem] xl:w-[55rem] text-white drop-shadow-md font-[900] md:text-[40px] lg:text-[41px] text-center 2xl:w-[40rem] 2xl:h-[10rem]" >

                                {image.text}
                              </p>

                          }
                        </div><br /><br /><br />
                        <KalyanMatrimonySpecification page="homeRegister" />
                        <br />
                      </div>
                      <div className=" md:col-span-4 lg:col-span-2  my-[0.8rem] ">


                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>

            <div className="hidden md:block  md:pr-[0.1rem] lg:pr-[1rem] absolute md:top-[8rem] lg:top-[9.5rem] md:ml-[53%] lg:ml-[57%] xl:ml-[65%] ">
              <div className={registerOrLogin == "1" ? `bg-[#DDD8D3] md:px-[0.5rem] lg:px-[2rem] rounded-t-[10px] rounded-b-[10px] md:w-[98.3%] lg:w-[82%] ` :
                `bg-[#DDD8D3] md:px-[0.5rem] lg:px-[2rem] rounded-t-[10px] rounded-b-[10px] w-[100%]`}>
                <div className="space-y-4 ">
                  <div className="flex justify-center space-x-4 pt-[1.25rem]   lg:pt-[1rem] ">
                    <div className=" flex justify-center ">
                      <button onClick={() => { setRegsiterOrLogin("1"); onClickTrack(EventName.Home_page_Register_Register_Button) }} className={classNames(" flex items-center md:h-[1.5rem] lg:h-[2.5rem] font-bold text-white  w-[10rem] justify-center whitespace-nowrap  rounded-tl-lg rounded-br-lg lg:py-2 md:text-[12px] lg:text-[13px]  "
                        , registerOrLogin == "1" ? "bg-[#D10A11]" : "bg-[#575556]")}>
                        REGISTER
                      </button>
                    </div>
                    <div className=" flex justify-center ">
                      <button onClick={() => { setRegsiterOrLogin("2"); onClickTrack(EventName.Home_page_Signin_Signin_Field) }} className={classNames(" flex items-center md:h-[1.5rem] lg:h-[2.5rem] font-bold text-white  w-[10rem] justify-center whitespace-nowrap rounded-tl-lg rounded-br-lg lg:py-2 md:text-[12px] lg:text-[13px]  "
                        , registerOrLogin == "2" ? "bg-[#D10A11]" : "bg-[#575556]")}>
                        SIGN IN
                      </button>
                    </div>
                  </div>
                  <div className=''>


                    {registerOrLogin == "1" ?
                      <RegisterForm utmsource="" />
                      : <LoginForm menu="signup" />
                    }
                  </div>

                </div>
              </div>
            </div>

          </div>
          <div className="md:hidden block mx-5">
            <div className={registerOrLogin == "1" ? `md:px-[0.5rem] lg:px-[2rem] rounded-t-[10px] rounded-b-[10px] md:w-[98.3%] lg:w-[82%]` :
              ` md:px-[0.5rem] lg:px-[2rem] rounded-t-[10px] rounded-b-[10px] w-[100%]`}>
              <div className="space-y-4 ">
                <div className="flex justify-center space-x-4 pt-[1.25rem]    ">
                  <div className=" flex justify-center ">
                    <button onClick={() => setRegsiterOrLogin("1")} className={classNames(" flex items-center md:h-[1.5rem] lg:h-[2.5rem] font-bold text-white  w-[10rem] justify-center whitespace-nowrap  rounded-tl-lg rounded-br-lg py-1 lg:py-2 md:text-[12px] lg:text-[13px]  "
                      , registerOrLogin == "1" ? "bg-[#D10A11]" : "bg-[#575556]")}>
                      REGISTER
                    </button>
                  </div>
                  <div className=" flex justify-center ">
                    <button onClick={() => setRegsiterOrLogin("2")} className={classNames(" flex items-center md:h-[1.5rem] lg:h-[2.5rem] font-bold text-white  w-[10rem] justify-center whitespace-nowrap rounded-tl-lg rounded-br-lg py-1 lg:py-2 md:text-[12px] lg:text-[13px]  "
                      , registerOrLogin == "2" ? "bg-[#D10A11]" : "bg-[#575556]")}>
                      SIGN IN
                    </button>
                  </div>
                </div>
                <div className=''>


                  {registerOrLogin == "1" ?
                    <RegisterForm utmsource="" />
                      : <LoginForm menu="signup" />
                  }
                </div>

              </div>
            </div>
          </div>

          <div className=" text-[#575556] p-[1.25rem] md:p-[2.25rem] lg:p-[3.25rem] text-left">
            <p className=" font-bold text-[19px] md:text-[23px] lg:text-[30px]">Find Lakhs of Indian Brides & Grooms in Kalyan Matrimony</p>
            <div className=" flex justify-between ">
              <div className=" pr-[3rem] ">
                <p className=" mt-[1.75rem] md:mt-[1.25rem] lg:mt-[1.75rem] font-semibold text-[12px] md:text-[14px] lg:text-[17px]">
                  Our name, backed by the trusted mega brand Kalyan Jewellers, carries with it our defining history and rich legacy of success and good fortune. Come over to make an auspicious and blessed new beginning to your life partner search with kalyanmatrimony.com.
                </p>
                <p className="  mt-[1.75rem] md:mt-[1.25rem] lg:mt-[1.75rem] font-semibold text-[12px] md:text-[14px] lg:text-[17px]">
                  At Kalyan Matrimony you will find lakhs of profiles carefully sourced from across the globe and with rich information.
                </p>
                <p className="  mt-[1.75rem] md:mt-[1.25rem] lg:mt-[1.75rem] font-semibold text-[12px] md:text-[14px] lg:text-[17px] ">
                  Go ahead ! Click the register button and start searching. Our trust - proofed and most intuitive register - verify - validate - match and connect approach is just what you need to find your life partner.
                </p>
                <p className="  mt-[1.75rem] md:mt-[1.25rem] lg:mt-[1.75rem] font-semibold text-[12px] md:text-[14px] lg:text-[17px]">
                  Register free now, complete your profile information, do not forget to upload your photo and horoscope, search and contact the most compatible Brides or Grooms.
                </p>
              </div>
              {/* <div className=''> */}


                {/* {registerOrLogin == "1" ?
                  <RegisterForm utmsource="" />
                    :<LoginForm menu="signup" />
                } */}
              <div className=" flex justify-center items-center ">
                <img className=" lg:w-[32rem]" src={require("../Images/Group 816.svg").default} alt="" width="400" height="400"/>
              </div>
            {/* </div> */}
          </div>
          {/* <!-- banner -2 --> */}
          <div className=" px-[0.1rem] md:pl-[1rem] lg:px-[2rem] pt-[2rem] pb-[1rem] text-left deserveBackground"
          >

            <p className=" pl-[1.2rem] md:pl-[1.5rem] lg:pl-[2.25rem]  text-white drop-shadow-md font-bold text-[15px] md:text-[26px] lg:text-[30px] "> You Deserve A Kalyan Matrimony Experience</p>
            {/* <!-- <img src={require("../Images/Group 817@2x.png" alt=""> --> */}
            <div className=" grid grid-cols-12">
              <div className=" col-span-4 pl-[1rem] md:pl-[1.5rem] lg:pl-[2.25rem] ">
                <div className=" pt-[1.5rem] md:pt-[0.5rem] lg:pt-[1.5rem] ">
                  <p className=" text-white drop-shadow-md font-bold text-[10px] md:text-[15px] lg:text-[17px]">
                    It is not how many you meet but who you meet. And we believe in giving you the best start.
                  </p>

                </div>
                <div className=" mt-[1rem] flex items-center justify-center ">
                  <FormPopup />
                  {/* <ForgetPasswordPopup /> */}
                </div>
              </div>
              <div className="col-span-8 md:col-span-5 lg:pl-[2rem]">
                <div className="hidden md:block space-y-4 pt-[1.5rem] ] ">
                  <div className=" flex   ">

                    <div className=" flex justify-center">
                      <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                    </div>
                    <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]  pl-[0.5rem] "

                    >
                      Fits your busy lifestyle
                    </p>
                  </div>
                  <div className=" flex   ">
                    <div className=" flex justify-center">
                      <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                    </div>
                    <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]  pl-[0.5rem] "

                    >
                      Match reviews across multiple criteria
                    </p>
                  </div>
                  <div className=" flex   ">
                    <div className=" flex justify-center">
                      <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                    </div>
                    <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]   pl-[0.5rem] "

                    >
                      Phone-verified matches
                    </p>
                  </div>
                  <div className=" flex   ">
                    <div className=" flex justify-center">
                      <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                    </div>
                    <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]   pl-[0.5rem] "

                    >
                      Intelligent matchmaking
                    </p>
                  </div>
                  <div className=" flex   ">
                    <div className=" flex justify-center">
                      <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                    </div>
                    <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]   pl-[0.5rem] "

                    >
                      All interactions are private, secure and confidential
                    </p>
                  </div>
                  <div className=" flex   ">
                    <div className=" flex justify-center">
                      <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                    </div>
                    <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]   pl-[0.5rem] "
                    >
                      An experienced team backed by technology works with you
                    </p>
                  </div>
                </div>

                <div className="md:hidden space-y-4 pt-[1.5rem] ] ">
                  <div className=" flex   ">
                    <div className='grid grid-cols-5 '>
                      <div className=" ">
                        <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                      </div>
                      <div className='col-span-4 '>
                        <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]  pl-[0.5rem] md:pl-0 " >
                          Fits your busy lifestyle
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" flex   ">
                    <div className=" grid grid-cols-8">
                      <div>
                        <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                      </div>

                      <div className='col-span-6 '>
                        <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]  pl-[0.5rem] md:pl-0 "

                        >
                          Match reviews across multiple criteria
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" flex   ">
                    <div className=" grid grid-cols-5">
                      <div>
                        <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                      </div>

                      <div className='col-span-4 '>
                        <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]   pl-[0.5rem] md:pl-0 "

                        >
                          Phone-verified matches
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" flex   ">
                    <div className=" grid grid-cols-5">
                      <div>
                        <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                      </div>

                      <div className='col-span-4 '>
                        <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]   pl-[0.5rem] md:pl-0 "

                        >
                          Intelligent matchmaking
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" flex   ">
                    <div className=" grid grid-cols-8 ">
                      <div>
                        <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                      </div>

                      <div className='col-span-5'>
                        <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]   pl-[0.5rem] md:pl-0 "

                        >
                          All interactions are private, secure and confidential
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className=" flex   ">
                    <div className=" grid grid-cols-8">
                      <div>
                        <img className="w-[2rem]" src={require("../Images/Group 818.svg").default} alt="" />
                      </div>

                      <div className='col-span-6 '>
                        <p className="  text-white drop-shadow-md font-bold text-[12px] md:text-[15px] lg:text-[17px]   pl-[0.5rem] md:pl-0 "
                        >
                          An experienced team backed by technology works with you
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='hidden md:block md:col-span-3'></div>

            </div>

          </div>
          <div className=" text-[#575556] p-[2rem] space-y-3 md:space-y-0 md:p-[2.25rem] lg:p-[3.25rem]">
            <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 lg:space-x-6 ">
              <div className=" md:col-span-1 lg:col-span-1 border-b-[1px] md:border-b-[0px] md:border-r-[1px] border-[#00000029] px-[10px] mt-[1rem] md:mt-0 ">
                <div className="flex  items-center">
                  <img src={require("../Images/Group 824.svg").default} alt="" width="80" height="90" />

                  <p className="text-[#D2951B] pl-[0.8rem] text-[18px] font-bold " >
                    ABOUT KALYAN
                    <br />
                    MATRIMONY
                  </p>

                </div>
                <p className=" font-semibold text-[14px] mt-3 md:h-[4rem]  " >
                  Launched in 2016, by the Kalyan Jewellers Group, Kalyan Matrimony (formerly Sanskriti Matrimony) carries with...
                </p>
                {/* <!-- <a>
                                <img className="h-[24px] w-[2px] mt-1 " src= {require("../Images/ " />
                            </a> --> */}
                <div className="flex items-center mb-[1.5rem] md:mb-0 mt-5 ">
                  <img src={require("../Images/Polygon 4.svg").default} alt="" />
                  <p className=" pl-2  font-bold text-[14px]" ><a onClick={() => onClickTrack(EventName.Home_page_About_Kalyan_Matrimony)} href={RouteSegments.ABOUT_US} target="_blank">Know more</a></p>
                </div>
                {/* <div className="md:hidden flex items-center mb-[1.5rem] md:mb-0 mt-5 ">
                <img src={require("../Images/Polygon 4.svg").default} alt="" />
                <p className=" pl-2  font-bold text-[14px]" ><a href={RouteSegments.MOBILE_ABOUT_US} target="_blank">Know more</a></p>
              </div> */}
              </div>
              <div className=" md:col-span-1 lg:col-span-1 border-b-[1px] md:border-b-[0px] lg:border-r-[1px] lg:border-[#00000029] px-[10px] mt-[1rem] md:mt-0 ">
                <div className="flex  items-center">
                  <img src={require("../Images/Group 828.svg").default} alt="" width="80" height="90"/>
                  <span className="text-[#D2951B] pl-[0.8rem] text-[18px] font-bold " >
                    SAFETY AND
                    <br /> SECURITY
                  </span>
                </div>
                <p className=" font-semibold text-[14px] mt-3 md:h-[4rem] " >
                  We do our best to keep the fake profiles out. But for your own safety, please follow the following tips.
                </p>
                <div className=" flex items-center mt-5 mb-[1.5rem] md:mb-0  ">
                  <img src={require("../Images/Polygon 4.svg").default} alt="" />
                  <p className=" pl-2  font-bold text-[14px]" ><a onClick={() => onClickTrack(EventName.Home_page_Safety_Security)} href={RouteSegments.SAFETY_AND_SECURITY} target="_blank">Know more</a></p>
                </div>
              </div>
              <div className=" md:col-span-1 mt-[1rem] lg:mt-0 lg:col-span-1 border-b-[1px] md:border-b-[0px] md:border-r-[1px] border-[#00000029]  px-[10px]">
                <div className="flex  items-center">
                  <img src={require("../Images/Group 829.svg").default} alt="" width="80" height="90"/>
                  <span className="text-[#D2951B] pl-[0.8rem] text-[18px] font-bold " >
                    HOW IT
                    <br />
                    WORKS
                  </span>
                </div>
                <p className=" font-semibold text-[14px] mt-3 md:h-[4rem] " >
                  We do our best to keep the fake profiles out. But for your own safety, please follow the following tips.
                </p>
                <div className=" flex items-center mt-5 mb-[1.5rem] md:mb-0  ">
                  <img src={require("../Images/Polygon 4.svg").default} alt="" />
                  <p className=" pl-2  font-bold text-[14px]"><a onClick={() => onClickTrack(EventName.Home_page_How_it_Works)} href={RouteSegments.HOW_IT_WORKS} target="_blank">Know more</a></p>
                </div>
              </div>
              <div className=" md:col-span-1 mt-[1rem]  lg:mt-0 lg:col-span-1 px-[10px] ">
                <div className="flex  items-center">
                  <img src={require("../Images/kalyan_logo.svg").default} alt="" />
                  <span className="text-[#D2951B] pl-[0.8rem] text-[18px] font-bold " >
                    ABOUT KALYAN
                    <br />
                    JEWELLERS
                  </span>
                </div>
                <p className="font-semibold text-[14px] mt-3 md:h-[4rem] " >
                  We are one of the oldest business families in India with a family legacy of 109 years in business. Started for the noble....
                </p>
                <div className=" flex items-center mt-5 mb-[1.5rem] md:mb-0  ">
                  <img src={require("../Images/Polygon 4.svg").default} alt="" />
                  <p className="pl-2 font-bold text-[14px]" ><a onClick={() => onClickTrack(EventName.Home_page_About_Kalyan_Jewellers)} href={RouteSegments.ABOUT_KALYAN_JEWELLERS} target="_blank">Know more</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {pageScrool &&
        <MoreLink subMenu="morelink" />
        }
          <MobileFooter />
      </main>
    }
    </div>
  )
}

export default withRouter(Register)