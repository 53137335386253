import Constants from "../../Constants/constants";

/**
 * The function `getMatchProfileById` retrieves a match profile from the state object based on the user
 * ID.
 * @param state - The `state` parameter is an object that represents the current state of the
 * application. It typically contains various properties and values that store the data and state of
 * the application. In this case, it seems to be related to a specific feature or module called
 * "KalyanLite".
 * @returns the match profile object from the state based on the profile ID obtained from the current
 * match data.
 */
export const getMatchProfileById = (state) => {
    const userId = state.KalyanLite?.currentMatch?.data?.profileid;
    return state.KalyanLite?.matchProfiles[userId]
}

/**
 * The function `getRecommendedMatches` returns an object containing various properties related to
 * recommended matches, extracted from the given `state` object.
 * @param state - The `state` parameter is an object that represents the current state of the
 * application. It contains various properties and values that store the data and state of different
 * parts of the application. In this case, it seems to be related to a module or feature called
 * "KalyanLite" and its matches
 * @returns an object with the following properties:
 */
export const getRecommendedMatches = (state) => {
    return {
        currentMatch: state?.KalyanLite?.currentMatch,
        initialMatchFetching: state?.KalyanLite?.initialMatch?.isFetching,
        initialMatchData: state?.KalyanLite?.initialMatch?.data,
        isLoadmoreFetching: state?.KalyanLite?.initialMatch?.isLoadmoreFetching,
        initialMatchPagination: state?.KalyanLite?.initialMatch?.pagination,
        hasMoreInitialMatch: state?.KalyanLite?.initialMatch?.hasMoreData,
        initialMatchContentCardData: state?.KalyanLite?.initialMatch?.contentCardData,
        initialMatchBanner: state?.KalyanLite?.initialMatch?.banner,
        initialCastedata : state?.KalyanLite?.initialMatch?.data?.castedata,
        initialSubcastedata : state?.KalyanLite?.initialMatch?.data?.subcastedata,
        initialShowcastemigration : state?.KalyanLite?.initialMatch?.data?.data?.showcastemigration
    }
}

/**
 * The function `getSelectedMatches` returns an object containing various properties related to
 * fetching and storing selected matches data from the state.
 * @param state - The `state` parameter is the Redux state object. It contains the current state of the
 * application, including the data stored in the Redux store.
 * @returns an object with the following properties:
 */
export const getSelectedMatches = (state) => {
    return {
        selectedMatchesFetching: state?.KalyanLite?.selectedMatches?.isFetching,
        selectedMatchesData: state?.KalyanLite?.selectedMatches?.data,
        isSelectedMatchesLoadmore: state?.KalyanLite?.selectedMatches?.isLoadmoreFetching,
        selectedMatchesPagination: state?.KalyanLite?.selectedMatches?.pagination,
        hasMoreSelectedMatches: state?.KalyanLite?.selectedMatches?.hasMoreData,
    }
}

export const getNameSearchedMatches = (state) => {
    return {
        nameSearchMatchesFetching: state?.KalyanLite?.nameSearchMatches?.isFetching,
        nameSearchMatchesData: state?.KalyanLite?.nameSearchMatches?.data,
        isnameSearchMatchesLoadmore: state?.KalyanLite?.nameSearchMatches?.isLoadmoreFetching,
        nameSearchMatchesPagination: state?.KalyanLite?.nameSearchMatches?.pagination,
        hasMorenameSearchMatches: state?.KalyanLite?.nameSearchMatches?.hasMoreData,
    }
}


export const getFilterMatchesByType = (state, type) => {
    return {
        allFilterMatches: state?.KalyanLite?.filterMatches,
        filterMatches: state?.KalyanLite?.filterMatches[type]?.data,
        isFilterMatchFetching: state?.KalyanLite?.filterMatches[type]?.isFetching,
        isFilterMatchLoadMore: state?.KalyanLite?.filterMatches[type]?.isLoadmoreFetching,
        filterMatchPagination: state?.KalyanLite?.filterMatches[type]?.pagination,
        hasMoreFilterMatch: state?.KalyanLite?.filterMatches[type]?.hasMoreData,
        filterContentCard:state?.KalyanLite?.filterMatches[type]?.contentCardData,
        filterBanner:state?.KalyanLite?.filterMatches[type]?.banner,
    }
}


export const getNextPreviousState = (state) => {
    let userId = state?.KalyanLite?.currentMatch?.data?.profileid;
    let selectedMatches = state?.KalyanLite?.selectedMatches?.data;
    let selectedMatchIds = selectedMatches?.map(match => match.profileid)
    let otherMatches = state?.KalyanLite?.initialMatch?.data?.filter(match => !selectedMatchIds.includes(match.profileid))
    let filterMatches = state?.KalyanLite?.filterMatches
    let catagory = state?.KalyanLite?.currentMatch?.category;
    let matchType = state?.KalyanLite?.currentMatch?.matchType;
    let currentMatchList = catagory === "INITIAL"
        ? state?.KalyanLite?.initialMatch?.data
        : catagory === "NAME_SEARCH"
            ? state?.KalyanLite?.nameSearchMatches?.data
            : catagory === "SELECTED_MATCH"
                ? state?.KalyanLite?.selectedMatches?.data
                : catagory === Constants.matchCatogory.otherMatches
                    ? otherMatches
                    : catagory === Constants.matchCatogory.filterMatches
                        ? filterMatches[matchType]?.data
                        : catagory === Constants.matchCatogory.basicSearch
                            ? state?.KalyanLite?.basicSearchMatches?.data
                            : [];
    let hasMoreData = catagory === "INITIAL"
        ? state?.KalyanLite?.initialMatch?.hasMoreData
        : catagory === "NAME_SEARCH"
            ? state?.KalyanLite?.nameSearchMatches?.hasMoreData
            : catagory === "SELECTED_MATCH"
                ? state?.KalyanLite?.selectedMatches?.hasMoreData
                : catagory === Constants.matchCatogory.otherMatches
                    ? state?.KalyanLite?.initialMatch?.hasMoreData
                    : catagory === Constants.matchCatogory.filterMatches
                        ? filterMatches[matchType]?.hasMoreData
                        : catagory === Constants.matchCatogory.basicSearch
                            ? state?.KalyanLite?.basicSearchMatches?.hasMoreData
                            : false

    let isNextLoading = catagory === "INITIAL"
        ? state?.KalyanLite?.initialMatch?.isLoadmoreFetching
        : catagory === "NAME_SEARCH"
            ? state?.KalyanLite?.nameSearchMatches?.isLoadmoreFetching
            : catagory === "SELECTED_MATCH"
                ? state?.KalyanLite?.selectedMatches?.isLoadmoreFetching
                : catagory === Constants.matchCatogory.otherMatches
                    ? state?.KalyanLite?.initialMatch?.isLoadmoreFetching
                    : catagory === Constants.matchCatogory.filterMatches
                        ? filterMatches[matchType]?.isLoadmoreFetching
                        : catagory === Constants.matchCatogory.basicSearch
                            ? state?.KalyanLite?.basicSearchMatches?.isLoadmoreFetching
                            : false


    let index = currentMatchList?.findIndex(match => match.profileid == userId)

    console.log("current-index", { index, currentMatchList, userId, state, dd: hasMoreData || index < (currentMatchList?.length - 1) });
    return {
        isNextAvailable: hasMoreData || index < (currentMatchList?.length - 1),
        isPreviousAvailable: index !== 0,
        isNextLoading,
    }
}



export const getSearchedMatches = (state) => {
    return {
        basicSearchMatchesFetching: state?.KalyanLite?.basicSearchMatches?.isFetching,
        basicSearchMatchesData: state?.KalyanLite?.basicSearchMatches?.data,
        isBasicSearchMatchLoadmore: state?.KalyanLite?.basicSearchMatches?.isLoadmoreFetching,
        basicSearchMatchesPagination: state?.KalyanLite?.basicSearchMatches?.pagination,
        hasMorebasicSearchMatches: state?.KalyanLite?.basicSearchMatches?.hasMoreData,
        basicSearchMatchesContentCardData: state?.KalyanLite?.basicSearchMatches?.contentCardData,
        basicSearchMatchesBanner: state?.KalyanLite?.basicSearchMatches?.banner,
        criteriamorethan15: state?.KalyanLite?.basicSearchMatches?.response?.resultcountcheck,
        totalBasicSearchCount: state?.KalyanLite?.basicSearchMatches?.totalCount,
    }
}