import { React, useRef } from "react";
import {
  LoginSocialFacebook,
  LoginSocialInstagram,
} from "reactjs-social-login";
import { useState, useEffect, useContext } from "react";
import { Image, Modal, Spin } from "antd";
import ImageCropper from "../Edit Profile/ImageCropper";
import { getFileExtension, validateImage } from "../../Utils";
import { LoginContext } from "../../Contexts/LoginContext";
import EndPoints from "../../Constants/EndPoints";
import config from "../../config";
import axios from "axios";
import Constants, { loginFrom,EditProfileMenu } from "../../Constants/constants";
import { CommonSuccessPopup } from "../CommonPopup";
import { InstagramLogin } from "@amraneze/react-instagram-login";
import Loader from "../Loader";
import FaceBookIcon from "../../Images/facebook-icon-logo-svgrepo-com.svg"
import { POST } from "../../Services/api_services";
import { imageSizeReducer ,handleImageSizeCompress} from "../../Components/ImageSizeReducer";
import addphotoIcon from '../../Components/Kalyanlite/assets/Images/addphotoIcon2.svg'
import facebookIcon from '../../Components/Kalyanlite/assets/Images/facebookIcon.svg'
import instragramIcon from '../../Components/Kalyanlite/assets/Images/instragramIcon.svg'
import whatsappIcon from '../../Components/Kalyanlite/assets/Images/whatsappIcon.svg'
import { NavBarContext } from "../../Contexts/NavBarContext";
import { useNavigate } from "react-router-dom";
import { RouteHelper } from "../../Routes/RouteSegment";
import { useSelector } from "react-redux";
import { getDeviceInfo } from "../Kalyanlite/utils";




function IconInstagram(props) {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fill="currentColor"
      height="24px"
      width="24px"
      {...props}
    >
      <path d="M512 306.9c-113.5 0-205.1 91.6-205.1 205.1S398.5 717.1 512 717.1 717.1 625.5 717.1 512 625.5 306.9 512 306.9zm0 338.4c-73.4 0-133.3-59.9-133.3-133.3S438.6 378.7 512 378.7 645.3 438.6 645.3 512 585.4 645.3 512 645.3zm213.5-394.6c-26.5 0-47.9 21.4-47.9 47.9s21.4 47.9 47.9 47.9 47.9-21.3 47.9-47.9a47.84 47.84 0 00-47.9-47.9zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zm-88 235.8c-7.3 18.2-16.1 31.8-30.2 45.8-14.1 14.1-27.6 22.9-45.8 30.2C695.2 844.7 570.3 840 512 840c-58.3 0-183.3 4.7-235.9-16.1-18.2-7.3-31.8-16.1-45.8-30.2-14.1-14.1-22.9-27.6-30.2-45.8C179.3 695.2 184 570.3 184 512c0-58.3-4.7-183.3 16.1-235.9 7.3-18.2 16.1-31.8 30.2-45.8s27.6-22.9 45.8-30.2C328.7 179.3 453.7 184 512 184s183.3-4.7 235.9 16.1c18.2 7.3 31.8 16.1 45.8 30.2 14.1 14.1 22.9 27.6 30.2 45.8C844.7 328.7 840 453.7 840 512c0 58.3 4.7 183.2-16.2 235.8z" />
    </svg>
  );
}




export default function SocialMediaUpload(props) {
  const [photos, setPhotos] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null)
  const [paging, setPaging] = useState(null);
  const [isSelected, setIsSelected] = useState("-1");
  const [selectedImage, setSelectedImage] = useState([]);
  const [showCrop, setShowCrop] = useState(false);
  const [originalImage, setOriginalImage] = useState();
  const [currentimage, setCurrentImage] = useState(null);
  const [photoId, setPhotoId] = useState();
  const [successMessage, setsuccessMessage] = useState("");
  const [showSuccessPopup, setshowSuccessPopup] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [notification, setNotification] = useState({
    show: false,
    title: "",
    message: "",
  });
  const [showLoader, setShowLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [provider, setProvider] = useState(null)
  const navigate = useNavigate();
  const {setMenuSelect } = useContext(NavBarContext);
  const userData = useSelector(
    (state) => state?.DashBoardAPI?.ProfileData?.data,
  );


  console.log("photos", photos);

  // Get the Uploaded photos from the FaceBook

  const getPhotos = async (token, next) => {
    setIsLoading(true);
    let url = new URL("https://graph.facebook.com/v18.0/me/photos");
    url.searchParams.set("fields", "images");
    url.searchParams.set("type", "uploaded");
    url.searchParams.set("limit", "10");
    url.searchParams.set("access_token", token);
    fetch(next ? next : url.toString())
      .then((res) => res.json())
      .then((data) => {
        console.log("getPhotos", data);
        setOpen(true);
        setIsLoading(false);
        setPaging(data?.paging);
        const newImages =
          data?.data?.map((photo) => photo?.images[0]?.source) ?? [];
        if (next) {
          setPhotos((prev) => [...prev, ...newImages]);
        } else {
          setPhotos(newImages);
        }
      });
  };

  // Get the Uploaded photos from the Instagram

  const getInstagramPhotos = async (userId, token, next) => {
    setIsLoading(true);
    fetch(
      next ? next : `https://graph.instagram.com/${userId}/media?fields=id,media_type,media_url&limit=10&access_token=${token}`
    )
      .then((res) => res.json())
      .then((data) => {
        console.log("getInstagramPhotos", data);
        setIsLoading(false);
        setShowLoader(false)
        setOpen(true);
        setPaging(data?.paging);

        const newImages = data?.data
          ?.filter((photo) => photo.media_type === "IMAGE")
          ?.map((photo) => photo?.media_url)
        if (next) {
          setPhotos((prev) => [...prev, ...newImages]);
        } else {
          setPhotos(newImages);
        }

      });
  };

  console.log("window.location.href", window.location.href);

  // Scroll to load the next 10 Images from FaceBook or Instagram

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !isLoading && paging?.next) {
      if (provider == "facebook") {
        getPhotos(token, paging?.next);
      } else {
        getInstagramPhotos(userId, token, paging?.next)
      }
    }
  };

  // Notification to show Messages

  const showNotification = ({ description = "", title = "Status!" }) => {
    setNotification({ show: true, title: title, message: description });
  };

  // Handling the Selected Image from the faceBook

  const handleImage = () => {
    if (isSelected != -1) {
      setShowLoader(true);
      const selected_image = photos[isSelected];
      setSelectedImage(selected_image);

      fetch(selected_image)
        .then((res) => res.blob())
        .then((blob) => {
          console.log(blob);
          const file_save = new File([blob], "selectedimage.jpeg", {
            type: blob.type,
          });
          console.log(file_save);
          handleCrop(file_save, isSelected);
        });
      setIsSelected("-1");
      setOpen(false);
    } else {
      console.log("Please select an image before clicking Ok");
      // showNotification({
      //   title: "No image",
      //   description: "Please select an image before clicking Ok",
      // });
    }
  };

  // Checks for Valid Image

  const handleCrop = (file, index) => {
    setOriginalImage(file);
    validateImage({
      file,
      onSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Height and Width must be more than 100px",
        });
      },
      onExtensionError: (fileType) => {
        showNotification({
          title: "Invalid image",
          description: "Invalid extension!",
        });
      },
      onFileSizeError: (img) => {
        showNotification({
          title: "Invalid image",
          description: "Image size should not be greater than 10mb",
        });
      },
      onSuccess: (img, readerStr) => {
        // handleOriginalImageUpload(file);
        imageSizeReducer(file,originalImageUpload)
        // handleImageSizeCompress(file,originalImageUpload)
        setCurrentImage(readerStr || "");
      },
    });
  };

  // Uploads the Image to the Profile

  const originalImageUpload = async (file) => {
    console.log("file.size", file);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", provider);
      fileData.append("original", file);
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.profileOriginalImageUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log(data, "datadatadatadata");
      if (data.data.statusCode === 200) {
        if (data.data.data.status === "Success") {
          setPhotoId(data.data.data.photoId);
          setShowLoader(false);
          setShowCrop(true);
        } else {
          props.close();
        }
      } else if (data.data.statusCode === 401) {
        logout();
      }
    } else {
      alert("Please upload file less than 10 mb");
      props.close();
    }
  };

  // Cropped Image is Passing through the handleUpload Function

  const cropedImage = (cropedImg) => {
    setLoader(true);
    handleUpload(originalImage, cropedImg);
    setShowCrop(false);
  };

  // Uploading the Cropped Image

  const handleUpload = async (file, cropedFile) => {
    //console.log("file.size", file.size);
    if (file.size / 1024 / 1024 < 10) {
      let fileData = new FormData();
      fileData.append("email", loginDetail()[0]);
      fileData.append("userId", loginDetail()[1]);
      fileData.append("photoUploadFrom", getDeviceInfo());
      fileData.append("uploadfrom", provider);
      fileData.append("photoId", photoId);
      fileData.append("croped", cropedFile);
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.croppedPhotoUploadUrl()}`,
        fileData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // //console.log(data, statusCode);
      if (data.status === 200) {
        if (data.data.data.status === "SUCCESS") {
          // setsuccessMessage("Image uploaded succesfully");
          setLoader(false);
          // setshowSuccessPopup(true);
          props.closeModal();
          props.callBackImage(cropedFile);
        }
      }
    } else {
      alert("Please upload file less than 10 mb");
    }
  };

  const getInstaAccessToken = async (code) => {
    setShowLoader(true);
    const request = {
      "redirect_uri": window.location.href,
      "code": code,
    }
    const response = await POST(`${config.cms_api}/getAccessToken`, request, { withCredentials: false })
    console.log(response, "responseresponse");
    if (response?.data?.code == 200) {
      setUserId(response?.data?.user_id)
      setToken(response?.data?.access_token)
      getInstagramPhotos(response?.data?.user_id, response?.data?.access_token)
    }
  }

  const isV3User = localStorage.getItem("isV3User")

  return (
    <div className="mb-[20px]">
      {loader && (
        <Loader
          className="loaderTransperancy"
          loaderClassName="loaderTransperancySpin"
        />
      )}

      {/* Image Crop */}
      {showLoader ? (
        <Loader
          className="loaderTransperancy"
          loaderClassName="loaderTransperancySpin"
        />
      ) : showCrop ? (
        <ImageCropper
          onCropClick={cropedImage}
          image={selectedImage}
          onClose={(isCropped) => {
            if (isCropped) {
              setShowCrop(false);
            } else {
              setShowCrop(false);
            }
          }}
          isFrom={"signout"}
        />
      ) : (
        <></>
      )}

      {showSuccessPopup && (
        <CommonSuccessPopup
          close={() => {
            setshowSuccessPopup(false);
          }}
          title={"Success!"}
          content={successMessage}
        />
      )}

      <Modal
        title={provider === "facebook" ? "Upload From FaceBook" : "Upload From Instagram"}
        // titleColor="D10A11"
        centered
        open={open}
        // onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={700}
        footer={null}
      >
        <div
          onScroll={handleScroll}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
            gap: "1rem",
            overflow: "auto",
            height: "70vh",
          }}
          className=""
        >
          {photos.map((photo, index) => (
            <div>
              <img
                width={200}
                src={photo}
                key={index}
                alt=""
                className={
                  index == isSelected
                    ? "border-[rgb(209,10,17)] border-[2px] p-[2px]"
                    : ""
                }
                onClick={() => setIsSelected(index)}
              />
            </div>
          ))}
          {isLoading ? (
            <div
              ref={(ref) => {
                if (ref) {
                  ref.scrollIntoView();
                }
              }}
            >
              <Loader tip="Loading">
                <div className="content" />
              </Loader>
            </div>
          ) : null}
        </div>
        <div className="flex justify-end pt-3">
          <button
            onClick={() => setOpen(false)}
            className="mr-4 text-[#7A7A7A] border border-[#9F9F9F] text-[9px] md:text-[9px] lg:text-[12px] p-2 rounded-[5px] cursor-pointer"
          >
            Cancel
          </button>
          <button
            onClick={handleImage}
            className="text-[#FFFFFF] bg-[#D10A11] text-[9px] md:text-[9px] lg:text-[12px] p-2 rounded-[5px]  md:w-8 lg:w-12 cursor-pointer"
          >
            OK
          </button>
        </div>
      </Modal>

      {isV3User ? <div className="grid grid-cols-2 gap-4 px-3">

        {(props?.menu == "newphoto" || props?.menu == "changephoto") &&
        <>
        <label>
      <div 
                        className="flex flex-col space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer" 
                        style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}}
                        onClick={() =>{
                            
                        }}
                      >
                          <img src={addphotoIcon} className="w-[3rem] h-[3rem] cursor-pointer" alt="" loading="lazy"/>
                          <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#D10A11] shadow-md rounded-xl cursor-pointer">
                            <p className="text-[#000000] font-medium font-Poppins text-[8px]">
                            
                            <a className="cursor-pointer">
                              Add Photo Now!
                            </a>
                            
                            <input
                              style={{ display: "none" }}
                              type="file"
                              onChange={(event) => {
                                props?.handleCrop(event.target.files[0]);
                              }}
                              accept="image/png, image/jpg, image/jpeg"
                              data-max-size="10485760"
                            />
                            
                            </p>
                          </div>
                      </div>
                      </label>

                      <div className="flex flex-col space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'}} onClick={() => window.open(Constants.kalyanSupportWhatsappUrl(userData?.smProfileId))} >
                        <img src={whatsappIcon} className="w-[3rem] h-[3rem] cursor-pointer" alt="" loading="lazy"/>
                        <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#49C858] shadow-md rounded-xl cursor-pointer" >
                        <p 
                          className="text-[#000000] font-medium font-Poppins text-[8px] cursor-pointer"
                        >
                          Add Photo via WhatsApp!
                        </p>
                        </div>
                      </div>
                      </>
          }


        <LoginSocialFacebook
          isOnlyGetToken
          appId="606506484678232"
          onResolve={({ provider, data }) => {
            getPhotos(data?.accessToken);
            setToken(data?.accessToken);
            setProvider("facebook")
          }}
          scope="user_photos"
          onReject={(err) => {
            console.log(err);
          }}
        >
          <div className="flex flex-col  space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }} onClick={() => { }}>
            <img src={facebookIcon} className="w-[2.5rem] h-[2.5rem]" loading="lazy" alt="" />
            <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#1976D2] shadow-md rounded-xl cursor-pointer">
              <p className="text-[#000000] font-medium font-Poppins text-[8px]">
                <a href="https://www.facebook.com/login.php?skip_api_login=1&api_key=606506484678232&kid_direct"
                  className="cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                >Upload From facebook</a>
              </p>
            </div>
          </div>
        </LoginSocialFacebook>

        <InstagramLogin
          clientId="266374166312609"
          buttonText="Upload from Instagram"
          scope="user_profile,user_media"
          cssClass="bg-[#485A96] h-[35px] text-[10px] md:text-[12px] lg:text-[14px] text-[white] font-bold cursor-pointer rounded-xl"
          onSuccess={(response) => {
            console.log("LoginSocialInstagram", response);
            getInstaAccessToken(response)
            setProvider("instagram")
          }}
          onFailure={(data) => {
            console.log("LoginSocialInstagram - fail", data);
          }}
        >
          <div className="flex flex-col space-y-4 items-center justify-center px-3 py-9 h-[10rem] bg-white shadow-md rounded-xl cursor-pointer" style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }} onClick={() => { }}>
            <img src={instragramIcon} className="w-[2.5rem] h-[2.5rem]" loading="lazy" alt="" />
            <div className="flex items-center justify-center xl:w-[8rem] md:w-[8rem] w-[7rem] h-6 bg-white border border-[#7F4BAD] shadow-md rounded-xl cursor-pointer">
              <p className="text-[#000000] font-medium font-Poppins text-[8px]">
                <a href="https://www.instagram.com/accounts/login/?force_authentication=1&enable_fb_login=1&next=%2Foauth%2Fauthorize%3Fclient_id%3D266374166312609%26response_type%3Dcode%26scope%3Duser_profile%2Cuser_media%26redirect_uri%3Dhttps%3A%2F%2Fv2-uat.kalyanmatrimony.com%2Fdashboard%26logger_id%3D7e472074-54f8-438c-838d-d9ed56a3a5c0"
                  className="cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Upload From Instagram
                </a>
              </p>
            </div>
          </div>
        </InstagramLogin>




      </div>
        : <div className="">
        <div className="flex justify-around">
          {/* FaceBook Login Button */}

          <LoginSocialFacebook
            isOnlyGetToken
            appId="606506484678232"
            onResolve={({ provider, data }) => {
              getPhotos(data?.accessToken);
              setToken(data?.accessToken);
              setProvider("facebook")
            }}
            scope="user_photos"
            onReject={(err) => {
              console.log(err);
            }}
          >
            <button
              className=" bg-[#485A96] h-[35px] text-[10px] md:text-[12px] lg:text-[14px] text-[white] font-bold cursor-pointer rounded"
            >
              <div className="flex">
                <img className=" w-[1.7rem] p-[5px] mr-[5px]" src={FaceBookIcon} alt="" />
                <span className="flex items-center p-1">Upload from facebook</span>
              </div>
            </button>
          </LoginSocialFacebook>


          <InstagramLogin
            clientId="266374166312609"
            buttonText="Upload from Instagram"
            scope="user_profile,user_media"
            cssClass="bg-[#485A96] h-[35px] text-[10px] md:text-[12px] lg:text-[14px] text-[white] font-bold cursor-pointer rounded"
            onSuccess={(response) => {
              console.log("LoginSocialInstagram", response);
              getInstaAccessToken(response)
              setProvider("instagram")
            }}
            onFailure={(data) => {
              console.log("LoginSocialInstagram - fail", data);
            }}
          >
            <div className="flex items-center">
              <IconInstagram className=" mx-[4px]" />
              <span className="flex items-center p-1">Upload from instagram</span>
            </div>
          </InstagramLogin>
        </div>
        </div>}
    </div>
  );
}
