import React, { useEffect, useState, useContext } from "react";
import EndPoints from "../../Constants/EndPoints";
import { GET, POST } from "../../Services/api_services";
import { ProfileAccordianComponent } from "../ViewMessageComponents/AccordianComponents";

import AccrodianSelectImage from '../../Images/Group 930@2x.png'

import { LoginContext } from "../../Contexts/LoginContext"; 

import Loader from "../Loader";
import { registerStarList } from "../../Store/Home/Action";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import PlusIcon from "../../Images/Path 3979.svg"
import Constants, {
  EventName,
} from "../../Constants/constants";
import { useAnalytics } from "../../Hooks/usePageChange";
import FamilyAddComponent from "./FamilyAddComponent";
import { NavBarContext } from "../../Contexts/NavBarContext";

const FamilyBasic = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [editMyProfileData, setEditMyProfielData] = useState();
  const [familyinfofreshData, setfamilyinfofresh] = useState();
  const [dashboard1Data, setDashboard1Data] = useState([]);
  const [profileCount, setProfileCount] = useState([]);

  const [isFamilyLoading, setIsFamilyLoading] = useState(true)
  const [isPrefLoading, setIsPrefLoading] = useState(false)
  const isV3User = localStorage.getItem("isV3User")
  const { completeNow,setCompleteNow } = useContext(NavBarContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // apicallDashboard()
    setDashboard1Data(props.dashboard1Data);
    setEditMyProfielData(props.data);
  }, [props.dashboard1Data, props.data]);

  useEffect(() => {
    setProfileCount(props.proileCount)
  },[props.proileCount])
  useEffect(() => {
    console.log("editMyProfileDataa", editMyProfileData);
  }, [
    editMyProfileData,
   
    familyinfofreshData,
  ]);

  const dispatch = useDispatch();
  //called whenever religion, mother tonge dropdown values updated
  const apiCallStar = async (motherTounge) => {
    // getCastUrl

    dispatch(registerStarList(motherTounge))
  };

  const { starList } = useSelector(state => ({
    starList: state.Home?.starList?.data?.data
  }));

  const { dropdownData } = useSelector((state) => ({
    dropdownData: state?.Home?.rData?.data,
  }));
  useEffect(()=>{
console.log(dropdownData,"dropdownDataq");
  },[])

  function familyCallBack() {
    setIsFamilyLoading(true);
    apiCallDropDown();
  }


  useEffect(() => {
   
      familyCallBack();
  }, [props.accordianSelect]);


  const apiCallDropDown = async (type) => {
    setLoading(true)
    let url = "";

      url = `${config.api.API_URL}${EndPoints.familyinfofreshUrl()}`;
    
    let { statusCode, data } = await GET(url);
    //console.log("dropdown data ", data);
    if (statusCode === 200) {
      setLoading(false)
      setDropDownData(data.data);
    } else if (statusCode === 401) {
      logout();
    }
   
    setIsFamilyLoading(false)
  };
  const setDropDownData = (data) => {
   
      setfamilyinfofresh(data);
    
  };

  function preferenceRefresh() {
    
      setIsPrefLoading(true);
      
    
  }

  const { onClickTrack } = useAnalytics()

  return (
    <div className="p-3">

        <div >
  
              {loading
                ? <Loader />
                : 
                <FamilyAddComponent
                  data={editMyProfileData}
                  callBackReload={props?.callBackReload}
                  refresh={familyCallBack}
                  dropDown={familyinfofreshData}
                   menu="addfamily"
                   postData={props?.postData}
                />
              }
          </div>
     

    
      </div>
  );
};

export default FamilyBasic;
