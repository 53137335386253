import React, { useState, useContext, useEffect, useMemo } from "react";
import {
  BasicDatePicker,
  BasicEditTextView,
  BasicTextView,
  BasiSelectView,
  EditButton,
  SaveCancelButton,
  SelectInput,
} from "../EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../../Constants/EndPoints";
import { IP_Address } from "../../../Constants/IpAddress";
import { POST } from "../../../Services/api_services";
import moment from "moment";
import config from "../../../config";
import { validateNumber } from "../../../Utils";
import Constants, {
  EventKeys,
  EventName,
} from "../../../Constants/constants";
import { useAnalytics } from "../../../Hooks/usePageChange";

const BasicForm = (props) => {
  //console.log(props.isPaid,'props.isPaid');
  const [isShowEdit, setEditView] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const [submitStatus, setSubmitStatus] = useState(false)
  const formik = useFormik({
    initialValues: {
      name: props?.data?.name ? props?.data?.name : "",
      dob: props?.data?.dob ? moment(props?.data?.dob,'DD/MM/YYYY').format('') : "",
      height: props?.data?.height ? props?.data?.height[0].id : "",
      weight: props?.data?.weight ? props?.data?.weight.replace(" kgs","") : "",
      maritalStatus: props?.data?.maritalStatus
        ? props?.data?.maritalStatus[0].id
        : "",
      bodytype: props?.data?.bodyType ? props?.data?.bodyType[0].id : "",
      complexion: props?.data?.complexion ? props?.data?.complexion[0].id : "",
      physicalStatus: props?.data?.physicalStatus
        ? props?.data?.physicalStatus[0].id
        : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Please Enter Name"),
      dob: Yup.string().required("Please Enter Date of Birth"),
      height: Yup.string().required("Please Select height"),
      weight: Yup.number().min(10).max(999).required("Please Enter Weight"),
      maritalStatus: Yup.string().required("Please Select Marital Status"),
      bodytype: Yup.string().required("Please Select Bodytype"),
      complexion: Yup.string().required("Please Select Complexion"),
      physicalStatus: Yup.string().required("Please Select Physical Status"),
    }),

    onSubmit: (values) => {
      //console.log(values);
      setSubmitStatus(true)
      apiCallBasic(values);
      // onClickTrack(EventName.profilebasicsaveButton);
    },
  });

  const apiCallBasic = async (value) => {
    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      maritialStatusId: value.maritalStatus,
      dob: moment(value.dob,'YYYY-MM-DD').format('DD/MM/YYYY'),
      name: value.name,
      heightInInchesId: value.height,
      weight: value.weight,
      bodyTypeId: value.bodytype,
      complexionId: value.complexion,
      physicalStatusId: value.physicalStatus,
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editbasicinfoUrl()}`,
      request
    );

    if (statusCode === 200) {
      if (data.data.status === "SUCCESS") {
        // alert("Basic Details saved");
        setSubmitStatus(false)
        props.callBackReload();
        setEditView(false);
        onClickTrack(EventName.profileBasicSaveButton , {[EventKeys.profileBasic]:value});
      }
    } else if (statusCode === 401) {
      logout();
    }
  };
  useEffect(()=>{

  },[props.isPaid])


  const selectedHeight = useMemo(()=>{
    return props?.dropDown?.heightInchesData?.filter(option=>option.id === formik.values.height)?.at(0)
  },[formik.values.height, props?.dropDown?.heightInchesData])

  const selectedMaritalStatus = useMemo(()=>{
    return props?.dropDown?.maritalStatus?.filter(option=>option.id === formik.values.maritalStatus)?.at(0)
  },[formik.values.maritalStatus, props?.dropDown?.maritalStatus])

  const selectedBodyType = useMemo(()=>{
    return props?.dropDown?.bodyTypeData?.filter(option=>option.id === formik.values.bodytype)?.at(0)
  },[formik.values.bodytype, props?.dropDown?.bodyTypeData])

  const selectedComplexion = useMemo(()=>{
    return props?.dropDown?.complexionData?.filter(option=>option.id === formik.values.complexion)?.at(0)
  },[formik.values.complexion, props?.dropDown?.complexionData])

  const selectedPhysicalStatus = useMemo(()=>{
    return props?.dropDown?.physicalStatusData?.filter(option=>option.id === formik.values.physicalStatus)?.at(0)
  },[formik.values.physicalStatus, props?.dropDown?.physicalStatusData])

  //console.log('new Date(formik.values.dob)',new Date(formik.values.dob),formik.values.dob);

  const onNameChange = (e)=>{
    let text = e.target.value ??"";
    text = text.trimStart().replace(/[^a-zA-Z ]/g, "").replace(/  +/g, " ");
    
    //Don't delete below commented code
    // const len = text.split(".").length??0;
    // //console.log('len',len);
    // if(len <= 2){
    //   formik.setFieldValue("name",text)
    // }else{
    //   formik.setFieldValue("name",formik.values.name)
    // }
    formik.setFieldValue("name",text)
  }


  const { onClickTrack } = useAnalytics()

  return (
    <div>
      {isShowEdit ? (
        <></>
      ) : (
        <div className="md:mb-8 p-5 md:p-0">
          <EditButton
            editCallBack={() => {
              setEditView(true);
            }}
          />

          <BasicTextView title={"Name"} value={props?.data?.name} />
          <BasicTextView title={"Age"} value={props?.data?.age} />
          <BasicTextView title={"Height"} value={props?.data?.height[0].name} />
          <BasicTextView title={"Weight"} value={props?.data?.weight} />
          <BasicTextView
            title={"Marital Status"}
            value={props?.data?.maritalStatus[0].name}
          />
          <BasicTextView
            title={"Body Type"}
            value={props?.data?.bodyType[0].name}
          />
          <BasicTextView
            title={"Complexion"}
            value={props?.data?.complexion[0].name}
          />
          <BasicTextView
            title={"Physical Status"}
            value={props?.data?.physicalStatus[0].name}
          />
        </div>
      )}
      {/* <!-- edit part  --> */}
      <form onSubmit={formik.handleSubmit}>
        {isShowEdit ? (
          <div className="p-5 md:p-0">
            {props.isPaid
              ? <BasicTextView title={"Name"} value={props?.data?.name} />
              : <BasicEditTextView
                title={"Name"}
                inputType={"text"}
                inputID={"name"}
                inputName={"name"}
                value={formik.values.name}
                onChange={onNameChange}
                error={formik.errors.name}
                required={true}
                disabled={props?.isPaid}
              />}

            {props.isPaid
              ? <BasicTextView title={"Age"} value={props?.data?.age} />
              : <BasicDatePicker
                title={"Date Of Birth"}
                formatedValue={moment(formik.values.dob).format('DD-MM-YYYY')}
                value={moment(formik.values.dob).toDate()}
                onChange={(date) => {
                  //console.log('Date Of Birth', date);
                  formik.setFieldValue("dob", moment(date).format('YYYY-MM-DD'), true)
                }}
                error={formik.errors.dob}
                required={true}
                minDate={moment().subtract(70,"year").add(1,"day").toDate()}
                maxDate={moment().subtract(props?.data?.gender === "F" ? 18 : 21, "year").toDate()}
                disabled={props?.isPaid}
                name="dob"
              />}

            <SelectInput
              name="height"
              title="Height"
              value={selectedHeight}
              onChange={(selected, ev) => {
                formik.setFieldValue("height", selected.id)
              }}
              error={formik.errors.height}
              defaultValue={formik.values.height}
              options={[{ id: null, name: "Select", isDisabled: true  }, ...props?.dropDown?.heightInchesData]}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            /> 

            {/* <BasiSelectView
              title={"Height"}
              inputID={"height"}
              inputName={"height"}
              value={formik.values.height}
              onChange={formik.handleChange}
              error={formik.errors.height}
              options={props?.dropDown?.heightInchesData}
              required={true}
            /> */}
            <BasicEditTextView
              title={"Weight"}
              inputID={"weight"}
              inputName={"weight"}
              value={formik.values.weight}
              onChange={(e)=>{
                if(validateNumber(e.target.value)){
                  formik.handleChange(e)
                }
              }}
              error={formik.errors.weight}
              required={true}
              inputType={"tel"} 
              className={"weight"}             

            />
             <SelectInput
              name="height"
              title="Marital Status"
              value={selectedMaritalStatus}
              onChange={(selected, ev) => {
                formik.setFieldValue("maritalStatus", selected.id)
              }}
              error={formik.errors.maritalStatus}
              defaultValue={formik.values.maritalStatus}
              options={[{ id: null, name: "Select", isDisabled: true  }, ...props?.dropDown?.maritalStatus]}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            /> 

            {/* <BasiSelectView
              title={"Marital Status"}
              inputID={"maritalStatus"}
              inputName={"maritalStatus"}
              value={formik.values.maritalStatus}
              onChange={formik.handleChange}
              error={formik.errors.maritalStatus}
              options={props?.dropDown?.maritalStatus}
              required={true}
            /> */}
             <SelectInput
              name="bodytype"
              title="Body Type"
              value={selectedBodyType}
              onChange={(selected, ev) => {
                formik.setFieldValue("bodytype", selected.id)
              }}
              error={formik.errors.bodytype}
              defaultValue={formik.values.bodytype}
              options={[{ id: null, name: "Select", isDisabled: true  }, ...props?.dropDown?.bodyTypeData]}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            /> 
            {/* <BasiSelectView
              title={"Body Type"}
              inputID={"bodytype"}
              inputName={"bodytype"}
              value={formik.values.bodytype}
              onChange={formik.handleChange}
              error={formik.errors.bodytype}
              options={props?.dropDown?.bodyTypeData}
              required={true}
            /> */}
             <SelectInput
              name="complexion"
              title="Complexion"
              value={selectedComplexion}
              onChange={(selected, ev) => {
                formik.setFieldValue("complexion", selected.id)
              }}
              error={formik.errors.complexion}
              defaultValue={formik.values.complexion}
              options={[{ id: null, name: "Select", isDisabled: true  }, ...props?.dropDown?.complexionData]}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            /> 
            {/* <BasiSelectView
              title={"Complexion"}
              inputID={"complexion"}
              inputName={"complexion"}
              value={formik.values.complexion}
              onChange={formik.handleChange}
              error={formik.errors.complexion}
              options={props?.dropDown?.complexionData}
              required={true}
            /> */}
            <SelectInput
              name="physicalStatus"
              title="Physical Status"
              value={selectedPhysicalStatus}
              onChange={(selected, ev) => {
                formik.setFieldValue("physicalStatus", selected.id)
              }}
              error={formik.errors.physicalStatus}
              defaultValue={{ id: null, name: "Select"  }}
              options={[{ id: null, name: "Select", isDisabled: true  }, ...props?.dropDown?.physicalStatusData]}
              required={true}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
            /> 
            {/* <BasiSelectView
              title={"Physical Status"}
              inputID={"physicalStatus"}
              inputName={"physicalStatus"}
              value={formik.values.physicalStatus}
              onChange={formik.handleChange}
              error={formik.errors.physicalStatus}
              options={props?.dropDown?.physicalStatusData}
              required={true}
            /> */}

            <SaveCancelButton
              saveCallBack={(e) => {
                e.preventDefault();
                
              }}
              cancelCallback={() => {
                setEditView(false);
                formik.resetForm()
                onClickTrack(EventName.profileBasicCancelButton)
              }}
              submitStatus={submitStatus}
            />
          </div>
        ) : (
          <></>
        )}
      </form>
    </div>
  );
};

export default BasicForm;
