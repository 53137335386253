import React, { useEffect, useState } from 'react'
import RegisterForm from '../../Components/RegisterForm/Form'
import LoginForm from '../../Components/LoginForm/Form'

export default function Specification(props) {
   const [registerOrLogin, setRegsiterOrLogin] = useState("1")
   function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
   }


   //console.log(props, "1234567890");
   return (
      <>

     
         <div className='font-segeo '>
            <div className={`${props.page == "homeRegister" ?
               "pl-[3rem] md:pl-[1rem] lg:pl-[3rem]  text-[#FFFFFF] font-bold font-segeo   text-[28px]"
               : "pl-0 float-left text-[#D10A10]  font-segeo  font-bold text-[28px]"}`}
            >
               Why Kalyan Matrimony

               <div className={`${props.page === "homeRegister" ?
                  "md:mt-[2rem]   lg:mt-[3rem]  lg:pl-[3rem]"
                  : "mt-3 md:mt-[2rem]  md:pl-[0rem] lg:mt-[3rem]  lg:pl-[0rem]"
                  }`}>

                  <div className={`${props.page == "homeRegister" ?
                     "text-[#FFFFFF]"
                     : " text-[#000000] font-normal"}`}>
                     <div className='hidden md:grid grid-cols-2 gap-1'>
                        <div className="space-y-5 ">
                           <div className="grid grid-cols-5 lg:flex">
                              <img src={require("../../Images/Group 805.svg").default} alt="" 
                              className='col-span-1 lg:flex w-[40px] md:w-[2rem] lg:w-[3rem]' 
                              />
                              <p className="col-span-4 mt-2 md:mt-0 lg:mt-2 font-bold  text-[18px] md:text-[12px] lg:text-[18px]  md:pl-0  pl-[0.5rem] lg:pl-[0.5rem]  "
                              >
                                 From the Trusted Brand Kalyan Jewellers
                              </p>
                           </div>
                           <div className="grid grid-cols-5 lg:flex">
                              <img src={require("../../Images/Group 808.svg").default} alt="" className='col-span-1 lg:flex md:w-[2rem] lg:w-[3rem]' />
                              <p className=" col-span-4 mt-2 md:mt-0 lg:mt-2  font-bold text-[18px] md:text-[12px] lg:text-[18px] md:pl-0  pl-[0.5rem] lg:pl-[0.5rem]  "
                              >
                                 Profile details are Secure and Confidential
                              </p>
                           </div>
                           <div className="grid grid-cols-5 lg:flex">
                              <img src={require("../../Images/Group 810.svg").default} alt="" className='col-span-1 lg:flex md:w-[2rem] lg:w-[3rem]' />
                              <p className="col-span-4  mt-2 md:mt-0 lg:mt-2   font-bold text-[18px] md:text-[12px] lg:text-[18px] md:pl-0  pl-[0.5rem] lg:pl-[0.5rem]  "
                              >
                                 Most Intuitive, User Friendly Technology
                              </p>
                           </div>
                        </div>
                        <div className="space-y-5">
                        <div className="grid grid-cols-5 lg:flex">
                              <img src={require("../../Images/Group 806.svg").default} alt="" className='col-span-1 lg:flex md:w-[2rem] lg:w-[3rem]'/>
                              <p className="col-span-4 md:hidden lg:flex mt-2 md:mt-0 lg:mt-2   font-bold text-[18px] md:text-[12px] lg:text-[18px] md:pl-0  pl-[0.5rem] lg:pl-[0.5rem]  "
                              >
                              Lakhs of Brides & Grooms Profiles
                              </p>
                              <p className="col-span-4 lg:hidden mt-2 md:mt-0 lg:mt-2   font-bold text-[18px] md:text-[12px] lg:text-[18px] md:pl-0  pl-[0.5rem] lg:pl-[0.5rem]  "
                              >
                              {`Lakhs of Brides 
                              & Grooms Profiles`}
                              {/* <p className='lg:hidden'>& Grooms Profiles</p> */}
                              </p>
                           </div>
                           <div className="grid grid-cols-5 lg:flex">
                              <img src={require("../../Images/Group 809.svg").default} alt="" className='col-span-1 lg:flex md:w-[2rem] lg:w-[3rem]'/>
                              <p className="col-span-4 mt-2 md:mt-0 lg:mt-2   font-bold text-[18px] md:text-[12px] lg:text-[18px] md:pl-0  pl-[0.5rem] lg:pl-[0.5rem]  "
                              >
                                 Phone number Verified Profiles
                              </p>
                           </div>
                           <div className="grid grid-cols-5 lg:flex">
                              <img className="col-span-1 lg:flex md:w-[2rem] lg:w-[3rem] " src={require("../../Images/Group 811.svg").default} alt=""/>
                              <p className="col-span-4 mt-2 md:mt-0 lg:mt-2   font-bold text-[18px] md:text-[12px] lg:text-[18px] md:pl-0  pl-[0.5rem] lg:pl-[0.5rem]  "
                              >
                                 Intelligent Matchmaking
                              </p>
                           </div>
                        </div>
                     </div>

                     <div className='md:hidden space-y-5 mt-5 mb-5'>

                        <div className=" flex ">
                           <div className="">
                              <img src={require("../../Images/Group 805.svg").default} alt="" className='w-[3rem]' />
                           </div>
                           <p className="   font-bold  text-[18px] md:text-[13px] lg:text-[18px]  pl-[0.5rem] "
                           >
                              From the Trusted Brand Kalyan Jewellers
                           </p>
                        </div>
                        <div className=" flex ">
                           <div className="">
                              <img src={require("../../Images/Group 808.svg").default} alt="" className='w-[3rem]' />
                           </div>
                           <p className=" mt-2 md:mt-0 lg:mt-2   font-bold text-[18px] md:text-[13px] lg:text-[18px]  pl-[0.5rem] "
                           >
                              Profile details are Secure and Confidential
                           </p>
                        </div>
                        <div className=" flex  ">
                           <div className="">
                              <img src={require("../../Images/Group 810.svg").default} alt="" className='w-[3rem]' />
                           </div>
                           <p className=" mt-2 md:mt-0 lg:mt-2   font-bold text-[18px] md:text-[13px] lg:text-[18px]  pl-[0.5rem] "
                           >
                              Most Intuitive, User Friendly Technology
                           </p>
                        </div>

                        <div className=" flex   ">
                           <div className="">
                              <img src={require("../../Images/Group 806.svg").default} alt="" className='w-[3rem]' />
                           </div>
                           <p className=" mt-2 md:mt-0 lg:mt-2   font-bold text-[18px] md:text-[13px] lg:text-[18px]  pl-[0.5rem] "
                           >
                              Lakhs of Brides & Grooms Profiles
                           </p>
                        </div>
                        <div className=" flex  ">
                           <div className="">
                              <img src={require("../../Images/Group 809.svg").default} alt="" className='w-[3rem]' />
                           </div>
                           <p className=" mt-2 md:mt-0 lg:mt-2   font-bold text-[18px] md:text-[13px] lg:text-[18px]  pl-[0.5rem] "
                           >
                              Phone number Verified Profiles
                           </p>
                        </div>
                        <div className=" flex  ">
                           <div className="">
                              <img className=" w-[3rem] " src={require("../../Images/Group 811.svg").default} alt="" />
                           </div>
                           <p className=" mt-2 md:mt-0 lg:mt-2   font-bold text-[18px] md:text-[13px] lg:text-[18px]  pl-[0.5rem] "
                           >
                              Intelligent Matchmaking
                           </p>
                        </div>

                     </div>

                  


                  </div>
               </div>
            </div>
         </div>
      </>
   )
}
