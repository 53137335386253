import React, { useEffect, useState, useContext, useMemo } from "react";
import {
  BasicEditTextView,
  SelectInput,
  SelectCurrencyInput,
  SaveCancelButton,
  SelectAstroInput
} from "../../Components/Edit Profile/EditProfileUtills";
import { useFormik } from "formik";
import { LoginContext } from "../../Contexts/LoginContext";
import * as Yup from "yup";
import EndPoints from "../../Constants/EndPoints";
import { POST } from "../../Services/api_services";
import {  validateNumber } from "../../Utils";
import Constants, { EventName } from "../../Constants/constants";
import config from "../../config";
import { useAnalytics } from "../../Hooks/usePageChange";
import { useDispatch, useSelector } from "react-redux";
import { registerDetail } from "../../Store/Home/Action";
import { NavBarContext } from "../../Contexts/NavBarContext";
import axios from "axios";

const FamilyAddComponent = (props) => {
  const { loginDetail, logout } = useContext(LoginContext);
  const [isShowEdit, setEditView] = useState(false);
  const [familyStatusDropDown, setfamilyStatusDropDown] = useState([]);
  const [familyTypeDropDown, setfamilyTypeDropDown] = useState([]);
  const [familyValuesDropDown, setfamilyValuesDropDown] = useState([]);
  const [parentsIsdCodeDropDown, setparentsIsdCodeDropDown] = useState([]);
  const [currencyDropDown, setcurrencyDropDown] = useState([]);
  // const [submitStatus, setSubmitStatus] = useState(false);
  const [isParentNumberChanged, setIsParentNumberChanged] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [familyRelationDropDown, setfamilyRelationDropDown] = useState([]);
  const [relationDropDown, setRelationDropDown] = useState([{id: 5 , name: "Mother"},{id: 6, name: "Father"}]);
  const dispatch = useDispatch();
  const[onchangefamilydata,setOnchangeFamilyData] = useState(true)
  console.log(onchangefamilydata,"onchangefamilydata");
  const { completeNow,setCompleteNow ,clickAddHabits, setClickAddHabits ,
    clickAddFamily, setClickAddFamily,clickAddAstro, setClickAddAstro,
    clickAddHoro, setClickAddHoro ,submitStatus, setSubmitStatus} = useContext(NavBarContext); 
     const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [commonError, setCommonError] = useState("");

   const [packStatusData,setPackStatusData] = useState(null)

   console.log(packStatusData,"packStatusDatapackStatusData");
  useEffect(() => {
    dispatch(registerDetail());
  }, []);

  const { dropdownData } = useSelector((state) => ({
    dropdownData: state?.Home?.rData?.data,
  }));

  const relationship = dropdownData?.relationShip[0]?.name

  console.log(clickAddFamily,'clickAddFamily');
  const formik = useFormik({
    initialValues: {
      familyStatus: props?.data?.familyStatus ? props?.data?.familyStatus[0]?.id : "",
      familyType: props?.data?.familyType ? props?.data?.familyType[0]?.id : "",
      familyValues: props?.data?.familyValues ? props?.data?.familyValues[0]?.id : "",
      parentsIsdCode: props?.data?.parentsIsdCode || "",
      parentNumber: props?.data?.parentsMobileNumber || "",
      parentsMonthly: props?.data?.parentsMonthly || "",
      currency: props?.data?.parentsCurrency?.currency || "",
      relationshipId : props?.data?.relationshipId 
      ? props?.data?.relationshipId 
      : "",
        },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      familyStatus: Yup.string(),
      familyType: Yup.string(),
      familyValues: Yup.string(),
      parentsIsdCode: Yup.string(),
      relationshipId: Yup.lazy((value, options) =>
        value || options.parent.parentNumber
          ? Yup.string().required('Please select a relationship ')
          : Yup.string()
      ),
      parentNumber: Yup.lazy((value, options) =>
        value || options.parent.relationshipId
          ? Yup.string()
              .test('valid-number', 'Please enter a valid Mobile Number', function (value) {
                const { parentsIsdCode } = this.parent;
                if (parentsIsdCode === '+91') {
                  return value.length === 10 || this.createError({ message: 'Please enter a valid Mobile Number', path: 'parentNumber' });
                } else {
                  return (value.length >= 7 && value.length <= 15) || this.createError({ message: 'Please enter a valid Mobile Number', path: 'parentNumber' });
                }
              })
              .required('Please enter Mobile Number')
          : Yup.string()
      ),
      parentsMonthly: Yup.string(),
    }),

    onSubmit: (values) => {
      setSubmitStatus(true);
      apiCallFamily(values);
      setCompleteNow(true)
      
     
    },
  });




  console.log(props?.data?.parentsIsdCode,"props?.data?.parentsIsdCode");
  useEffect(() => {
    setfamilyStatusDropDown(props?.dropDown?.familyStatusData || []);
    setfamilyTypeDropDown(props?.dropDown?.familyTypeData || []);
    setfamilyValuesDropDown(props?.dropDown?.familyValuesData || []);
    setparentsIsdCodeDropDown(props?.dropDown?.isdcode || []);
    setcurrencyDropDown(props?.dropDown?.currencyData || []);
    setfamilyRelationDropDown(dropdownData?.relationShip || [])
  }, [props?.dropDown]);


  useEffect(() => { }, [
    familyStatusDropDown,
    familyTypeDropDown,
    familyValuesDropDown,
    parentsIsdCodeDropDown,
    currencyDropDown,
    relationDropDown
  ]);

  const apiCallFamily = async (values) => {
    const request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),
      FamilyStatusId: values.familyStatus,
      familyTypeId: values.familyType,
      familyValuesId: values.familyValues,
      parentsMobileNumber: isParentNumberChanged ? values.parentNumber : "",
      parentsIsdcode: values.parentsIsdCode,
      parentsMonthly: values.parentsMonthly,
      parentsCurrency: values.currency === selectedParentsCurrenyCode?.name ? selectedParentsCurrenyCode.id : values.currency,
      relationshipId : values.relationshipId
    };

    const { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.editfamilyinfosaveUrl()}`,
      request
    );

    if (statusCode === 200) {

      setTimeout(() => {
        setClickAddFamily(false)
        setCompleteNow(true)
        props?.postData()
      }, 2000);
      setClickAddFamily(false)
      if (data.data.status === "SUCCESS") {
        setSubmitStatus(false);
        setIsParentNumberChanged(false);
        props?.callBackReload();  
        setClickAddFamily(false)
        setEditView(false);
        onClickTrack(EventName.profileFamilySaveButton, { [EventName.profileFamilySaveButton]: values });
      
      
      } else {
        setSubmitStatus(false);
        setPhoneNumberError(data?.data?.statusMessage);
      }
    } else if (statusCode === 401) {
      logout();
    }
  };

  
  const selectedFamilyStatus = useMemo(() => {
    return familyStatusDropDown?.find(option => option.id === formik.values.familyStatus) || null;
  }, [familyStatusDropDown, formik.values.familyStatus]);

  const selectedFamilyType = useMemo(() => {
    return familyTypeDropDown?.find(option => option.id === formik.values.familyType) || null;
  }, [familyTypeDropDown, formik.values.familyType]);

  const selectedFamilyValues = useMemo(() => {
    return familyValuesDropDown?.find(option => option.id === formik.values.familyValues) || null;
  }, [familyValuesDropDown, formik.values.familyValues]);

  const selectedParentsIsdCode = useMemo(() => {
    return parentsIsdCodeDropDown?.find(option => option.isdcode === formik.values.parentsIsdCode) || null;
  }, [formik.values.parentsIsdCode, parentsIsdCodeDropDown]);

  const selectedParentsCurrenyCode = useMemo(() => {
    return currencyDropDown?.find(option => option.name === formik.values.currency) || null;
  }, [formik.values.currency, currencyDropDown]);


  const selectedRelation = useMemo(()=>{
    return relationDropDown?.filter(option => option.id == formik.values.relationshipId)?.at(0)
  },[relationDropDown, formik.values.relationshipId])

  const { onClickTrack } = useAnalytics();

  const saveButtonStyle = formik.dirty ? 'bg-red-500' : 'bg-gray-500';



  return (
    <div>
      
      <form onSubmit={formik.handleSubmit}>
        <div className="grid md:grid-cols-2">
          <SelectAstroInput
            name="familyStatus"
            title="Family Status"
            value={selectedFamilyStatus}
            onChange={(selected) => {
              formik.setFieldValue("familyStatus", selected.id)
              setOnchangeFamilyData(false)
            }
            }
            error={formik.errors.familyStatus}
            defaultValue={formik.values.familyStatus}
            options={[{ id: "", name: "Select", isDisabled: true }, ...familyStatusDropDown]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menu="packastro"
          />
          <SelectAstroInput
            name="familyType"
            title="Family Type"
            value={selectedFamilyType}
            onChange={(selected) =>{ formik.setFieldValue("familyType", selected.id)
              setOnchangeFamilyData(false)
            }}
            error={formik.errors.familyType}
            defaultValue={formik.values.familyType}
            options={[{ id: "", name: "Select", isDisabled: true }, ...familyTypeDropDown]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menu="packastro"
          />
          <SelectAstroInput
            name="familyValues"
            title="Family Values"
            value={selectedFamilyValues}
            onChange={(selected) => {formik.setFieldValue("familyValues", selected.id)
              setOnchangeFamilyData(false)
            }}
            error={formik.errors.familyValues}
            defaultValue={formik.values.familyValues}
            options={[{ id: "", name: "Select", isDisabled: true }, ...familyValuesDropDown]}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            menu="packastro"
          />
          <SelectAstroInput
            name="parentsIsdCode"
            title="Parent's ISD Code"
            value={selectedParentsIsdCode}
            onChange={(selected) => {formik.setFieldValue("parentsIsdCode", selected.isdcode)
              setOnchangeFamilyData(false)

            }}
            error={formik.errors.parentsIsdCode}
            defaultValue={formik.values.parentsIsdCode}
            options={[{ id: "", name: "Select", isDisabled: true }, ...parentsIsdCodeDropDown]}
            getOptionLabel={(option) => `${option.name ? option.name : option.isdcode + " - " + option.country}`}
            getOptionValue={(option) => option.isdcode}
            menu="packastro"
          />
          <BasicEditTextView
            title="Parent's MobileNumber"
            inputID="parentNumber"
            inputName="parentNumber"
            value={formik.values.parentNumber}
            onChange={(e) => {
              if (validateNumber(e.target.value)) {
                setPhoneNumberError("");
                setIsParentNumberChanged(true);
                formik.setFieldValue("parentNumber", e.target.value);
                setOnchangeFamilyData(false)

              }
            }}
            // onKeyDown={blockInvalidCharForNumber}
            error={formik.errors.parentNumber || phoneNumberError}
            maxLength={formik.values.parentsIsdCode === "+91" ? 10 : 15}
            menu="addfamily"
            required={true}
          />
           <SelectAstroInput
              name="relationshipId"
              title="Parents Relation"
              value={selectedRelation}
              onChange={(e) => {
                formik.setFieldValue("relationshipId", e.id)
                setOnchangeFamilyData(false)

              }}
              error={formik.errors.relationshipId}
              defaultValue={formik.values.relationshipId}
              options={[{ id: "", name: "Select", isDisabled: true }, ...relationDropDown]}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              menu="packastro"
            />
         
          <div className="  mt-[2rem] ">
              <div className="items-center  md:pl-[1rem] lg:pl-[1rem] ">
                <p className=" md:text-[13px] lg:text-[14px] flex text-[#000000] font-bold  ">
                  Parent's Monthly Income { <span className="text-[#D10A11] ml-1"> *</span>}
                  {/* <span className="text-[#D10A11]">*</span> */}
                </p>
              </div>
              <div className=" md:pr-[1rem] lg:pr-[1rem]  md:pl-[1rem]">
                <p className="  ">
                  <div className="flex space-x-3 ">
                    {/* <label className=" select font-bold text-[#575556] w-[8rem]"> */}
                      <SelectCurrencyInput

                        menu="addfamily"
                        name="currency"
                        id="currency"
                        title="currency"
                        error={formik.errors.currency}
                        defaultValue={formik.values.currency}
                        value={selectedParentsCurrenyCode}
                        // onChange={formik.handleChange}
                        onChange={(selected, ev) => {
                          formik.setFieldValue("currency", selected.name)
                          setOnchangeFamilyData(false)

                        }}
                        className="  w-full md:p-[0.4rem] lg:p-2.5  border border-[#8F8F8F] text-[#575556] font-semibold md:text-[12px] lg:text-[14px] bg-white rounded-[8px]"
                      
                       
                        options={[{ id: "", name: "Select", isDisabled: true }, ... currencyDropDown]}
                        getOptionLabel={(option) => `${option.name ? option.name : option.currency }`}
                        getOptionValue={(option) => option.id}
                        
                        
                      />
                  
                    <p className=" flex items-center w-[100%] pt-[1px] RegPhone">
                      <input
                      inputID={"parentsMonthly"}
                      inputName={"parentsMonthly"}
                        type="tel"
                        onChange={(e)=>{
                          if(validateNumber(e.target.value)){
                            formik.handleChange(e)
                           

                          }
                        }}
                        id="parentsMonthly"
                        value={formik.values.parentsMonthly}
                        className=" RegPhone  w-[100%] border border-[#8F8F8F] rounded-[3px] h-[35px] p-[0.4rem] block  md:p-[0.4rem] lg:p-2.5 text-[#575556] font-semibold md:text-[12px] lg:text-[12px]"
                      />
                    </p>
                   
                    {}
                  </div>
                  {formik.errors.parentsMonthly && (
                      <p className="text-[red] text-[12px] font-bold">
                        {formik.errors.parentsMonthly}
                      </p>
                    )}
                </p>
              </div>
            </div>
        </div>
        {commonError && (
            <div className="text-red-500 font-bold text-[13px] text-center mt-4">
              {commonError}
            </div>
          )}
         <SaveCancelButton
          cancel={() => setEditView(false)}


          
          saveCallBack={(e) => {
            e.preventDefault();
            if (!formik.values.familyStatus || !formik.values.familyType || !formik.values.familyValues
               || !formik.values.parentsIsdCode
              || !formik.values.parentNumber || !formik.values.parentsMonthly || !formik.values.relationshipId
            ) {
              setCommonError("All fields are mandatory");
            } else {
              setCommonError("");
              formik.handleSubmit();
            }
            }}
          submitStatus={submitStatus}
          isShowSave={true}
           menu="astroadd"
           error={formik.errors}
           value={props?.data}
           inputvalue={formik.values}
           setOnchangeFamilyData={setOnchangeFamilyData}
           onchangefamilydata={onchangefamilydata}
          

        /> 
        
      </form>
    </div>
  );
};

export default FamilyAddComponent;
