import React from 'react'
import { Navigate } from 'react-router-dom';

function ConditionalRoute({ children, allow = true }) {
    if (!allow) {
        return <Navigate replace={true} to="/dashboard" />
    }
    return children;
}

export default ConditionalRoute;
