import React, { useContext, useEffect, useRef, useState } from "react";
import NavBar, { LiteNavBar } from "../../NavBar";
import { Navigate, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Constants, { EditProfileMenu, commonPopupButtonNames, commonPopupTitleNames } from "../../../Constants/constants";
import Pusher from "pusher-js";
import config from "../../../config";
import { useDispatch, useSelector } from "react-redux";
import { dashboardProfileData } from "../../../Store/DashboardAPI/Action";
import RouteSegments, { RouteHelper } from "../../../Routes/RouteSegment";
import { UnreadMsgCount, getChatList, getCustomerServiceConversation, getGroupChatList, groupMessageReceived, messageReceived, reactionReceived, readbyReceived } from "../../../Store/Chats/Action";
import NotificationReceivedSound from "../../../sounds/notification-received.mp3";
import { getGroupAuthId } from "../utils";
import { LoginContext } from "../../../Contexts/LoginContext";
import EndPoints from "../../../Constants/EndPoints";
import { GET, POST } from "../../../Services/api_services";
import { CommonDangerPopup, CommonNewPopup, CommonQualityPopup ,KalyanRewardsPopup} from "../../CommonPopup";
import { Update_Notification_Status } from "../../../Helpers/api_helpers";
// import  {AddParentsNumber,AddPhoto,Matches,StartMessage,NewMatches,MsgSeenNotReply,PartnerPreference,ReadAndReply,UnreadMessage} from '../../../Components/Kalyanlite/assets/InitialPopupImages';
import * as Icons from '../../../Components/Kalyanlite/assets/InitialPopupImages';
import ParentsPhonePopupLite from "../../DashboardComponents/ParentsPhonePopupLite";
import { loginUser } from "../../../Store/Login/Action";
import FamilyCodePopup from "../Popup/FamilyCodePopup";
import NotificationIcon from "../../Kalyanlite/assets/Images/Group 653.svg"
import { getToken, onMessage } from 'firebase/messaging';
import { messaging } from "../../../../src/firebase/firebaseConfig";
import { createPortal } from "react-dom";
import { getActivityTotalPoints, pointAwarded } from "../../../Store/KalyanRewards/Action";
import * as Images from "../../../Components/Kalyanlite/assets/images"
import { toast } from "react-toastify";

let popUpImageSrc;

function LiteLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [popupOpen, setPopupOpen] = useState()
  const [popUpContent, setPopupContent] = useState('')
  const [notificationId, setNotificationId] = useState()
  const [buttonName, setButtonName] = useState('');
  const [imageSrc,setImageSrc] = useState()
  const [title,setTitle] = useState()
  const [showParentNumPopup, setShowParentNumPopup] = useState(false);
  const { loginDetail, logout } = useContext(LoginContext);
  const initialref = useRef(false)
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showFamilyCodePopup,setShowFamilyCodePopup] = useState(false)
  const [showTrailPackPopup, setShowTrailPackPopup] = useState(false)
  const [showNotificationPermission, setShowNotificationPermission] = useState(false)
  const [showRewardsPopup, setShowRewardsPopup] = useState(false)


  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

  const { ProfileData } = useSelector(state => ({
    ProfileData: state.DashBoardAPI?.ProfileData?.data
  }));



  useEffect(() => {

    console.log("pusher count");

    const audio = new Audio(NotificationReceivedSound);
    // const channelName = `private-App.Models.User.1`;
    const channelName = `private-App.Models.User.${localStorage.getItem(
      Constants.loginLocalStorageKeys.chatLoginId,
    )}`;
    const customerCareChannelName = `private-user.${localStorage.getItem
      (Constants.loginLocalStorageKeys.chatLoginId,
    )}`;
    // console.log("channelName", channelName);

    const pusher2 = new Pusher("kalyan123", {
      wsHost: config.messengerUrl,
      wsPort: "443",
      wssPort: "443",
      cluster: "ap2",
      authEndpoint: `${config.api.chat_api}/broadcasting/auth`,
      enabledTransports: ["ws", "wss"],
      // enabledTransports: ["ws"],
      encrypted: false,
      // disableStats: true,
      forceTLS: false,
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            Constants.loginLocalStorageKeys.chatLogin,
          )}`,
        },
      },
    });
    const channel = pusher2.subscribe(channelName);
    channel.bind(
      "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      (data) => {
        console.log("SOCKET DATA New", data);
        console.log(data.message, "data.message");

        if (data.type === "App\\Notifications\\GroupChatMessage") {

          const sendId = Array.isArray(data?.message) ? data?.message[0].sender_id : data?.message?.sender_id

          console.log("sendId", sendId, getGroupAuthId(), Array.isArray(data?.message));

          if (!(sendId == getGroupAuthId())) {
            dispatch(groupMessageReceived(data.message));
            audio.play();
          }
          dispatch(getGroupChatList({ profileId: localStorage.getItem(Constants.loginLocalStorageKeys.profileId) }))
        } else if (data.type === "App\\Notifications\\ChatReaction") {

          if (!(data?.data?.reacted_by_id == getGroupAuthId())) {
            dispatch(reactionReceived(data))
          }

        } else if (data?.type === "App\\Notifications\\ChatReadStatus") {
          dispatch(readbyReceived(data.data))
        } else if (data?.type === "App\\Notifications\\SendPointsToUserEvent") {
          dispatch(pointAwarded())
        }
        else {
          dispatch(messageReceived(data.message));
          dispatch(getChatList({
            page_size: Constants.matchRowCount,
            page: 1,
          }))

          if (!data.is_muted) {
            audio.play();
          }
        }

      },
    );


    channel.bind("App\\Notifications\\ChatReaction", (data) => {
      console.log("SOCKET DATA ChatReaction", data);
    })


    // Customer care channel subscription
    const customerCareChannel = pusher2.subscribe(customerCareChannelName);
    customerCareChannel.bind("pusher:subscription_succeeded", () => {
      console.log(`Subscribed to customer care channel: ${customerCareChannelName}`);
    });
  
    customerCareChannel.bind("App\\Events\\SendMessageToCustomerEvent", (data) => {
      console.log("agent SOCKET DATA", data);
      
        dispatch(UnreadMsgCount({
          sender_id: localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
        }));
        audio.play().catch(e => console.error("Audio play error:", e));
        dispatch(
          getCustomerServiceConversation({
            sender_id: localStorage.getItem(Constants.loginLocalStorageKeys.loginId)
          }),
        );
    });

    const showActivityFeedback = (data) => {
        dispatch(pointAwarded(data))
    }

    customerCareChannel.bind("App\\Events\\SendPointsToUserEvent", (data) => {
      console.log("SOCKET DATA", data);
      if (data?.activityId == 1) {
        showActivityFeedback(data)
      }
    });

    customerCareChannel.bind("App\\Events\\UserPointEvent", (data) => {
      console.log("SOCKET DATA", data);
      if (data?.activityId > 1) {
        showActivityFeedback(data)
        // dispatch(pointAwarded(data))
        // toast(
        //   <div className='flex items-center'>
        //     <img src={Images.KalyanCoin} className='w-10 h-10 mr-4 kalyan-coin' alt='' />
        //     {data?.messageData}
        //   </div>,
        //   {
        //     hideProgressBar: true,
        //     // onClose: () => {
        //     //     dispatch(hideCelebration())
        //     // },
        //     onClick: () => {
        //       navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[13].urlPath))
        //     }
        //   }
        // )
      }
    });


    return () => {
      pusher2.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(dashboardProfileData({}, logout));
    // dispatch(getActivityTotalPoints())
    if(localStorage.getItem("checkbox")){
      if((localStorage.getItem("LOGIN_FROM") == "/new/matches" || localStorage.getItem("LOGIN_FROM") == "/")){
        if (sessionStorage.getItem("functionCalled") == null) {
    
          let name = localStorage.getItem(Constants.loginLocalStorageKeys.loginName) || localStorage.getItem(Constants.loginLocalStorageKeys.loginPhone);
          let password = localStorage.getItem("pw");
    
          dispatch(loginUser({ name, password }, navigate));
    
          sessionStorage.setItem("functionCalled", "true");
        }
      }
    }
  }, []);


  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await window.Notification.requestPermission();
  
    if (permission === "granted") {
      window.navigator.serviceWorker.register(process.env.PUBLIC_URL + '/firebase-messaging-sw.js', { scope: "/firebase-cloud-messaging-push-scope" }).then(async () => {
        const token = await getToken(messaging, {
          vapidKey: "BOkYKLBpw9iIkK7p3rSiRiOUvvaTj77ZBIueLdsSRy9BeiragVXvww6zR1IawhNGDhJjsxB0JolYTzBwqapVGyI",
        });
  
        localStorage.setItem(Constants.loginLocalStorageKeys.fcmToken, token)
        console.log("Token generated : ", token);
      })
      //We can send token to server
    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
  }

  // useEffect(() => {

  //   if (window.Notification.permission !== "granted") {
  //     setShowNotificationPermission(true)
  //   }else{
  //     requestPermission()
  //   }

  // }, []);



  const closePermission = () => {
    requestPermission()
    setShowNotificationPermission(false)
  }


  const fetchGroupData = async () => {
    const profileId = ProfileData?.profileId;
    if (!profileId) {
      // Handle the case when profileId is not available
      console.error('ProfileId not available');
      return;
    }

    const { statusCode, data } = await GET(
      `${config.api.API_URL}${EndPoints.getfamilyUrl(profileId)}`
    );


    if (statusCode === 200) {
      if (data.status === 'Success') {
        console.log(data.data.groupMembers, 'groupMembers');
        if (data.data.groupMembers?.length > 0) {
          const relation = data.data.groupMembers?.filter(member => member?.parentsMobileNumber == localStorage.getItem(Constants.loginLocalStorageKeys.familyLoginMobileNumber))
          if (relation[0]?.relationshipName) {
            localStorage.setItem(Constants.loginLocalStorageKeys.familyLoginRelation, relation[0]?.relationshipName)
          }
        }
      }
    }
  }

  useEffect(() => {

    if (ProfileData) {
      fetchGroupData()
    localStorage.setItem(Constants.loginLocalStorageKeys.profileId, ProfileData?.profileId)
    localStorage.setItem(Constants.loginLocalStorageKeys.loginUserStatus, ProfileData?.status)
    localStorage.setItem(Constants.loginLocalStorageKeys.loginEmail, ProfileData?.usermailid ?? "")
    localStorage.setItem(Constants.loginLocalStorageKeys.loginUserName, ProfileData?.username)
    localStorage.setItem(Constants.loginLocalStorageKeys.loginPhone, ProfileData?.userphoneno)
    localStorage.setItem(Constants.loginLocalStorageKeys.loginCountryCode, ProfileData?.usercountrycode)
    localStorage.setItem(Constants.loginLocalStorageKeys.loginPhone, ProfileData?.userphoneno)
   }
  }, [ProfileData])


  const isV3User = localStorage.getItem("isV3User")




  useEffect(()=>{
    if (initialref.current) return

    // if (ProfileData?.popup) {
    //   ProfileData.popup = [
    //     {
    //       "showpopup": "AddParentsNumber",
    //       "notificationId": 11428535,
    //       "content": "Dear (Profile Name), Please add your parents' number to complete your profile. Click here to add a parent's phone number."
    //     }


    //   ]
    // }

    if(ProfileData?.popup?.length > 0){
      // ProfileData.popup[0] = {
      //   "showpopup": "ReadAndReply",
      //   "notificationId": 11428535,
      //   "content": "You have <7> unread messages. Click here to read and reply now."
      // }
      if(ProfileData?.popup[0]?.showpopup == 'upgrade' || ProfileData?.popup[0]?.showpopup == 'photo' || ProfileData?.popup[0]?.showpopup == 'paymentpagevisited'){
        setPopupOpen(false)
      }
      else{
        if (ProfileData?.popup[0]?.showpopup) {     
          initialref.current = true     
        setPopupContent(ProfileData?.popup[0]?.content)
        setNotificationId(ProfileData?.popup[0]?.notificationId)
        const src= Icons[ProfileData?.popup[0]?.showpopup]
        setImageSrc(src);
        setButtonName(commonPopupButtonNames[ProfileData?.popup[0]?.showpopup])
        setTitle(commonPopupTitleNames[ProfileData?.popup[0]?.showpopup])
        if(isCommunitySite && ProfileData?.popup[0]?.showpopup == 'DownloadApp'){
          setPopupOpen(false)
        }else{
          setPopupOpen(true)
        }
        
      }
    }
    }

  },[ProfileData])

  //check if family code is not setted, then show family code popup
  useEffect(()=>{
    if(ProfileData?.familycode == "Y"){
      setShowFamilyCodePopup(true)
    }
  },[ProfileData])

  //show kalyan rewards popup after 3min
  useEffect(() => {
    if (ProfileData?.showactivitypopup == "Y") {
      setTimeout(() => {
        setShowRewardsPopup(true)
      }, Constants.kalyanRewardsPopupWaitTime);
    }
  }, [ProfileData])


  const onPopupClose = async(data)=>{    
    setPopupOpen(false)    
    const request  = {      
        "email" : localStorage.getItem(Constants.loginLocalStorageKeys.loginName),
        "userId" : localStorage.getItem(Constants.loginLocalStorageKeys.loginId),
        "isClicked" : data,
        "notificationId" : notificationId            
    }
    Update_Notification_Status(request);

    if (!data) {
      sessionStorage.removeItem(Constants.loginLocalStorageKeys.notificationId,notificationId)
    }
  
  }
  const redirectTo = ({ path, notiId }) => {
    if (path == 'DownloadApp') {
      window.open(RouteSegments.staticLinkForAppDownload.android)
      return;
    }
    sessionStorage.setItem(Constants.loginLocalStorageKeys.notificationId, notiId)
    if (path == 'AddPhoto') {
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[2].urlPath), {
        state: { isFromInitialPopup: true },
       });
    }
    else if (path == 'PartnerPreference') {
      navigate(RouteHelper.getEditProfileUrl(EditProfileMenu[1].urlPath), {
        state: { isFromInitialPopup: true },
       });
    } else if (path == 'Matches') {

    } else if (path == "StartMessage") {
      navigate(RouteSegments.MATCH_CONVERSATIONS_LITE, { state: { from: "newConversations", isFromChat: true } })
    } else if (path == 'NewMatches') {
      partnerPrefSearchApi()
    } else if (path == "AddParentsNumber") {
      setPopupOpen(false)
      setShowParentNumPopup(true)
    } else if (path == "ReadAndReply"
      || path == "UnreadMessage"
      || path == "MsgSeenNotReply") {
      navigate(RouteSegments.CHAT_LITE, {
        state: { isFromInitialPopup: true },
      });
    }
   else if (path=="TrialPack") {
    console.log(path,"path");
      setShowTrailPackPopup(true)
      navigate(RouteSegments.MATCH_LITE, { replace: true })
    }


  }


  useEffect(() => {
    //CLC
    const path = searchParams.get("path")
    const notiId = searchParams.get("notificationId")
    const loginUrl = sessionStorage.getItem(Constants.loginLocalStorageKeys.loginUrl);


    if (path && notiId) {
      searchParams.delete("path")
      searchParams.delete("notificationId")
      searchParams.delete("isNew")
      sessionStorage.setItem(Constants.loginLocalStorageKeys.notificationPath, path)
      setSearchParams(searchParams, { replace: true })
      onPopupClose(true);
      redirectTo({
        path,
        notiId
      })
    }else if(loginUrl?.includes("pp_matches")){
      partnerPrefSearchApi()
    }
  }, [searchParams])



  const partnerPrefSearchApi = async () => {
    let request = {
      email: loginDetail()[0] ?? null,
      userId: loginDetail()[1],
    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.partnerpreferencesearch()}`,
      request
    );

    if (statusCode === 200) {

      const locState = {
        isFrom: "basicAdvanceSearch",
        menu: "savepreferences",
        searchTerms: data?.data,
        ppedited: false,
        isBasicSearch: false,
        loading: true
      }
      sessionStorage.setItem(Constants.loginLocalStorageKeys.filterState, JSON.stringify(locState))
      navigate(RouteSegments.MATCH_LITE, {
        state: locState,
        replace: true,
      });


    } else if (statusCode === 401) {
      logout();
    }
  }


  if (!isV3User) {
    return <Navigate to={RouteSegments.DASHBOARD} replace={true} />
  }


  const onPopupBtnClick = () => {
    onPopupClose(true);
    redirectTo({
      path: ProfileData?.popup[0]?.showpopup,
      notiId: ProfileData?.popup[0]?.notificationId
    });
  }

  const onRewardsPopupClose = () => {
    setShowRewardsPopup(false)
  }


  return (
    <div>

      {showRewardsPopup && createPortal(
        <KalyanRewardsPopup close={onRewardsPopupClose} />,
        document.body
      )}
      {showFamilyCodePopup &&
      <FamilyCodePopup close={()=>setShowFamilyCodePopup(false)} />
      }
      {ProfileData?.popup[0]?.showpopup =="TrialPack"?<></>:popupOpen &&
        <CommonNewPopup closePopup={() => onPopupClose(false)} message={popUpContent} buttonClick={onPopupBtnClick} buttonName={buttonName} title={title} image={imageSrc} />
      }

{showNotificationPermission &&
        <CommonDangerPopup
          icon={NotificationIcon}
          close={closePermission}
          content={"Please grant notification permission for better experience"}
        />
      }

       {showTrailPackPopup &&
    
    <CommonQualityPopup OnClose={()=>setShowTrailPackPopup(false)}/>}
      {showParentNumPopup && (<ParentsPhonePopupLite
        close={() => setShowParentNumPopup(false)}
        title={"Add Parent's Phone Number"}
        notificationId={ProfileData?.popup[0]?.showpopup == "AddParentsNumber"}
        callBackApply={() => { /* Add your callback logic here if needed */ }}
        parentsIsdCode={ProfileData?.parentsIsdCode}
        parentsMobileNumber={ProfileData?.parentsMobileNumber}
      />
      )}
      
      <div className='font-Poppins-Regular bg-[#eae6df] h-full md:h-screen w-screen overflow-y-hidden overflow-hidden fixed'>
        <div className='hidden md:block w-full h-[8.7rem] md:h-0 lg:h-[9rem] bg-[#d10a11] absolute top-0' />
        <div className='flex md:items-center md:justify-center h-full w-full overflow-x-hidden overflow-y-hidden'>
          <div className='overflow-hidden z-[1]  flex flex-col items-center justify-center'>

            <div className='w-[100vw] lg:w-[86vw]'>
              <LiteNavBar isFrom='conversation' />
            </div>
            <div className=' overflow-hidden  overflow-x-hidden  w-[100vw] h-full grow lg:w-[86vw] md:h-[93vh] xl:h-[82vh] 2xl:h-[85vh]  lg:h-[80vh]'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LiteLayout;
